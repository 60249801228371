import React from "react";
import _ from "lodash";
import ModalWrapper from "../common/ModalWrapper";
import { generateUserMasterKey, _decrypt } from "./VaultHelper";
import UserMasterPasswordForm from "./UserMasterPasswordForm";
import { VaultAPI } from "apis";
import LoadingUI from "../common/LoadingUI";
import ClickToCopyWrapper from "../common/ClickToCopyWrapper";
import ClickToCopyButton from "../common/ClickToCopyButton";
import PasswordEyeInput from "../common/PasswordEyeInput";
import { FiExternalLink } from "react-icons/fi";
import ReactTooltip from "react-tooltip";

import { thClass, tdClass } from "helpers/StyleClass";

// ex. https://admanager.google.com/57931037
const GAM_CONSOLE_URL = "https://admanager.google.com";

class VaultAccountViewByNetworkModal extends React.PureComponent {
  constructor(props) {
    super(props);

    // Important!
    // 1. enter user's password (not master password)
    // 2. get account info
    this.state = {
      list: [],
      account: null,
      isLoading: false,
      errorMsg: null,

      mpErrorMsg: null,
      isVaultOpen: false,
    };

    this.handleSubmitUserMP = this.handleSubmitUserMP.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.getAccountByNetwork = this.getAccountByNetwork.bind(this);
    this.handleGoToGAMConsole = this.handleGoToGAMConsole.bind(this);
  }

  handleCloseModal() {
    this.setState({
      list: [],
      account: null,
      isLoading: false,
      errorMsg: null,

      mpErrorMsg: null,
      isVaultOpen: false,
    });
    this.props.handleClose();
  }

  async getAccountByNetwork(enKey) {
    this.setState({ isLoading: true });
    try {
      const { code, networkId } = this.props.network;
      const account = code
        ? await VaultAPI.getAccountByNetworkCode({ networkCode: code })
        : await VaultAPI.getAccountByNetworkId({ networkId });

      // account.password = _decrypt(enKey, account.secret);
      // account.recoveryEmail = _decrypt(enKey, account.recovery_email);

      const list = _.map(account, (item) => {
        return {
          ...item,
          password: _decrypt(enKey, item.secret),
          recoveryEmail: _decrypt(enKey, item.recovery_email),
        };
      });

      this.setState({ list, errorMsg: null });
    } catch (err) {
      console.log("Failed to get account by network code", err);

      if (err.toString() === `{"error":"Not found."}`) {
        this.setState({ errorMsg: "Has no vault account" });
      } else {
        this.setState({ errorMsg: err.toString() });
      }
    }

    this.setState({ isLoading: false });
  }

  async handleSubmitUserMP(userMasterPassword) {
    this.setState({ isLoading: true });
    try {
      const user = await VaultAPI.getCurrentUser();

      // Use user password to extract gam account secret
      const userMkey = await generateUserMasterKey(userMasterPassword);
      const decryptSecret = _decrypt(userMkey, user.protected_secret);
      const enKey = Buffer.from(decryptSecret, "hex");

      this.setState({ mpErrorMsg: null, isVaultOpen: true });

      this.getAccountByNetwork(enKey);
    } catch (err) {
      console.log("wrong password", err);
      this.setState({ mpErrorMsg: "Wrong Password" });
    }

    this.setState({ isLoading: false });
  }

  handleGoToGAMConsole(networkCode) {
    window.open(`${GAM_CONSOLE_URL}/${networkCode}`);
  }

  parsePurpose({ for_gam_api, for_console_proxy }) {
    if (for_gam_api && for_gam_api === 1)
      return <div className="font-semibold text-green-600">For GAM API</div>;

    if (for_console_proxy && for_console_proxy === 1)
      return (
        <div className="font-semibold text-gray-600">For Console Proxy</div>
      );

    return "";
  }

  render() {
    const { isOpenModal, network } = this.props;
    const { isLoading, account, list, errorMsg, mpErrorMsg, isVaultOpen } =
      this.state;

    const gamLink = `${GAM_CONSOLE_URL}/${network.code}`;

    return (
      <ModalWrapper
        isOpen={isOpenModal}
        showCloseFooter={true}
        handleClose={this.handleCloseModal}
        width="60%"
      >
        <div className="mb-4">
          <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
            <div className="mt-4 flex">
              {network.networkId} - {network.name}
              <div className="flex items-center">
                <div className="ml-2 text-xs text-blue-700">
                  <ClickToCopyWrapper
                    copyText={gamLink}
                    tooltipMessage="Copy GAM console link"
                  ></ClickToCopyWrapper>
                </div>

                <div>
                  <button
                    type="button"
                    className="rounded hover:shadow flex items-center bg-gray-100 px-1 text-xs text-blue-700"
                    onClick={() => this.handleGoToGAMConsole(network.code)}
                    data-tip
                    data-for={`${network.code}-external-link`}
                  >
                    <FiExternalLink></FiExternalLink>
                  </button>
                  <ReactTooltip
                    id={`${network.code}-external-link`}
                    type="dark"
                    effect="solid"
                  >
                    Open this network's GAM console
                  </ReactTooltip>
                </div>
              </div>
            </div>
          </div>

          <div className="text-red-700">
            {errorMsg && (
              <div>
                {errorMsg}

                <div>
                  Go to{" "}
                  <button
                    className="underline"
                    type="button"
                    onClick={() => {
                      window.open(`${window.location.origin}/#/vault-accounts`);
                    }}
                  >
                    Vault Account Settings
                  </button>{" "}
                  to bind account.
                </div>
              </div>
            )}
          </div>

          {isVaultOpen ? (
            <div>
              {isLoading ? (
                <LoadingUI></LoadingUI>
              ) : (
                <table className="w-full">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className={`${thClass} w-1/6`}>Email</th>
                      <th className={thClass}>Password</th>
                      <th className={`${thClass} w-1/6`}>Recovery Email</th>
                      <th className={`${thClass} w-1/6`}>Purpose</th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-100 text-sm">
                    {list.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td className={tdClass}>
                            <div className="flex items-center justify-between gap-2">
                              {item.email}
                              <ClickToCopyButton
                                copyText={item.email}
                              ></ClickToCopyButton>
                            </div>
                          </td>
                          <td className={tdClass}>
                            <div className="flex items-center gap-2">
                              <div className="w-full">
                                <PasswordEyeInput
                                  id="i2w-vault-account-password"
                                  defaultValue={item.password}
                                  disabled={true}
                                ></PasswordEyeInput>
                              </div>

                              <ClickToCopyButton
                                copyText={item.password}
                              ></ClickToCopyButton>
                            </div>
                          </td>
                          <td className={tdClass}>
                            <div className="flex items-center justify-between gap-2">
                              {item.recoveryEmail}
                              <ClickToCopyButton
                                copyText={item.recoveryEmail}
                              ></ClickToCopyButton>
                            </div>
                          </td>
                          <td className={tdClass}>{this.parsePurpose(item)}</td>
                        </tr>
                      );
                    })}
                    {list.length === 0 && (
                      <tr>
                        <td
                          className={`${tdClass} text-center text-gray-600`}
                          colSpan="4"
                        >
                          No account found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            <UserMasterPasswordForm
              handleSubmitUserMP={this.handleSubmitUserMP}
              mpErrorMsg={mpErrorMsg}
              isLoading={isLoading}
            ></UserMasterPasswordForm>
          )}
        </div>
      </ModalWrapper>
    );
  }
}

export default VaultAccountViewByNetworkModal;

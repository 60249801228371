import React from "react";
import Select from "react-select";
import _ from "lodash";
import { OnboardingAPI } from "apis";

import ModalWrapper from "components/common/ModalWrapper";
import CountrySelector from "components/common/CountrySelector";
import {
  buttonModalConfirmClass,
  buttonModalConfirmDisable,
  buttonModalCancelClass,
  inputClass,
} from "helpers/StyleClass.js";

import { MESSAGE } from "constants/Message";

const PROFILE = {
  AD_MANAGER: "GAM Small Business - Free",
  INVOICED_AD_MANAGER: "GAM Small Business - Invoiced",
  AD_MANAGER_360_BASIC: "GAM 360 - Basic",
  AD_MANAGER_360_ALL_ENTITY: "GAM 360 - All Entity",
  AD_MANAGER_360_CUSTOM_TEAM: "GAM 360 - Custom Team Access",
};

const PRODUCT = {
  DT: "v1.0",
  CSTV2: "v2.0 with Codeless Explorer",
  CSTV2NP: "v2.0 without Codeless Explorer",
};

class OnboardNetworkModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: null,

      accountId: props.accountId,
      networkList: [],
      selectedNetwork: null,
      selectedProfile: null,
      selectedTeamId: null,
      selectedCountry: null,
      selectedProduct: null,
    };

    this.handleNetworkChange = this.handleNetworkChange.bind(this);
    this.handleTeamIdInputChange = this.handleTeamIdInputChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  async componentDidMount() {
    const { accountId } = this.state;

    const result = await OnboardingAPI.getOnboardNetworkCandidates({
      accountId,
    });

    if (result) {
      this.setState({
        networkList: _.map(result, (n) => {
          return {
            value: n.code,
            label: `${n.code} ${n.name}`,
          };
        }),
      });
    }
  }

  handleNetworkChange(selected) {
    this.setState({
      selectedNetwork: selected,
      selectedProfile: _.keys(PROFILE)[0], // default basic gam
      selectedProduct: _.keys(PRODUCT)[1], // default cstv2
    });
  }

  handleCountryChange(selected) {
    this.setState({
      selectedCountry: selected,
    });
  }

  handleTeamIdInputChange(e) {
    this.setState({
      selectedTeamId: e.target.value,
    });
  }

  async handleConfirm() {
    const {
      selectedNetwork,
      selectedProfile,
      selectedCountry,
      selectedProduct,
      selectedTeamId,
      accountId,
    } = this.state;

    const [code, name] = selectedNetwork.label.split(" ");

    const params = {
      accountId,
      code,
      name,
      teamId:
        selectedProfile === "AD_MANAGER_360_CUSTOM_TEAM"
          ? selectedTeamId
          : undefined,
      geoCountryId: _.parseInt(selectedCountry),
      product: selectedProduct,
      gamNetworkType: selectedProfile,
    };

    this.props.onLoading(true);

    try {
      const result = await OnboardingAPI.onboardNetwork(params);
      if (result) {
        this.props.onFinished({
          accountId,
          network: result,
        });
        this.setState({ msg: ["Network onboard success", MESSAGE.SUCCESS] });
      }
    } catch (error) {
      this.setState({ msg: [error, MESSAGE.ERROR] });
    }

    this.props.onLoading(false);
  }

  handleClose() {
    this.props.onClose();
  }

  render() {
    const {
      msg,
      networkList,
      selectedNetwork,
      selectedProfile,
      selectedCountry,
      selectedProduct,
      selectedTeamId,
    } = this.state;

    const isDisabled =
      !selectedNetwork ||
      !selectedProfile ||
      !selectedCountry ||
      !selectedProduct ||
      (selectedProfile === "AD_MANAGER_360_CUSTOM_TEAM" && !selectedTeamId);

    return (
      <ModalWrapper
        isOpen={true}
        showCloseFooter={false}
        customOverlayStyle={{ zIndex: 100 }}
        width="30%"
        height="80%"
      >
        <>
          <div className="mb-2 text-lg font-semibold">Onboard Network</div>

          <div
            className="flex flex-col"
            style={{ height: "calc(100% - 2.5rem)" }}
          >
            <div className="border-b py-2">
              <label>1. Select Network</label>
              <Select
                onChange={this.handleNetworkChange}
                options={networkList}
              />
            </div>
            <div className="border-b flex flex-col gap-2 py-4">
              <label>2. Select GAM Profile Setting:</label>
              {_.keys(PROFILE).map((key) => (
                <label
                  htmlFor={`radio_${key}`}
                  className={`${
                    !selectedNetwork ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  key={key}
                >
                  <input
                    id={`radio_${key}`}
                    type="radio"
                    name="profile_radio"
                    value={key}
                    className={`mr-2 ${
                      !selectedNetwork ? "cursor-not-allowed" : ""
                    }`}
                    disabled={!selectedNetwork}
                    checked={selectedProfile === key}
                    onChange={() => this.setState({ selectedProfile: key })}
                  />
                  {PROFILE[key]}
                </label>
              ))}
              {selectedProfile === "AD_MANAGER_360_CUSTOM_TEAM" && (
                <input
                  type="text"
                  className={inputClass}
                  placeholder="Type in your Team ID..."
                  onChange={this.handleTeamIdInputChange}
                ></input>
              )}
            </div>

            <div className="border-b py-4">
              <label>3. Select Payment Country:</label>
              <CountrySelector
                selectedItems={selectedCountry}
                handleChanged={this.handleCountryChange}
                isMulti={false}
                isReadonly={!selectedNetwork}
              ></CountrySelector>
            </div>

            <div className="flex flex-col gap-2 py-4">
              <label>4. Select Product Version:</label>
              {_.keys(PRODUCT).map((key) => (
                <label
                  htmlFor={`radio_${key}`}
                  className={`${
                    !selectedNetwork ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  key={key}
                >
                  <input
                    id={`radio_${key}`}
                    type="radio"
                    name="product_radio"
                    value={key}
                    className={`mr-2 ${
                      !selectedNetwork ? "cursor-not-allowed" : ""
                    }`}
                    disabled={!selectedNetwork}
                    checked={selectedProduct === key}
                    onChange={() => this.setState({ selectedProduct: key })}
                  />
                  {PRODUCT[key]}
                </label>
              ))}
            </div>

            <div className="mt-auto">
              {msg && (
                <div
                  className={`mb-1 mt-auto ${
                    msg[1] === MESSAGE.ERROR ? "text-red-500" : "text-green-500"
                  }`}
                >
                  {msg[0]}
                </div>
              )}
              <div className="flex items-center justify-end gap-2">
                {msg && msg[1] === MESSAGE.SUCCESS ? (
                  <>
                    <button
                      type="button"
                      className={buttonModalConfirmClass}
                      onClick={this.handleClose}
                    >
                      Close
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className={buttonModalCancelClass}
                      onClick={this.handleClose}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className={
                        isDisabled
                          ? buttonModalConfirmDisable
                          : buttonModalConfirmClass
                      }
                      onClick={this.handleConfirm}
                      disabled={isDisabled}
                    >
                      Add Network
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      </ModalWrapper>
    );
  }
}

export default OnboardNetworkModal;

import React from "react";
import _ from "lodash";

import { NetworkAPI } from "apis";
import NetworksTable from "./NetworksTable";
import ItemsFilter from "../common/ItemsFilter";

import { networkStatus } from "constants/NetworkStatus";
import HeaderTabs from "components/common/HeaderTabs";

class Networks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInput: "",
      selectedFilterType: "All",
      filters: [],
      items: [],
      filteredItems: [],
      isLoading: false,
      isSearching: false,
      errMsg: "",
    };

    this.executeSearch = this.executeSearch.bind(this);

    this.handleFilterTypeChange = this.handleFilterTypeChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleUpdateNetworkStatus = this.handleUpdateNetworkStatus.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      let items = await NetworkAPI.getNetworks();
      // console.log(items);

      items = _.orderBy(items, ["networkId"], ["desc"]);

      const filters = ["All", ..._.keys(networkStatus)].map((status) => {
        return {
          label: _.startCase(_.toLower(status)),
          value: status,
          count:
            status === "All"
              ? items.length
              : _.filter(items, (item) => item.status.includes(status)).length,
        };
      });

      this.setState({
        filters,
        items,
        filteredItems: items,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  handleSearch(inputValue) {
    this.setState({ searchInput: inputValue }, this.executeSearch);
  }

  handleFilterTypeChange(filterType) {
    this.setState(
      {
        selectedFilterType: filterType,
      },
      this.executeSearch
    );
  }

  handleUpdateNetworkStatus({ status, networkId }) {
    const { items } = this.state;

    const updatedItems = items.map((item) => {
      if (item.networkId === networkId) {
        return {
          ...item,
          status,
        };
      }

      return item;
    });

    this.setState(
      {
        items: updatedItems,
      },
      this.executeSearch
    );
  }

  executeSearch() {
    const { searchInput, selectedFilterType, items } = this.state;

    const filterTypeItems =
      selectedFilterType === "All"
        ? items
        : items.filter((item) => item.status.includes(selectedFilterType));

    const filterKeys = ["networkId", "name"];

    let filteredItems = _.filter(filterTypeItems, (item) => {
      let isMatch = false;

      _.forEach(filterKeys, (fKey) => {
        const value = _.get(item, fKey);

        if (value && _.toLower(value).includes(_.toLower(searchInput))) {
          isMatch = true;
        }
      });

      return isMatch;
    });

    filteredItems = _.orderBy(filteredItems, ["networkId"], ["desc"]);

    if (searchInput === "") {
      this.setState({
        isSearching: false,
        filteredItems,
      });
    } else {
      this.setState({
        isSearching: true,
        filteredItems,
      });
    }
  }

  render() {
    const {
      filteredItems,
      errMsg,
      isLoading,
      isSearching,
      filters,
      selectedFilterType,
    } = this.state;

    const tabs = filters.map((filter) => ({
      key: filter.value,
      title: `${filter.label} (${filter.count})`,
    }));

    const filter = _.find(filters, { value: selectedFilterType });
    const filterCount = filter ? filter.count : 0;

    return (
      <div>
        <div className="bg-white px-12">
          <div className="mb-4 flex justify-between pt-8 ">
            <div className="text-4xl font-extrabold text-gray-900">
              Networks
            </div>
          </div>
        </div>

        <div className="min-h-screen bg-gray-200 px-12 py-4">
          {isLoading ? (
            "Loading..."
          ) : (
            <div>
              {errMsg ? (
                <div>{errMsg}</div>
              ) : (
                <>
                  <div className="mb-4">
                    <div className="-mt-1 mb-3">
                      <HeaderTabs
                        tabs={tabs}
                        selectedTabKey={selectedFilterType}
                        handleTabChange={this.handleFilterTypeChange}
                      ></HeaderTabs>
                    </div>
                    <ItemsFilter
                      handleSearch={this.handleSearch}
                      placeholder={"Search by network id, name"}
                    ></ItemsFilter>
                    <div className="mx-1 my-1 text-sm text-gray-700">
                      {isSearching
                        ? `Found ${filteredItems.length} out of `
                        : ""}
                      {filterCount} networks
                    </div>
                  </div>
                  <NetworksTable
                    items={filteredItems}
                    updateNetworkStatus={this.handleUpdateNetworkStatus}
                  ></NetworksTable>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Networks;

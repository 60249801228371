import React from "react";
import ModalWrapper from "components/common/ModalWrapper";
import { MESSAGE } from "constants/Message";

import {
  buttonModalCancelClass,
  buttonModalConfirmClass,
} from "helpers/StyleClass";

class PromptModal extends React.PureComponent {
  render() {
    const {
      isOpenModal,
      handleConfirm,
      handleCancel,
      handleClose,
      header,
      children,
      msg,
      isLoading = false,
      redo = true,
      width = "30%",
    } = this.props;

    return (
      <ModalWrapper
        isOpen={isOpenModal}
        showCloseFooter={false}
        customOverlayStyle={{ zIndex: 100 }}
        width={width}
      >
        <div>
          <div className="mb-2 text-xl font-bold text-gray-700">
            {header || "Caution"}
          </div>

          <div
            className="mb-8 overflow-y-auto text-gray-600"
            style={{ maxHeight: "600px" }}
          >
            {children}
          </div>

          {msg && (
            <div
              className={`mb-1 overflow-y-auto ${
                msg[1] === MESSAGE.ERROR ? "text-red-500" : "text-green-500"
              }`}
              style={{ maxHeight: "100px" }}
            >
              {msg[0]}
            </div>
          )}
          <div className="relative flex h-10 w-full items-end justify-end gap-2">
            {isLoading ? (
              <div className="absolute bottom-0 right-0">
                <div className="loader"></div>
              </div>
            ) : (
              <>
                {msg &&
                (msg[1] === MESSAGE.SUCCESS ||
                  (!redo && msg[1] === MESSAGE.ERROR)) ? (
                  <>
                    <button
                      type="button"
                      className={buttonModalConfirmClass}
                      onClick={handleClose ? handleClose : handleCancel}
                    >
                      Close
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className={buttonModalCancelClass}
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className={buttonModalConfirmClass}
                      onClick={handleConfirm}
                    >
                      Proceed
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default PromptModal;

import React from "react";
import _ from "lodash";
import Select from "react-select";

import { NetworkAPI } from "apis";

class YieldSetsSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      options: [],
      selectedValue: null,
      defaultValue: null,
      isLoading: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const queryParams = {
      networkId: this.props.networkId,
    };
    const items = await NetworkAPI.getYieldSets(queryParams);

    if (this.props.handleOnLoadData) this.props.handleOnLoadData(items);

    let options = _.map(items, (r) => {
      return {
        value: r.yieldSetId,
        label: r.yieldSetId + " - " + r.name,
      };
    });

    let defaultItem;
    if (this.props.defaultValue) {
      defaultItem = _.find(options, (o) => {
        return o.value == this.props.defaultValue;
      });
      // console.log(this.props.defaultValue, defaultItem);
    }

    this.setState(
      {
        items,
        options,
        selectedValue: defaultItem,
        isLoading: false,
      },
      () => {
        if (defaultItem) {
          this.handleChange(defaultItem);
        } else {
          if (this.props.handleNoData) this.props.handleNoData();
        }
      }
    );
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedYieldSet !== this.props.selectedYieldSet) {
      if (this.props.selectedYieldSet === null) {
        this.setState({
          selectedValue: null,
        });
      }
      //  else {
      //   const item = _.find(this.state.items, {
      //     yieldSetId: this.props.selectedYieldSet,
      //   });
      //   this.setState({ selectedValue: item });
      // }
    }

    if (prevProps.networkId !== this.props.networkId) {
      this.setState({ isLoading: true });
      const queryParams = {
        networkId: this.props.networkId,
      };
      const items = await NetworkAPI.getYieldSets(queryParams);

      let options = _.map(items, (r) => {
        return {
          value: r.yieldSetId,
          label: r.yieldSetId + " - " + r.name,
        };
      });

      this.setState({
        items,
        options,
        isLoading: false,
      });
    }
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    const item = _.find(this.state.items, { yieldSetId: option.value });
    this.props.handleOnChange(item);
  }

  render() {
    const { options, selectedValue, isLoading } = this.state;
    return (
      <>
        <Select
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isLoading={isLoading}
        />
      </>
    );
  }
}

export default YieldSetsSelector;

import React from "react";
import ReactTooltip from "react-tooltip";

import YbSearch from "./YBSearch";
import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";

const button =
  "rounded inline-block cursor-pointer bg-gray-800 p-2 text-center text-base text-white";

class TreeLayerHeader extends React.PureComponent {
  render() {
    const {
      toggleSelectAllCompatibleUnits,
      searchTextChange,
      searchText,
      isAllCompatibleSelected,
      numCompatibleUnits,
    } = this.props;

    const isSelectAllDisabled = numCompatibleUnits === 0 || searchText;
    const tooltipMessage =
      numCompatibleUnits === 0
        ? "No compatible units to be selected"
        : "Select all compatible units";

    const randeomCode = Math.random().toString(36).substring(7);

    return (
      <div className="border flex items-center">
        <div className="border-r-2 p-1">
          {/* show tooltip when button not disabled */}
          {searchText ? (
            <button
              type="button"
              disabled={isSelectAllDisabled}
              className={button}
              onClick={toggleSelectAllCompatibleUnits}
            >
              {isAllCompatibleSelected ? (
                <FaRegCheckSquare></FaRegCheckSquare>
              ) : (
                <FaRegSquare></FaRegSquare>
              )}
            </button>
          ) : (
            <>
              <button
                type="button"
                disabled={isSelectAllDisabled}
                className={button}
                onClick={toggleSelectAllCompatibleUnits}
                data-tip
                data-for={`${randeomCode}-tree-header`}
              >
                {isAllCompatibleSelected ? (
                  <FaRegCheckSquare></FaRegCheckSquare>
                ) : (
                  <FaRegSquare></FaRegSquare>
                )}
              </button>

              <ReactTooltip
                id={`${randeomCode}-tree-header`}
                type="dark"
                effect="solid"
              >
                {tooltipMessage}
              </ReactTooltip>
            </>
          )}
        </div>

        <div className="w-full">
          <YbSearch
            value={searchText}
            type="text"
            placeholder="Search by name ..."
            onChange={searchTextChange}
          />
        </div>
      </div>
    );
  }
}

export default TreeLayerHeader;

export const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";
export const inputClassDisabled =
  "bg-gray-300 flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

export const titleClass = "block text-gray-800 font-bold mb-1"; //"font-semibold";

export const buttonConfirmClass =
  "my-2 text-blue-900 rounded shadow font-semibold bg-blue-200 hover:bg-blue-300 px-4 py-2 text-sm overflow-x-auto";

export const buttonConfirmDisable =
  "my-2 text-gray-500 rounded shadow font-semibold bg-blue-200 px-4 py-2 text-sm overflow-x-auto cursor-not-allowed";

export const buttonActionClass =
  "px-2 py-2 mr-1 my-1 bg-blue-400 text-xs rounded shadow hover:bg-blue-600 text-white font-semibold";

export const buttonActionDisable =
  "px-2 py-2 mr-1 my-1 bg-blue-200 text-xs rounded shadow text-white font-semibold cursor-not-allowed";

export const buttonActionRedClass =
  "px-2 py-2 mr-1 my-1 bg-red-400 text-xs rounded shadow hover:bg-red-600 text-white font-semibold";

export const buttonModalConfirmClass =
  "shadow rounded bg-blue-400 px-8 py-2 font-semibold text-white hover:bg-blue-600";

export const buttonModalConfirmDisable =
  "shadow rounded bg-blue-200 px-8 py-2 font-semibold text-white cursor-not-allowed";

export const buttonModalCancelClass =
  "rounded shadow bg-gray-400 px-8 py-2 font-semibold text-white hover:bg-gray-500";

export const buttonModalCancelDisable =
  "rounded shadow bg-gray-200 px-8 py-2 font-semibold text-white cursor-not-allowed";

export const thClass =
  "px-4 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
export const tdClass = "px-4 py-2 border";

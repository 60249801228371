import React from "react";
import _ from "lodash";
import Select from "react-select";
import { currencies } from "../../constants/Currencies";

class CurrencySelector extends React.Component {
  constructor(props) {
    super(props);

    const currencyOptions = props.currencies || currencies;
    const options = _.map(currencyOptions, (c) => {
      return { value: c, label: c };
    });

    const selectedValue = _.find(options, { value: props.selectedCurrency });

    this.state = {
      options,
      selectedValue,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCurrency !== this.props.selectedCurrency) {
      const selectedValue =
        _.find(this.state.options, {
          value: this.props.selectedCurrency,
        }) || null;
      this.setState({
        selectedValue,
      });
    }
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    this.props.handleCurrencyChanged(option.value);
  }

  render() {
    const { options, selectedValue } = this.state;
    const { width = "w-64" } = this.props;

    return (
      <>
        <Select
          className={`z-50 ${width}`}
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isSearchable={true}
        />
      </>
    );
  }
}

export default CurrencySelector;

import React from "react";
import _ from "lodash";

import TreeLayerHeader from "./TreeLayerHeader";
import OnboardItemComponent from "./OnboardItemComponent";
import NoActiveUnits from "./NoActiveUnits";

import TreeStyle from "./css/tree-selector.module.css";
import ItemStyle from "./css/inventory-item.module.css";

const ITEMS_PER_PAGE = 500;

class TreeLayer extends React.Component {
  constructor(props) {
    super(props);

    let paginatedCandidates = _.chunk(this.props.candidates, ITEMS_PER_PAGE);
    let currentPageCount = 0;

    let compatibleUnits = _.filter(this.props.candidates, (item) => {
      return item.isCompatible && !item.isOnboarded;
    });

    this.state = {
      searchText: "",
      // filteredCandidates: this.props.candidates,
      filteredCandidates: paginatedCandidates[currentPageCount],
      paginatedCandidates,
      currentPageCount: ++currentPageCount,
      totalPageCount: paginatedCandidates.length,

      compatibleUnits,
    };

    this.onFilterCandidates = _.debounce(
      this.onFilterCandidates.bind(this),
      500
    );
    this.toggleSelectAllCompatibleUnits =
      this.toggleSelectAllCompatibleUnits.bind(this);
    this.searchTextChange = this.searchTextChange.bind(this);

    this.onShowMoreCandidates = this.onShowMoreCandidates.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.candidates !== this.props.candidates) {
      let paginatedCandidates = _.chunk(this.props.candidates, ITEMS_PER_PAGE);
      let currentPageCount = 0;

      this.setState({
        searchText: "",
        isSearching: false,
        // filteredCandidates: this.props.candidates,
        filteredCandidates: paginatedCandidates[currentPageCount],
        paginatedCandidates,
        currentPageCount: ++currentPageCount,
        totalPageCount: paginatedCandidates.length,
      });
    }
  }

  searchTextChange(value) {
    this.setState({
      searchText: value,
      isSearching: true,
    });

    this.onFilterCandidates(value);
  }

  onFilterCandidates(searchText) {
    // console.log("onfilter", searchText);
    let regexp = new RegExp(searchText, "i");
    let filteredCandidates = _.filter(this.props.candidates, (item) => {
      // still show item that is being drilled down
      return item.isDrilledDown || item.name.match(regexp);
    });

    if (searchText === "") {
      let currentPageCount = 0;

      this.setState({
        isSearching: false,
        // filteredCandidates: this.props.candidates,
        filteredCandidates: this.state.paginatedCandidates[currentPageCount],
        // paginatedCandidates,
        currentPageCount: ++currentPageCount,
        totalPageCount: this.state.paginatedCandidates.length,
      });
    } else {
      this.setState({
        isSearching: false,
        filteredCandidates,
        currentPageCount: 0,
        totalPageCount: 0,
      });
    }
  }

  onShowMoreCandidates() {
    let newCandidates =
      this.state.paginatedCandidates[this.state.currentPageCount];
    this.setState({
      filteredCandidates: [...this.state.filteredCandidates, ...newCandidates],
      currentPageCount: ++this.state.currentPageCount,
    });
  }

  toggleSelectAllCompatibleUnits() {
    // select from filtered list? Not now
    const { compatibleUnits } = this.state;
    if (compatibleUnits.length === 0) return;

    const { onAddUnits, onRemoveUnits } = this.props;

    if (this.props.isAllCompatibleSelected) {
      return onRemoveUnits(compatibleUnits);
    } else {
      return onAddUnits(compatibleUnits);
    }
  }

  render() {
    const {
      candidates,
      layerNum,
      isAllCompatibleSelected,

      onSelectUnit,
      onSelectAllChildUnits,
      onDrillDown,
    } = this.props;

    if (candidates.length > 0) {
      // Sort candidates!
      let filteredCandidates = _.orderBy(
        this.state.filteredCandidates,
        [
          "hasChildren",
          "isCompatible",
          "childrenOnboardStatus",
          "dailyCompatibleAvgReq",
          "isOnboarded",
        ],
        ["desc", "desc", "desc", "desc", "desc"]
      );

      const totalCandidates = _.flatten(this.state.paginatedCandidates).length;

      return (
        <React.Fragment>
          <TreeLayerHeader
            toggleSelectAllCompatibleUnits={this.toggleSelectAllCompatibleUnits}
            searchTextChange={this.searchTextChange}
            searchText={this.state.searchText}
            isAllCompatibleSelected={isAllCompatibleSelected}
            numCompatibleUnits={this.state.compatibleUnits.length}
          ></TreeLayerHeader>

          {/* <div>
              current page count: {this.state.currentPageCount}/
              {this.state.totalPageCount}
            </div>
            <div>
              candidates count: {filteredCandidates.length}/{totalCandidates}
            </div> */}

          {!this.state.isSearching && this.state.searchText && (
            <div
              style={{
                fontSize: "16px",
                color: "#333333",
                padding: "8px",
                borderTop: "1px solid darkgray",
              }}
            >
              <div>
                Search result matched <b>{filteredCandidates.length}</b> out of{" "}
                {totalCandidates} ad units
              </div>
            </div>
          )}

          {this.state.isSearching ? (
            <div
              style={{
                borderTop: "1px solid darkgray",
              }}
            >
              Searching <i>{this.state.searchText}</i>...
            </div>
          ) : (
            <div className={TreeStyle.itemList}>
              {filteredCandidates.map((candidate) => {
                return (
                  <OnboardItemComponent
                    key={candidate.extGamUnitId}
                    item={candidate}
                    layerNum={layerNum}
                    onSelectUnit={onSelectUnit}
                    onSelectAllChildUnits={onSelectAllChildUnits}
                    onDrillDown={onDrillDown}
                  ></OnboardItemComponent>
                );
              })}

              {this.state.currentPageCount < this.state.totalPageCount && (
                <button
                  type="button"
                  onClick={this.onShowMoreCandidates}
                  className={ItemStyle.showMoreItemsBtn}
                >
                  Show more ad units
                  <span style={{ fontSize: "smaller", marginLeft: "8px" }}>
                    <i className="fa fa-arrow-down"></i>
                  </span>
                </button>
              )}
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return <NoActiveUnits></NoActiveUnits>;
    }
  }
}

export default TreeLayer;

import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { FiArrowDown } from "react-icons/fi";
import { Link } from "react-router-dom";
import NumberFormat from "../../common/NumberFormat";
import { MdEdit } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import { ACTIONS, COLUMNS } from "constants/Invoices";
import { buttonActionClass, buttonActionDisable } from "helpers/StyleClass";
import Checkbox from "components/common/Checkbox";

/**
 * Publisher
Invoice no
Start date
End date
currency
amount
i2w approval status
approval status
 */

// Please advise whether you have received the invoice from ABC which was said posted to us on 2/3/2018.
class InvoicesTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
    };

    this.toggleSelect = this.toggleSelect.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
  }

  toggleSelect(invoiceId) {
    const { selectedItems } = this.state;

    if (selectedItems.includes(invoiceId)) {
      this.setState({
        selectedItems: selectedItems.filter((id) => id !== invoiceId),
      });
    } else {
      this.setState({
        selectedItems: [...selectedItems, invoiceId],
      });
    }
  }

  toggleSelectAll() {
    const { items } = this.props;
    const { selectedItems } = this.state;

    if (selectedItems.length === items.length) {
      this.setState({
        selectedItems: [],
      });
    } else {
      this.setState({
        selectedItems: items.map((item) => item.invoiceId),
      });
    }
  }

  render() {
    const {
      items,
      isSaving,
      batchActions,
      actions,
      columns,
      tabName,
      onHandleAdvise,
      onHandleApprove,
      onHandleIssue,
      onHandleAudit,
      onHandleVoid,
      onHandleBadDebt,
      onHandleSendOverdueEmail,
      handleOpenNotes,
    } = this.props;

    const { selectedItems } = this.state;

    const thClass =
      "px-2 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
    const tdClass = "px-2 py-2";

    return (
      <div className="relative">
        {batchActions.length > 0 && (
          <div
            className="absolute right-0 flex items-center gap-2 text-sm text-gray-700"
            style={{ top: "-2.75rem" }}
          >
            {selectedItems.length} invoice selected
            {batchActions.map((action) => (
              <button
                className={
                  selectedItems.length !== 0
                    ? buttonActionClass
                    : buttonActionDisable
                }
                onClick={() =>
                  this.props.handleBatchActions &&
                  this.props.handleBatchActions(action, selectedItems)
                }
                disabled={selectedItems.length === 0}
                key={action}
              >
                {action}
              </button>
            ))}
          </div>
        )}

        <table className="shadow divide-y min-w-full">
          <thead className="bg-gray-100">
            <tr>
              {batchActions.length > 0 && (
                <th className={thClass + " w-10"}>
                  <Checkbox
                    isChecked={
                      _.xor(
                        selectedItems,
                        items.map((item) => item.invoiceId)
                      ).length === 0
                    }
                    onChange={this.toggleSelectAll}
                  ></Checkbox>
                </th>
              )}
              <th className={thClass} style={{ width: "20%" }}>
                Publisher
              </th>
              <th className={thClass}>Invoice</th>
              <th className={thClass + " flex items-center"}>
                <div>
                  Start Date<br></br>
                  End Date
                </div>
                <FiArrowDown></FiArrowDown>
              </th>
              <th className={thClass + " text-right"}>Invoice Amount</th>

              {_.indexOf(columns, COLUMNS.AFTER_TAX_AMOUNT) !== -1 && (
                <th className={thClass + " text-right"}>After Tax Amount</th>
              )}

              {_.indexOf(columns, COLUMNS.INVOICE_STATUS) !== -1 && (
                <th className={thClass}>Invoice Status</th>
              )}

              {_.indexOf(columns, COLUMNS.APPROVAL_STATUS) !== -1 && (
                <th className={thClass}>Approval Status</th>
              )}

              {_.indexOf(columns, COLUMNS.PAYMENT_STATUS) !== -1 && (
                <th className={thClass}>Payment Status</th>
              )}

              {_.indexOf(columns, COLUMNS.RECEIVED_AMOUNT) !== -1 && (
                <th className={thClass + " text-right"}>Received Amount</th>
              )}

              {_.indexOf(columns, COLUMNS.DUE_DATE) !== -1 && (
                <th className={thClass + " text-right"}>Due Date</th>
              )}

              {_.indexOf(columns, COLUMNS.COLLECTION_DATE) !== -1 && (
                <th className={thClass + " text-right"}>Collection Date</th>
              )}

              {_.indexOf(columns, COLUMNS.BADDEBT_DATE) !== -1 && (
                <th className={thClass + " text-right"}>Bad Debt Date</th>
              )}

              <th className={thClass + " text-right"}>Last updated</th>
              {actions && <th className={thClass}>Action</th>}
            </tr>
          </thead>
          <tbody className="divide-y bg-white text-sm">
            {items.map((item) => {
              return (
                <tr key={item.invoiceId} className="hover:bg-gray-100">
                  {batchActions.length > 0 && (
                    <td
                      className={tdClass + ` cursor-pointer`}
                      onClick={() => this.toggleSelect(item.invoiceId)}
                    >
                      <Checkbox
                        isChecked={selectedItems.includes(item.invoiceId)}
                        onChange={() => this.toggleSelect(item.invoiceId)}
                      ></Checkbox>
                    </td>
                  )}
                  <td
                    className={tdClass + ` cursor-pointer`}
                    onClick={() => this.toggleSelect(item.invoiceId)}
                  >
                    {item.publisher.publisherId} - {item.publisher.name}
                  </td>
                  <td className={tdClass + " font-semibold text-gray-800"}>
                    <div className="flex gap-2 align-middle">
                      {item.invoiceNo}

                      <button
                        type="button"
                        className="hover:shadow px-2 py-1"
                        data-tip
                        data-for={`${item.invoiceId}-notes-btn`}
                        onClick={() => handleOpenNotes(item.invoiceId)}
                      >
                        <MdEdit></MdEdit>
                      </button>

                      <ReactTooltip
                        id={`${item.invoiceId}-notes-btn`}
                        type="dark"
                        effect="solid"
                        place="bottom"
                      >
                        Invoice Notes
                      </ReactTooltip>
                    </div>
                  </td>
                  <td className={tdClass}>
                    {item.startDateStr} <div>{item.endDateStr}</div>
                  </td>
                  <td className={tdClass + " text-right"}>
                    <span className="font-semibold text-gray-900">
                      <NumberFormat
                        value={item.amount}
                        prefix="$"
                      ></NumberFormat>
                    </span>

                    <div className="text-xs leading-none text-gray-600">
                      {item.currency}
                    </div>
                  </td>
                  {_.indexOf(columns, COLUMNS.AFTER_TAX_AMOUNT) !== -1 && (
                    <td className={tdClass + " text-right"}>
                      <span className="font-semibold text-gray-900">
                        {/* <div className="text-xs text-gray-600 font-normal leading-none">
                          After tax:
                        </div> */}
                        <NumberFormat
                          value={item.afterTaxAmount}
                          prefix="$"
                        ></NumberFormat>
                      </span>

                      <div className="text-xs leading-none text-gray-600">
                        {item.currency}
                      </div>
                    </td>
                  )}
                  {_.indexOf(columns, COLUMNS.INVOICE_STATUS) !== -1 && (
                    <td className={tdClass + " text-gray-700"}>
                      {item.status}
                    </td>
                  )}
                  {_.indexOf(columns, COLUMNS.APPROVAL_STATUS) !== -1 && (
                    <td className={tdClass}>
                      <div className="text-gray-700">
                        Intowow:
                        <span
                          className={`rounded ml-1 px-2 text-xs font-semibold ${
                            item.i2WApproved === "APPROVED"
                              ? "bg-green-200 text-green-900"
                              : "bg-red-200 text-red-900"
                          }`}
                        >
                          {item.i2WApproved}
                        </span>
                      </div>

                      <div className="text-gray-700">
                        Publisher:
                        <span
                          className={`rounded ml-1 px-2 text-xs font-semibold ${
                            item.approved === "APPROVED"
                              ? "bg-green-200 text-green-900"
                              : "bg-red-200 text-red-900"
                          }`}
                        >
                          {item.approved}
                        </span>
                      </div>
                    </td>
                  )}
                  {_.indexOf(columns, COLUMNS.PAYMENT_STATUS) !== -1 && (
                    <td className={tdClass + " text-xs text-gray-700"}>
                      {item.payment ? item.payment.status : "-"}
                    </td>
                  )}
                  {_.indexOf(columns, COLUMNS.RECEIVED_AMOUNT) !== -1 && (
                    <td className={tdClass + " text-right"}>
                      <span className="font-semibold text-gray-900">
                        <NumberFormat
                          value={item.payment.receivedAmt}
                          prefix="$"
                        ></NumberFormat>
                      </span>

                      <div className="text-xs leading-none text-gray-600">
                        {item.currency}
                      </div>
                    </td>
                  )}
                  {_.indexOf(columns, COLUMNS.DUE_DATE) !== -1 && (
                    <td className={tdClass + " text-right text-gray-700"}>
                      <span
                        className={`${
                          moment(item.dueDate).isBefore(moment())
                            ? "text-red-700"
                            : ""
                        }`}
                      >
                        {moment(item.dueDate).format("YYYY/MM/DD HH:mm:ss")}
                        <div className="text-xs text-gray-600">
                          ({moment(item.dueDate).fromNow()})
                        </div>
                      </span>
                    </td>
                  )}
                  {_.indexOf(columns, COLUMNS.COLLECTION_DATE) !== -1 && (
                    <td className={tdClass + " text-right text-gray-700"}>
                      {item.payment
                        ? moment(item.payment.collectionDate).format(
                            "YYYY/MM/DD HH:mm:ss"
                          )
                        : "-"}
                    </td>
                  )}
                  {_.indexOf(columns, COLUMNS.BADDEBT_DATE) !== -1 && (
                    <td className={tdClass + " text-right text-gray-700"}>
                      {item.payment
                        ? moment(item.payment.badDebtDate).format(
                            "YYYY/MM/DD HH:mm:ss"
                          )
                        : "-"}
                    </td>
                  )}
                  <td className={tdClass + " text-right text-gray-700"}>
                    {item.updatedAtStr}
                  </td>
                  {actions && (
                    <td className={tdClass}>
                      {actions.map((action) => {
                        if (action === ACTIONS.ISSUE) {
                          return (
                            <button
                              key={action}
                              type="button"
                              disabled={isSaving}
                              className={`rounded shadow my-1 mr-1 bg-blue-400 px-2 py-1 text-xs font-semibold text-white hover:bg-blue-600
          ${
            isSaving
              ? "cursor-not-allowed bg-gray-100"
              : "bg-white hover:bg-gray-100"
          }`}
                              onClick={() => {
                                return onHandleIssue(item);
                              }}
                            >
                              Issue
                            </button>
                          );
                        }

                        if (action === ACTIONS.AUDIT) {
                          return (
                            <button
                              key={action}
                              type="button"
                              disabled={isSaving}
                              className={`rounded shadow my-1 mr-1 bg-blue-400 px-2 py-1 text-xs font-semibold text-white hover:bg-blue-600
          ${
            isSaving
              ? "cursor-not-allowed bg-gray-100"
              : "bg-white hover:bg-gray-100"
          }`}
                              onClick={() => {
                                return onHandleAudit(item);
                              }}
                            >
                              Audit
                            </button>
                          );
                        }

                        if (action === ACTIONS.ADVISE) {
                          return (
                            <button
                              key={action}
                              type="button"
                              disabled={isSaving}
                              className={`rounded shadow my-1 mr-1 bg-blue-400 px-2 py-1 text-xs font-semibold text-white hover:bg-blue-600
    ${
      isSaving ? "cursor-not-allowed bg-gray-100" : "bg-white hover:bg-gray-100"
    }`}
                              onClick={() => {
                                return onHandleAdvise(item);
                              }}
                            >
                              Advise
                            </button>
                          );
                        }

                        if (action === ACTIONS.APPROVE) {
                          return (
                            <button
                              key={action}
                              type="button"
                              disabled={isSaving}
                              className={`rounded shadow my-1 mr-1 bg-blue-400 px-2 py-1 text-xs font-semibold text-white hover:bg-blue-600
    ${
      isSaving ? "cursor-not-allowed bg-gray-100" : "bg-white hover:bg-gray-100"
    }`}
                              onClick={() => {
                                return onHandleApprove(item);
                              }}
                            >
                              Approve
                            </button>
                          );
                        }

                        if (action === ACTIONS.RECEIVE_PAYMENT) {
                          return (
                            <Link
                              key={action}
                              to={`/ops-mgmt/billing/payment/${item.payment.paymentId}/receive`}
                              className={`rounded shadow my-1 mr-1 bg-blue-400 px-2 py-1 text-xs font-semibold text-white hover:bg-blue-600
                              ${
                                isSaving
                                  ? "cursor-not-allowed bg-gray-100"
                                  : "bg-white hover:bg-gray-100"
                              }`}
                            >
                              Receive
                            </Link>
                          );
                        }

                        if (action === ACTIONS.VOID) {
                          return (
                            <button
                              key={action}
                              type="button"
                              disabled={isSaving}
                              className={`rounded shadow my-1 bg-red-400 px-2 py-1 text-xs font-semibold text-white hover:bg-red-600
  ${
    isSaving ? "cursor-not-allowed bg-gray-100" : "bg-white hover:bg-gray-100"
  }`}
                              onClick={() => {
                                return onHandleVoid({
                                  // invoiceId: item.invoiceId,
                                  ...item,
                                  // reason: "Test void",
                                });
                              }}
                            >
                              Void
                            </button>
                          );
                        }

                        if (action === ACTIONS.BAD_DEBT) {
                          return (
                            <button
                              key={action}
                              type="button"
                              disabled={isSaving}
                              className={`rounded shadow my-1 ml-1 bg-red-400 px-2 py-1 text-xs font-semibold text-white hover:bg-red-600
  ${
    isSaving ? "cursor-not-allowed bg-gray-100" : "bg-white hover:bg-gray-100"
  }`}
                              onClick={() => {
                                return onHandleBadDebt({
                                  // invoiceId: item.invoiceId,
                                  ...item,
                                  // reason: "Test void",
                                });
                              }}
                            >
                              Bad Debt
                            </button>
                          );
                        }

                        if (action === ACTIONS.SEND_OVERDUE_EMAIL) {
                          return (
                            <button
                              key={action}
                              type="button"
                              disabled={isSaving}
                              className={`rounded shadow my-1 mr-1 bg-blue-400 px-2 py-1 text-xs font-semibold text-white hover:bg-blue-600
                          ${
                            isSaving
                              ? "cursor-not-allowed bg-gray-100"
                              : "bg-white hover:bg-gray-100"
                          }`}
                              onClick={() => {
                                return onHandleSendOverdueEmail(item);
                              }}
                            >
                              Send Overdue Email
                            </button>
                          );
                        }

                        return <></>;
                      })}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default InvoicesTable;

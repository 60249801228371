import React from "react";
import Select from "react-select";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import ModalWrapper from "components/common/ModalWrapper";
import ModalFooter from "components/common/ModalFooter";
import AdxAccounContent from "./AdxAccountContent";

import { OnboardingAPI } from "apis";
import { MESSAGE } from "constants/Message";

import { inputClass } from "helpers/StyleClass.js";
import { FiPlusCircle } from "react-icons/fi";

class YiedlSetModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddAdxAccount: false,
      msg: null,

      isNew: !this.props.selectedYieldSet,
      yieldSetId: this.props.selectedYieldSet
        ? this.props.selectedYieldSet.yieldSetId
        : null,
      name: this.props.selectedYieldSet ? this.props.selectedYieldSet.name : "",
      adxAccountId:
        this.props.selectedYieldSet && this.props.selectedYieldSet.adxAccount
          ? this.props.selectedYieldSet.adxAccount.adxAccountId
          : null,
      result: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleAddConfirm = this.handleAddConfirm.bind(this);
    this.handleEditConfirm = this.handleEditConfirm.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddAdxAccount = this.handleAddAdxAccount.bind(this);
  }

  handleInputChange(e) {
    this.setState({ name: e.target.value });
  }

  handleSelectChange(selectedOption) {
    this.setState({ adxAccountId: selectedOption.value });
  }

  async handleAddConfirm() {
    const { networkId } = this.props;
    const { name, adxAccountId } = this.state;
    this.props.onLoading(true);

    const param = {
      networkId,
      name,
      adxAccountId,
    };

    try {
      const result = await OnboardingAPI.createYieldSet(param);
      if (result) {
        this.setState({ msg: ["Create Success", MESSAGE.SUCCESS], result });
      }
    } catch (error) {
      this.setState({ msg: [error, MESSAGE.ERROR] });
    }
    this.props.onLoading(false);
  }

  async handleEditConfirm() {
    const { yieldSetId, name, adxAccountId } = this.state;
    this.props.onLoading(true);

    const param = {
      yieldSetId,
      name,
      adxAccountId,
    };

    try {
      const result = await OnboardingAPI.updateYieldSet(param);
      if (result) {
        this.setState({ msg: ["Updated Success", MESSAGE.SUCCESS], result });
      }
    } catch (error) {
      this.setState({ msg: [error, MESSAGE.ERROR] });
    }
    this.props.onLoading(false);
  }

  handleClose() {
    const { result } = this.state;
    if (result) {
      this.props.onClose(result);
    }
  }

  handleAddAdxAccount(data) {
    this.props.onAddAdxAccount(data);
    this.setState({ isAddAdxAccount: false, adxAccountId: data.adxAccountId });
  }

  render() {
    const { adxAccounts } = this.props;
    const { msg, adxAccountId, name, isNew, yieldSetId, isAddAdxAccount } =
      this.state;

    const adxAccountOptions = adxAccounts.map((account) => ({
      value: account.adxAccountId,
      label: account.name,
    }));

    const selectedAdxAccount = _.find(adxAccountOptions, {
      value: adxAccountId,
    });

    return (
      <ModalWrapper
        isOpen={true}
        showCloseFooter={false}
        customOverlayStyle={{ zIndex: 100 }}
        width="30%"
        height="60%"
      >
        <div className="flex h-full flex-col gap-3">
          <div className="border-b -mx-5 border-gray-400 px-5 pb-4 text-lg font-semibold">
            {isNew ? "New" : "Edit"} Yield Set
          </div>

          {!isNew && (
            <div>
              <label>Yield Set Id</label>
              <div className="font-semibold">{yieldSetId}</div>
            </div>
          )}

          <div>
            <label>Name</label>
            <input
              type="text"
              placeholder="Yield Set Name"
              className={inputClass}
              onChange={this.handleInputChange}
              value={name}
            ></input>
          </div>

          <div>
            <div className="flex gap-2">
              <label>Adx Account</label>
              {!isAddAdxAccount && (
                <>
                  <button
                    type="button"
                    className="rounded hover:shadow flex items-center bg-gray-100 text-base text-blue-700 hover:text-blue-500"
                    data-tip
                    data-for="addAdxAccount"
                    onClick={() => this.setState({ isAddAdxAccount: true })}
                  >
                    <FiPlusCircle></FiPlusCircle>
                  </button>
                  <ReactTooltip id="addAdxAccount" type="dark" effect="solid">
                    Add New Adx Account
                  </ReactTooltip>
                </>
              )}
            </div>

            <div className="w-full">
              {isAddAdxAccount ? (
                <div className="border rounded flex flex-col gap-2 border-gray-400 px-3 pb-3 pt-2">
                  <AdxAccounContent
                    networkId={this.props.networkId}
                    onLoading={this.props.onLoading}
                    onCancel={() => this.setState({ isAddAdxAccount: false })}
                    onClose={(data) => this.handleAddAdxAccount(data)}
                  ></AdxAccounContent>
                </div>
              ) : (
                <Select
                  options={adxAccountOptions}
                  value={selectedAdxAccount}
                  onChange={this.handleSelectChange}
                  isSearchable={true}
                />
              )}
            </div>
          </div>

          <div className="mt-auto">
            <ModalFooter
              msg={msg}
              confirmText={isNew ? "Create" : "Update"}
              onClose={this.handleClose}
              onCancel={this.props.onCancel}
              onConfirm={() =>
                isNew ? this.handleAddConfirm() : this.handleEditConfirm()
              }
              disableCancel={isAddAdxAccount}
              disableConfirm={name === "" || !adxAccountId || isAddAdxAccount}
            ></ModalFooter>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default YiedlSetModal;

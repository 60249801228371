export const PATHS = {
  // console
  ybConsoleOpsLog: "/console/ops-log",

  // playground
  ybConsoleHomeDashboard: "/playground/console-overview-mockup/:pubId",
  ybConsoleHomeDashboardReseller:
    "/playground/console-overview-mockup-reseller/:pubId",

  // tools
  batchUpdate: "/tools/batch-update-auto-pilot-config",
  gptRequestsComparison: "/tools/gpt-requests-comparison",
  dxDyTrends: "/tools/dx-dy-trends",

  // control-center (ap configs)
  controlCenter: "/control-center",

  // auto pilot segment research configs
  segmentResearch: "/segment-research",

  // ops-mgmt
  opsManagement: "/ops-mgmt",

  // healthcheck
  reportingStatus: "/healthcheck/reporting-status",

  // biz
  bizOverview: "/biz/overview",
  bizNetwork: "/biz/network/:networkId",
  intowowRevenue: "/revenue/overview",
  intowowBilling: "/billing/overview",

  intowowBusiness: "/business",
  // businessBilling: "/business/billing",

  // unit-insight
  unitInsight: "/unit-insight/:unitId",

  // cst-report
  cstReport: "/cst/report/:networkId",

  // snapshot
  snapshot: "/snapshot/:snapshotKey",
  snapshotLatest: "/snapshot/latest",
  yieldSetSnapshot: "/network/:networkId/yield-set-snapshot",

  // observer
  observer: "/observer/:unitId",
  anatomy: "/anatomy/:unitId", // just a rename for observer
  anatomyDebug: "/anatomy/debug/:expId",

  // cst
  anatomyCST: "/anatomy/cst/:unitId",

  // vse
  vseBase: "/vse",

  diagnoserBase: "/diagnoser",

  // vault
  vaultBase: "/vault-accounts",

  // users
  usersBase: "/vault-users",

  // units search
  unitsSearch: "/network/:networkId/units/search",

  pricingRules: "/network/:networkId/pricing-rules",

  // app-configurator
  appConfigurator: "/app-configurator",

  // inventory-rev
  inventoryRev: "/inventory-rev-analysis-dashboard",

  // integration-verification
  integrationVerify: "/integration-verification/:report",

  // competitors-summary
  competitorSummary: "/competitors-summary",
};

// To add a new route with title replacing document.title:
// 1. Specify the `path` (e.g., "/new-route/:param").
// 2. Define the `title` using placeholders for any dynamic parameters (e.g., "Page Title {param}").
// Example routes:
// { path: PATHS.snapshot, title: "Ops Log {snapshotKey}" },
// { path: PATHS.yieldSetSnapshot, title: "TEST Log {networkId} 12333" }
export const routes = [
  {
    path: PATHS.appConfigurator,
    title: "App Configurator | YB Observer",
  },
  {
    path: "/ops-mgmt/account/network/:networkId/upr-setting",
    title: "{networkId} Network Custom UPR | YB Observer",
  },
  {
    path: PATHS.unitsSearch,
    title: "Search Units of {networkId} | YB Observer",
  },
  {
    path: PATHS.inventoryRev,
    title: "Inventory and Revenue Analysis Dashboard | YB Observer",
  },
  {
    path: PATHS.integrationVerify,
    title: "Integration Verification | YB Observer",
  },
  {
    path: "/ops-mgmt/account/publisher/:publisherId/gam-accounts",
    title: "{publisherId} Onboard GAM Account | YB Observer",
  },
  {
    path: "/ops-mgmt/account/network/:networkId/yield-sets",
    title: "{networkId} Onboard Yield Set | YB Observer",
  },
  {
    path: "/ops-mgmt/account/onboard-ad-units",
    title: "Onboard Ad Units",
  },
  {
    path: "/ops-mgmt/account/networks",
    title: "Networks | YB Observer",
  },
  {
    path: "/ops-mgmt/account/onboard-ad-units-tree-view",
    title: "Onboard Ad Units Tree View",
  },
  {
    path: "/ops-mgmt/account/ad-units-batch-actions",
    title: "Ad Units Batch Actions",
  },
  {
    path: "/ops-mgmt/account/network/:networkId/features",
    title: "{networkId} Network Features | YB Observer",
  },
  {
    path: PATHS.vaultBase,
    title: "Vault GAM Accounts | YB Observer",
  },
  {
    path: PATHS.competitorSummary,
    title: "Competitor Summary | YB Observer",
  },
  {
    path: PATHS.batchUpdate,
    title: "Batch Update AP Configs | YB Tools",
  },
];

export function pathToRegex(path) {
  const regexString = path
    .replace(/:([^\s/]+)/g, "(?<$1>[^/]+)")
    .replace(/\//g, "\\/");
  return new RegExp(`^${regexString}$`);
}

export function parseTitle({ path, title }, pathname) {
  const regex = pathToRegex(path);
  const params = extractParams(regex, pathname);
  return formatTitle(title, params);
}

function extractParams(regex, pathname) {
  const match = pathname.match(regex);
  return match ? match.groups : null;
}

function formatTitle(template, params) {
  return template.replace(
    /{([^\s{}]+)}/g,
    (_, key) => params[key] || `{${key}}`
  );
}

import React from "react";
import _ from "lodash";

const WEEKDAY_NAMES = [
  "Sun",
  "Mon",
  "Tues",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  // "Sunday",
  // "Monday",
  // "Tuesday",
  // "Wednesday",
  // "Thursday",
  // "Friday",
  // "Saturday",
];
class CstPredictScheduleFormatter extends React.PureComponent {
  render() {
    const { configValue, isTextOnlyFormatter = true } = this.props;
    const { time_scale, time_points } = configValue || {};

    if (!time_scale || !time_points) {
      return "-";
    }

    let formatTimePoints = _.map(time_points, (hr) => {
      const weekday = _.floor(hr / 24);
      const hour = hr % 24;
      return {
        weekday,
        hour,
      };
    });
    formatTimePoints = _.groupBy(formatTimePoints, "weekday");

    return (
      <div>
        <div>
          Time Scale: <span className="font-semibold">{time_scale}</span>
        </div>
        <div>Time Points:</div>
        <div className="font-semibold">
          {time_scale === "day" ? (
            <div>
              {isTextOnlyFormatter ? (
                <div>{time_points.join(", ")}</div>
              ) : (
                <HoursViewer selectedHours={time_points}></HoursViewer>
              )}
            </div>
          ) : (
            <div>
              {" "}
              {_.keys(formatTimePoints).map((weekday) => {
                const items = _.map(formatTimePoints[weekday], "hour");
                return (
                  <div className="text-sm" key={weekday}>
                    {WEEKDAY_NAMES[weekday]}({items.length}):
                    {isTextOnlyFormatter ? (
                      <div>{items.join(", ")}</div>
                    ) : (
                      <HoursViewer selectedHours={items}></HoursViewer>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function HoursViewer({ selectedHours }) {
  let hours = _.fill(new Array(24), false);
  hours = _.map(hours, (d, index) => {
    const isSelected = _.indexOf(selectedHours, index) !== -1;
    return { hr: index, isSelected };
  });
  return (
    <>
      <div className="mb-1 flex flex-row">
        {hours.slice(0, 12).map(({ hr, isSelected }) => {
          return (
            <div
              key={hr}
              className={`mr-1 inline-block text-center ${
                isSelected ? "bg-white" : "bg-gray-500"
              }`}
              style={{ width: "20px", height: "20px", fontSize: "12px" }}
            >
              {isSelected && hr}
            </div>
          );
        })}
      </div>
      <div className="mb-1 flex flex-row">
        {hours.slice(12).map(({ hr, isSelected }) => {
          return (
            <div
              key={hr}
              className={`mr-1 inline-block text-center ${
                isSelected ? "bg-white" : "bg-gray-500"
              }`}
              style={{ width: "20px", height: "20px", fontSize: "12px" }}
            >
              {isSelected && hr}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default CstPredictScheduleFormatter;

import React from "react";

function HighLightRowWrapper({
  children,
  selected = false,
  baseClass = "border-b",
  selectedClass = "bg-yellow-200",
  unselectedClass = "hover:bg-gray-100",
}) {
  return (
    <tr
      className={`${baseClass} ${selected ? selectedClass : unselectedClass} `}
    >
      {children}
    </tr>
  );
}

export default HighLightRowWrapper;

import React from "react";

class SimpleDataTable extends React.PureComponent {
  render() {
    const { items, columns } = this.props;

    return (
      <table className="border shadow table w-full text-sm">
        <thead className="border-b bg-gray-200 text-xs text-blue-800">
          <tr>
            {columns.map((col) => (
              <th
                className={`border px-2 py-1 text-center ${
                  col.className || ""
                }`}
                style={{ ...(col.style || {}) }}
                key={col.key}
              >
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white text-gray-900">
          {items.map((item, idx) => (
            <tr className="border-b hover:bg-gray-100" key={item.id || idx}>
              {columns.map((col, cIdx) => (
                <td
                  className={`border px-2 py-1 text-center ${
                    col.className || ""
                  }`}
                  style={{ ...(col.style || {}) }}
                  key={col.key}
                >
                  {item[col.key] || item[cIdx] || ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default SimpleDataTable;

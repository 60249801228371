export const REPORTKEY = {
  COUNTRY: "country_distribution_trend_report",
  DEMAND: "demand_type_distribution_trend_report",
  DEVICE: "device_category_distribution_trend_report",
  REQUEST: "request_type_distribution_trend_report",
};

export const COUNTRY = {
  MONTHYEAR: "month_year",
  COUNTRY: "country",
  REQUEST: "request_v2",
  IMPRESSION: "impression",
  UNFILLED: "unfilled_impression",
  CODE: "total_code_served",
};

export const DEMAND = {
  MONTHYEAR: "month_year",
  DEMANDTYPE: "demand_type",
  IMPRESSION: "impression",
  REVENUE: "revenue",
  ECPM: "ecpm",
  DROPOFFF: "dropoff_rate",
  CODE: "total_code_served",
};

export const DEVICE = {
  MONTHYEAR: "month_year",
  CATEGORY: "device_category",
  REQUEST: "request_v2",
  IMPRESSION: "impression",
  UNFILLED: "unfilled_impression",
  CODE: "total_code_served",
};

export const REQUEST = {
  MONTHYEAR: "month_year",
  TYPE: "request_type",
  REQUEST: "request_v2",
  IMPRESSION: "impression",
  UNFILLED: "unfilled_impression",
  CODE: "total_code_served",
};

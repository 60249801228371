import React from "react";
import PromptModal from "components/common/PromptModal";
import { NetworkAPI } from "apis";
import { MESSAGE } from "constants/Message";

const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900 font-semibold";

class SyncVerifyNetworkButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      msg: [],
      isLoading: false,
      isPromptModalOpen: false,
      promptHeader: null,
      promptMessage: null,
      promptAction: null,
      result: null,
    };

    this.handleSyncNetwork = this.handleSyncNetwork.bind(this);
    this.handleVerifyNetwork = this.handleVerifyNetwork.bind(this);
    this.handleClosePromptModal = this.handleClosePromptModal.bind(this);
  }

  async handleSyncNetwork(isPrompt = true) {
    const { networkId, name } = this.props;

    if (isPrompt) {
      this.setState({
        result: null,
        isPromptModalOpen: true,
        promptHeader: "Sync GAM Network",
        promptMessage: (
          <>
            Are you sure you want to sync the GAM Network? <br />
            <span className="font-semibold text-blue-600">
              {networkId} - {name}
            </span>
          </>
        ),
        promptAction: () => {
          this.handleSyncNetwork(false);
        },
      });

      return;
    }

    try {
      this.setState({ isLoading: true });
      const response = await NetworkAPI.syncGamNetwork({ networkId });
      if (response) {
        this.setState({
          msg: ["Sync Success!", MESSAGE.SUCCESS],
          result: response,
        });
      }
    } catch (error) {
      this.setState({
        msg: [error, MESSAGE.ERROR],
      });
    }

    this.setState({ isLoading: false });
  }

  async handleVerifyNetwork(isPrompt = true) {
    const { networkId, name } = this.props;

    if (isPrompt) {
      this.setState({
        result: null,
        isPromptModalOpen: true,
        promptHeader: "Verify GAM Network",
        promptMessage: (
          <>
            Are you sure you want to verify the GAM Network? <br />
            <span className="font-semibold text-blue-600">
              {networkId} - {name}
            </span>
          </>
        ),
        promptAction: () => {
          this.handleVerifyNetwork(false);
        },
      });

      return;
    }

    try {
      this.setState({ isLoading: true });
      const response = await NetworkAPI.verifyGamNetwork({ networkId });
      if (response) {
        this.setState({
          msg: ["Verify Success!", MESSAGE.SUCCESS],
          result: response,
        });
      }
    } catch (error) {
      this.setState({
        msg: [error, MESSAGE.ERROR],
      });
    }

    this.setState({ isLoading: false });
  }

  handleClosePromptModal() {
    const { result } = this.state;
    if (result) this.props.updateNetworkStatus(result);
    this.setState({ isPromptModalOpen: false, msg: [] });
  }

  render() {
    const { status } = this.props;

    const {
      isLoading,
      isPromptModalOpen,
      msg,
      promptHeader,
      promptMessage,
      promptAction,
    } = this.state;

    return (
      <>
        <div className="mt-2 flex items-center justify-end gap-2">
          {(status === "PROBING" || status === "FAILED") && (
            <button
              type="button"
              className={buttonActionClass}
              onClick={() => this.handleVerifyNetwork()}
            >
              Verify GAM Network
            </button>
          )}

          {(status === "RUNNING" || status === "PAUSED") && (
            <button
              type="button"
              className={buttonActionClass}
              onClick={() => this.handleSyncNetwork()}
            >
              Sync GAM Network
            </button>
          )}

          {isPromptModalOpen && (
            <PromptModal
              msg={msg}
              isLoading={isLoading}
              isOpenModal={isPromptModalOpen}
              handleConfirm={promptAction}
              handleCancel={this.handleClosePromptModal}
              header={promptHeader}
            >
              {promptMessage}
            </PromptModal>
          )}
        </div>
      </>
    );
  }
}

export default SyncVerifyNetworkButton;

export function changeQueryString(props, queryParams) {
  return props.history.push({
    search: "?" + new URLSearchParams(queryParams).toString(),
  });
}

export function getQueryStringToObject(queryString) {
  const urlParams = new URLSearchParams(queryString);
  const paramsObject = Object.fromEntries(urlParams);
  return paramsObject;
}

export function setQueryString(props, object, silent = false) {
  const urlParams = new URLSearchParams(props.location.search);
  for (const key in object) {
    urlParams.set(key, object[key]);
  }

  if (silent) {
    return urlParams.toString();
  } else {
    props.history.replace(`?${urlParams.toString()}`);
  }
}

import _ from "lodash";

// Deprecated!!
// only used in user hint config (prophet section only)
// const KEY_LEVEL = {
//   BASIC: "BASIC",
//   ADVANCED: "ADVANCED", // default to advanced
// };

// prophet section only!
const GROUP_CATEGORY = {
  GENERAL: "General",
  CST: "CST",
  CST_X: "CST X", // child object "cst_x_setting"
  CST_ALGO_TUNING: "CST Algo Tuning", // child object "cst_algo_tuning"
  BENCHMARK: "Benchmark",
  THIRD_PARTY_DELIVERY: "Third Party Delivery",
  DIRECT_DELIVERY: "Direct Delivery",
  DISPATCHER_BEHAVIOR: "Dispatcher Behavior",
  OPTIMIZATION_ALGORITHM_TUNING: "Optimization Algorithm Tuning",
  METHODOLOGY_OPTIMIZATION: "Methodology Optimization",
  PRICING_OPTIMIZATION: "Pricing Optimization",
  SIZING_OPTIMIZATION: "Sizing Optimization",
};

const prophetGroupings = [
  {
    groupName: GROUP_CATEGORY.GENERAL,
    keys: [
      "force_inspection",
      "accountable_demand_types",
      "enable_dispatcher_debugging",
      "enable_client_refresh",
      "enable_request_api",
      "enable_trap_group",
      "enable_hybrid_opt",
      "hybrid_detector_traffic_ratio",
      "publisher_refresh_control_type",
      "forbid_client_refresh_labels",
      "delivered_data_consider_billable_demand_types",
      "minimum_qualified_experiment_lifespan",
      "minumum_qualified_experiment_request_volume",
      "maximum_experiment_lifespan",
      "maximum_traffic_ratio",
      "minimum_traffic_ratio",
      "default_traffic_ratio",
    ],
  },
  {
    groupName: GROUP_CATEGORY.CST,
    keys: [
      "cst_custom_layer1_upr_price",
      "custom_layer1_upr_price_pattern",
      "cst_custom_layer2_upr_prices",
      "cst_minimum_report_aggregation_interval",
      "disallow_catchall",
      "cst_catchall_update_correlator",
      "cst_catchall_prebid_drop_enabled",
      "cst_catchall_dynamic_prebid_disabled",
      "hybrid_catch_all_enable_url_trunc",
      "cst_catchall_auto_weight",
      "cst_use_cpm_only_cost_type",
      "cst_algorithms",
      "cst_sxs_enabled",
      "cst_predict_schedule",
      "cst_bm_ecpm_tracking_ratio",
    ],
  },
  {
    groupName: GROUP_CATEGORY.CST_X,
    // these keys are not unique, therefore added cst_x_setting.xxxx
    keys: [
      "cst_x_setting.cst_custom_layer1_upr_price",
      "cst_x_setting.custom_layer1_upr_price_pattern",
      "cst_x_setting.cst_algorithms",
      "cst_x_setting.cst_predict_schedule",
    ],
  },
  {
    groupName: GROUP_CATEGORY.CST_ALGO_TUNING,
    // these keys are not unique, therefore added cst_algo_tuning.xxxx
    keys: [
      "cst_algo_tuning.tuning_interval",
      "cst_algo_tuning.simulation_interval",
      "cst_algo_tuning.simulation_granularity",
      "cst_algo_tuning.with_x",
    ],
  },
  {
    groupName: GROUP_CATEGORY.BENCHMARK,
    keys: [
      "minimum_benchmark_traffic_ratio",
      "minimum_inspection_mode_benchmark_traffic_ratio",
      "enable_benchmark_instant_refresh",
      "enable_benchmark_safeframe_size_correction",
      "enable_benchmark_key99_ab_testing",
      // "benchmark_key99_ab_testing_keys",
      "benchmark_performance_multiplier",
      "enable_benchmark_tfcd_abuse",
      "enable_benchmark_viewability_abuse",
      "enable_benchmark_prebid_drop_price_abuse",
      "benchmark_abuse_sizes",
      "benchmark_random_sizes",
    ],
  },
  {
    groupName: GROUP_CATEGORY.THIRD_PARTY_DELIVERY,
    keys: [
      "third_party_delivery_security_control_type",
      "third_party_revenue_multiplier",
      "third_party_delivery_security_algo_version",
      "enable_third_party_performance_ecpm_representation",
    ],
  },
  {
    groupName: GROUP_CATEGORY.DIRECT_DELIVERY,
    keys: [
      "enable_direct_order_pacing_security",
      "direct_order_pacing_security_minimum_traffic_ratio",
    ],
  },
  {
    groupName: GROUP_CATEGORY.DISPATCHER_BEHAVIOR,
    keys: [
      "default_dispatcher_price_in_usd",
      "dispatcher_creative_duplication_count",
      "enable_dispatcher_iframe_size_busting",
      "enable_dispatcher_iframe_maximization",
    ],
  },
  {
    groupName: GROUP_CATEGORY.OPTIMIZATION_ALGORITHM_TUNING,
    keys: ["opt_shadow_group"],
  },
  {
    groupName: GROUP_CATEGORY.METHODOLOGY_OPTIMIZATION,
    keys: [
      "enable_ad_pods",
      "enable_optimization_client_request_sizes",
      "enable_optimization_instant_refresh",
      "enable_viewability_reset_unknown_optimization",
      "enable_client_refresh_sizes_override",
    ],
  },
  {
    groupName: GROUP_CATEGORY.PRICING_OPTIMIZATION,
    keys: [
      "enable_upr_floor_pricing",
      "enable_prebid_dynamic_upr_pricing",
      "upr_detector_proposer_minimum_price",
      "upr_detector_proposer_maximum_price",
    ],
  },
  {
    groupName: GROUP_CATEGORY.SIZING_OPTIMIZATION,
    keys: [
      "enable_strict_ad_display_security",
      "maximum_ignored_request_pattern_proportion",
      "minimum_resizing_gpt_request_ratio",
      "enable_nucleus_sizing",
      "enable_lookalike_sizing",
      "minimum_ad_size_impression_ratio",
      "allowed_ad_sizes",
      "extra_ad_sizes",
      "forbid_ad_sizes",
      "enable_ad_fitting",
      "width_fitting_flexibility",
      "enable_dynamic_ad_fitting",
      "enable_flexible_sizing",
      "width_expanding_flexibility",
      "height_expanding_flexibility",
      "width_contracting_flexibility",
      "height_contracting_flexibility",
      "width_resizing_flexibility",
      "height_resizing_flexibility",
      "suggested_maximum_width",
      "suggested_maximum_height",
      "suggested_minimum_width",
      "suggested_minimum_height",
      "enable_ad_contraction",
    ],
  },
];

export function getAutoPilotConfigSchema(defaultConfig, isHintConfig) {
  // add default values
  const schemas = _getSchemas();
  _.forEach(schemas, (s) => {
    if (s.section === "system") {
      s.defaultValue = _.get(defaultConfig, [s.key], "-");
    } else {
      s.defaultValue = _.get(defaultConfig, [s.section, s.key], "-");
    }
  });
  // group to sections
  const sections = _getSections();
  const groupedSchemas = _.groupBy(schemas, "section");
  const configSchema = _.mapValues(sections, (s) => {
    let gs = groupedSchemas[s.sectionKey];
    if (s.sectionKey === "prophet") {
      s.configSchemas = _.reduce(
        prophetGroupings,
        (result, group) => {
          let { groupName, keys } = group;
          _.forEach(gs, (keyConfig) => {
            if (_.indexOf(keys, keyConfig.key) !== -1) {
              keyConfig.groupCategory = groupName;
              result.push(keyConfig);
            }
          });
          return result;
        },
        []
      );
    } else {
      s.configSchemas = gs;
    }

    return s;
  });

  // IMPORTANT: hint configs only involves the prophet section of the config
  if (isHintConfig) {
    return { prophet: _.get(configSchema, "prophet") };
  }

  return configSchema;
}

export function getSchemaByKey(configKey) {
  const schemaMap = _schemaMap();
  return _.get(schemaMap, configKey);
}

function _getSections() {
  return {
    system: {
      sectionKey: "system",
      sectionTitle: "System",
      sectionConfigKey: "",
      indexOrder: 1,
    },
    forerunner: {
      sectionKey: "forerunner",
      sectionTitle: "Forerunner",
      sectionConfigKey: "forerunner",
      indexOrder: 2,
    },
    segmentation: {
      sectionKey: "segmentation",
      sectionTitle: "Segmentation",
      sectionConfigKey: "segmentation",
      indexOrder: 3,
    },
    prophet: {
      sectionKey: "prophet",
      sectionTitle: "Prophet",
      sectionConfigKey: "prophet",
      indexOrder: 4,
    },
  };
}

function _schemaMap() {
  const schemas = _getSchemas();
  return _.mapKeys(schemas, "key");
}

export function getAllConfigKeys() {
  const schemas = _getSchemas();
  return _.map(schemas, (s) => {
    return `${s.section}.${s.key}`;
  });
}

function _getSchemas() {
  return [
    {
      key: "enabled",
      // defaultValue: true,
      description: "Whether or not to trigger autopilot for this item.",
      valueType: "boolean", // json schema type

      section: "system",
    },
    {
      key: "minimum_daily_request_volume",
      // defaultValue: 15000,
      unit: "requests", // 15000 requests
      description:
        "The minimum required daily GPT and AMP request volume for an ad unit to be optimized by autopilot.",
      format: "number", // how the ui should present this value
      valueType: "integer",
      minimum: 0, // inclusive

      section: "forerunner",
    },
    {
      key: "custom_segmentation",
      title: "Custom Segmentation",
      // defaultValue: "None",
      unit: "A batch of custom segments",
      description: "Manually configure segmentation",
      format: "SEGMENTS", // how the ui should present this value
      valueType: "batch_of_segments",
      section: "segmentation",
      isUnitOnly: true, // Only allow this config to show in Unit Level
    },
    {
      key: "minimum_propose_segmentation_interval",
      // defaultValue: 43200,
      unit: "second",
      description:
        "The resting interval in seconds between two segmentation processes.",
      format: "time", // how the ui should present this value
      valueType: "integer",
      minimum: 1, // inclusive,

      section: "segmentation",
    },
    {
      key: "minimum_resegmentation_interval",
      // defaultValue: 604800,
      unit: "second",
      description:
        "The minimum required interval in seconds for an ad unit to be flagged for resegmentation.",
      format: "time", // how the ui should present this value
      valueType: "integer",
      minimum: 0, // inclusive,

      section: "segmentation",
    },
    {
      key: "minimum_device_category_request_volume",
      // defaultValue: 5000,
      unit: "request",
      description:
        "The minimum required GPT and AMP requests for a device category be segmented explicitly.",
      format: "number", // how the ui should present this value
      valueType: "integer",
      minimum: 0, // inclusive,

      section: "segmentation",
    },
    {
      key: "minimum_inventory_registry_request_volume",
      // defaultValue: 5000,
      unit: "request",
      description:
        "The minimum required GPT and AMP requests for a registered inventory be segmented explicitly.",
      format: "number", // how the ui should present this value
      valueType: "integer",
      minimum: 0, // inclusive,

      section: "segmentation",
    },
    {
      key: "minimum_request_pattern_request_ratio",
      // defaultValue: 0.05,
      unit: "ratio",
      description:
        "The minimum required (requests / total requests) ratio for a request pattern be considered valid for segmentation.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      maximum: 1, // inclusive

      section: "segmentation",
    },
    {
      key: "allowed_device_categories",
      // defaultValue: "None",
      unit: "categories",
      description:
        "The device categories allowed to be managed by autopilot. Default to None, meaning all categories are allowed.",
      format: "array", // how the ui should present this value
      valueType: "device_categories",
      arrayOptions: ["Desktop", "Smartphone", "Tablet"],
      isUnitOnly: true, // Only allow this config to show in Unit Level

      section: "segmentation",
    },
    // PROPHET
    // General
    {
      key: "force_inspection",
      // defaultValue: false,
      description: "Whether this unit is under user inspection mode or not.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "accountable_demand_types",
      title: "Accountable Demand Types",
      // defaultValue:  ["Ad Exchange", "Exchange Bidding", "AdSense"],
      unit: "demand types",
      description:
        "The demand types that are accountable for request RPM calculation when algorithm is iterating experiments.",
      format: "array", // how the ui should present this value
      valueType: "demand_types",
      arrayOptions: [
        "Ad Exchange",
        "Exchange Bidding",
        "AdSense",
        "Price Priority",
        "Bulk",
        "Network",
        "Header Bidding",
        "Billable House",
        "Mediation",
        "SDK Bidding",
      ],
      section: "prophet",
    },
    {
      key: "enable_dispatcher_debugging",
      title: "Enable Debugging", // optional (otherwise use key to convert title)
      // defaultValue: false,
      description:
        "Whether or not to tag dispatcher line item with a debug-only value. Units with this key enabled will be equivalent as being paused, but remains full autopilot functionality in debugging mode.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "enable_trap_group",
      title: "Enable Trap Group", // optional (otherwise use key to convert title)
      // defaultValue: true,
      description:
        "Trap group to check if publisher forgot to configure inverse-targeting.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "enable_client_refresh",
      // defaultValue: true,
      description:
        "Whether or not to allow client refresh API call for this item.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "enable_request_api",
      title: "Enable Request API", // optional (otherwise use key to convert title)
      // defaultValue: false?,
      description:
        "Whether or not to allow request api for this item. If client refresh and request api are both disabled, this item will be suspended as there's no legal method available for proposal.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "enable_hybrid_opt",
      title: "Enable Hybrid Optimization",
      // defaultValue: false,
      description: "Enable Hybrid Optimization",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "hybrid_detector_traffic_ratio",
      // defaultValue: 0.05,
      unit: "ratio",
      description: "Traffic ratio for hybrid detector",
      format: "number", // how the ui should present this value
      valueType: "integer",
      // minimum: "minimum_traffic_ratio", // inclusive
      minimum: 0, // inclusive
      maximum: 100, // inclusive
      section: "prophet",
    },

    {
      key: "cst_custom_layer1_upr_price",
      title: "CST Custom Layer 1 UPR Price",
      // no default value
      unit: "price",
      description: "CST custom layer 1 UPR price (overrides existing price)",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0,
      section: "prophet",
    },
    {
      key: "custom_layer1_upr_price_pattern",
      title: "CST Custom Layer 1 UPR Price Pattern",
      // no default value
      unit: "prices",
      description: `We want to set custom layer1 floor price with some daily pattern. For example,
      we want hour[0 ~ 3] to use d1 price, hour[4~10] to use d9 price and hour[11~23] 
      to use d3 price.`,
      format: "array", // how the ui should present this value
      valueType: "array_float", // "upr_price_pattern"
      minItems: 24, // array_xxx min items
      maxItems: 24, // array_xxx max items
      sample: `0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.15, 0.15, 0.15, 0.15, 0.25, 0.25, 0.25, 0.2, 0.2, 0.2, 0.2, 0.2, 0.1, 0.1, 0.1, 0.1, 0.1`,
      note: `Overrides CST Custom Layer 1 UPR Price`,
      section: "prophet",
    },
    {
      key: "cst_custom_layer2_upr_prices",
      title: "CST Custom Layer 2 UPR Prices",
      // no default value
      unit: "upr prices",
      description:
        "Make 2nd layer upr prices configurable and also support weightings. This is a replacement of old config *hybrid_catch_all_upr_weighting*",
      format: "array", // how the ui should present this value
      valueType: "upr_prices",
      section: "prophet",
    },
    {
      key: "cst_minimum_report_aggregation_interval",
      title: "CST Minimum Report Aggregation Interval",
      // defaultValue: 1,
      unit: "hour",
      description:
        "Report should be aggregated by time that time span of each record should be larger or equal than this value.",
      format: "enum", // how the ui should present this value
      valueType: "integer_enum",
      enum: {
        1: 1,
        3: 3,
        6: 6,
        12: 12,
        24: 24,
      },
      section: "prophet",
    },
    {
      key: "disallow_catchall",
      // defaultValue: false,
      description:
        "A experimental flag to test not using catchall layer in cstv1 and cstv2p product",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "cst_catchall_update_correlator",
      // defaultValue: false,
      description:
        "Set changeCorrelator = true when passback in cst catchall traffic",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "cst_catchall_prebid_drop_enabled",
      // defaultValue: false,
      description: "Allows cst catchall to remove prebid demand",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "cst_catchall_dynamic_prebid_disabled",
      // defaultValue: false,
      description:
        "Allows cst catchall to disable dynamic prebid (in2w_key2=np)",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "hybrid_catch_all_enable_url_trunc",
      // defaultValue: false,
      description: `For cstv2p l2, would insert detector groups for url_trunc_dispatcher and url_trunc_smart_tag = true and false. And would take the one with higher rrpm into opt groups.`,
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "cst_catchall_auto_weight",
      description: `PRECONDITION: cst_custom_layer2_upr_prices is null and hybrid_catch_all_use_bm_upr is false. In case of false, only lowest price would be used. In case of true, the weight of each price will be calculated automatically based on experiment data. While cst_bm_ecpm_tracking_ratio is not zero, only price higher than bm_ecpm * cst_bm_ecpm_tracking_ratio would be considered.`,
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "cst_use_cpm_only_cost_type",
      // defaultValue: false,
      description:
        "Should first layer price predictor use performance data from cpm cost type only?",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "cst_algorithms",
      title: "CST Algorithms",
      // no default value
      unit: "algorithms",
      description: `CST algorithms order is matter. The first one will be used to decide the
      price of 'o' group.`,
      format: "array", // how the ui should present this value
      valueType: "cst_algorithms",
      section: "prophet",
    },
    {
      key: "cst_sxs_enabled",
      // defaultValue: false,
      description: `Allow prophet to perform cst algorithms simulation more than
      the first algorithm and record results in the experiment metadata.`,
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "cst_predict_schedule",
      // defaultValue: false,
      description: `Schedule a day and time to implement prediction.`,
      valueType: "cst_predict_schedule", // json schema type
      section: "prophet",
    },
    {
      key: "cst_bm_ecpm_tracking_ratio",
      title: "CST Benchmark eCPM Tracking Ratio",
      // defaultValue: 0.0,
      unit: "ratio",
      description:
        "The ratio to set the floor price for layer 2 based on benchmark's ecpm.",
      format: "number", // how the ui should present this value
      valueType: "float",
      // minimum: "minimum_traffic_ratio", // inclusive
      minimum: 0, // inclusive
      maximum: 1, // inclusive
      section: "prophet",
    },

    ///////// CST X: inside cst_x_setting
    {
      key: "cst_x_setting.cst_custom_layer1_upr_price", // inside cst_x_setting
      title: "CST Custom Layer 1 UPR Price",
      // no default value
      unit: "price",
      description:
        "If set, force x group to use custom price. The price should be in the x_prices. If not set, would use algo to predict price for x group",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0,
      section: "prophet",
    },
    {
      key: "cst_x_setting.custom_layer1_upr_price_pattern", // inside cst_x_setting
      title: "CST Custom Layer 1 UPR Price Pattern",
      // no default value
      unit: "prices",
      description: `If set, force x group to use custom price according time and ignore cst_custom_layer1_upr_price. The prices should be in the x_prices.
        If not set, would use algo to predict price for x group.
      `,
      format: "array", // how the ui should present this value
      valueType: "array_float", // "upr_price_pattern"
      minItems: 24, // array_xxx min items
      maxItems: 24, // array_xxx max items
      sample: `0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.15, 0.15, 0.15, 0.15, 0.25, 0.25, 0.25, 0.2, 0.2, 0.2, 0.2, 0.2, 0.1, 0.1, 0.1, 0.1, 0.1`,
      note: `Overrides CST Custom Layer 1 UPR Price`,
      section: "prophet",
    },
    {
      key: "cst_x_setting.cst_algorithms", // inside cst_x_setting
      title: "CST Algorithms",
      // no default value
      unit: "algorithms",
      description: `If set, the algos would be taken to predict price with x_prices  
        as candidate and the result of 1st algo would be taken as price of x group.
        If not set, would use algos of o  group with x_prices  as candidate.
        `,
      format: "array", // how the ui should present this value
      valueType: "cst_algorithms",
      section: "prophet",
    },
    {
      key: "cst_x_setting.cst_predict_schedule", // inside cst_x_setting
      // defaultValue: false,
      description: `If set, would propose x group according this schedule. If not set, would use schedule of "o".`,
      valueType: "cst_predict_schedule", // json schema type
      section: "prophet",
    },

    ///////// CST X: inside cst_algo_tuning
    {
      key: "cst_algo_tuning.tuning_interval", // inside cst_algo_tuning
      title: "CST Algo Tuning Interval",
      unit: "days",
      description:
        "The interval in days for re-tuning. zero if you don't want to re-tune.",
      format: "time", // how the ui should present this value
      valueType: "integer",
      minimum: 0, // inclusive,
      section: "prophet",
    },
    {
      key: "cst_algo_tuning.simulation_interval", // inside cst_algo_tuning
      title: "CST Algo Simulation Interval",
      unit: "days",
      description: "The interval in days for tuner running simulation.",
      format: "time", // how the ui should present this value
      valueType: "integer",
      minimum: 0, // inclusive,
      maximum: 30,
      section: "prophet",
    },
    {
      key: "cst_algo_tuning.simulation_granularity", // inside cst_algo_tuning
      title: "CST Algo Simulation Granularity",
      unit: "hours",
      description:
        "In how much hours, we would aggregate rows to calculate o_req * d_rrpm while simulation.",
      format: "enum", // how the ui should present this value
      valueType: "integer_enum",
      enum: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        6: "6",
        8: "8",
        12: "12",
        24: "24",
      },
      section: "prophet",
    },
    {
      key: "cst_algo_tuning.with_x",
      title: "CST Algo With X",
      description: `Whether to deploy algo to only x group.`,
      valueType: "boolean", // json schema type
      section: "prophet",
    },

    {
      key: "publisher_refresh_control_type",
      // defaultValue: 1,
      unit: "enum",
      description:
        "The control that specifies how Yieldbooster should react and cooperate with publisher's existing refresh mechanism.",
      format: "enum", // how the ui should present this value
      valueType: "integer_enum",
      enum: {
        0: "As usual",
        1: "Reset refresh traffic",
        2: "Block refresh traffic",
      },
      section: "prophet",
    },
    {
      key: "forbid_client_refresh_labels",
      title: "Forbid Publisher Refresh Labels",
      unit: "client refresh labels",
      description:
        "The list of client refresh labels tagged by Intowow that should be excluded from optimization.",
      format: "array", // how the ui should present this value
      valueType: "client_refresh_labels",
      arrayOptions: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "10+",
        "25+",
        "50+",
        "100+",
      ],
      section: "prophet",
    },

    {
      key: "delivered_data_consider_billable_demand_types",
      title: "Enable Delivered Data to Consider Billable Demand Types",
      description: `While consolidating delivered data, consider billable demand types as eligible`,
      valueType: "boolean", // json schema type
      section: "prophet",
    },

    {
      key: "minimum_qualified_experiment_lifespan",
      // defaultValue: 14400,
      unit: "second",
      description:
        "The minimum lifespan in seconds for an experiment to be qualified for iteration reference.",
      format: "time", // how the ui should present this value
      valueType: "integer",
      minimum: 0, // inclusive,
      section: "prophet",
    },
    {
      key: "minumum_qualified_experiment_request_volume",
      // defaultValue: 20000,
      unit: "request",
      description:
        "The minimum required request volume for an experiment to be qualified for iteration reference.",
      format: "number", // how the ui should present this value
      valueType: "integer",
      minimum: 0, // inclusive,
      section: "prophet",
    },
    {
      key: "maximum_experiment_lifespan",
      // defaultValue: 28800,
      unit: "second",
      description:
        "The maximum allowed lifespan in seconds for an experiment to be considered in-time. Any segment whose latest experiment has outlived this threshold will be iterated into a new epoch.",
      format: "time", // how the ui should present this value
      valueType: "integer",
      minimum: 0, // inclusive,
      section: "prophet",
    },
    {
      key: "maximum_traffic_ratio",
      // defaultValue: 0.9,
      unit: "ratio",
      description: "The maximum allowed traffic ratio to manage a segment.",
      format: "number", // how the ui should present this value
      valueType: "float",
      // minimum: "minimum_traffic_ratio", // inclusive
      minimum: 0, // inclusive
      maximum: 1, // inclusive
      section: "prophet",
    },
    {
      key: "minimum_traffic_ratio",
      // defaultValue: 0.1,
      unit: "ratio",
      description: "The minimum allowed traffic ratio to manage a segment.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0.01, // inclusive,
      // maximum: "maximum_traffic_ratio", // inclusive
      maximum: 1, // inclusive
      section: "prophet",
    },
    {
      key: "default_traffic_ratio",
      // defaultValue: 0.3,
      unit: "ratio",
      description: "The default traffic ratio to manage a segment.",
      format: "number", // how the ui should present this value
      valueType: "float",
      // minimum: "minimum_traffic_ratio", // inclusive
      // maximum: "maximum_traffic_ratio", // inclusive
      minimum: 0, // inclusive,
      maximum: 1, // inclusive
      section: "prophet",
    },
    {
      key: "minimum_benchmark_traffic_ratio",
      // defaultValue: 0.05,
      unit: "ratio",
      description:
        "The minimum required total group weight for benchmark groups in an experiment.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0,
      maximum: 1,
      section: "prophet",
    },
    {
      key: "minimum_inspection_mode_benchmark_traffic_ratio",
      // defaultValue: 0.3,
      unit: "ratio",
      description:
        'The minimum required total group weight for benchmark groups in an experiment where "Inspection Mode" is enabled.',
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0,
      maximum: 1,
      section: "prophet",
    },
    {
      key: "enable_benchmark_instant_refresh",
      // defaultValue: true,
      description:
        "Whether or not to cancel the default 1s delay when benchmark group utilizes the client refresh API call.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "enable_benchmark_safeframe_size_correction",
      // defaultValue: false,
      description:
        "Whether or not to correct safeframe size information in GPT request to ad server. This key is proven to improve rpm performance for AMP units if set to True.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "enable_benchmark_key99_ab_testing",
      title: "Enable Benchmark key99 A/B Testing",
      // defaultValue: false,
      description: "A flag to enable benchmark key99 a/b testing feature.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    // {
    //   key: "benchmark_key99_ab_testing_keys",
    //   // defaultValue: null
    //   description: "A flag to enable benchmark key99 a/b testing feature.",
    //   valueType: ""
    //   section: "prophet",
    // },
    {
      key: "benchmark_performance_multiplier",
      // defaultValue: 1,
      unit: "ratio",
      description:
        "The multiplier to which algorithm should adjust benchmark performance.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0.01,
      maximum: 1,
      section: "prophet",
    },
    {
      key: "enable_benchmark_tfcd_abuse",
      title: "Enable Benchmark TFCD Abuse",
      // defaultValue: true,
      description: "Whether or not tfcd abuse method for this unit is allowed.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "enable_benchmark_viewability_abuse",
      title: "Enable Benchmark Viewability Abuse",
      // defaultValue: true,
      description:
        "Whether or not viewability abuse method for this unit is allowed.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "enable_benchmark_prebid_drop_price_abuse",
      // defaultValue: true,
      description:
        "Whether or not Prebid drop price abuse method is allowed for this item.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "benchmark_abuse_sizes",
      // defaultValue: "None",
      unit: "Ad Sizes",
      description:
        "The custom sizes that are used in the benchmark abuser group.",
      format: "AD_SIZES_LAYERS", // how the ui should present this value
      valueType: "ad_sizes_layers",
      // ex: [[[1, 1]]]
      section: "prophet",
    },
    {
      key: "benchmark_random_sizes",
      title: "Benchmark Random Sizes (Deprecated)",
      isDeprecated: true,
      // defaultValue: "None",
      unit: "Sets of Ad Sizes",
      description:
        "The collection of ad size combinations that will be requested randomly for traffic allocated to benchmark groups.",
      format: "AD_SIZES_OF_AD_SIZES", // how the ui should present this value
      valueType: "sets_of_ad_sizes",
      // [[300, 250]], [[1,1]]??
      section: "prophet",
    },
    {
      key: "third_party_delivery_security_control_type",
      title: "Third Party Security Control Type",
      // defaultValue: 1,
      unit: "enum",
      description:
        "The control specifying how Yieldbooster should secure publisher's third party demand delivery.",
      format: "enum", // how the ui should present this value
      valueType: "integer_enum",
      enum: {
        0: "Ignore third party demand",
        1: "Represent third party strength with unit-level request rpm",
        2: "Represent third party strength with unit-level ecpm",
        3: "Calculate third party strength based on segment-level simulation",
      },
      section: "prophet",
    },
    {
      key: "third_party_revenue_multiplier",
      // defaultValue: 0.8,
      unit: "ratio",
      description:
        "A multiplier to denote the true value of publishers' third party programmatic revenue.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      maximum: 1, // inclusive
      section: "prophet",
    },
    {
      key: "third_party_delivery_security_algo_version",
      title: "Third Party Security Algo Version",
      // defaultValue: v202101",
      unit: "version",
      description:
        "The algo version based on which the third party delivery security algorithm should be toggled.",
      format: "enum", // how the ui should present this value
      valueType: "string_enum",
      enum: {
        v202101: "v202101",
      },
      section: "prophet",
    },
    {
      key: "enable_third_party_performance_ecpm_representation",
      title: "Enable Third Party eCPM Representation (Deprecated)",
      isDeprecated: true,
      // defaultValue: false,
      description:
        "Whether to enable using third party eCPM to represent third party earning power instead of request RPM.",
      valueType: "boolean",
      section: "prophet",
    },
    {
      key: "enable_direct_order_pacing_security",
      // defaultValue: false,
      description: "Whether or not to safeguard direct order pacing.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "direct_order_pacing_security_minimum_traffic_ratio",
      // defaultValue: 0.3,
      unit: "ratio",
      description:
        "The minimum traffic ratio when direct order pacing is turned on.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      maximum: 1, // inclusive
      section: "prophet",
    },
    {
      key: "default_dispatcher_price_in_usd",
      title: "Default Dispatcher USD Price",
      // defaultValue: 350,
      unit: "USD",
      description: "The value CPM in USD for setting up dispatcher line items.",
      format: "currency", // how the ui should present this value
      valueType: "float",
      minimum: 0,
      section: "prophet",
    },
    {
      key: "dispatcher_creative_duplication_count",
      // defaultValue: 3,
      unit: "duplication count",
      description:
        "The duplication count of dispatcher creative. Only set up this value in cases of shared units or infinite scrolling units.",
      format: "number", // how the ui should present this value
      valueType: "integer",
      minimum: 1, // inclusive
      maximum: 10, // inclusive
      section: "prophet",
    },
    {
      key: "enable_dispatcher_iframe_size_busting",
      // defaultValue: false,
      description:
        "Whether or not dispatcher should specifically bust ad div. Only networks / units with fixed div sizes are required to be turned on, otherwise turned off. If this flag is on, enable_client_refresh_sizes_override are fixed to False.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "enable_dispatcher_iframe_maximization",
      // defaultValue: false,
      description:
        "Whether or not to fix dispatcher creative size always to the maximum possible.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "opt_shadow_group",
      title: "Setup optimization shadow group algorithm",
      // defaultValue: "NO",
      unit: "algorithm",
      description:
        "Used to test different optimization promotion algorithms or test different optimization weighting algorithms.",
      format: "enum", // how the ui should present this value
      valueType: "string_enum",
      enum: {
        NO: "NO",
        T_MINUS_TWO: "T_MINUS_TWO",
      },
      section: "prophet",
    },
    {
      key: "enable_ad_pods",
      // defaultValue: false,
      description: "Whether or not to enable ad pods for this item.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "enable_optimization_client_request_sizes",
      // defaultValue: true,
      description:
        'Whether or not to allow "client_request_sizes" sizes set be proposed in optimization group using Intowow\'s AdX line items.',
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "enable_optimization_instant_refresh",
      // defaultValue: false,
      description:
        "Whether or not to force turn on instant refresh for client-refresh optimization groups.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },

    {
      key: "enable_viewability_reset_unknown_optimization",
      // defaultValue: false,
      description:
        "Whether or not to include viewability reset to unknown gpt request parameter reset method as an optimization method for iteration.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "enable_client_refresh_sizes_override",
      title: "Enable Client Refresh Sizes Override (Deprecated)",
      isDeprecated: true,
      // defaultValue: false,
      description:
        "Whether or not to enable candidates that use client_refresh API with custom sizes. Only turn this on for networks or ad units that do not implement refresh on its own.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
    {
      key: "enable_upr_floor_pricing",
      // defaultValue: false,
      description: "Whether to enable upr floor pricing feature.",
      valueType: "boolean",
      section: "prophet",
    },
    {
      key: "enable_prebid_dynamic_upr_pricing",
      // defaultValue: false,
      description:
        "Whether to enable dynamic upr pricing based on Prebid hb_pb value.",
      valueType: "boolean",
      section: "prophet",
    },

    {
      key: "upr_detector_proposer_minimum_price",
      title: "Minimum price for UPR Detector Proposer",
      // defaultValue: null, // optional
      unit: "price",
      description: "The minimum price for UPR Detector Proposer",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      section: "prophet",
    },

    {
      key: "upr_detector_proposer_maximum_price",
      title: "Maximum price for UPR Detector Proposer",
      // defaultValue: null, // optional
      unit: "price",
      description: "The maximum price for UPR Detector Proposer",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      section: "prophet",
    },

    {
      key: "enable_strict_ad_display_security",
      // defaultValue: false,
      description:
        "Whether to enable strict security for ad creative display on UI to prevent any potential UI breaking incidents.",
      valueType: "boolean",
      section: "prophet",
    },
    {
      key: "maximum_ignored_request_pattern_proportion",
      // defaultValue: 0.1,
      unit: "ratio",
      description:
        "Request pattern from the least n% impression will be ignored. If you want to refer to only the top 90% impression request patterns, please enter the value of (1-0.9) = 0.1.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      maximum: 0.99, // inclusive
      section: "prophet",
    },
    {
      key: "minimum_resizing_gpt_request_ratio",
      title: "Minimum GPT Dominant Request Ratio",
      // defaultValue: 0.8,
      unit: "ratio",
      description:
        "The minimum allowed (GPT requests / total requests) ratio for Yieldbooster to apply flexible sizing optimization on a segment.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      maximum: 1, // inclusive
      section: "prophet",
    },
    {
      key: "enable_nucleus_sizing",
      // defaultValue: true,
      description:
        "Whether to enable nucleus sizing (aka. single sizing) optimization. When turned on, you might need to spend extra care to monitor holistic revenue performance.",
      valueType: "boolean",
      section: "prophet",
    },
    {
      key: "enable_lookalike_sizing",
      // defaultValue: true,
      description:
        "Whether to enable lookalike sizing (aka. custom sizing) optimization. When turned on, publishers will see unexpected sizes on their UI and GAM reporting.",
      valueType: "boolean",
      section: "prophet",
    },
    {
      key: "minimum_ad_size_impression_ratio",
      title: "Minimum Usable Ad Size Impression Ratio",
      // defaultValue: 0.01,
      unit: "ratio",
      description:
        "The minimum required (impressions / total impressions) ratio for any delivered ad sizes to be considered a valid ad size.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      maximum: 1, // inclusive
      section: "prophet",
    },
    {
      key: "allowed_ad_sizes",
      // defaultValue: "None",
      unit: "Ad Sizes",
      description:
        "The collection of ad sizes from which Yieldbooster can only use. Sizes outside of this collection are considered forbidden.",
      format: "AD_SIZES", // how the ui should present this value
      valueType: "ad_sizes",
      // [[300, 250]]
      section: "prophet",
    },
    {
      key: "extra_ad_sizes",
      // defaultValue: "None",
      unit: "Ad Sizes",
      description:
        "The collection of additional ad sizes that can be used by Yieldbooster to optimize.",
      format: "AD_SIZES", // how the ui should present this value
      valueType: "ad_sizes",
      // [[300, 250]]
      section: "prophet",
    },
    {
      key: "forbid_ad_sizes",
      // defaultValue: "None",
      unit: "Ad Sizes",
      description:
        "The collection of ad sizes that are forbidden to appear at all.",
      format: "AD_SIZES", // how the ui should present this value
      valueType: "ad_sizes",
      // [[300, 250]]
      section: "prophet",
    },
    {
      key: "enable_ad_fitting",
      // defaultValue: false,
      description:
        "Whether to enable ad fitting to enable ad display resizing.",
      valueType: "boolean",
      section: "prophet",
    },

    {
      key: "width_fitting_flexibility",
      // defaultValue: 0.4,
      unit: "ratio",
      description:
        "The width fitting flexibility that we can apply to resize ad creatives given the maximum and minimum allowed ad width. For example, if the maximum ad contrainer width is 300, we are allowed to request ad sizes whose width is up to 420 (300*1.4) AND whose height can be within the height container constraints after fitting.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      maximum: 1, // inclusive
      section: "prophet",
    },

    {
      key: "enable_dynamic_ad_fitting",
      // defaultValue: true,
      description:
        "Whether to enable dynamic (intelligent) ad fitting to client-side UI ad display.",
      valueType: "boolean",
      section: "prophet",
    },
    {
      key: "enable_flexible_sizing",
      // defaultValue: true,
      description: "Whether or not to enable flexible sizing for this item.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },

    {
      key: "width_expanding_flexibility",
      // defaultValue: 0,
      unit: "ratio",
      description:
        "The maximum allowed flexibility to expand width of the given publisher UI maximum width contraints.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      maximum: 1, // inclusive
      section: "prophet",
    },
    {
      key: "height_expanding_flexibility",
      // defaultValue: 0,
      unit: "ratio",
      description:
        "The maximum allowed flexibility to expand height of the given publisher UI maximum height contraints.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      maximum: 1, // inclusive
      section: "prophet",
    },
    {
      key: "width_contracting_flexibility",
      // defaultValue: 0,
      unit: "ratio",
      description:
        "The maximum allowed flexibility to shrink width of the given publisher UI minimum width contraints.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      maximum: 1, // inclusive
      section: "prophet",
    },
    {
      key: "height_contracting_flexibility",
      // defaultValue: 0,
      unit: "ratio",
      description:
        "The maximum allowed flexibility to shrink height of the given publisher UI minimum height contraints.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      maximum: 1, // inclusive
      section: "prophet",
    },

    {
      key: "width_resizing_flexibility",
      title: "Width Resizing Flexibility (Deprecated)",
      isDeprecated: true,
      // defaultValue: 0.12,
      unit: "ratio",
      description:
        "The maximum allowed flexibility to expand or shrink the width in flexible sizing optimization.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      maximum: 1, // inclusive
      section: "prophet",
    },
    {
      key: "height_resizing_flexibility",
      title: "Height Resizing Flexibility (Deprecated)",
      isDeprecated: true,
      // defaultValue: 0.5,
      unit: "ratio",
      description:
        "The maximum allowed flexibility to expand or shrink the height in flexible sizing optimization.",
      format: "number", // how the ui should present this value
      valueType: "float",
      minimum: 0, // inclusive,
      maximum: 1, // inclusive
      section: "prophet",
    },

    {
      key: "suggested_maximum_width",
      // defaultValue: "None",
      unit: "px",
      description:
        "The suggested maximum allowed width for the ad slot on the UI.",
      format: "number", // how the ui should present this value
      valueType: "integer",
      minimum: 1, // inclusive,
      section: "prophet",
    },
    {
      key: "suggested_maximum_height",
      // defaultValue: "None",
      unit: "px",
      description:
        "The suggested maximum allowed height for the ad slot on the UI.",
      format: "number", // how the ui should present this value
      valueType: "integer",
      minimum: 1, // inclusive,
      section: "prophet",
    },
    {
      key: "suggested_minimum_width",
      // defaultValue: "None",
      unit: "px",
      description:
        "The suggested minimum allowed width for the ad slot on the UI.",
      format: "number", // how the ui should present this value
      valueType: "integer",
      minimum: 1, // inclusive,
      section: "prophet",
    },
    {
      key: "suggested_minimum_height",
      // defaultValue: "None",
      unit: "px",
      description:
        "The suggested minimum allowed height for the ad slot on the UI.",
      format: "number", // how the ui should present this value
      valueType: "integer",
      minimum: 1, // inclusive,
      section: "prophet",
    },
    {
      key: "enable_ad_contraction",
      title: "Enable Ad Contraction (Deprecated)",
      isDeprecated: true,
      // defaultValue: true,
      description:
        "Whether or not to allow Google contracted sizes being served in optimization.",
      valueType: "boolean", // json schema type
      section: "prophet",
    },
  ];
}

import React from "react";
import _ from "lodash";
import PopupWrapper from "../../../common/PopupWrapper";
import SegmentsViewTable from "../../../common/SegmentsViewTable";
import { showEmpty } from "helpers/Compare";

class BatchOfSegmentsFormatter extends React.PureComponent {
  render() {
    const {
      includeBatchKey,
      batchSegments,
      showActionButtons,
      isEditCardOpen,
      handleDuplicateSegment,
      handleArchiveSegment,
      handleUnarchiveSegment,
      handleEditSegment,
      handleRemoveSegment,
    } = this.props;

    if (!batchSegments) {
      return showEmpty(batchSegments);
    }

    const { batch_key, segments } = batchSegments;

    if (includeBatchKey && !batch_key) {
      return "None";
    }

    const activeSegments = _.filter(segments, (s) => {
      return !s.deprecated;
    });

    const deprecatedSegments = _.filter(segments, (s) => {
      return s.deprecated;
    });

    const disabledBtnClass = "text-gray-600 px-2 cursor-not-allowed";

    return (
      <div>
        {!showActionButtons && (
          <div>
            Batch: {batch_key} ({activeSegments.length} segments)
          </div>
        )}

        <div>
          {activeSegments.map((seg, i) => {
            return (
              <div key={seg.segment_key}>
                <div className="border flex w-full justify-between bg-gray-100 p-1">
                  <SegmentDetailPopover
                    triggerElement={
                      <div className="cursor-pointer text-blue-600 hover:underline">
                        {seg.segment_key}
                      </div>
                    }
                    segment={seg}
                  ></SegmentDetailPopover>

                  {showActionButtons && (
                    <div className="flex w-1/3 justify-end">
                      {seg.isEditable && (
                        <button
                          type="button"
                          className={
                            isEditCardOpen
                              ? disabledBtnClass
                              : "px-2 text-blue-700 hover:text-blue-900"
                          }
                          disabled={isEditCardOpen}
                          onClick={() => handleEditSegment(seg.segment_key)}
                        >
                          Edit
                        </button>
                      )}

                      <button
                        type="button"
                        className={
                          isEditCardOpen
                            ? disabledBtnClass
                            : "px-2 text-blue-700 hover:text-blue-900"
                        }
                        disabled={isEditCardOpen}
                        onClick={() => handleDuplicateSegment(seg.segment_key)}
                      >
                        Duplicate
                      </button>

                      {seg.isEditable ? (
                        <button
                          type="button"
                          className={
                            isEditCardOpen
                              ? disabledBtnClass
                              : "px-2 text-blue-700 hover:text-blue-900"
                          }
                          disabled={isEditCardOpen}
                          onClick={() => handleRemoveSegment(seg.segment_key)}
                        >
                          Remove
                        </button>
                      ) : (
                        <button
                          type="button"
                          className={
                            isEditCardOpen
                              ? disabledBtnClass
                              : "px-2 text-red-700 hover:text-red-900"
                          }
                          disabled={isEditCardOpen}
                          onClick={() => handleArchiveSegment(seg.segment_key)}
                        >
                          Archive
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {showActionButtons && (
          <div
            className={`mt-2 ${
              deprecatedSegments.length > 0 && "border-t border-gray-600"
            }`}
          >
            {deprecatedSegments.map((seg, i) => {
              return (
                <div
                  key={seg.segment_key}
                  className="border flex w-full justify-between bg-gray-300 p-1"
                >
                  <SegmentDetailPopover
                    triggerElement={
                      <div className="cursor-pointer text-blue-600 hover:underline">
                        {seg.segment_key}
                      </div>
                    }
                    segment={seg}
                  ></SegmentDetailPopover>

                  {showActionButtons && (
                    <div className="flex w-1/3 justify-end">
                      <button
                        type="button"
                        className={
                          isEditCardOpen
                            ? disabledBtnClass
                            : "px-2 text-blue-700 hover:text-blue-900"
                        }
                        onClick={() => handleDuplicateSegment(seg.segment_key)}
                        disabled={isEditCardOpen}
                      >
                        Duplicate
                      </button>

                      <button
                        type="button"
                        className={
                          isEditCardOpen
                            ? disabledBtnClass
                            : "px-2 text-gray-700 hover:text-gray-900"
                        }
                        onClick={() => handleUnarchiveSegment(seg.segment_key)}
                        disabled={isEditCardOpen}
                      >
                        Unarchive
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

class SegmentDetailPopover extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    // segment: { segment_key, targeting }
    const { triggerElement, segment } = this.props;
    return (
      <PopupWrapper
        place="bottom center"
        triggerType="click"
        hideArrow={false}
        triggerElement={triggerElement}
        popupElement={
          <div className="shadow border p-4" style={{ width: "600px" }}>
            {/* <div>{segment.segment_key}</div> */}
            <div>
              {/* <textarea
                className="w-full text-sm h-32"
                value={JSON.stringify(segment.targeting, null, 4)}
                disabled
                cols={6}
              ></textarea> */}
              <SegmentsViewTable segments={[segment]}></SegmentsViewTable>
            </div>
          </div>
        }
      ></PopupWrapper>
    );
  }
}

export default BatchOfSegmentsFormatter;

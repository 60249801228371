import React from "react";
import _ from "lodash";

import { PublisherAPI } from "apis";
import PublishersTable from "./PublishersTable";
import UpdatePublisherTrialStatus from "./UpdatePublisherTrialStatus";
import UpdatePublisherCurrencySetting from "./UpdatePublisherCurrencySetting";
import PublisherFilter from "../../common/PublisherFilter";
import ModalWrapper from "components/common/ModalWrapper";

class Publishers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      publishers: [],
      filteredPublishers: [],
      isLoading: false,
      isSearching: false,
      errMsg: "",
      isOpenTrialModal: false,
      isOpenCurrencyModal: false,
      selectedPublisher: null,
      inputValue: "",
    };

    this.handleSearchPublisher = this.handleSearchPublisher.bind(this);
  }

  async componentDidMount() {
    document.title = `Publishers | YB Observer`;
    this.setState({ isLoading: true });
    try {
      let publishers = await PublisherAPI.getPublishersDetail();

      publishers = _.orderBy(publishers, ["publisherId"], ["desc"]);

      this.setState({
        publishers,
        filteredPublishers: publishers,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  handleSearchPublisher(inputValue) {
    const filterInput =
      inputValue === undefined ? this.state.inputValue : inputValue;
    let filterKeys = ["publisherId", "name", "accountType", "type"];

    let filteredPublishers = _.filter(this.state.publishers, (item) => {
      let isMatch = false;

      _.forEach(filterKeys, (fKey) => {
        const value = _.get(item, fKey);

        if (value && _.toLower(value).includes(_.toLower(filterInput))) {
          isMatch = true;
        }
      });

      return isMatch;
    });

    filteredPublishers = _.orderBy(
      filteredPublishers,
      ["publisherId"],
      ["desc"]
    );

    if (filterInput === "") {
      this.setState({
        isSearching: false,
        filteredPublishers,
        inputValue: filterInput,
      });
    } else {
      this.setState({
        isSearching: true,
        filteredPublishers,
        inputValue: filterInput,
      });
    }
  }

  handleTrialModal = (item, isOpen) => {
    this.setState({
      selectedPublisher: item,
      isOpenTrialModal: isOpen,
    });
  };

  handleUpdateTrialStatus = ({
    publisherId,
    accountStatus: {
      trialStatus: { expDate, billableStartDate },
    },
    accountType,
  }) => {
    const idx = _.findIndex(this.state.publishers, { publisherId });
    const publishers = [...this.state.publishers];
    publishers[idx].accountStatus.trialStatus.expDate = expDate;
    publishers[idx].accountStatus.trialStatus.billableStartDate =
      billableStartDate;
    publishers[idx].accountType = accountType;
    this.setState({ publishers });
    this.handleSearchPublisher();
  };

  handleCurrencyModal = (item, isOpen) => {
    this.setState({
      selectedPublisher: item,
      isOpenCurrencyModal: isOpen,
    });
  };

  handleUpdateCurrencySetting = ({ publisherId, reportingCurrency }) => {
    const idx = _.findIndex(this.state.publishers, { publisherId });
    const publishers = [...this.state.publishers];
    publishers[idx].reportingCurrency = reportingCurrency;
    this.setState({ publishers });
    this.handleSearchPublisher();
  };

  render() {
    const {
      publishers,
      filteredPublishers,
      errMsg,
      isLoading,
      isSearching,
      isOpenTrialModal,
      isOpenCurrencyModal,
      selectedPublisher,
    } = this.state;

    return (
      <div>
        <ModalWrapper
          isOpen={isOpenTrialModal || isOpenCurrencyModal}
          showCloseFooter={false}
          customContentStyle={{
            overflow: "unset",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 0, // hack to make the modal NOT BLURRY
            width: "50%",
            height: "",
          }}
        >
          {isOpenTrialModal && (
            <UpdatePublisherTrialStatus
              publisher={selectedPublisher}
              handleClose={() => this.handleTrialModal(null, false)}
              handleUpdateData={this.handleUpdateTrialStatus}
            ></UpdatePublisherTrialStatus>
          )}
          {isOpenCurrencyModal && (
            <UpdatePublisherCurrencySetting
              publisher={selectedPublisher}
              handleClose={() => this.handleCurrencyModal(null, false)}
              handleUpdateData={this.handleUpdateCurrencySetting}
            ></UpdatePublisherCurrencySetting>
          )}
        </ModalWrapper>
        <div className="bg-white px-12">
          <div className="mb-4 flex justify-between pt-8 ">
            <div className="text-4xl font-extrabold text-gray-900">
              Publishers
            </div>

            <div className="flex gap-4">
              <div>
                <button
                  type="button"
                  className="rounded shadow bg-blue-400 px-4 py-2 font-semibold text-white hover:bg-blue-600"
                  onClick={() =>
                    this.props.history.push(
                      "/ops-mgmt/account/create-publisher"
                    )
                  }
                >
                  Create Publisher
                </button>
              </div>

              <div>
                <button
                  type="button"
                  className="rounded shadow bg-blue-400 px-4 py-2 font-semibold text-white hover:bg-blue-600"
                  onClick={() =>
                    this.props.history.push("/ops-mgmt/account/create-reseller")
                  }
                >
                  Create Reseller
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="min-h-screen bg-gray-200 px-12 py-8">
          {isLoading ? (
            "Loading..."
          ) : (
            <div>
              {errMsg ? (
                <div>{errMsg}</div>
              ) : (
                <>
                  <div className="mb-4">
                    <PublisherFilter
                      handleSearch={this.handleSearchPublisher}
                      placeholder={"Search by publisher id and name"}
                    ></PublisherFilter>
                    <div className="mx-1 my-1 text-sm text-gray-700">
                      {isSearching
                        ? `Found ${filteredPublishers.length} out of `
                        : ""}
                      {publishers.length} publishers
                    </div>
                  </div>
                  <PublishersTable
                    handleTrialModal={(data) =>
                      this.handleTrialModal(data, true)
                    }
                    handleCurrencyModal={(data) =>
                      this.handleCurrencyModal(data, true)
                    }
                    items={filteredPublishers}
                  ></PublishersTable>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Publishers;

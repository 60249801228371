import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import LoadingUI from "components/common/LoadingUI";
import ModalWrapper from "components/common/ModalWrapper";
import HighchartWrapper from "components/common/HighchartWrapper";
import PopupWrapper from "components/common/PopupWrapper";
import DateRangePopover from "components/playground/home-dashboard-v2/DateRangePopover";
import { DATE_RANGE_TYPE } from "components/playground/home-dashboard-v2/DateHelper";

import { IoIosArrowDown } from "react-icons/io";

import { NET_REV, netRevTitle } from "constants/Unit";
import { AnatomyAPI } from "apis";
import { addChartBgSection } from "helpers/Utils";

const netDataMap = new Map([
  [NET_REV.GOOGLE, "net_google_incr_rev_ratio"],
  [NET_REV.HOLISTIC, "net_holistic_incr_rev_ratio"],
  [NET_REV.BILLABLE, "net_billable_lift"],
]);

class UnitRevenueLiftTrendModal extends React.Component {
  constructor(props) {
    super(props);

    const today = moment().format("YYYY-MM-DD");

    this.state = {
      errmsg: "",
      isLoading: false,
      startDate: moment(today).subtract(30, "d").format("YYYY-MM-DD"),
      endDate: today,
      dataType: this.props.dataType,

      originData: null,
      options: null,
    };

    this.child = React.createRef();

    this.handleCancel = this.handleCancel.bind(this);
    this.handleClosePopover = this.handleClosePopover.bind(this);
    this.handleDateRangeFilterChanged =
      this.handleDateRangeFilterChanged.bind(this);
    this.handleDataTypeChange = this.handleDataTypeChange.bind(this);
    this.handleChartOption = this.handleChartOption.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
  }

  componentDidMount() {
    this.handleGetData();
  }

  handleCancel() {
    this.child.current.handleClosePopup();
  }

  handleClosePopover() {
    this.child.current.handleClosePopup();
  }

  handleDateRangeFilterChanged({ startDate, endDate }) {
    this.setState(
      {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        originData: null,
      },
      () => {
        this.handleGetData();
      }
    );
  }

  handleDataTypeChange(dataType) {
    this.setState({ dataType }, () => {
      this.handleGetData();
    });
  }

  async handleGetData() {
    if (this.state.originData && this.state.originData[this.state.dataType]) {
      this.handleChartOption();
      return;
    }

    this.setState({ isLoading: true, options: null, errmsg: "" });
    try {
      const result = await AnatomyAPI.getUnitRevLiftTrend({
        extGamUnitId: this.props.extUnitId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        reportType: this.state.dataType,
      });

      this.setState(
        {
          originData: {
            ...(this.state.originData || {}),
            [this.state.dataType]: result,
          },
        },
        () => {
          this.handleChartOption();
        }
      );
    } catch (e) {
      this.setState({ errmsg: e.message });
    }

    this.setState({ isLoading: false });
  }

  handleChartOption() {
    const { dataType, originData } = this.state;
    const metric = netDataMap.get(dataType);

    const data = _.cloneDeep(originData[dataType]);
    if (!data) return;

    const dataSeries = _(data)
      .map((d) => {
        const y = _.round(d[metric] * 100, 2);
        return {
          x: new Date(d.date).getTime(),
          y,
          color: y < 0 && "red",
        };
      })
      .sortBy("x")
      .value();

    const dataMin = _.get(_.minBy(dataSeries, "y"), "y", 0);

    const options = {
      chart: {
        height: 320,
        zoomType: "x",
        events: {
          load: function () {
            addChartBgSection(this);
          },
        },
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormat: "<b>{point.y}%</b>",
      },
      yAxis: {
        title: {
          text: "%",
        },
        min: dataMin < 0 ? dataMin : 0,
        plotBands: this._createYAxisPlotBands(),
      },

      series: [
        {
          name: netRevTitle.get(dataType),
          type: "line",
          data: dataSeries,
        },
      ],
    };

    this.setState({ options });
  }

  _createYAxisPlotBands() {
    return [
      {
        from: -9999999,
        to: 0,
        color: "#f4e6e6",
      },
    ];
  }

  _getDateRange(start = this.state.startDate, end = this.state.endDate) {
    const startDay = moment(start);
    const endDay = moment(end);
    const dayDiff = endDay.diff(startDay, "days");

    const range = [];
    for (let i = 0; i <= dayDiff; i++) {
      range.push(startDay.clone().add(i, "days").format("YYYY-MM-DD"));
    }

    return range;
  }

  render() {
    const { unitId, unitName, networkName, networkId } = this.props;

    const { startDate, endDate, dataType, options, isLoading, errmsg } =
      this.state;

    const startDateFormatted = moment(startDate).format("YYYY-MM-DD ddd");
    const endDateFormatted = moment(endDate).format("YYYY-MM-DD ddd");

    return (
      <ModalWrapper
        isOpen={true}
        handleClose={this.props.handleClose}
        showCloseFooter={true}
      >
        {networkName && networkId && (
          <>
            <div className="mb-1 font-semibold text-gray-600">Network:</div>
            <div className="mb-4 flex">
              <div className="mr-1 font-semibold text-gray-800">
                {networkId}
              </div>
              <div className="truncate mr-1 text-gray-800" title={networkName}>
                {networkName}
              </div>
            </div>
          </>
        )}
        <div className="mb-1 font-semibold text-gray-600">Unit:</div>
        <div className="mb-4 flex">
          <div className="mr-1 font-semibold text-gray-800">{unitId}</div>
          <div className="truncate mr-1 text-gray-800" title={unitName}>
            {unitName}
          </div>
        </div>
        <div className="mb-1 font-semibold text-gray-600">Date Range:</div>
        <PopupWrapper
          ref={this.child}
          place="bottom left"
          triggerType="click"
          hideArrow={false}
          triggerElement={
            <div className="border rounded hover:shadow focus:outline-none mb-4 inline-flex cursor-pointer items-center border-gray-400 bg-white px-4 py-2 text-sm text-gray-700">
              <span className="mr-4">
                <span className="text-sm text-gray-600">
                  From <b className="text-gray-700">{startDateFormatted}</b> to{" "}
                  <b className="text-gray-700">{endDateFormatted}</b>
                </span>
              </span>

              <IoIosArrowDown></IoIosArrowDown>
            </div>
          }
          popupElement={
            <DateRangePopover
              startDate={startDate}
              endDate={endDate}
              dateRanges={[
                DATE_RANGE_TYPE.THIS_WEEK,
                DATE_RANGE_TYPE.LAST_WEEK,
                DATE_RANGE_TYPE.LAST_2_WEEKS,
                DATE_RANGE_TYPE.THIS_MONTH,
                DATE_RANGE_TYPE.LAST_MONTH,
              ]}
              minDate={moment().subtract(5, "year").toDate()}
              handleCancel={this.handleCancel}
              handleClosePopover={this.handleClosePopover}
              handleDateRangeFilterChanged={this.handleDateRangeFilterChanged}
            ></DateRangePopover>
          }
        ></PopupWrapper>
        <div className="mb-1 font-semibold text-gray-600">Date Type:</div>
        <div className="mb-4 flex gap-2">
          {_.map(NET_REV, (value) => {
            return (
              <button
                className={`rounded mr-1 px-4 py-1 font-semibold text-gray-700 ${
                  dataType === value
                    ? "bg-blue-200"
                    : "bg-gray-100 hover:bg-gray-200 hover:text-gray-800"
                }`}
                key={value}
                onClick={() => this.handleDataTypeChange(value)}
              >
                {netRevTitle.get(value)}
              </button>
            );
          })}
        </div>
        <div className="w-full">
          {isLoading && <LoadingUI></LoadingUI>}
          {errmsg !== "" && <div className="text-red-800">{errmsg}</div>}
          {options && <HighchartWrapper options={options}></HighchartWrapper>}
        </div>
      </ModalWrapper>
    );
  }
}

export default UnitRevenueLiftTrendModal;

import React from "react";
import TreeStyle from "./css/tree-selector.module.css";
import CommonStyle from "./css/common.module.css";

export default function NoActiveUnits(props) {
  return (
    <a
      className={`col-md-12 ${TreeStyle.tableColumn} ${CommonStyle.accesibleItem}`}
      disabled
    >
      <div
        className={`row ${TreeStyle.node} ${TreeStyle.leaf} ${TreeStyle.noItems}`}
      >
        <div className="col-md-12" style={{ textAlign: "center" }}>
          No active units
        </div>
      </div>
    </a>
  );
}

import React from "react";
import _ from "lodash";
import { FiX } from "react-icons/fi";
import { showEmpty } from "helpers/Compare";

class AdSizesFormatter extends React.PureComponent {
  render() {
    const { adSizes, showRemoveButton, handleRemoveAdSize } = this.props;

    if (!adSizes || _.isEmpty(adSizes)) {
      return showEmpty(adSizes);
    }

    if (typeof adSizes === "string") {
      return adSizes;
    }

    // if (typeof adSizes === "number") {
    //   return adSizes;
    // }
    // console.log(adSizes, typeof adSizes);

    return (
      <div className="flex flex-wrap">
        {adSizes.map((size, i) => {
          const sizeString = typeof size === "string" ? size : size.join("x");

          return (
            <div
              key={i}
              className="mb-1 mr-2 flex items-center bg-gray-400 px-2"
            >
              <div className="font-mono">{sizeString}</div>
              {showRemoveButton && (
                <button
                  type="button"
                  className="ml-2 text-blue-600 hover:text-blue-800"
                  onClick={() => handleRemoveAdSize(size)}
                >
                  <FiX></FiX>
                </button>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default AdSizesFormatter;

import React from "react";
import ReactTooltip from "react-tooltip";

import YbReqStatDiagram from "./ReqStatDiagram";
import NumberFormat from "./NumberFormat";

import Style from "./css/req-stat-tooltip.module.css";

const YbReqStatTooltip = (props) => {
  const { item, tooltipKey } = props;

  return (
    <ReactTooltip id={tooltipKey} type="dark" effect="solid" place="top">
      <div className={Style.statTooltipWrapper}>
        <div className={Style.subHead}>
          Daily Avg Req:{" "}
          <NumberFormat value={item.dailyTotalAvg} format="0,0a"></NumberFormat>
        </div>

        <div className={Style.mainHead}>
          Compatible Req:{" "}
          <NumberFormat
            value={item.dailyCompatibleAvgReq}
            format="0,0a"
          ></NumberFormat>
        </div>

        <div className={Style.diagramWrapper}>
          <YbReqStatDiagram item={item} isGraphOnly></YbReqStatDiagram>
        </div>

        <div className={Style.stat}>
          <div className={Style.gptIndicator}></div>
          GPT: <NumberFormat value={item.gptReq} format="0,0a"></NumberFormat> (
          <NumberFormat
            value={item.gptReqPercentage}
            format="0,0"
            postfix="%"
          ></NumberFormat>
          )
        </div>

        <div className={Style.stat}>
          <div className={Style.ampIndicator}></div>
          AMP: <NumberFormat value={item.ampReq} format="0,0a"></NumberFormat> (
          <NumberFormat
            value={item.ampReqPercentage}
            format="0,0"
            postfix="%"
          ></NumberFormat>
          )
        </div>

        <div className={Style.stat}>
          <div className={Style.othersIndicator}></div>
          Others:{" "}
          <NumberFormat value={item.othersReq} format="0,0a"></NumberFormat> (
          <NumberFormat
            value={item.othersReqPercentage}
            format="0,0"
            postfix="%"
          ></NumberFormat>
          )
        </div>
      </div>
    </ReactTooltip>
  );
};

export default YbReqStatTooltip;

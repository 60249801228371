import React from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

import { NetworkAPI, OnboardingAPI } from "apis";

import Checkbox from "components/common/Checkbox";
import ViewerWrapper from "components/common/ViewerWrapper";
import TextAreaWithCsv from "components/common/TextAreaWithCsv";
import PromptModal from "components/common/PromptModal";
import NetworksSelector from "./NetworksSelector";
import YieldSetsSelector from "./YieldSetsSelector";

import { FaExternalLinkAlt } from "react-icons/fa";

import {
  thClass,
  tdClass,
  buttonModalConfirmClass,
  buttonConfirmClass,
  buttonConfirmDisable,
} from "helpers/StyleClass";
import { MESSAGE } from "constants/Message";

const ACTION = {
  RUNNING: "RUNNING",
  PAUSED: "PAUSED",
  MIGRATE: "migrate",
};

class AdUnitsBatchActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      validateErrMsg: null,

      selectedNetwork: null,
      selectedYieldSet: null,

      unitIds: [],
      selectedAction: ACTION.RUNNING,

      validatedUnits: null,
      selectedUnitIds: [],
      resultUnits: [],

      isPromptModalOpen: false,
      isSaving: false,
      promptMsg: [],
      promptHeader: "",
      promptMessage: "",
      promptAction: null,
    };

    this.handleSelectNetwork = this.handleSelectNetwork.bind(this);
    this.handleSelectYieldSet = this.handleSelectYieldSet.bind(this);
    this.handleSelectUnitChanged = this.handleSelectUnitChanged.bind(this);
    this.handleChangeTextArea = this.handleChangeTextArea.bind(this);
    this.handleActionChanged = this.handleActionChanged.bind(this);
    this.handleValidateUnitIds = this.handleValidateUnitIds.bind(this);
    this.handleUpdateUnits = this.handleUpdateUnits.bind(this);
  }

  handleSelectNetwork(network) {
    this.setState({
      selectedNetwork: network,
      selectedYieldSet: null,
    });
  }

  handleSelectYieldSet(yieldSet) {
    this.setState({
      selectedYieldSet: yieldSet,
    });
  }

  handleChangeTextArea(list) {
    this.setState({
      unitIds: list.map((d) => _.toInteger(d)),
    });
  }

  handleActionChanged(action) {
    this.setState({
      selectedAction: action,
      selectedYieldSet: null,
    });
  }

  handleSelectUnitChanged(unitId) {
    const { selectedUnitIds } = this.state;
    const index = _.indexOf(selectedUnitIds, unitId);

    if (index === -1) {
      this.setState({
        selectedUnitIds: [...selectedUnitIds, unitId],
      });
    } else {
      this.setState({
        selectedUnitIds: _.filter(selectedUnitIds, (u) => u !== unitId),
      });
    }
  }

  async handleValidateUnitIds() {
    this.setState({
      validateErrMsg: null,
      isLoading: true,
      validatedUnits: null,
      selectedUnitIds: [],
      resultUnits: [],
    });

    try {
      const { selectedNetwork, unitIds } = this.state;
      const networkId = selectedNetwork.networkId;
      const { units } = await NetworkAPI.getUnitsByUnitIds({
        networkId,
        unitIds,
      });

      this.setState({
        validatedUnits: units,
        selectedUnitIds: units.map((u) => u.adUnitId),
      });
    } catch (err) {
      this.setState({
        validateErrMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isLoading: false });
  }

  async handleUpdateUnits(isPrompt = true) {
    const {
      selectedAction,
      selectedUnitIds,
      validatedUnits,
      selectedNetwork,
      selectedYieldSet,
    } = this.state;

    if (isPrompt) {
      this.setState({
        isPromptModalOpen: true,
        promptMsg: [],
        promptHeader: `Update units ${this._getActionText(selectedAction)}`,
        promptMessage: (
          <>
            <div>Are you sure to update {selectedUnitIds.length} units?</div>
            {selectedAction === ACTION.MIGRATE && (
              <div>
                And migrate to yield set:{" "}
                <span className="font-semibold text-blue-600">
                  {selectedYieldSet.yieldSetId} - {selectedYieldSet.name}
                </span>
              </div>
            )}
            {selectedUnitIds.map((id) => {
              const unit = _.find(validatedUnits, { adUnitId: id });
              return unit ? (
                <div key={id} className="font-semibold text-blue-600">
                  {unit.adUnitId} - {unit.name}
                </div>
              ) : (
                ""
              );
            })}
          </>
        ),
        promptAction: () => this.handleUpdateUnits(false),
      });

      return;
    }

    this.setState({ isSaving: true });

    let result;

    try {
      switch (selectedAction) {
        case ACTION.RUNNING:
        case ACTION.PAUSED:
          const params = {
            unitIds: selectedUnitIds,
            status: selectedAction,
          };
          result = await NetworkAPI.updateUnitsByStatus({
            networkId: selectedNetwork.networkId,
            params,
          });

          if (result) {
            this.setState({
              promptMsg: [
                `Successfully updated ${selectedUnitIds.length} units to ${
                  selectedAction === ACTION.RUNNING ? "RUNNING" : "PAUSED"
                } status`,
                MESSAGE.SUCCESS,
              ],
            });
          }
          break;
        case ACTION.MIGRATE:
          result = await OnboardingAPI.migrateYieldSet({
            networkId: selectedNetwork.networkId,
            yieldSetId: selectedYieldSet.yieldSetId,
            adUnitIds: selectedUnitIds,
          });

          if (result) {
            this.setState({
              promptMsg: ["Successfully migrated yield set", MESSAGE.SUCCESS],
            });
          }
          break;
        default:
          break;
      }

      const { units } = await NetworkAPI.getUnitsByUnitIds({
        networkId: selectedNetwork.networkId,
        unitIds: selectedUnitIds,
      });

      this.setState({
        validatedUnits: null,
        resultUnits: units,
      });
    } catch (err) {
      this.setState({
        promptMsg: [err, MESSAGE.ERROR],
      });
    }

    this.setState({ isSaving: false });
  }

  _getActionText(action) {
    switch (action) {
      case ACTION.RUNNING:
        return "to RUNNING status";
      case ACTION.PAUSED:
        return "to PAUSED status";
      case ACTION.MIGRATE:
        return "to be migrated into the yield set";
      default:
        return "";
    }
  }

  render() {
    const {
      isSaving,
      isPromptModalOpen,
      promptHeader,
      promptMessage,
      promptAction,
      promptMsg,
      isLoading,
      validateErrMsg,
      selectedNetwork,
      selectedAction,
      selectedYieldSet,
      unitIds,
      validatedUnits,
      resultUnits,
      selectedUnitIds,
    } = this.state;

    let buttonDisable = false;

    if (!validatedUnits) buttonDisable = true;
    if (validatedUnits && validatedUnits.length === 0) buttonDisable = true;
    if (selectedAction === ACTION.MIGRATE && !selectedYieldSet)
      buttonDisable = true;

    return (
      <ViewerWrapper title="Ad Units Batch Actions" isLoading={isLoading}>
        <div className="flex">
          <div className="w-1/2">
            <div className="mb-4">
              1. Select Network:
              <div>
                <NetworksSelector
                  selectedNetwork={selectedNetwork}
                  handleOnChange={this.handleSelectNetwork}
                ></NetworksSelector>
              </div>
            </div>
            <div className="mb-4">
              2. Actions:
              <div className="flex flex-col gap-1">
                <label htmlFor={ACTION.RUNNING} className="cursor-pointer">
                  <input
                    disabled={!selectedNetwork}
                    id={ACTION.RUNNING}
                    type="radio"
                    name={`radio_${ACTION.RUNNING}`}
                    value={ACTION.RUNNING}
                    checked={selectedAction === ACTION.RUNNING}
                    onChange={() => this.handleActionChanged(ACTION.RUNNING)}
                  />{" "}
                  Update unit status to RUNNING
                </label>

                <label htmlFor={ACTION.PAUSED} className="cursor-pointer">
                  <input
                    disabled={!selectedNetwork}
                    id={ACTION.PAUSED}
                    type="radio"
                    name={`radio_${ACTION.PAUSED}`}
                    value={ACTION.PAUSED}
                    checked={selectedAction === ACTION.PAUSED}
                    onChange={() => this.handleActionChanged(ACTION.PAUSED)}
                  />{" "}
                  Update unit status to PAUSED
                </label>

                <label htmlFor={ACTION.MIGRATE} className="cursor-pointer">
                  <input
                    disabled={!selectedNetwork}
                    id={ACTION.MIGRATE}
                    type="radio"
                    name={`radio_${ACTION.MIGRATE}`}
                    value={ACTION.MIGRATE}
                    checked={selectedAction === ACTION.MIGRATE}
                    onChange={() => this.handleActionChanged(ACTION.MIGRATE)}
                  />{" "}
                  Migrate yield set
                </label>
                {selectedAction === ACTION.MIGRATE && (
                  <YieldSetsSelector
                    selectedNetwork={selectedNetwork}
                    selectedYieldSet={selectedYieldSet}
                    networkId={selectedNetwork.networkId}
                    handleOnChange={this.handleSelectYieldSet}
                  ></YieldSetsSelector>
                )}
              </div>
            </div>
            <div className="mb-4">
              3. Input ad unit ids: (Use <b>comma</b>, <b>whitespace</b> or{" "}
              <b>line break</b> to separate ids){" "}
              {selectedNetwork && (
                <div className="rounded flex items-center gap-1 bg-blue-200 px-4 py-2 text-sm text-gray-900">
                  Copy IDs from
                  <span
                    className="flex cursor-pointer items-center gap-2 underline hover:text-blue-700"
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/#/network/${selectedNetwork.networkId}/units/search`
                      )
                    }
                  >
                    Ad Units Search page <FaExternalLinkAlt />
                  </span>
                </div>
              )}
              <div className="text-sm text-gray-600">ex. 423,235,246</div>
              <TextAreaWithCsv
                onChange={this.handleChangeTextArea}
                disabled={!selectedNetwork}
                countUnit="units"
              ></TextAreaWithCsv>
            </div>
            <div className="mb-2 flex justify-end">
              <button
                className={
                  !selectedNetwork || unitIds.length === 0
                    ? buttonConfirmDisable
                    : buttonConfirmClass
                }
                onClick={this.handleValidateUnitIds}
              >
                Validate and review
              </button>
            </div>
            {validateErrMsg && (
              <div className="w-full text-right text-red-600">
                {validateErrMsg}
              </div>
            )}
          </div>
          <div className="ml-8 w-1/2">
            <div
              className={`border ${
                validatedUnits && "shadow border-blue-400"
              } rounded mb-4 p-4`}
            >
              <div className="mb-2 font-semibold text-gray-700">
                {resultUnits.length !== 0 ? "Update" : "Validate"} Results:
              </div>
              {validatedUnits && (
                <table className="w-full">
                  <thead className="bg-gray-300">
                    <tr>
                      <th className={thClass}></th>
                      <th className={thClass}>GAM Unit Id</th>
                      <th className={thClass}>Name</th>
                      <th className={thClass}>Status</th>
                      <th className={thClass}>Mode</th>
                      <th className={thClass}>Yield Set Id</th>
                      <th className={thClass}>Yield Set Name</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {validatedUnits.length === 0 && (
                      <tr>
                        <td className={tdClass + " text-center"} colSpan={7}>
                          No valid data
                        </td>
                      </tr>
                    )}
                    {validatedUnits.map((unit) => (
                      <tr key={unit.adUnitId}>
                        <td className={tdClass}>
                          <Checkbox
                            isChecked={
                              _.indexOf(selectedUnitIds, unit.adUnitId) !== -1
                            }
                            onChange={() =>
                              this.handleSelectUnitChanged(unit.adUnitId)
                            }
                          ></Checkbox>
                        </td>
                        <td className={tdClass}>{unit.extGamAdUnitId}</td>
                        <td className={tdClass}>{unit.name}</td>
                        <td
                          className={`${tdClass} font-semibold ${
                            unit.status === ACTION.PAUSED
                              ? "text-orange-600"
                              : "text-green-600"
                          }`}
                        >
                          {unit.status}
                        </td>
                        <td className={tdClass}>{unit.mode}</td>
                        <td className={tdClass}>{unit.yieldSet.yieldSetId}</td>
                        <td className={tdClass}>{unit.yieldSet.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {resultUnits.length !== 0 && (
                <table className="w-full">
                  <thead className="bg-gray-300">
                    <tr>
                      <th className={thClass}>GAM Unit Id</th>
                      <th className={thClass}>Name</th>
                      <th className={thClass}>Status</th>
                      <th className={thClass}>Mode</th>
                      <th className={thClass}>Yield Set Id</th>
                      <th className={thClass}>Yield Set Name</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {resultUnits.map((unit) => (
                      <tr key={unit.adUnitId}>
                        <td className={tdClass}>{unit.extGamAdUnitId}</td>
                        <td className={tdClass}>{unit.name}</td>
                        <td
                          className={`${tdClass} font-semibold ${
                            unit.status === ACTION.PAUSED
                              ? "text-orange-600"
                              : "text-green-600"
                          }`}
                        >
                          {unit.status}
                        </td>
                        <td className={tdClass}>{unit.mode}</td>
                        <td className={tdClass}>{unit.yieldSet.yieldSetId}</td>
                        <td className={tdClass}>{unit.yieldSet.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {!validatedUnits && resultUnits.length === 0 && (
                <div className="text-sm text-gray-600">
                  Click the validate button to see validate results
                </div>
              )}
            </div>
            <div className="mb-2 flex justify-center">
              {!buttonDisable && (
                <button
                  className={
                    selectedUnitIds.length === 0
                      ? buttonConfirmDisable
                      : buttonConfirmClass
                  }
                  onClick={() => this.handleUpdateUnits()}
                >
                  Update selected units ({selectedUnitIds.length})
                </button>
              )}
            </div>
          </div>
        </div>

        {isPromptModalOpen && (
          <PromptModal
            msg={promptMsg}
            isLoading={isSaving}
            isOpenModal={isPromptModalOpen}
            handleConfirm={promptAction}
            handleCancel={() => this.setState({ isPromptModalOpen: false })}
            header={promptHeader}
          >
            {promptMessage}
          </PromptModal>
        )}
      </ViewerWrapper>
    );
  }
}

export default AdUnitsBatchActions;

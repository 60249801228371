import React from "react";
import _ from "lodash";
import ReactModal from "react-modal";
import ModalWrapper from "../common/ModalWrapper";
import { decrypt } from "./VaultHelper";
import LoadingUI from "components/common/LoadingUI";

const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";
const inputClassDisabled =
  "bg-gray-300 flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

ReactModal.setAppElement("body");
const selectInputClass =
  "bg-white flex w-full border border-gray-400 rounded py-2 px-3 leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";

class VaultAccountStatusModal extends React.Component {
  constructor(props) {
    super(props);

    const { account } = props;
    let accountStatus = 1;
    if (account) {
      account.password = decrypt(account.secret);
      account.recoveryEmail = decrypt(account.recovery_email);
      accountStatus = account.status;
    }

    this.state = {
      account,
      accountStatus,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStatusChanged = this.handleStatusChanged.bind(this);
  }

  handleCancel() {
    this.setState({
      account: null,
      accountStatus: 1,
    });
    this.props.handleClose();
  }

  handleSubmit() {
    const { account, accountStatus } = this.state;
    this.props.handleSave({
      accountId: account.id,
      status: accountStatus === "1" ? 1 : 2,
    });
  }

  handleStatusChanged(e) {
    this.setState({ accountStatus: e.target.value });
  }

  render() {
    const {
      account,
      isOpenModal,
      // handleClose,
      isSaving,
      saveErrorMsg,
      isLoadingModal,
    } = this.props;
    const { accountStatus } = this.state;

    return (
      <ModalWrapper
        isOpen={isOpenModal}
        showCloseFooter={false}
        // handleClose={handleClose}
        width="50%"
      >
        <div>
          <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
            Update status:
          </div>
          <div>
            {isLoadingModal ? (
              <LoadingUI></LoadingUI>
            ) : (
              <form
                autoComplete="false"
                onSubmit={(e) => {
                  e.preventDefault();
                  return this.handleSubmit();
                }}
              >
                <div>
                  <div className="my-2">
                    <label
                      className="mb-1 block pr-4 font-semibold leading-none text-gray-800"
                      htmlFor="account-email"
                    >
                      Email
                    </label>
                    <div className="w-2/3">
                      <input
                        className={inputClassDisabled}
                        id="account-email"
                        type="email"
                        defaultValue={account.email}
                        disabled={true}
                      ></input>
                    </div>
                  </div>
                  <div className="my-2">
                    <label
                      className="mb-1 block pr-4 font-semibold leading-none text-gray-800"
                      htmlFor="account-status"
                    >
                      Status
                    </label>
                    <div className="w-2/3">
                      <select
                        required
                        className={selectInputClass}
                        id="account-status"
                        value={accountStatus}
                        onChange={this.handleStatusChanged}
                      >
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mt-8 flex items-center justify-end">
                  <div className="text-red-700">{saveErrorMsg}</div>
                  <button
                    className="rounded flex-shrink-0 border-4 border-transparent py-1 px-2 text-sm text-blue-500 hover:text-blue-800"
                    type="button"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>

                  <button
                    className="rounded flex-shrink-0 border-4 border-blue-500 bg-blue-500 py-1 px-2 text-sm font-semibold text-white hover:border-blue-700 hover:bg-blue-800"
                    type="submit"
                    disabled={isSaving}
                  >
                    {isSaving ? "Saving..." : "Update status"}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default VaultAccountStatusModal;

import React from "react";
import _ from "lodash";
import { notify } from "react-notify-toast";

import { VaultAPI } from "apis";
import { buttonConfirmClass } from "helpers/StyleClass";
import { decrypt, encrypt, initVault, generateVaultHash } from "./VaultHelper";
import VaultAccountBindNetworkModal from "./VaultAccountBindNetworkModal";
import VaultAccountsTable from "./VaultAccountsTable";
import VaultAccountFormModal from "./VaultAccountFormModal";
import VaultAccountChangePasswordModal from "./VaultAccountChangePasswordModal";
import MasterPasswordForm from "./MasterPasswordForm";
import VaultAccountStatusModal from "./VaultAccountStatusModal";
import PromptModal from "components/common/PromptModal";

import { inputClass } from "helpers/StyleClass";
import { MESSAGE } from "constants/Message";

const optionTab =
  "rounded px-4 py-1 font-semibold text-gray-700 bg-gray-100 hover:bg-white hover:text-gray-800 cursor-pointer";

const selectedTab =
  "rounded px-4 py-1 font-semibold text-gray-700 bg-blue-200 cursor-pointer";

const TAB = {
  ACCOUNT: "ACCOUNT",
  NETWORK: "NETWORK",
};

const NETWORK_TAB = {
  ALL: "ALL",
  ASSIGNED: "ASSIGNED",
  NONE: "NONE",
};

class VaultViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      isVaultOpen: false,

      mpErrorMsg: null,
      vaultAccounts: null,
      vaultNetworks: null,

      modalType: "ADD", // ADD, EDIT
      isModalOpen: false,
      isSaving: false,
      isRemoving: false,
      saveErrorMsg: null,
      currentAccount: {},

      isBindModalOpen: false,
      isPWModalOpen: false,
      isStatusModalOpen: false,

      isLoadingModal: false,

      viewTab: TAB.NETWORK,
      networkTab: NETWORK_TAB.ALL,
      searchText: "",

      // prompt related
      isPromptOpen: false,
      isPromptLoading: false,
      promptMsg: [],
      promptHeader: "",
      promptMessage: "",
      promptAction: null,
    };

    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleSubmitMP = this.handleSubmitMP.bind(this);
    this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenEditModal = this.handleOpenEditModal.bind(this);
    this.handleSaveAccount = this.handleSaveAccount.bind(this);
    this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
    this.handleOpenBindModal = this.handleOpenBindModal.bind(this);

    this.handleBindNetwork = this.handleBindNetwork.bind(this);
    this.handleRemoveBindNetwork = this.handleRemoveBindNetwork.bind(this);
    this.handleClearSaveErrorMsg = this.handleClearSaveErrorMsg.bind(this);

    this.handleOpenPWModal = this.handleOpenPWModal.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);

    this.handleOpenStatusModal = this.handleOpenStatusModal.bind(this);
    this.handleUpdateAccountStatus = this.handleUpdateAccountStatus.bind(this);

    this.getVaultAccountById = this.getVaultAccountById.bind(this);
  }

  async handleBindNetwork({ networkCode, accountId, isForceBind, purpose }) {
    this.setState({ isSaving: true });
    try {
      const masterHash = await generateVaultHash();
      const saveParams = {
        networkCode,
        accountId,
        isForceBind,
        vaultHash: masterHash,
        ...purpose,
      };
      const r = await VaultAPI.bindNetworkToVaultAccount(saveParams);
      console.log("bind network response", r);

      this.setState({ saveErrorMsg: null });
      notify.show("Network bind saved!", "success");

      setTimeout(() => {
        this.handleGetVaultAccounts();
        this.handleCloseModal();
      }, 500);
    } catch (err) {
      this.setState({
        saveErrorMsg: err.toString(),
      });
    }

    this.setState({ isSaving: false });
  }

  async handleRemoveBindNetwork({ networkCode, accountId }) {
    this.setState({ isRemoving: true });
    try {
      const masterHash = await generateVaultHash();
      const saveParams = {
        networkCode,
        accountId,
        vaultHash: masterHash,
      };
      const r = await VaultAPI.removeNetworkAccountBinding(saveParams);
      console.log("bind network response", r);

      this.setState({ saveErrorMsg: null });
      notify.show("Network bind removed!", "success");

      setTimeout(() => {
        this.handleGetVaultAccounts();
        this.handleCloseModal();
      }, 500);
    } catch (err) {
      this.setState({
        saveErrorMsg: err.toString(),
      });
    }

    this.setState({ isRemoving: false });
  }

  handleClearSaveErrorMsg() {
    this.setState({ saveErrorMsg: null });
  }

  async handleDeleteAccount(account, isPrompt = true) {
    if (isPrompt) {
      this.setState({
        isPromptOpen: true,
        isPromptLoading: false,
        promptMsg: [],
        promptHeader: "Delete Account",
        promptMessage: (
          <div>
            Delete this account?
            <div className="font-semibold text-red-600">{account.email}</div>
          </div>
        ),
        promptAction: () => this.handleDeleteAccount(account, false),
      });
      return;
    }
    // const userConfirm = window.confirm(`Delete this account ${account.email}?`);
    // if (!userConfirm) return;

    try {
      this.setState({ isPromptLoading: true });

      const masterHash = await generateVaultHash();
      const deleteParams = {
        accountId: account.id,
        vaultHash: masterHash,
      };

      const r = await VaultAPI.deleteVaultAccount(deleteParams);
      console.log(r);

      // notify.show("Account deleted!", "success");
      this.setState({
        promptMsg: ["Account deleted!", MESSAGE.SUCCESS],
      });

      setTimeout(() => {
        this.handleGetVaultAccounts();
      }, 500);
    } catch (err) {
      console.error(err);
      this.setState({
        isPromptLoading: false,
        promptMsg: [
          `Failed to delete account: ${err.toString()}`,
          MESSAGE.ERROR,
        ],
      });
      // notify.show(`Failed to delete account: ${err.toString()}`, "error");
    }
  }

  async handleChangePassword({ oldPassword, newPassword }) {
    this.setState({ isSaving: true });
    try {
      const { currentAccount } = this.state;
      const originalPassword = decrypt(currentAccount.secret);
      const originalRecoveryEmail = decrypt(currentAccount.recovery_email);
      if (oldPassword !== originalPassword) {
        throw "Wrong old password";
      } else {
        const masterHash = await generateVaultHash();

        const saveParams = {
          email: currentAccount.email,
          secret: encrypt(newPassword),
          recoveryEmail: encrypt(originalRecoveryEmail),
          vaultHash: masterHash,
        };

        const r = await VaultAPI.updateVaultAccount(saveParams);
        console.log("save account response", r);

        this.setState({ saveErrorMsg: null });
        notify.show("Account saved!", "success");

        setTimeout(() => {
          this.handleGetVaultAccounts();
          this.handleCloseModal();
        }, 500);
      }
    } catch (err) {
      this.setState({
        saveErrorMsg: err.toString(),
      });
    }

    this.setState({ isSaving: false });
  }

  async handleSaveAccount(account) {
    const { modalType } = this.state;
    // console.log("save account", modalType, account);

    this.setState({ isSaving: true });
    try {
      const masterHash = await generateVaultHash();

      const saveParams = {
        email: account.email,
        secret: encrypt(account.password),
        recoveryEmail: encrypt(account.recoveryEmail),
        vaultHash: masterHash,
      };

      const r =
        modalType === "ADD"
          ? await VaultAPI.createVaultAccount(saveParams)
          : await VaultAPI.updateVaultAccount(saveParams);
      console.log("save account response", r);

      this.setState({ saveErrorMsg: null });
      notify.show("Account saved!", "success");

      setTimeout(() => {
        this.handleGetVaultAccounts();
        this.handleCloseModal();
      }, 500);
    } catch (err) {
      this.setState({
        saveErrorMsg: err.toString(),
      });
    }

    this.setState({ isSaving: false });
  }

  async handleUpdateAccountStatus(account) {
    // console.log("update account status", account);

    this.setState({ isSaving: true });
    try {
      const masterHash = await generateVaultHash();

      const saveParams = {
        accountId: account.accountId,
        status: account.status,
        vaultHash: masterHash,
      };

      const r = await VaultAPI.updateVaultAccountStatus(saveParams);
      console.log("update account status response", r);

      this.setState({ saveErrorMsg: null });
      notify.show("Account status updated!", "success");

      setTimeout(() => {
        this.handleGetVaultAccounts();
        this.handleCloseModal();
      }, 500);
    } catch (err) {
      this.setState({
        saveErrorMsg: err.toString(),
      });
    }

    this.setState({ isSaving: false });
  }

  handleOpenAddModal() {
    this.setState({ isModalOpen: true, modalType: "ADD", currentAccount: {} });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false,
      isPWModalOpen: false,
      isBindModalOpen: false,
      isStatusModalOpen: false,
      currentAccount: {},
      saveErrorMsg: null,
    });
  }

  // get the newest account info when opening any edit modal
  async getVaultAccountById(accountId) {
    this.setState({ isLoadingModal: true });

    let account = null;
    try {
      account = await VaultAPI.getVaultGamAccountById({ accountId });
      console.log(account);
    } catch (err) {
      console.log(
        "failed to get this vault account",
        err.error || err.toString()
      );
    }
    this.setState({ isLoadingModal: false });

    return account;
  }

  handleOpenBindModal(account) {
    this.setState({ isBindModalOpen: true, currentAccount: account });
  }

  async handleOpenEditModal(account) {
    // console.log("opene edit", account);
    const newAccount = await this.getVaultAccountById(account.id);
    this.setState({
      isModalOpen: true,
      modalType: "EDIT",
      currentAccount: newAccount,
    });
  }

  async handleOpenPWModal(account) {
    const newAccount = await this.getVaultAccountById(account.id);
    this.setState({
      isPWModalOpen: true,
      currentAccount: newAccount,
    });
  }

  async handleOpenStatusModal(account) {
    const newAccount = await this.getVaultAccountById(account.id);
    this.setState({
      isStatusModalOpen: true,
      currentAccount: newAccount,
    });
  }

  async handleGetVaultAccounts() {
    this.setState({ isLoading: true });
    try {
      let accounts = await VaultAPI.getVaultGamAccounts();
      accounts = _.orderBy(accounts, ["id"], ["desc"]);
      this.setState({
        vaultAccounts: accounts,
        vaultNetworks: this._processByNetwork(accounts),
      });
    } catch (err) {
      console.log("failed to get vault accounts", err.error || err.toString());
      this.setState({ isVaultOpen: false });
    }
    this.setState({ isLoading: false });
  }

  async handleSubmitMP(masterPassword) {
    this.setState({ isLoading: true });
    try {
      // console.log("mp", masterPassword);
      await initVault(masterPassword);

      this.setState({ mpErrorMsg: null, isVaultOpen: true });

      await this.handleGetVaultAccounts();
    } catch (err) {
      console.log("wrong password", err);
      this.setState({ mpErrorMsg: "Wrong Password" });
    }

    this.setState({ isLoading: false });
  }

  handleSearchTextChange(e) {
    this.setState({ searchText: e.target.value });
  }

  _processByNetwork(accounts) {
    const result = [
      {
        name: "None",
        networkId: -1,
        code: "",
        accounts: [],
      },
    ];

    _.forEach(accounts, (acc) => {
      if (acc.networks.length === 0) {
        result[0].accounts.push(acc);
      } else {
        _.forEach(acc.networks, (network) => {
          const found = _.find(result, {
            code: network.code,
          });
          const newAcc = {
            ...acc,
            for_gam_api: network.for_gam_api,
            for_console_proxy: network.for_console_proxy,
          };

          if (found) {
            found.accounts.push(newAcc);
          } else {
            result.push({
              ...network,
              accounts: [newAcc],
            });
          }
        });
      }
    });

    return _.orderBy(result, ["networkId"], ["desc"]);
  }

  render() {
    const {
      mpErrorMsg,
      isVaultOpen,
      vaultAccounts,
      vaultNetworks,
      isLoading,
      errMsg,

      modalType,
      isModalOpen,
      isSaving,
      isRemoving,
      saveErrorMsg,
      currentAccount,

      isBindModalOpen,
      isPWModalOpen,
      isStatusModalOpen,
      isLoadingModal,

      viewTab,
      networkTab,
      searchText,

      isPromptOpen,
      isPromptLoading,
      promptMsg,
      promptHeader,
      promptMessage,
      promptAction,
    } = this.state;

    const filteredAccounts = (vaultAccounts || []).filter((a) => {
      if (searchText === "") return true;

      if (viewTab === TAB.ACCOUNT) {
        return a.email.toLowerCase().includes(searchText.toLowerCase());
      }

      if (viewTab === TAB.NETWORK) {
        return a.networks.some((n) =>
          n.name.toLowerCase().includes(searchText.toLowerCase())
        );
      }

      return true;
    });

    const filteredNetworks = (vaultNetworks || []).filter((n) => {
      if (searchText === "") return true;

      if (viewTab === TAB.NETWORK) {
        return n.name.toLowerCase().includes(searchText.toLowerCase());
      }

      return true;
    });

    const countByNetwork = {
      [NETWORK_TAB.NONE]: filteredAccounts
        ? _.filter(filteredAccounts, (a) => a.networks.length === 0).length
        : 0,
      [NETWORK_TAB.ALL]: filteredAccounts ? filteredAccounts.length : 0,
      [NETWORK_TAB.ASSIGNED]: filteredAccounts
        ? _.filter(filteredAccounts, (a) => a.networks.length !== 0).length
        : 0,
    };

    let displayData = null;

    if (viewTab === TAB.ACCOUNT) {
      displayData = filteredAccounts;
    } else {
      if (networkTab === NETWORK_TAB.ALL) {
        displayData = filteredNetworks;
      } else if (networkTab === NETWORK_TAB.ASSIGNED) {
        displayData = _.filter(filteredNetworks, (n) => n.code !== "");
      } else {
        displayData = _.filter(filteredNetworks, (n) => n.code === "");
      }
    }

    return (
      <div className="bg-white">
        <div className="px-12">
          <div className="mb-4 flex justify-between pt-8">
            <div className="text-4xl font-extrabold text-gray-900">
              Vault GAM Accounts
            </div>
          </div>
        </div>
        <div className="min-h-screen bg-gray-200 px-12 py-4">
          {isVaultOpen ? (
            <div>
              <div className="mb-2 flex gap-2">
                {_.keys(TAB).map((key) => (
                  <div
                    key={key}
                    className={viewTab === TAB[key] ? selectedTab : optionTab}
                    onClick={() =>
                      this.setState({ viewTab: TAB[key], searchText: "" })
                    }
                  >
                    View by {_.startCase(_.lowerCase(TAB[key]))}
                  </div>
                ))}
                {viewTab === TAB.NETWORK && (
                  <>
                    <div className="border border-gray-300"></div>
                    {_.keys(NETWORK_TAB).map((key) => (
                      <div
                        key={key}
                        className={
                          networkTab === NETWORK_TAB[key]
                            ? selectedTab
                            : optionTab
                        }
                        onClick={() =>
                          this.setState({ networkTab: NETWORK_TAB[key] })
                        }
                      >
                        {_.startCase(_.lowerCase(NETWORK_TAB[key]))}(
                        {countByNetwork[key]})
                      </div>
                    ))}
                  </>
                )}
              </div>
              <input
                className={inputClass}
                value={searchText}
                placeholder={
                  viewTab === TAB.ACCOUNT
                    ? "Search by email"
                    : "Search by network name"
                }
                onChange={this.handleSearchTextChange}
              ></input>
              <div className="flex items-end justify-between">
                <div className="pb-1 text-sm text-gray-800">
                  {viewTab === TAB.ACCOUNT &&
                    filteredAccounts &&
                    filteredAccounts.length}{" "}
                  {viewTab === TAB.NETWORK && countByNetwork[networkTab]}{" "}
                  accounts
                </div>
                <button
                  type="button"
                  className={buttonConfirmClass}
                  onClick={this.handleOpenAddModal}
                >
                  Add account
                </button>
              </div>

              <VaultAccountsTable
                items={displayData}
                viewTab={viewTab}
                isLoading={isLoading}
                errMsg={errMsg}
                handleOpenEditModal={this.handleOpenEditModal}
                handleDeleteAccount={this.handleDeleteAccount}
                handleOpenBindModal={this.handleOpenBindModal}
                handleOpenPasswordModal={this.handleOpenPWModal}
                handleOpenStatusModal={this.handleOpenStatusModal}
                getVaultAccountById={this.getVaultAccountById}
                isLoadingModal={isLoadingModal}
              ></VaultAccountsTable>

              {isPWModalOpen && (
                <VaultAccountChangePasswordModal
                  account={currentAccount}
                  isOpenModal={isPWModalOpen}
                  isSaving={isSaving}
                  saveErrorMsg={saveErrorMsg}
                  handleClose={this.handleCloseModal}
                  handleSave={this.handleChangePassword}
                  isLoadingModal={isLoadingModal}
                ></VaultAccountChangePasswordModal>
              )}

              {isStatusModalOpen && (
                <VaultAccountStatusModal
                  account={currentAccount}
                  isOpenModal={isStatusModalOpen}
                  isSaving={isSaving}
                  saveErrorMsg={saveErrorMsg}
                  handleClose={this.handleCloseModal}
                  handleSave={this.handleUpdateAccountStatus}
                  isLoadingModal={isLoadingModal}
                ></VaultAccountStatusModal>
              )}

              {isModalOpen && (
                <VaultAccountFormModal
                  modalType={modalType}
                  isOpenModal={isModalOpen}
                  handleClose={this.handleCloseModal}
                  isSaving={isSaving}
                  saveErrorMsg={saveErrorMsg}
                  account={currentAccount}
                  handleSave={this.handleSaveAccount}
                  isLoadingModal={isLoadingModal}
                ></VaultAccountFormModal>
              )}

              {isBindModalOpen && (
                <VaultAccountBindNetworkModal
                  isOpenModal={isBindModalOpen}
                  handleClose={this.handleCloseModal}
                  isSaving={isSaving}
                  isRemoving={isRemoving}
                  saveErrorMsg={saveErrorMsg}
                  account={currentAccount}
                  networks={vaultNetworks}
                  handleBindNetwork={this.handleBindNetwork}
                  handleRemoveBindNetwork={this.handleRemoveBindNetwork}
                  handleClearSaveErrorMsg={this.handleClearSaveErrorMsg}
                ></VaultAccountBindNetworkModal>
              )}
            </div>
          ) : (
            <MasterPasswordForm
              handleSubmitMP={this.handleSubmitMP}
              mpErrorMsg={mpErrorMsg}
              isLoading={isLoading}
            ></MasterPasswordForm>
          )}
        </div>

        {isPromptOpen && (
          <PromptModal
            isOpenModal={isPromptOpen}
            isLoading={isPromptLoading}
            handleCancel={() => this.setState({ isPromptOpen: false })}
            header={promptHeader}
            msg={promptMsg}
            handleConfirm={promptAction}
          >
            {promptMessage}
          </PromptModal>
        )}
      </div>
    );
  }
}

export default VaultViewer;

import React from "react";
import ReactTooltip from "react-tooltip";

import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import YbReqStatWrapper from "./ReqStatWrapper";
import { MCMLabel } from "./MCMLabel";

import { onClickableAreaClicked } from "./utils";
import ItemStyle from "./css/inventory-item.module.css";

/**
 * Leaf Unit:
 * 1. Selectable
 * 2. Not Selectable
 *    - Not compatible
 *    - Onboarded
 *    - Onboarded and not compatible
 */
export function LeafUnitItemComponent(props) {
  const { item, layerNum, onSelectUnit, onDrillDown } = props;

  let unitItemClass = ItemStyle.unitItem;
  let checkboxStatus = item.checkboxStatus;
  let checkboxClass = ItemStyle.checkbox;
  let checkboxElement = "";

  const isSelectable = item.isCompatible && !item.isOnboarded;
  if (isSelectable) {
    if (checkboxStatus === "SELECTED") {
      checkboxClass += " " + ItemStyle.selected;
    }

    checkboxElement = (
      <button
        type="button"
        className={checkboxClass}
        onClick={(event) => {
          onSelectUnit(item);
          event.stopPropagation();
        }}
      >
        <FaCheck></FaCheck>
      </button>
    );
  } else {
    // Already onboarded
    if (item.isOnboarded) {
      unitItemClass += " " + ItemStyle.onboarded;
      checkboxClass += " " + ItemStyle.isOnboarded;
      checkboxElement = (
        <>
          <button
            type="button"
            className={checkboxClass}
            data-tip
            data-for={`"checkbox-${item.extGamUnitId}"`}
          >
            <FaCheck></FaCheck>
          </button>
          <ReactTooltip
            id={`"checkbox-${item.extGamUnitId}"`}
            type="dark"
            effect="solid"
            place="top"
          >
            Already onboarded
          </ReactTooltip>
        </>
      );
    }
    // Not Compatible
    else if (!item.isCompatible) {
      unitItemClass += " " + ItemStyle.notCompatible;
    }
  }

  if (item.queryError) {
    unitItemClass = ItemStyle.unitItemHasError;
    checkboxElement = (
      <>
        <span
          style={{ color: "red" }}
          data-tip
          data-for={`"error-hint-${item.extGamUnitId}"`}
        >
          <FaExclamationTriangle></FaExclamationTriangle>
        </span>
        <ReactTooltip
          id={`"error-hint-${item.extGamUnitId}"`}
          type="dark"
          effect="solid"
          place="top"
        >
          {item.queryError.message}
        </ReactTooltip>
      </>
    );
  }

  return (
    <div
      className={unitItemClass}
      onClick={() =>
        onClickableAreaClicked(item, layerNum, onDrillDown, onSelectUnit)
      }
    >
      <div className={ItemStyle.checkboxWrapper}>{checkboxElement}</div>

      <div className={ItemStyle.clickableArea}>
        <div className={ItemStyle.itemName}>
          {item.name}
          <div>
            {item.siteNetworkCodes && (
              <MCMLabel
                id={item.extId}
                siteNetworkCodes={item.siteNetworkCodes}
              ></MCMLabel>
            )}
          </div>
        </div>

        <div>
          {!item.hasChildren && !item.isCompatible && (
            <div className={ItemStyle.notCompatibleText}>Not compatible</div>
          )}
          <YbReqStatWrapper item={item}></YbReqStatWrapper>
        </div>

        <div style={{ width: "16px" }}></div>
        {/* <div className={ItemStyle.arrowWrapper}> */}
        {/* keep this spacing so that it is aligned with parent unit */}
        {/* </div> */}
      </div>
    </div>
  );
}

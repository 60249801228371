export const REQUEST_TYPES = {
  GPT: "Google Publisher Tag",
  AMP: "AMP Ad Tag",
  MOBILE_SDK: "Mobile Ads SDK",
  VIDEO: "Video Tag",
  GPT_SIMPLE_URL: "GPT Simple URL",
  OTHER: "Other",
  ADS_BY_GOOGLE: "Ads By Google",
  SHOW_ADS: "Show Ads",
};

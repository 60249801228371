import React from "react";
import _ from "lodash";
import AdSizesFormatter from "./AdSizesFormatter";
import { showEmpty } from "helpers/Compare";

class SetOfAdSizesFormatter extends React.PureComponent {
  render() {
    const { setsOfAdSizes, showActionButtons, handleRemoveSet, handleEditSet } =
      this.props;

    if (
      !setsOfAdSizes ||
      !_.isArray(setsOfAdSizes) ||
      _.isEmpty(setsOfAdSizes)
    ) {
      return showEmpty(setsOfAdSizes);
    }

    const percentage = _.round((1 / setsOfAdSizes.length) * 100);

    return (
      <div>
        {setsOfAdSizes.map((adSizes, i) => {
          return (
            <div key={i} className="mb-1 flex items-center">
              <div className="pr-1 font-normal text-gray-600">
                <small>{percentage}%</small>
              </div>
              <div className="border flex w-full justify-between bg-gray-100 p-1">
                {/* <div className="px-1 text-gray-600">({adSizes.length})</div> */}
                <AdSizesFormatter
                  adSizes={adSizes}
                  showRemoveButton={false}
                ></AdSizesFormatter>

                {showActionButtons && (
                  <div className="flex w-1/3 justify-end">
                    {typeof adSizes === "string" ? (
                      ""
                    ) : (
                      <button
                        type="button"
                        className="px-2 text-blue-700 hover:text-blue-900"
                        onClick={() => handleEditSet(i)}
                      >
                        Edit
                      </button>
                    )}
                    <button
                      type="button"
                      className="px-2 text-blue-700 hover:text-blue-900"
                      onClick={() => handleRemoveSet(i)}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default SetOfAdSizesFormatter;

export function fixExpression(inputValue) {
  // turn inputValue into lowercase string to allow case insensitive matching
  const expression = inputValue.toUpperCase();
  return expression
    .replace(/TRUE/g, "true")
    .replace(/FALSE/g, "false")
    .replace(/AND/g, "and")
    .replace(/OR/g, "or")
    .replace(/&&/g, "and")
    .replace(/\|\|/g, "or")
    .replace(/:/g, "");
}

export function parseTagToKeyValue(tag) {
  const tList = tag.split(":");
  const value = Number(tList[tList.length - 1]);
  if (isNaN(value)) {
    return { [tList.join("")]: true };
  } else {
    return { [tList.slice(0, -1).join("")]: value };
  }
}

export function parseTagsToObject(tags) {
  return tags.reduce((acc, tag) => {
    return {
      ...acc,
      ...parseTagToKeyValue(tag),
    };
  }, {});
}

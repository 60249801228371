import React from "react";
import _ from "lodash";
import { FiExternalLink } from "react-icons/fi";
import LoadingUI from "../common/LoadingUI";
import DateTimeFormatter from "../common/DateTimeFormatter";
import NetworkStatusIndicator from "../common/NetworkStatusIndicator";
import ReactTooltip from "react-tooltip";
import VaultAccountViewModal from "./VaultAccountViewModal";
import ClickToCopyWrapper from "../common/ClickToCopyWrapper";

const buttonActionClass =
  "px-2 py-2 mr-1 my-1 bg-blue-400 text-xs rounded shadow hover:bg-blue-600 text-white font-semibold";

const buttonActionRedClass =
  "px-2 py-2 mr-1 my-1 bg-red-400 text-xs rounded shadow hover:bg-red-600 text-white font-semibold";

const thClass =
  "px-4 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
const tdClass = "px-4 py-2 border";

// ex. https://admanager.google.com/57931037
const GAM_CONSOLE_URL = "https://admanager.google.com";

class VaultAccountsTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAccountModalOpen: false,
      currentAccount: null,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleGoToGAMConsole = this.handleGoToGAMConsole.bind(this);
  }

  async handleOpenModal(account) {
    const newAccount = await this.props.getVaultAccountById(account.id);
    this.setState({
      isAccountModalOpen: true,
      currentAccount: newAccount,
    });
  }

  handleCloseModal() {
    this.setState({
      isAccountModalOpen: false,
      currentAccount: null,
    });
  }

  handleGoToGAMConsole(networkCode) {
    window.open(`${GAM_CONSOLE_URL}/${networkCode}`);
  }

  handleOpenPasswordModal(account) {
    this.setState({
      isPWModalOpen: true,
      currentAccount: account,
    });
  }

  _parsePurpose({ for_gam_api, for_console_proxy }) {
    if (for_gam_api && for_gam_api === 1) {
      return <div className="font-semibold text-green-600">For GAM API</div>;
    }

    if (for_console_proxy && for_console_proxy === 1) {
      return (
        <div className="font-semibold text-gray-600">For Console Proxy</div>
      );
    }

    return <div></div>;
  }

  render() {
    const {
      items,
      isLoading,
      errMsg,
      viewTab,
      handleOpenEditModal,
      handleDeleteAccount,
      handleOpenBindModal,
      handleOpenPasswordModal,
      handleOpenStatusModal,
      isLoadingModal,
    } = this.props;
    const { isAccountModalOpen, currentAccount } = this.state;

    if (isLoading) {
      return <LoadingUI></LoadingUI>;
    }

    if (errMsg) {
      return <div className="text-red-700">{errMsg}</div>;
    }

    if (!items) {
      return "no items";
    }

    return (
      <div>
        {viewTab === "ACCOUNT" ? (
          <table className="shadow divide-y min-w-full">
            <thead className="bg-gray-100">
              <tr>
                <th className={thClass}>id</th>
                <th
                  className={thClass}
                  // style={{ width: "40%" }}
                >
                  email
                </th>
                <th className={thClass} style={{ width: "500px" }}>
                  networks
                </th>
                <th className={thClass}>purpose</th>
                <th className={thClass + " text-right"}>Created at</th>
                <th className={thClass + " text-right"}>Updated at</th>
                <th className={thClass + " text-right"}>Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y bg-white text-sm">
              {items.map((item) => {
                if (item.networks.length === 0) {
                  return (
                    <tr key={`${item.email}`} className="hover:bg-gray-100">
                      <td className={`${tdClass}`}>{item.id}</td>
                      <td className={`${tdClass}`}>
                        <div className="leading-none">
                          <span
                            className="cursor-pointer font-medium text-blue-600 hover:underline"
                            onClick={() => this.handleOpenModal(item)}
                          >
                            {item.email}
                          </span>
                        </div>
                        <div>
                          <span
                            className={`rounded px-2 text-xs ${
                              item.status === 1
                                ? "bg-green-200 text-green-800"
                                : "bg-red-200 text-red-800"
                            }`}
                          >
                            {item.status === 1 ? "Active" : "Inactive"}
                          </span>
                        </div>
                      </td>

                      <td className={tdClass}></td>
                      <td className={tdClass}></td>

                      <td
                        className={
                          tdClass + " text-right text-xs text-gray-800"
                        }
                      >
                        <DateTimeFormatter
                          datetime={item.created_at}
                          isLineBreak={true}
                        ></DateTimeFormatter>
                      </td>
                      <td
                        className={
                          tdClass + " text-right text-xs text-gray-800"
                        }
                      >
                        <DateTimeFormatter
                          datetime={item.updated_at}
                          isLineBreak={true}
                        ></DateTimeFormatter>
                      </td>

                      <td className={tdClass + " text-right"}>
                        <ButtonList
                          account={item}
                          handleOpenPasswordModal={(item) =>
                            handleOpenPasswordModal(item)
                          }
                          handleOpenEditModal={(item) =>
                            handleOpenEditModal(item)
                          }
                          handleOpenStatusModal={(item) =>
                            handleOpenStatusModal(item)
                          }
                          handleOpenBindModal={(item) =>
                            handleOpenBindModal(item)
                          }
                          handleDeleteAccount={(item) =>
                            handleDeleteAccount(item)
                          }
                        ></ButtonList>
                      </td>
                    </tr>
                  );
                }
                return _.map(item.networks, (network, i) => {
                  const gamLink = `${GAM_CONSOLE_URL}/${network.code}`;
                  let networkContent = "";

                  if (!network.networkId) {
                    networkContent = (
                      <div key={i} className="text-gray-700">
                        N/A code: {network.code}
                      </div>
                    );
                  } else {
                    networkContent = (
                      <div
                        key={i}
                        className="flex items-end justify-between text-sm "
                      >
                        <div className="flex items-center">
                          <div className="mr-1 flex items-center">
                            <NetworkStatusIndicator
                              status={network.status}
                            ></NetworkStatusIndicator>
                            <span className="ml-1">{network.networkId}</span>
                          </div>

                          <div className="font-medium">{network.name}</div>

                          {network.networkId && (
                            <div className="flex items-center">
                              <div className="ml-2 text-xs text-blue-700">
                                <ClickToCopyWrapper
                                  copyText={gamLink}
                                  tooltipMessage="Copy GAM console link"
                                ></ClickToCopyWrapper>
                              </div>

                              <div>
                                <button
                                  type="button"
                                  className="rounded hover:shadow flex items-center bg-gray-100 px-1 text-xs text-blue-700"
                                  onClick={() =>
                                    this.handleGoToGAMConsole(network.code)
                                  }
                                  data-tip
                                  data-for={`${network.code}-external-link`}
                                >
                                  <FiExternalLink></FiExternalLink>
                                </button>
                                <ReactTooltip
                                  id={`${network.code}-external-link`}
                                  type="dark"
                                  effect="solid"
                                >
                                  Open this network's GAM console
                                </ReactTooltip>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }

                  return (
                    <tr
                      key={`${item.email}-${
                        network.networkId || network.code
                      }-${i}`}
                      className="hover:bg-gray-100"
                    >
                      {i === 0 && (
                        <>
                          <td
                            className={`${tdClass} ${
                              item.networks.length > 1 ? "align-top" : ""
                            }`}
                            rowSpan={item.networks.length}
                          >
                            {item.id}
                          </td>
                          <td
                            className={`${tdClass} ${
                              item.networks.length > 1 ? "align-top" : ""
                            }`}
                            rowSpan={item.networks.length}
                          >
                            <div className="leading-none">
                              <span
                                className="cursor-pointer font-medium text-blue-600 hover:underline"
                                onClick={() => this.handleOpenModal(item)}
                              >
                                {item.email}
                              </span>
                            </div>
                            <div>
                              <span
                                className={`rounded px-2 text-xs ${
                                  item.status === 1
                                    ? "bg-green-200 text-green-800"
                                    : "bg-red-200 text-red-800"
                                }`}
                              >
                                {item.status === 1 ? "Active" : "Inactive"}
                              </span>
                            </div>
                          </td>
                        </>
                      )}

                      <td className={tdClass}>{networkContent}</td>
                      <td className={tdClass}>{this._parsePurpose(network)}</td>

                      {i === 0 && (
                        <>
                          <td
                            className={
                              tdClass +
                              ` text-right text-xs text-gray-800 ${
                                item.networks.length > 1 ? "align-top" : ""
                              }`
                            }
                            rowSpan={item.networks.length}
                          >
                            <DateTimeFormatter
                              datetime={item.created_at}
                              isLineBreak={true}
                            ></DateTimeFormatter>
                          </td>
                          <td
                            className={
                              tdClass +
                              ` text-right text-xs text-gray-800 ${
                                item.networks.length > 1 ? "align-top" : ""
                              }`
                            }
                            rowSpan={item.networks.length}
                          >
                            <DateTimeFormatter
                              datetime={item.updated_at}
                              isLineBreak={true}
                            ></DateTimeFormatter>
                          </td>

                          <td
                            className={
                              tdClass +
                              ` text-right ${
                                item.networks.length > 1 ? "align-top" : ""
                              }`
                            }
                            rowSpan={item.networks.length}
                          >
                            <ButtonList
                              account={item}
                              handleOpenPasswordModal={(item) =>
                                handleOpenPasswordModal(item)
                              }
                              handleOpenEditModal={(item) =>
                                handleOpenEditModal(item)
                              }
                              handleOpenStatusModal={(item) =>
                                handleOpenStatusModal(item)
                              }
                              handleOpenBindModal={(item) =>
                                handleOpenBindModal(item)
                              }
                              handleDeleteAccount={(item) =>
                                handleDeleteAccount(item)
                              }
                            ></ButtonList>
                          </td>
                        </>
                      )}
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>
        ) : (
          <table className="shadow divide-y min-w-full">
            <thead className="bg-gray-100">
              <tr>
                <th className={thClass} style={{ width: "500px" }}>
                  networks
                </th>
                <th className={thClass}>email</th>
                <th className={thClass}>purpose</th>
                <th className={thClass + " text-right"}>Created at</th>
                <th className={thClass + " text-right"}>Updated at</th>
                <th className={thClass + " text-right"}>Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y bg-white text-sm">
              {items.map((network) => {
                return _.map(network.accounts, (account, i) => {
                  const gamLink = `${GAM_CONSOLE_URL}/${network.code}`;
                  let networkContent = "";

                  if (network.networkId < 0 || network.code === "") {
                    networkContent = (
                      <div key={i} className="text-gray-700">
                        None
                      </div>
                    );
                  } else if (!network.networkId) {
                    networkContent = (
                      <div key={i} className="text-gray-700">
                        N/A code: {network.code}
                      </div>
                    );
                  } else {
                    networkContent = (
                      <div
                        key={i}
                        className="flex items-end justify-between text-sm "
                      >
                        <div className="flex items-center">
                          <div className="mr-1 flex items-center">
                            <NetworkStatusIndicator
                              status={network.status}
                            ></NetworkStatusIndicator>
                            <span className="ml-1">{network.networkId}</span>
                          </div>

                          <div className="font-medium">{network.name}</div>

                          {network.networkId && (
                            <div className="flex items-center">
                              <div className="ml-2 text-xs text-blue-700">
                                <ClickToCopyWrapper
                                  copyText={gamLink}
                                  tooltipMessage="Copy GAM console link"
                                ></ClickToCopyWrapper>
                              </div>

                              <div>
                                <button
                                  type="button"
                                  className="rounded hover:shadow flex items-center bg-gray-100 px-1 text-xs text-blue-700"
                                  onClick={() =>
                                    this.handleGoToGAMConsole(network.code)
                                  }
                                  data-tip
                                  data-for={`${network.code}-external-link`}
                                >
                                  <FiExternalLink></FiExternalLink>
                                </button>
                                <ReactTooltip
                                  id={`${network.code}-external-link`}
                                  type="dark"
                                  effect="solid"
                                >
                                  Open this network's GAM console
                                </ReactTooltip>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }

                  return (
                    <tr
                      key={`${account.email}-${
                        network.networkId || network.code
                      }-${i}`}
                      className="hover:bg-gray-100"
                    >
                      {i === 0 && (
                        <td
                          className={`${tdClass} ${
                            network.accounts.length > 1 ? "align-top" : ""
                          }`}
                          rowSpan={network.accounts.length}
                        >
                          {networkContent}
                        </td>
                      )}

                      <td className={`${tdClass}`}>
                        <div className="leading-none">
                          <span
                            className="cursor-pointer font-medium text-blue-600 hover:underline"
                            onClick={() => this.handleOpenModal(account)}
                          >
                            {account.email}
                          </span>
                        </div>
                        <div>
                          <span
                            className={`rounded px-2 text-xs ${
                              account.status === 1
                                ? "bg-green-200 text-green-800"
                                : "bg-red-200 text-red-800"
                            }`}
                          >
                            {account.status === 1 ? "Active" : "Inactive"}
                          </span>
                        </div>
                      </td>

                      <td className={tdClass}>{this._parsePurpose(account)}</td>

                      <td
                        className={
                          tdClass + " text-right text-xs text-gray-800"
                        }
                      >
                        <DateTimeFormatter
                          datetime={account.created_at}
                          isLineBreak={true}
                        ></DateTimeFormatter>
                      </td>
                      <td
                        className={
                          tdClass + " text-right text-xs text-gray-800"
                        }
                      >
                        <DateTimeFormatter
                          datetime={account.updated_at}
                          isLineBreak={true}
                        ></DateTimeFormatter>
                      </td>
                      <td className={tdClass + " text-right"}>
                        <ButtonList
                          account={account}
                          handleOpenPasswordModal={(item) =>
                            handleOpenPasswordModal(item)
                          }
                          handleOpenEditModal={(item) =>
                            handleOpenEditModal(item)
                          }
                          handleOpenStatusModal={(item) =>
                            handleOpenStatusModal(item)
                          }
                          handleOpenBindModal={(item) =>
                            handleOpenBindModal(item)
                          }
                          handleDeleteAccount={(item) =>
                            handleDeleteAccount(item)
                          }
                        ></ButtonList>
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>
        )}

        {isAccountModalOpen && (
          <VaultAccountViewModal
            account={currentAccount}
            isOpenModal={isAccountModalOpen}
            handleClose={this.handleCloseModal}
            isLoadingModal={isLoadingModal}
          ></VaultAccountViewModal>
        )}
      </div>
    );
  }
}

class ButtonList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { account } = this.props;

    return (
      <>
        <button
          type="button"
          className={buttonActionClass}
          onClick={() => this.props.handleOpenPasswordModal(account)}
          data-tip
          data-for={`${account.email}-pw-button`}
        >
          PW
        </button>

        <ReactTooltip
          id={`${account.email}-pw-button`}
          type="dark"
          effect="solid"
        >
          Change password
        </ReactTooltip>

        <button
          type="button"
          className={buttonActionClass}
          onClick={() => this.props.handleOpenEditModal(account)}
          data-tip
          data-for={`${account.email}-edit-button`}
        >
          Edit
        </button>

        <ReactTooltip
          id={`${account.email}-edit-button`}
          type="dark"
          effect="solid"
        >
          Edit account
        </ReactTooltip>

        <button
          type="button"
          className={buttonActionClass}
          onClick={() => this.props.handleOpenStatusModal(account)}
          data-tip
          data-for={`${account.email}-update-status-button`}
        >
          Status
        </button>

        <ReactTooltip
          id={`${account.email}-update-status-button`}
          type="dark"
          effect="solid"
        >
          Update account status
        </ReactTooltip>

        <button
          type="button"
          className={buttonActionClass}
          onClick={() => this.props.handleOpenBindModal(account)}
          data-tip
          data-for={`${account.email}-bind-button`}
        >
          Bind
        </button>
        <ReactTooltip
          id={`${account.email}-bind-button`}
          type="dark"
          effect="solid"
        >
          Bind network to account
        </ReactTooltip>

        <button
          type="button"
          className={buttonActionRedClass}
          onClick={() => this.props.handleDeleteAccount(account)}
          data-tip
          data-for={`${account.email}-delete-button`}
        >
          Delete
        </button>
        <ReactTooltip
          id={`${account.email}-delete-button`}
          type="dark"
          effect="solid"
        >
          Delete account
        </ReactTooltip>
      </>
    );
  }
}

export default VaultAccountsTable;

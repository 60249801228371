import React from "react";
import _ from "lodash";
import {
  Sparklines,
  SparklinesLine,
  // SparklinesReferenceLine,
} from "../../libraries/sparklines/Sparklines";
// import NumberFormat from "../common/NumberFormat";

const WIDTH = 40;
const HEIGHT = 15;
class UnitCardMiniSparkline extends React.PureComponent {
  render() {
    const { data, width = WIDTH } = this.props;

    return (
      <>
        {data && (
          <Sparklines
            data={data}
            svgHeight={HEIGHT}
            svgWidth={width}
            height={HEIGHT}
            width={width}
            max={1}
            min={0}
            // onMouseEnter={this.handleMouseEnterToPoint}
            // onMouseLeaveGraph={this.handleMouseLeaveGraph}
          >
            <SparklinesLine
              style={{
                point: {
                  r: "4",
                  fillOpacity: "0",
                },
              }}
            />
          </Sparklines>
        )}
      </>
    );
  }
}

export default UnitCardMiniSparkline;

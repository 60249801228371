import React from "react";

import { inputClass } from "helpers/StyleClass";
import { FaTimes } from "react-icons/fa";

class YbSearch extends React.Component {
  constructor(props) {
    super(props);

    this.searchTextChange = this.searchTextChange.bind(this);
    this.clearText = this.clearText.bind(this);
  }

  searchTextChange(event) {
    this.props.onChange(event.target.value);
  }

  clearText() {
    this.props.onChange("");
  }

  render() {
    const placeholder = this.props.placeholder;
    const searchText = this.props.value;

    return (
      <div className="relative">
        <input
          value={searchText}
          type="text"
          placeholder={placeholder}
          className={inputClass}
          onChange={this.searchTextChange}
          style={{ border: "unset" }}
        />
        <span
          onClick={this.clearText}
          className={`absolute text-lg ${
            searchText
              ? "inline cursor-pointer text-gray-800 hover:text-blue-600"
              : "hidden"
          }`}
          style={{ right: "10px", top: "7px" }}
        >
          <FaTimes></FaTimes>
        </span>
      </div>
    );
  }
}

export default YbSearch;

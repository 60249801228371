import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { notify } from "react-notify-toast";
import { BillingAPI } from "apis";

import InvoicesTable from "./InvoicesTable";
import PublisherFilter from "./PublisherFilter";
import ModalWrapper from "components/common/ModalWrapper";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import LoadingUI from "components/common/LoadingUI";
import { FiEdit, FiTrash } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import PromptModal from "components/common/PromptModal";
import NumberFormat from "components/common/NumberFormat";
import { ACTIONS } from "constants/Invoices";
import { MESSAGE } from "constants/Message";

function _orderItems(items) {
  return _.orderBy(items, ["startDate", "dueDate"], ["desc", "asc"]);
}

class InvoicesWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: [],
      filteredInvoices: [],
      isLoading: false,
      isSaving: false,
      isSearching: false,
      errMessage: "",
      searchText: "",
      defaultSearchText: "",

      isNotesModalOpen: false,
      notesInvoiceId: null,

      isOpenPromptModal: false,
      promptInvoiceList: [],
      promptInvoiceAction: null,
      promptMsg: [],
      promptMessage: "",
      promptAction: null,
      promptHeader: null,
    };

    this.onHandleAdvise = this.onHandleAdvise.bind(this);
    this.onHandleIssue = this.onHandleIssue.bind(this);
    this.onHandleAudit = this.onHandleAudit.bind(this);
    this.onHandleVoid = this.onHandleVoid.bind(this);
    this.onHandleBadDebt = this.onHandleBadDebt.bind(this);
    this.onHandleApprove = this.onHandleApprove.bind(this);
    this.onHandleSendOverdueEmail = this.onHandleSendOverdueEmail.bind(this);
    this.handleSearchByPublisher = this.handleSearchByPublisher.bind(this);
    this.handleBatchActions = this.handleBatchActions.bind(this);
    this.handlePromptClose = this.handlePromptClose.bind(this);
    this.handleQueryInvoices = this.handleQueryInvoices.bind(this);

    this.handleOpenNotes = this.handleOpenNotes.bind(this);
    this.handleCloseNotesModal = this.handleCloseNotesModal.bind(this);
  }

  async componentDidMount() {
    try {
      this.setState({
        isLoading: true,
      });
      const invoices = await this.queryInvoices();
      const filteredInvoices = _orderItems(invoices);

      this.setState({
        filteredInvoices,
        invoices: filteredInvoices,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
        errMessage: err,
      });
    }
  }

  async queryInvoices() {
    const queryParams = this.props.queryParams;
    const items = await BillingAPI.getInvoices(queryParams);

    const invoices = _.map(items, (item) => {
      item.startDateStr = moment.utc(item.startDate).format("YYYY/MM/DD");
      item.endDateStr = moment
        .utc(item.endDate)
        .add(1, "second")
        .format("YYYY/MM/DD");

      item.updatedAtStr = moment(item.updatedAt).format("YYYY/MM/DD HH:mm:ss");

      return item;
    });

    return invoices;
  }

  handleSearchByPublisher(inputValue) {
    let filterKeys = ["publisher.publisherId", "publisher.name"];

    let filteredInvoices = _.filter(this.state.invoices, (invoice) => {
      let isMatch = false;

      _.forEach(filterKeys, (fKey) => {
        const value = _.get(invoice, fKey);

        if (value && _.toLower(value).includes(_.toLower(inputValue))) {
          isMatch = true;
        }
      });

      return isMatch;
    });

    filteredInvoices = _orderItems(filteredInvoices);

    if (inputValue === "") {
      this.setState({
        isSearching: false,
        filteredInvoices,
        searchText: inputValue,
      });
    } else {
      this.setState({
        isSearching: true,
        filteredInvoices,
        searchText: inputValue,
      });
    }
  }

  async onHandleAdvise(invoice) {
    const { invoiceId, invoiceNo } = invoice;
    const userConfirm = window.confirm(
      `Are you sure to advise invoice number ${invoiceNo}?`
    );
    if (!userConfirm) return;

    try {
      const r = await BillingAPI.adviseInvoice(invoiceId);

      notify.show("Advised invoice!", "success");

      this.setState({
        isLoading: true,
      });
      const invoices = await this.queryInvoices();
      const filteredInvoices = _orderItems(invoices);

      this.setState({
        filteredInvoices,
        invoices: filteredInvoices,
        isLoading: false,
        defaultSearchText: this.state.searchText, // so the search input can show this text
      });

      this.handleSearchByPublisher(this.state.searchText);
    } catch (err) {
      console.log(err);
      notify.show("Failed to advise", "error");

      this.setState({
        isLoading: false,
        errMessage: err,
      });
    }
  }

  async onHandleApprove(invoice) {
    const { invoiceId, invoiceNo } = invoice;
    const userConfirm = window.confirm(
      `Are you sure to approve invoice number ${invoiceNo}?`
    );
    if (!userConfirm) return;

    try {
      const r = await BillingAPI.approveInvoice(invoiceId);

      notify.show("Approved invoice!", "success");

      this.setState({
        isLoading: true,
      });
      const invoices = await this.queryInvoices();
      const filteredInvoices = _orderItems(invoices);

      this.setState({
        filteredInvoices,
        invoices: filteredInvoices,
        isLoading: false,
        defaultSearchText: this.state.searchText, // so the search input can show this text
      });

      this.handleSearchByPublisher(this.state.searchText);
    } catch (err) {
      console.log(err);
      notify.show("Failed to approve", "error");

      this.setState({
        isLoading: false,
        errMessage: err,
      });
    }
  }

  async onHandleIssue(invoice) {
    const { invoiceId, invoiceNo } = invoice;
    const pubId = invoice.publisher.publisherId;
    const userConfirm = window.confirm(
      `Are you sure to issue invoice number ${invoiceNo}?`
    );
    if (!userConfirm) return;

    try {
      const r = await BillingAPI.issueInvoice({ invoiceId, pubId });
      console.log(r);

      const result = _.get(r, "result.data.update.result");
      console.log(result);
      if (result !== "ok") {
        throw result;
      }
      notify.show("Issued invoice!", "success");

      this.setState({
        isLoading: true,
      });
      const invoices = await this.queryInvoices();
      const filteredInvoices = _orderItems(invoices);

      this.setState({
        filteredInvoices,
        invoices: filteredInvoices,
        isLoading: false,
        defaultSearchText: this.state.searchText, // so the search input can show this text
      });

      this.handleSearchByPublisher(this.state.searchText);
    } catch (err) {
      console.log(err);
      notify.show("Failed to issue invoice", "error");

      this.setState({
        isLoading: false,
        errMessage: err,
      });
    }
  }

  async onHandleAudit(invoice) {
    const { invoiceId } = invoice;
    const pubId = invoice.publisher.publisherId;
    const userPrompt = window.prompt(
      `[Publisher Audit] Insert the PO (Purchase order) number below:`,
      ""
    );

    if (!userPrompt) return;

    try {
      const poNumber = userPrompt;
      const r = await BillingAPI.auditInvoice({ invoiceId, pubId, poNumber });
      console.log(r);

      const result = _.get(r, "result.data.update.invoice");
      console.log(result);
      notify.show("Invoice audited!", "success");

      this.setState({
        isLoading: true,
      });
      const invoices = await this.queryInvoices();
      const filteredInvoices = _orderItems(invoices);

      this.setState({
        filteredInvoices,
        invoices: filteredInvoices,
        isLoading: false,
        defaultSearchText: this.state.searchText, // so the search input can show this text
      });

      this.handleSearchByPublisher(this.state.searchText);
    } catch (err) {
      console.log(err);
      notify.show("Failed to issue invoice", "error");

      this.setState({
        isLoading: false,
        errMessage: err,
      });
    }
  }

  async onHandleVoid(invoice) {
    const { invoiceId, invoiceNo } = invoice;
    const userPrompt = window.prompt(
      `What is the reason of voiding invoice ${invoiceNo}?`,
      "Wrong amount"
    );

    if (userPrompt === "") {
      notify.show(
        "Void failed! Please give a reason in order to void invoice.",
        "error"
      );
      return;
    }
    if (!userPrompt) return;

    try {
      const r = await BillingAPI.voidInvoice({ invoiceId, reason: userPrompt });
      console.log(r);

      const result = _.get(r, "result.data.update.result[0]");
      console.log(result);
      if (result.error) {
        throw result.error;
      }

      notify.show("Voided invoice!", "success");

      this.setState({
        isLoading: true,
      });
      const invoices = await this.queryInvoices();
      const filteredInvoices = _orderItems(invoices);

      this.setState({
        filteredInvoices,
        invoices: filteredInvoices,
        isLoading: false,
        defaultSearchText: this.state.searchText, // so the search input can show this text
      });

      this.handleSearchByPublisher(this.state.searchText);
    } catch (err) {
      console.log(err);
      notify.show("Failed to void invoice", "error");

      this.setState({
        isLoading: false,
        errMessage: err,
      });
    }
  }

  async onHandleBadDebt(invoice) {
    const { invoiceNo, payment } = invoice;
    const userPrompt = window.prompt(
      `[Bad Debt] To move this invoice (invoiceNo: ${invoiceNo}) to bad debt, insert the invoiceNo below:`,
      ""
    );

    if (!userPrompt) return;
    if (userPrompt !== invoiceNo) {
      notify.show("Wrong invoiceNo! No action is performed.", "error");
      return;
    }

    try {
      const r = await BillingAPI.badDebtInvoice({
        paymentId: payment.paymentId,
      });
      console.log(r);

      const result = _.get(r, "result.data.update.payment");
      console.log(result);
      if (result.error) {
        throw result.error;
      }

      notify.show("Successfully marked invoice as bad debt!", "success");

      this.setState({
        isLoading: true,
      });
      const invoices = await this.queryInvoices();
      const filteredInvoices = _orderItems(invoices);

      this.setState({
        filteredInvoices,
        invoices: filteredInvoices,
        isLoading: false,
        defaultSearchText: this.state.searchText, // so the search input can show this text
      });

      this.handleSearchByPublisher(this.state.searchText);
    } catch (err) {
      console.log(err);
      notify.show("Failed to mark invoice as bad debt", "error");

      this.setState({
        isLoading: false,
        errMessage: err,
      });
    }
  }

  async onHandleSendOverdueEmail(invoice) {
    const { invoiceNo, invoiceId } = invoice;
    const userConfirm = window.confirm(
      `Confirm to send overdue email for invoice number ${invoiceNo}?`
    );

    if (!userConfirm) return;

    try {
      const result = await BillingAPI.sendOverdueEmail({
        invoiceId,
      });
      console.log(result);
      if (result.error) {
        throw result.error;
      }

      notify.show("Successfully sent overdue email!", "success");
    } catch (err) {
      console.log(err);
      notify.show("Failed to sent overdue email.", "error");
    }
  }

  async handleBatchActions(action, selected, isPrompt = true) {
    const list = selected
      .map((invoiceId) => _.find(this.state.invoices, { invoiceId }))
      .filter((item) => item)
      .map((item) => _.cloneDeep(item));

    if (isPrompt) {
      this.setState({
        isOpenPromptModal: true,
        promptMsg: [],
        promptInvoiceAction: action,
        promptInvoiceList: _.cloneDeep(list),
        promptHeader: `Batch ${_.startCase(action)}`,
        promptAction: () => this.handleBatchActions(action, selected, false),
      });
      return;
    }

    this.setState({
      isSaving: true,
    });

    if (action === ACTIONS.APPROVE) {
      const errList = [];
      for (let i = 0; i < list.length; i++) {
        try {
          const result = await BillingAPI.approveInvoice(selected[i]);
          // const result = await mockApiCall(list[i].invoiceId);
          if (result.error) {
            throw result.error;
          }

          if (result) {
            list[i].apiResult = MESSAGE.SUCCESS;
          }
        } catch (err) {
          list[i].apiResult = MESSAGE.ERROR;
          errList.push(`[${list[i].invoiceNo}] ${err}`);
          this.setState({
            promptMsg: [
              <>
                {errList.length} out of {list.length} invoices failed.
                {errList.map((err) => (
                  <div key={err}>{err}</div>
                ))}
              </>,
              MESSAGE.ERROR,
            ],
          });
        }

        this.setState({
          promptInvoiceList: _.cloneDeep(list),
        });
      }

      if (_.every(list, ["apiResult", MESSAGE.SUCCESS])) {
        this.setState({
          promptMsg: ["All invoices are approved!", MESSAGE.SUCCESS],
        });
      }
    }

    this.setState({
      isSaving: false,
    });
  }

  handlePromptClose() {
    this.setState({
      isOpenPromptModal: false,
    });

    this.handleQueryInvoices();
  }

  async handleQueryInvoices() {
    this.setState({
      isLoading: true,
    });
    const invoices = await this.queryInvoices();
    const filteredInvoices = _orderItems(invoices);

    this.setState({
      filteredInvoices,
      invoices: filteredInvoices,
      isLoading: false,
      defaultSearchText: this.state.searchText, // so the search input can show this text
    });

    this.handleSearchByPublisher(this.state.searchText);
  }

  handleOpenNotes(invoiceId) {
    this.setState({
      isNotesModalOpen: true,
      notesInvoiceId: invoiceId,
    });
  }

  handleCloseNotesModal() {
    this.setState({
      isNotesModalOpen: false,
      notesInvoiceId: null,
      // notesErrMessage: "",
      // isNotesModalOpen: false,
      // isNotesLoading: false,
      // isNoteSaving: false,
    });
  }

  render() {
    const {
      invoices,
      filteredInvoices,
      errMessage,
      isSaving,
      isSearching,
      isLoading,
      isNotesModalOpen,
      notesInvoiceId,

      isOpenPromptModal,
      promptMsg,
      promptInvoiceList,
      promptInvoiceAction,
      promptAction,
      promptHeader,
    } = this.state;

    if (isLoading) {
      return "Loading...";
    }

    if (errMessage) {
      return <div className="text-red-700">{errMessage.toString()}</div>;
    }

    return (
      <div>
        <div className="mb-6">
          <PublisherFilter
            handleSearch={this.handleSearchByPublisher}
            placeholder={"Search by publisher id and name"}
            defaultValue={this.state.defaultSearchText}
          ></PublisherFilter>
          <div className="mx-1 my-1 text-sm text-gray-700">
            {isSearching ? `Found ${filteredInvoices.length} out of ` : ""}
            {invoices.length} invoices
          </div>
        </div>

        <InvoicesTable
          items={filteredInvoices}
          isLoading={isLoading}
          isSaving={isSaving}
          onHandleAdvise={this.onHandleAdvise}
          onHandleApprove={this.onHandleApprove}
          onHandleIssue={this.onHandleIssue}
          onHandleAudit={this.onHandleAudit}
          onHandleVoid={this.onHandleVoid}
          onHandleBadDebt={this.onHandleBadDebt}
          onHandleSendOverdueEmail={this.onHandleSendOverdueEmail}
          handleOpenNotes={this.handleOpenNotes}
          handleBatchActions={this.handleBatchActions}
          actions={this.props.actions} // ISSUE, ADVISE, APPROVE, VOID, BAD_DEBT(tbd)
          batchActions={this.props.batchActions || []}
          columns={this.props.columns}
          tabName={this.props.tabName}
        ></InvoicesTable>

        {isNotesModalOpen && (
          <InvoiceNotesModal
            invoiceId={notesInvoiceId}
            isOpenModal={isNotesModalOpen}
            handleClose={this.handleCloseNotesModal}
            handleSaveNote={this.handleSaveNote}
          ></InvoiceNotesModal>
        )}

        {isOpenPromptModal && (
          <PromptModal
            redo={false}
            msg={promptMsg}
            isLoading={isSaving}
            isOpenModal={isOpenPromptModal}
            handleConfirm={promptAction}
            handleCancel={() => this.setState({ isOpenPromptModal: false })}
            handleClose={this.handlePromptClose}
            header={promptHeader}
            width="40%"
          >
            <>
              <div className="mb-1">
                Are you sure to{" "}
                <span className="font-semibold">
                  {_.toLower(promptInvoiceAction)}
                </span>{" "}
                these invoices?
              </div>
              <InvoicePromptTable list={promptInvoiceList}></InvoicePromptTable>
            </>
          </PromptModal>
        )}
      </div>
    );
  }
}

const thClass =
  "px-2 py-2 text-blue-800 bg-gray-200 text-left text-xs uppercase border";
const tdClass = "px-2 py-2 border";
class InvoiceNotesModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.noteRef = React.createRef();

    this.state = {
      // current Note
      currentNoteId: null,
      currentNote: "",

      // list of notes
      notes: null, // { noteId, createdAt, updatedAt, note }
      isNotesLoading: false,
      isNoteSaving: false,
      notesErrMessage: "",
      savedMessage: "",
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSaveNote = this.handleSaveNote.bind(this);
    this.handleEditClicked = this.handleEditClicked.bind(this);
    this.handleDeleteNote = this.handleDeleteNote.bind(this);
    this.handleCancelEdit = this.handleCancelEdit.bind(this);
    this.handleCurrentNoteChanged = this.handleCurrentNoteChanged.bind(this);
  }

  async componentDidMount() {
    await this.getInvoiceNotes();
  }

  async getInvoiceNotes() {
    const { invoiceId } = this.props;

    try {
      this.setState({
        isNotesModalOpen: true,
        isNotesLoading: true,
      });
      const notes = await BillingAPI.getInvoiceNotes({
        invoiceId,
      });
      console.log(notes);

      this.setState({
        notes,
        isNotesLoading: false,
        notesErrMessage: null,
      });
    } catch (err) {
      this.setState({
        notes: null,
        isNotesLoading: false,
      });
    }
  }

  handleEditClicked(note) {
    this.noteRef.current.focus();
    this.setState({ currentNoteId: note.noteId, currentNote: note.note });
  }

  handleCancelEdit() {
    this.setState({
      currentNoteId: null,
      currentNote: "",
    });
  }

  async handleDeleteNote(noteId) {
    const userConfirm = window.confirm(`Delete note?`);
    if (!userConfirm) return;

    try {
      const r = await BillingAPI.deleteInvoiceNote({ noteId });
      // console.log(r);
      this.setState({
        currentNote: "",
        currentNoteId: null,
      });
      await this.getInvoiceNotes();
    } catch (err) {
      console.log(err);
      notify.show("Failed to delete invoice note.", "error");
    }
  }

  // noteId: optional (when editing or deleting)
  async handleSaveNote() {
    //{ invoiceId, note, noteId, isDelete }
    const { invoiceId } = this.props;
    const { currentNote, currentNoteId } = this.state;
    try {
      // console.log(invoiceId, currentNote, currentNoteId);
      let params = {
        invoiceId,
        note: currentNote,
        noteId: currentNoteId || null,
      };

      const r = await BillingAPI[
        currentNoteId ? "updateInvoiceNote" : "createInvoiceNote"
      ](params);
      // console.log(r);

      this.setState({
        currentNote: "",
        currentNoteId: null,
        notesErrMessage: null,
      });

      await this.getInvoiceNotes();
    } catch (err) {
      console.log(err);
      this.setState({
        notesErrMessage: err,
      });
    }
  }

  handleCancel() {
    document.body.style.overflow = "auto";
    this.setState({
      notes: null,
      isNotesLoading: false,
      isNoteSaving: false,
      notesErrMessage: "",
      currentNote: "",
      currentNoteId: null,
    });
    this.props.handleClose();
  }

  handleCurrentNoteChanged(event) {
    this.setState({ currentNote: event.target.value });
  }

  render() {
    const {
      notes,
      currentNoteId,
      currentNote,
      savedMessage,

      isNotesLoading,
      isNoteSaving,
      notesErrMessage,
    } = this.state;

    return (
      <ModalWrapper isOpen={this.props.isOpenModal}>
        <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
          Invoice Notes:
        </div>

        {isNotesLoading ? (
          <LoadingUI></LoadingUI>
        ) : (
          <>
            <div className="text-sm">
              <div className="mb-2 mt-4">
                <label
                  className="mb-1 block pr-4 font-semibold text-gray-800"
                  htmlFor="invoice-note"
                >
                  {currentNoteId ? `Edit Note` : "Create New Note"}:
                </label>
                <div>
                  <textarea
                    ref={this.noteRef}
                    className="rounded focus:outline-none border w-full appearance-none border-gray-400 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white"
                    id="invoice-note"
                    rows="1"
                    maxLength="1024"
                    value={currentNote}
                    onChange={this.handleCurrentNoteChanged}
                  ></textarea>
                  {currentNote.length > 0 && (
                    <small className="float-left leading-none text-gray-600">
                      {1024 - currentNote.length}
                    </small>
                  )}

                  <div className="flex justify-end gap-2">
                    {currentNoteId && (
                      <button
                        className="rounded flex-shrink-0 px-2 py-1 text-sm text-blue-800 hover:bg-blue-100"
                        type="button"
                        onClick={this.handleCancelEdit}
                        disabled={isNoteSaving}
                      >
                        {isNoteSaving ? "Saving..." : "Cancel"}
                      </button>
                    )}

                    <button
                      className="rounded flex-shrink-0 border-blue-500 bg-blue-500 px-2 py-1 text-sm text-white hover:border-blue-700 hover:bg-blue-800"
                      type="button"
                      onClick={this.handleSaveNote}
                      disabled={isNoteSaving}
                    >
                      {isNoteSaving
                        ? "Saving..."
                        : `${currentNoteId ? "Update note" : "Create note"}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {savedMessage && (
              <div className="font-semibold text-gray-600">{savedMessage}</div>
            )}

            {notesErrMessage && (
              <div className="font-semibold text-red-700">
                {notesErrMessage}
              </div>
            )}

            <hr className="my-4"></hr>

            <div className="mb-4 text-sm text-gray-800">
              <div className="mb-1 font-semibold text-gray-800">
                Notes {notes && `(${notes.length})`}:
              </div>
              <div className="overflow-y-auto" style={{ maxHeight: "320px" }}>
                {notes && !_.isEmpty(notes) ? (
                  <table className="shadow divide-y min-w-full">
                    <thead>
                      <tr>
                        <th className={thClass}>Created at</th>
                        <th className={thClass}>Note</th>
                        <th className={thClass}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notes.map((item) => {
                        return (
                          <tr
                            key={item.noteId}
                            className={`${
                              currentNoteId === item.noteId
                                ? "bg-gray-400"
                                : "hover:bg-gray-100"
                            } `}
                          >
                            <td className={tdClass}>
                              <DateTimeFormatter
                                datetime={item.createdAt}
                                isLineBreak={true}
                              ></DateTimeFormatter>
                            </td>
                            <td className={tdClass} width="50%">
                              {currentNoteId === item.noteId && (
                                <span className="mr-4 bg-red-100 px-2 font-semibold text-red-700">
                                  EDITING
                                </span>
                              )}

                              {item.note}

                              {item.updatedAt !== item.createdAt && (
                                <>
                                  <span
                                    className="ml-2 font-semibold text-orange-700"
                                    data-tip
                                    data-for={`invoice-note-${item.invoiceId}-update-time`}
                                  >
                                    (edited)
                                  </span>
                                  <ReactTooltip
                                    id={`invoice-note-${item.invoiceId}-update-time`}
                                    type="dark"
                                    effect="solid"
                                    place="top"
                                  >
                                    Edited on{" "}
                                    <DateTimeFormatter
                                      datetime={item.updatedAt}
                                    ></DateTimeFormatter>
                                  </ReactTooltip>
                                </>
                              )}
                            </td>
                            <td className={tdClass}>
                              <div className="flex items-center justify-center gap-2">
                                <button
                                  type="button"
                                  className={`rounded px-2 py-1 ${
                                    currentNoteId
                                      ? "cursor-not-allowed text-gray-500"
                                      : "hover:shadow hover:bg-gray-100"
                                  }`}
                                  onClick={() => this.handleEditClicked(item)}
                                  disabled={currentNoteId}
                                >
                                  <FiEdit></FiEdit>
                                </button>
                                <button
                                  type="button"
                                  className={`rounded px-2 py-1 ${
                                    currentNoteId
                                      ? "cursor-not-allowed text-gray-500"
                                      : "hover:shadow hover:bg-gray-100"
                                  }`}
                                  onClick={() =>
                                    this.handleDeleteNote(item.noteId)
                                  }
                                  disabled={currentNoteId}
                                >
                                  <FiTrash></FiTrash>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  "No notes"
                )}
              </div>
            </div>
          </>
        )}

        <div className="border-t -mx-6 -mb-4 flex justify-end px-6 py-1">
          <button
            className="rounded flex-shrink-0 border-4 border-transparent px-2 py-1 text-sm font-semibold text-blue-500 hover:text-blue-800"
            type="button"
            onClick={this.handleCancel}
          >
            Close
          </button>
        </div>
      </ModalWrapper>
    );
  }
}

class InvoicePromptTable extends React.PureComponent {
  render() {
    const { list } = this.props;

    return (
      <div className="overflow-y-auto" style={{ maxHeight: "420px" }}>
        <table className="shadow divide-y w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className={thClass}>Publisher</th>
              <th className={thClass} style={{ width: "8rem" }}>
                Invoice
              </th>
              <th className={thClass} style={{ width: "6rem" }}>
                <div>
                  Start Date<br></br>
                  End Date
                </div>
              </th>
              <th className={thClass + " text-right"} style={{ width: "7rem" }}>
                Invoice Amount
              </th>
              <th className={thClass} style={{ width: "7rem" }}></th>
            </tr>
          </thead>
          <tbody className="divide-y bg-white text-sm">
            {list.map((item) => {
              return (
                <tr key={`${item.invoiceId}`}>
                  <td className={tdClass}>
                    {item.publisher.publisherId} - {item.publisher.name}
                  </td>
                  <td className={tdClass + " font-semibold text-gray-800"}>
                    <div className="flex gap-2 align-middle">
                      {item.invoiceNo}
                    </div>
                  </td>
                  <td className={tdClass}>
                    {item.startDateStr} <div>{item.endDateStr}</div>
                  </td>
                  <td className={tdClass + " text-right"}>
                    <span className="font-semibold text-gray-900">
                      <NumberFormat
                        value={item.amount}
                        prefix="$"
                      ></NumberFormat>
                    </span>

                    <div className="text-xs leading-none text-gray-600">
                      {item.currency}
                    </div>
                  </td>
                  <td
                    className={
                      tdClass +
                      " text-center" +
                      ` ${
                        item.apiResult === MESSAGE.SUCCESS
                          ? "font-semibold text-green-600"
                          : ""
                      }` +
                      ` ${
                        item.apiResult === MESSAGE.ERROR
                          ? "font-semibold text-red-600"
                          : ""
                      }`
                    }
                  >
                    {item.apiResult || "--"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default InvoicesWrapper;

import _ from "lodash";
import queryString from "query-string";

import { api, fetchRespJson, postData } from "apis/base";

export default {
  // network/ad units onboarding management
  validateOnboardGamAdUnits,
  onboardGamAdUnits,

  getOnboardNetworkCandidates,
  onboardNetwork,

  getWebPropertyCodeOptions,

  getYieldSetsWithUnitSummary,
  createYieldSet,
  updateYieldSet,
  migrateYieldSet,

  getAdxAccounts,
  createAdxAccount,

  getOnboardAdUnitCandidates,
  getRecommendedAdUnitCandidates,
};

async function getRecommendedAdUnitCandidates(params) {
  let apiUrl = `${api}/network/${params.networkId}/recommended-ad-unit-candidates`;
  return await postData(apiUrl, params);
}

// params: networkId, parentAdUnitIds, recursive, reload
async function getOnboardAdUnitCandidates(params) {
  let apiUrl = `${api}/network/${params.networkId}/ad-unit-candidates`;
  return await postData(apiUrl, params);
}

// params: networkId, yieldSetId, adUnitIds
async function migrateYieldSet(params) {
  let apiUrl = `${api}/network/${params.networkId}/migrate-yield-set`;
  return await postData(apiUrl, params);
}

// params: adxAccountId, name, yieldSetId
async function updateYieldSet(params) {
  let apiUrl = `${api}/network/${params.networkId}/update-yield-set`;
  return await postData(apiUrl, params);
}

// params: adxAccountId, name, networkId
async function createYieldSet(params) {
  let apiUrl = `${api}/network/${params.networkId}/create-yield-set`;
  return await postData(apiUrl, params);
}

// params: code, name, networkId
async function createAdxAccount(params) {
  let apiUrl = `${api}/network/${params.networkId}/create-adx-account`;
  return await postData(apiUrl, params);
}

async function getAdxAccounts({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/adx-accounts`;
  return await fetchRespJson(apiUrl);
}

async function getYieldSetsWithUnitSummary({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/yield-sets-with-unit-summary`;
  return await fetchRespJson(apiUrl);
}

async function getWebPropertyCodeOptions({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/web-property-code-options`;
  return await fetchRespJson(apiUrl);
}

async function getOnboardNetworkCandidates({ accountId }) {
  let apiUrl = `${api}/gam-account/${accountId}/network-candidates`;
  return await fetchRespJson(apiUrl);
}

async function onboardNetwork(params) {
  let apiUrl = `${api}/gam-account/${params.accountId}/onboard-network`;
  return await postData(apiUrl, params);
}

async function validateOnboardGamAdUnits(params) {
  let apiUrl = `${api}/validate-onboard-gam-ad-units`;
  return await postData(apiUrl, params);
}

async function onboardGamAdUnits(params) {
  let apiUrl = `${api}/onboard-gam-ad-units`;
  return await postData(apiUrl, params);
}

import React from "react";
import ReactTooltip from "react-tooltip";
import ItemStyle from "./css/inventory-item.module.css";

export function MCMLabel({ siteNetworkCodes, id }) {
  return (
    <>
      <span
        className={`rounded ${ItemStyle.mcmLabel}`}
        data-tip
        data-for={id + "-mcm-tooltip"}
      >
        MCM
      </span>
      <ReactTooltip
        id={id + "-mcm-tooltip"}
        type="dark"
        effect="solid"
        place="top"
      >
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "6px",
          }}
        >
          Child Network Codes:
        </div>
        <div
          style={{
            backgroundColor: "#F2F2F2",
            border: "1px solid #A6A6A6",
            borderRadius: "4px",
            padding: "6px",
            marginBottom: "4px",
          }}
        >
          {typeof siteNetworkCodes === "string"
            ? siteNetworkCodes
            : siteNetworkCodes &&
              siteNetworkCodes.map((code) => {
                return <div key={code}>{code}</div>;
              })}
        </div>
      </ReactTooltip>
    </>
  );
}

import React from "react";

import YbReqStatTooltip from "./ReqStatTooltip";
import YbReqStatDiagram from "./ReqStatDiagram";

import ItemStyle from "./css/inventory-item.module.css";

const YbReqStatWrapper = ({ item }) => {
  // only show if portfolio has data
  const hasPortfolioData =
    item.portfolio &&
    item.portfolio.length > 0 &&
    item.portfolio[0] &&
    item.portfolio[0].length > 0;

  // workaround
  // if hasData but also not compatible
  const workaroundStyle =
    hasPortfolioData && !item.isCompatible ? { marginTop: "8px" } : {};

  return (
    <div className={ItemStyle.itemStat} style={workaroundStyle}>
      {hasPortfolioData && (
        <>
          <YbReqStatTooltip
            item={item}
            tooltipKey={item.extGamUnitId + "-req-tooltip"}
          ></YbReqStatTooltip>
          <div data-tip data-for={item.extGamUnitId + "-req-tooltip"}>
            <YbReqStatDiagram item={item}></YbReqStatDiagram>
          </div>
        </>
      )}
    </div>
  );
};

export default YbReqStatWrapper;

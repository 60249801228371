import React from "react";
import _ from "lodash";
import Select from "react-select";
import { getSchemaByKey } from "../control-center/constants/AutoPilotConfig";
import APConfigItemForm from "../control-center/auto-pilot/config/APConfigItemForm";
import ConfigValueReadonly from "../control-center/auto-pilot/config/ConfigValueReadonly";
import ModalWrapper from "../common/ModalWrapper";
import { AutoPilotAPI } from "apis";
import Checkbox from "../common/Checkbox";
import { notify } from "react-notify-toast";
import LoadingUI from "../common/LoadingUI";
import { getTargetingsByHintType } from "../control-center/constants/AutoPilotConfigHints";
import { getAllConfigKeys } from "../control-center/constants/AutoPilotConfig";
import NetworksSelector from "components/ops-mgmt/account/NetworksSelector";
import { getQueryStringToObject } from "helpers/QueryString";
import { FaExternalLinkAlt } from "react-icons/fa";

import {
  buttonModalConfirmClass,
  buttonModalConfirmDisable,
} from "helpers/StyleClass";

// the string that is sending to api
const OPERATION_TYPE = {
  DELETE: "DELETE",
  OVERWRITE: "OVERWRITE", // SET
};

const CONFIG_TYPE = {
  BASE_CONFIG: "Base Config",
  HINT_DESKTOP: "Hint: Desktop",
  HINT_TABLET: "Hint: Tablet",
  HINT_SMARTPHONE: "Hint: Smartphone",
};

class BatchUpdateAPConfigs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unitIds: [],
      numOfUnits: 0,
      errorMessage1: null,

      keyOptions: _getKeys(),
      selectedKey: null,
      selectedKeySchema: null,
      operationType: null,
      overwriteValue: undefined,
      selectedNetworkId: null,

      keyChangesList: [], // key, op, value

      isReviewModalOpened: false,
      isLoading: false,
      isSaving: false,
      unitInfos: null,
      invalidUnits: null,
      shouldForceIterate: false,
      saveErrorMessage: null,

      configType: CONFIG_TYPE.BASE_CONFIG,
    };

    this.onTextAreaChanged = this.onTextAreaChanged.bind(this);
    this.onKeySelected = this.onKeySelected.bind(this);
    this.onOpTypeChanged = this.onOpTypeChanged.bind(this);

    this.onOverwriteValueChanged = this.onOverwriteValueChanged.bind(this);
    this.onOverwriteValueHasError = this.onOverwriteValueHasError.bind(this);

    this.onApply = this.onApply.bind(this);
    this.handleRemoveKeyChange = this.handleRemoveKeyChange.bind(this);
    this.handlePreviewThenExecute = this.handlePreviewThenExecute.bind(this);
    this.handleCloseReviewModal = this.handleCloseReviewModal.bind(this);
    this.handleConfirmExecute = this.handleConfirmExecute.bind(this);
    this.toggleShouldForceIterate = this.toggleShouldForceIterate.bind(this);

    this.handleChangeConfigType = this.handleChangeConfigType.bind(this);
    this.handleNetworksChanged = this.handleNetworksChanged.bind(this);
  }

  async componentDidMount() {
    const { networkId } = getQueryStringToObject(this.props.location.search);

    networkId &&
      this.setState({
        selectedNetworkId: _.toInteger(networkId),
      });
  }

  onTextAreaChanged(event) {
    let hasError = false;
    const transformFn = (content) => {
      const charArr = content.split("");
      let validContent = [];
      for (let c of charArr) {
        const lastC = _.last(validContent);
        switch (c) {
          case "\r": {
            continue;
          }

          case "\n":
          case " ": {
            // Ignore if we see continuous seperator
            if (lastC === undefined || lastC === ",") {
              continue;
            }

            c = ",";
            validContent.push(c);
            break;
          }

          default: {
            if (c >= "0" && c <= "9") {
              validContent.push(c);
            } else if (lastC && lastC != ",") {
              validContent.push(",");
            }
            break;
          }
        }
      }

      return validContent.join("");
    };

    let content = transformFn(event.target.value);
    // console.error("--------------------");
    // console.error('"' + content + '"');
    // console.error("--------------------");
    let unitIds = _(content.split(",")).compact().uniq().value();

    this.setState({
      numOfUnits: unitIds.length,
      unitIds,
    });

    // TODO: error validation
    // if not a number
    // if duplicate

    // if (hasError) {
    //   this.setState({
    //     errorMessage1: "Unit IDs only!"
    //   });
    // }
  }

  onKeySelected(selectedKey) {
    // console.log(selectedKey);

    const configKey = selectedKey.split(".")[1];
    const selectedKeySchema = getSchemaByKey(configKey);
    // console.log(selectedKeySchema);

    this.setState({
      selectedKey,
      selectedKeySchema,

      // clear
      operationType: null,
      overwriteValue: undefined,
    });
  }

  onOpTypeChanged(opType) {
    this.setState({
      operationType: opType,
      // clear
      overwriteValue: undefined,
    });
  }

  onOverwriteValueChanged(value) {
    this.setState({
      overwriteValue: value,
    });
  }

  onOverwriteValueHasError(error) {
    this.setState({
      errorMessage2: error,
    });
  }

  onApply(event) {
    event.preventDefault();
    // remove this key from options
    // add to list of changes
    const { selectedKey, operationType, overwriteValue, keyOptions } =
      this.state;

    // check first!
    if (!operationType) {
      return;
    }

    if (
      operationType === OPERATION_TYPE.OVERWRITE &&
      _.isUndefined(overwriteValue)
    ) {
      return;
    }

    let kc = {
      key: selectedKey,
      op: operationType,
    };

    if (operationType === OPERATION_TYPE.OVERWRITE) {
      let value = overwriteValue;
      const valueType = getSchemaByKey(selectedKey.split(".")[1]).valueType;

      switch (valueType) {
        case "integer":
        case "integer_enum": {
          value = _.parseInt(value);
          break;
        }

        case "float": {
          value = _.floor(value, 2);
          break;
        }

        case "boolean": {
          if (typeof overwriteValue === "string") {
            value = overwriteValue === "true" ? true : false;
          }
          break;
        }

        // "string_enum"
        default: {
        }
      }
      kc.value = value;
    }

    // remove key from options
    const remainingKeyOptions = _.without(keyOptions, selectedKey);
    this.setState({
      keyChangesList: [...this.state.keyChangesList, kc],

      // clear
      keyOptions: remainingKeyOptions,
      selectedKey: null,
      selectedKeySchema: null,
      operationType: null,
      overwriteValue: undefined,
    });
  }

  handleRemoveKeyChange(ckc) {
    const list = _.filter(this.state.keyChangesList, (kc) => {
      return kc.key !== ckc.key;
    });

    this.setState({
      keyChangesList: list,
      // put back into option
      keyOptions: [...this.state.keyOptions, ckc.key],
    });
  }

  async handlePreviewThenExecute() {
    // open modal
    // query metadata
    // loading
    // show preview
    this.setState({
      isReviewModalOpened: true,
      isLoading: true,
    });

    //
    const unitIds = _.map(this.state.unitIds, (uid) => {
      return _.parseInt(uid);
    });
    const result = await AutoPilotAPI.getUnitInfos({
      unitIds,
    });

    const unitInfos = _.map(unitIds, (id) => {
      const item = _.find(result.units, { gamUnitId: id });
      if (!item) {
        return {
          groupKey: "",
          gamUnitId: id,
        };
      }
      return {
        ...item,
        groupKey: `[N:${item.gamNetworkId}]${item.gamNetworkName}|[YS:${item.yieldSetId}]${item.yieldSetName}`,
      };
    });

    const validUnitInfos = _.filter(unitInfos, (ui) => {
      return (
        ui.groupKey !== "" && ui.gamNetworkId === this.state.selectedNetworkId
      );
    });

    const invalidUnits = _.orderBy(
      _.difference(unitInfos, validUnitInfos),
      ["groupKey"],
      ["desc"]
    );

    this.setState({
      isLoading: false,
      unitInfos: _.values(_.groupBy(validUnitInfos, "groupKey")),
      invalidUnits: _.values(_.groupBy(invalidUnits, "groupKey")),
    });
  }

  handleCloseReviewModal() {
    this.setState({
      isReviewModalOpened: false,
    });
  }

  toggleShouldForceIterate(shouldForceIterate) {
    this.setState({
      shouldForceIterate,
    });
  }

  async handleConfirmExecute() {
    // post api
    this.setState({ isSaving: true, saveErrorMessage: null });

    try {
      const operations = _.map(this.state.keyChangesList, (kc) => {
        let key = kc.key;
        let value = kc.value;
        // Important! special case! Added extra outer layer of array
        if (kc.key === "prophet.benchmark_abuse_sizes") {
          value = [kc.value];
        }

        // "system" is a dummy section title
        if (kc.key === "system.enabled") {
          key = "enabled";
        }

        return {
          key,
          op: kc.op === OPERATION_TYPE.OVERWRITE ? "set" : "delete",
          value,
        };
      });

      const configType = this.state.configType;
      let targetings = undefined;
      if (configType !== CONFIG_TYPE.BASE_CONFIG) {
        switch (configType) {
          case CONFIG_TYPE.HINT_DESKTOP: {
            targetings = getTargetingsByHintType("DC_DESKTOP");
            break;
          }
          case CONFIG_TYPE.HINT_TABLET: {
            targetings = getTargetingsByHintType("DC_TABLET");
            break;
          }
          case CONFIG_TYPE.HINT_SMARTPHONE: {
            targetings = getTargetingsByHintType("DC_SMARTPHONE");
            break;
          }
          default: {
            // nothing
          }
        }
      }

      const params = {
        unitIds: _.reduce(
          this.state.unitInfos,
          (sum, ui) => {
            return sum.concat(_.map(ui, "gamUnitId"));
          },
          []
        ),
        operations,
        targetings,
        shouldForceIterate: this.state.shouldForceIterate,
      };

      const result = await AutoPilotAPI.batchExecuteApConfig(params);
      console.log(result);

      console.log("executed!!!");
      notify.show(
        "Execution succeeded! Automatically reloading page...",
        "success"
      );

      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log("execution failedddd!!!");
      console.log(err);
      notify.show("Failed to execute", "error");

      console.log("Error executing batch update", err);
      this.setState({
        saveErrorMessage: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isSaving: false });
  }

  handleChangeConfigType(configType) {
    this.setState({
      configType: configType,
    });
  }

  handleNetworksChanged(network) {
    const { networkId } = network;
    this.setState({
      selectedNetworkId: networkId,
    });
  }

  render() {
    const {
      unitIds,
      numOfUnits,
      errorMessage1,
      keyOptions,
      selectedKey,
      selectedKeySchema,
      operationType,
      overwriteValue,
      keyChangesList,
      isReviewModalOpened,
      isSaving,
      saveErrorMessage,
      isLoading,
      unitInfos,
      invalidUnits,
      shouldForceIterate,
      configType,
      selectedNetworkId,
    } = this.state;

    const invalidUnitsCount = _.reduce(
      invalidUnits,
      (sum, ui) => sum + ui.length,
      0
    );

    const unitInfosCount = _.reduce(unitInfos, (sum, ui) => sum + ui.length, 0);

    return (
      <>
        <div className="bg-white px-12">
          <h1 className="pb-2 pt-8 text-4xl font-extrabold text-gray-900">
            <div className="text-base font-bold leading-none text-gray-600">
              Tools
            </div>
            Batch Update Auto Pilot Config
          </h1>
        </div>

        <div className="flex min-h-full bg-gray-200 px-12 py-8">
          <div className="w-1/2">
            <div className="mb-4 w-full">
              <div className="text-lg font-bold">Select Network</div>
              <NetworksSelector
                selectedId={selectedNetworkId}
                handleOnChange={this.handleNetworksChanged}
              ></NetworksSelector>
            </div>

            <div className="mb-4 border-b-2">
              <div className="text-lg font-bold">Internal Gam Unit IDs:</div>
              Gam Unit Id should be separated by <b>","</b> or <b>line break</b>
              .<br></br>{" "}
              {selectedNetworkId && (
                <div className="rounded flex items-center gap-1 bg-blue-200 px-4 py-2 text-sm text-gray-900">
                  Copy IDs from
                  <span
                    className="flex cursor-pointer items-center gap-2 underline hover:text-blue-700"
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/#/network/${selectedNetworkId}/units/search`
                      )
                    }
                  >
                    Ad Units Search page <FaExternalLinkAlt />
                  </span>
                </div>
              )}
              <div className="text-sm text-gray-600">
                eg. 161957, 161958, 161959, 161960, 162411
              </div>
              <div>
                <textarea
                  rows="5"
                  className="border w-full p-4"
                  onChange={this.onTextAreaChanged}
                ></textarea>
              </div>
              <div>Number of units: {numOfUnits}</div>
            </div>

            <div className="border rounded mb-4 bg-white p-4">
              <div className="flex justify-between text-sm text-gray-800">
                <label htmlFor="radio_base_config" className="cursor-pointer">
                  <input
                    id="radio_base_config"
                    type="radio"
                    value={CONFIG_TYPE.BASE_CONFIG}
                    checked={configType === CONFIG_TYPE.BASE_CONFIG}
                    onChange={() =>
                      this.handleChangeConfigType(CONFIG_TYPE.BASE_CONFIG)
                    }
                  />{" "}
                  Base Config
                </label>

                <label htmlFor="radio_hint_desktop" className="cursor-pointer">
                  <input
                    id="radio_hint_desktop"
                    type="radio"
                    value={CONFIG_TYPE.HINT_DESKTOP}
                    checked={configType === CONFIG_TYPE.HINT_DESKTOP}
                    onChange={() =>
                      this.handleChangeConfigType(CONFIG_TYPE.HINT_DESKTOP)
                    }
                  />{" "}
                  Hint: Desktop
                </label>

                <label htmlFor="radio_hint_tablet" className="cursor-pointer">
                  <input
                    id="radio_hint_tablet"
                    type="radio"
                    value={CONFIG_TYPE.HINT_TABLET}
                    checked={configType === CONFIG_TYPE.HINT_TABLET}
                    onChange={() =>
                      this.handleChangeConfigType(CONFIG_TYPE.HINT_TABLET)
                    }
                  />{" "}
                  Hint: Tablet
                </label>

                <label
                  htmlFor="radio_hint_smartphone"
                  className="cursor-pointer"
                >
                  <input
                    id="radio_hint_smartphone"
                    type="radio"
                    value={CONFIG_TYPE.HINT_SMARTPHONE}
                    checked={configType === CONFIG_TYPE.HINT_SMARTPHONE}
                    onChange={() =>
                      this.handleChangeConfigType(CONFIG_TYPE.HINT_SMARTPHONE)
                    }
                  />{" "}
                  Hint: Smartphone
                </label>
              </div>
            </div>

            <div className="border rounded bg-white p-4">
              <div className="font-bold">Select key:</div>
              <UpdateKeySelector
                keyOptions={keyOptions}
                onKeySelected={this.onKeySelected}
                value={selectedKey}
              ></UpdateKeySelector>

              {selectedKey && (
                <>
                  <form onSubmit={this.onApply}>
                    <div className="my-4 bg-gray-200 p-4">
                      <div className="font-bold">{selectedKey}</div>
                      <div className="text-sm text-gray-700">
                        {selectedKeySchema.description}
                      </div>

                      <div className="mt-4">
                        <label
                          htmlFor="radio_delete"
                          className="block cursor-pointer font-semibold text-gray-700"
                        >
                          <input
                            id="radio_delete"
                            type="radio"
                            value={OPERATION_TYPE.DELETE}
                            checked={operationType === OPERATION_TYPE.DELETE}
                            onChange={() =>
                              this.onOpTypeChanged(OPERATION_TYPE.DELETE)
                            }
                          />{" "}
                          Delete
                        </label>

                        <label
                          htmlFor="radio_overwrite"
                          className="block cursor-pointer font-semibold text-gray-700"
                        >
                          <input
                            id="radio_overwrite"
                            type="radio"
                            value={OPERATION_TYPE.OVERWRITE}
                            checked={operationType === OPERATION_TYPE.OVERWRITE}
                            onChange={() =>
                              this.onOpTypeChanged(OPERATION_TYPE.OVERWRITE)
                            }
                          />{" "}
                          Overwrite
                        </label>
                      </div>

                      {operationType === OPERATION_TYPE.OVERWRITE && (
                        <>
                          <div className="pl-4">
                            <APConfigItemForm
                              valueType={selectedKeySchema.valueType}
                              configValue={overwriteValue}
                              valueSchema={selectedKeySchema}
                              handleNewValueChanged={
                                this.onOverwriteValueChanged
                              }
                              handleHasError={this.onOverwriteValueHasError}
                            ></APConfigItemForm>
                          </div>
                        </>
                      )}
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="hover:shadow bg-blue-400 px-8 py-2 font-bold text-white"
                      >
                        Apply
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>

          <div className="w-1/2 pl-4">
            <div className="font-semibold text-gray-600">
              Changes for{" "}
              <b className="capitalize text-gray-900">{configType}</b> (
              {keyChangesList.length})
            </div>
            <hr></hr>
            <div className="mt-4">
              {keyChangesList.map((kc) => {
                const key = kc.key.split(".")[1];
                const valueType = getSchemaByKey(key).valueType;

                return (
                  <div
                    key={kc.key}
                    className="rounded shadow mb-2 flex bg-gray-100 px-4 py-2"
                  >
                    <div className="w-5/6">
                      <div className="font-bold text-gray-800">{kc.key}</div>
                      <div className="text-xs font-semibold text-gray-700">
                        {kc.op}
                      </div>
                      <div>
                        {kc.op === OPERATION_TYPE.OVERWRITE && (
                          <ConfigValueReadonly
                            valueType={valueType}
                            configValue={kc.value}
                          ></ConfigValueReadonly>
                        )}
                      </div>
                    </div>
                    <div className="w-1/6">
                      <button
                        type="button"
                        className="rounded hover:shadow bg-gray-500 px-4 py-2 text-sm text-white"
                        onClick={() => this.handleRemoveKeyChange(kc)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mt-4 flex justify-center">
              <button
                type="button"
                className={buttonModalConfirmClass}
                onClick={this.handlePreviewThenExecute}
              >
                Review then execute
              </button>
            </div>

            {isLoading && <LoadingUI></LoadingUI>}
          </div>
        </div>

        <ModalWrapper
          isOpen={isReviewModalOpened}
          showCloseFooter={false}
          handleClose={this.handleCloseReviewModal}
          width="60%"
        >
          <div>
            <div className="mb-2 text-xl font-bold text-gray-600">
              Review changes for{" "}
              <b className="capitalize text-gray-900">{configType}</b>
            </div>

            {isLoading ? (
              "Loading..."
            ) : (
              <>
                {invalidUnitsCount > 0 && (
                  <div className="border rounded mb-4 border-gray-300 bg-gray-100 px-4 py-2">
                    <div className="border-b-2 bg-gray-100 text-lg font-semibold text-red-600">
                      Invalid ({invalidUnitsCount}):
                      <span className="ml-2 text-base text-gray-700">
                        *Invalid units will not be changed upon Confirm
                        Execution.
                      </span>
                    </div>
                    <div
                      className="overflow-y-auto"
                      style={{ maxHeight: "200px" }}
                    >
                      {invalidUnits &&
                        invalidUnits.map((ui, index) => {
                          const a = ui[0];
                          return (
                            <div key={index}>
                              {a.groupKey === "" ? (
                                <>
                                  <div className="flex">
                                    {/* {ui[0].groupKey} */}
                                    <div
                                      className="font-semibold"
                                      title="Network"
                                    >
                                      {/* <div className="text-xs font-light">NETWORK</div> */}
                                      Network not found
                                    </div>
                                  </div>
                                  <ul className="list-disc pl-8">
                                    {ui.map((u) => {
                                      return (
                                        <li key={u.gamUnitId}>
                                          Unit: [{u.gamUnitId}] --
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </>
                              ) : (
                                <>
                                  <div className="flex items-center">
                                    <div
                                      className="font-semibold"
                                      title="Network"
                                    >
                                      Network: [{a.gamNetworkId}]{" "}
                                      {a.gamNetworkName}
                                    </div>
                                    <div
                                      className="pl-2 text-sm font-semibold text-gray-700"
                                      title="Yieldset"
                                    >
                                      Yieldset: [{a.yieldSetId}]{" "}
                                      {a.yieldSetName}
                                    </div>
                                  </div>
                                  <ul className="list-disc pl-8">
                                    {ui.map((u) => {
                                      return (
                                        <li key={u.gamUnitId}>
                                          Unit: [{u.gamUnitId}] {u.gamUnitName}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
                <div className="mb-4">
                  <div className="border-b-2 bg-gray-100 text-lg font-bold">
                    Units ({unitInfosCount}):
                  </div>
                  <div
                    className="overflow-y-auto"
                    style={{ maxHeight: "280px" }}
                  >
                    {unitInfos &&
                      unitInfos.map((ui, index) => {
                        const a = ui[0];
                        return (
                          <div key={index}>
                            <div className="flex items-center">
                              {/* {ui[0].groupKey} */}
                              <div className="font-semibold" title="Network">
                                {/* <div className="text-xs font-light">NETWORK</div> */}
                                Network: [{a.gamNetworkId}] {a.gamNetworkName}
                              </div>
                              <div
                                className="pl-2 text-sm font-semibold text-gray-700"
                                title="Yieldset"
                              >
                                {/* <div className="text-xs font-light">YIELDSET</div> */}
                                Yieldset: [{a.yieldSetId}] {a.yieldSetName}
                              </div>
                            </div>
                            <ul className="list-disc pl-8">
                              {ui.map((u) => {
                                return (
                                  <li key={u.gamUnitId}>
                                    Unit: [{u.gamUnitId}] {u.gamUnitName}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        );
                      })}
                    {/* <pre>{JSON.stringify(unitInfos, null, 2)}</pre> */}
                  </div>
                </div>

                <div className="mb-4">
                  <div className="border-b-2 bg-gray-100 text-lg font-bold">
                    Changes ({keyChangesList.length}):
                  </div>
                  <div
                    className="overflow-y-auto"
                    style={{ maxHeight: "280px" }}
                  >
                    {keyChangesList.map((kc) => {
                      const key = kc.key.split(".")[1];
                      const valueType = getSchemaByKey(key).valueType;

                      return (
                        <div
                          key={kc.key}
                          className="rounded shadow mb-2 bg-gray-100 px-4 py-2"
                        >
                          <div className="font-bold text-gray-800">
                            {kc.key}
                          </div>
                          <div className="text-xs font-semibold text-gray-700">
                            {kc.op}
                          </div>
                          <div>
                            {kc.op === OPERATION_TYPE.OVERWRITE && (
                              <ConfigValueReadonly
                                valueType={valueType}
                                configValue={kc.value}
                              ></ConfigValueReadonly>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {/* <pre>{JSON.stringify(keyChangesList, null, 2)}</pre> */}
                  </div>
                </div>

                <div className="rounded mt-4 bg-orange-200 px-2 py-3 leading-none">
                  <div className="mb-2 text-sm text-orange-800">
                    If there are urgent changes in the config that needs to be
                    affected immediately, check the box below:
                  </div>
                  <label
                    className={
                      isSaving ? "cursor-not-allowed" : "cursor-pointer"
                    }
                  >
                    <Checkbox
                      isChecked={shouldForceIterate}
                      isDisabled={isSaving}
                      onChange={this.toggleShouldForceIterate}
                    ></Checkbox>

                    <span className="ml-2 align-middle text-sm font-semibold text-gray-700 hover:text-gray-800">
                      Force AutoPilot to iterate ASAP
                    </span>
                  </label>
                </div>

                <div className="mt-4 flex flex-row-reverse items-center">
                  <div>
                    <button
                      type="button"
                      className={
                        isSaving ||
                        unitInfosCount === 0 ||
                        keyChangesList.length === 0
                          ? buttonModalConfirmDisable
                          : buttonModalConfirmClass
                      }
                      onClick={this.handleConfirmExecute}
                      disabled={isSaving}
                    >
                      {isSaving ? "Executing..." : "Confirm execution"}
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      disabled={isSaving}
                      className={`px-4 py-2 text-blue-700 ${
                        isSaving ? "cursor-not-allowed" : ""
                      }`}
                      onClick={this.handleCloseReviewModal}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="text-red-600">{saveErrorMessage}</div>
                </div>
              </>
            )}
          </div>
        </ModalWrapper>
      </>
    );
  }
}

class UpdateKeySelector extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedValue) {
    this.props.onKeySelected(selectedValue.value);
  }

  render() {
    const { value, keyOptions } = this.props;

    const options = _.map(keyOptions, (value, key) => {
      return {
        value,
        label: value,
        // label: _.startCase(_.lowerCase(key.replace("_twd", "")))
      };
    });

    const selectedValue = _.find(options, {
      value: value,
    });

    return (
      <div className="w-full text-sm">
        <Select
          // defaultValue={selectedValue}
          value={selectedValue || ""}
          onChange={this.handleChange}
          options={options}
          // isSearchable={false}
        ></Select>
      </div>
    );
  }
}

function _getKeys() {
  return getAllConfigKeys();
}

export default BatchUpdateAPConfigs;

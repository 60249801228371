import React from "react";
import _ from "lodash";
import PriceSectionView from "./PriceSectionView";

function PriceSettingView({ s }) {
  return (
    <div>
      <PriceSectionView
        item={{ price: s.price, priceType: s.priceType }}
        currency={s.currency}
      ></PriceSectionView>
      <div className="text-xs">
        Is Set For Everything: {s.isSetForEverything ? "true" : "false"}
      </div>
    </div>
  );
}

export default PriceSettingView;

import React from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { notify } from "react-notify-toast";
import { OnboardingAPI } from "apis";
import LoadingUI from "components/common/LoadingUI";
import PromptModal from "components/common/PromptModal";
import { getQueryStringToObject } from "helpers/QueryString";

import NetworksSelector from "./NetworksSelector";
import YieldSetsSelector from "./YieldSetsSelector";

import { TreeLayers } from "./tree-view/TreeLayers";
import { SelectedItemsTable } from "./tree-view/SelectedItemsTable";

import {
  formatCandidates,
  _recursivelySubtractParentSelectedDirectCount,
  _recursivelyAddParentSelectedDirectCount,
  _findRootUnit,
  _getUnitFullPath,
} from "./tree-view/utils";

import { ONBOARD_LIMIT_UNIT_COUNT } from "./tree-view/constants";
import { FiRefreshCw } from "react-icons/fi";
import { buttonConfirmClass } from "helpers/StyleClass";
import { MESSAGE } from "constants/Message";

class OnboardAdUnitsTreeView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPromptModalOpen: false,
      promptMessage: null,
      promptAction: null,
      msg: [],
      isLoading: false,
      queryNetworkId: null,
      queryYieldSetId: null,
      selectedNetwork: null,
      selectedYieldSet: null,
      rootLayer: [],
      layers: [],
      // to remember the units in each layer that has been expanded
      drilledDownUnits: [],

      // for bottom selected items table
      selectedItems: [],

      activeQuery: null,
    };

    this.handleSelectNetwork = this.handleSelectNetwork.bind(this);
    this.handleSelectYieldSet = this.handleSelectYieldSet.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleOnboard = this.handleOnboard.bind(this);

    this.onSelectUnit = this.onSelectUnit.bind(this);
    this.onAddUnits = this.onAddUnits.bind(this);
    this.onRemoveUnits = this.onRemoveUnits.bind(this);
    this.onSelectAllChildUnits = this.onSelectAllChildUnits.bind(this);
    this.onDrillDown = this.onDrillDown.bind(this);

    // for bottom selected items table
    this.onRemoveSelectedUnit = this.onRemoveSelectedUnit.bind(this);
    this.onRemoveAllSelectedUnits = this.onRemoveAllSelectedUnits.bind(this);
  }

  componentDidMount() {
    const { networkId, yieldSetId } = getQueryStringToObject(
      this.props.location.search
    );

    if (networkId) {
      this.setState({
        queryNetworkId: _.toInteger(networkId),
        isLoading: true,
      });
    }

    if (yieldSetId && networkId) {
      this.setState({
        queryYieldSetId: _.toInteger(yieldSetId),
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedNetwork &&
      prevState.selectedNetwork &&
      this.state.selectedNetwork.networkId !==
        prevState.selectedNetwork.networkId
    ) {
      this.setState({
        layers: [],
        selectedItems: [],
      });
    }

    if (this.state.selectedNetwork && this.state.selectedYieldSet) {
      if (this.state.selectedYieldSet && !prevState.selectedYieldSet) {
        this.handleRefresh();
      }
    }
    if (this.state.activeQuery && !prevState.activeQuery) {
      this.setState({
        isLoading: true,
      });

      try {
        const result = await OnboardingAPI.getOnboardAdUnitCandidates({
          networkId: this.state.selectedNetwork.networkId,
          parentAdUnitIds: [this.state.activeQuery.targetUnit.extGamUnitId],
          recursive: true,
          reload: false,
        });

        const candidates = formatCandidates(
          result,
          this.state.activeQuery.targetUnit
        );
        this.state.activeQuery.targetUnit.childUnits = candidates;
        this.state.activeQuery.pendingOp();
      } catch (error) {}

      this.setState({
        isLoading: false,
      });
    }
  }

  handleSelectNetwork(network) {
    if (!this.state.queryYieldSetId) {
      this.setState({ isLoading: false });
    }

    if (network) {
      this.setState({
        selectedNetwork: network,
        selectedYieldSet: null,
      });
    }
  }

  handleSelectYieldSet(ys) {
    this.setState({ isLoading: false });

    if (ys) {
      this.setState({
        selectedYieldSet: ys,
      });
    }
  }

  async handleRefresh(reload = false) {
    if (this.state.selectedNetwork === null) return;
    if (this.state.isLoading) return;

    this.setState({
      isLoading: true,
    });

    try {
      const result = await OnboardingAPI.getOnboardAdUnitCandidates({
        networkId: this.state.selectedNetwork.networkId,
        parentAdUnitIds: ["root"],
        recursive: false,
        reload,
      });

      this.setState({
        rootLayer: formatCandidates(result),
        layers: [formatCandidates(result)],
        selectedItems: [],
      });
    } catch (error) {}

    this.setState({
      isLoading: false,
    });
  }

  async handleOnboard(isPrompt = true) {
    const { selectedYieldSet, selectedItems } = this.state;
    if (isPrompt) {
      this.setState({
        isPromptModalOpen: true,
        msg: null,
        promptMessage: (
          <>
            The following Ad Units will be onboard. <br />
            <span className="font-semibold text-blue-600">
              {selectedItems.map((item) => (
                <div key={item.extGamUnitId}>{item.name}</div>
              ))}
            </span>
          </>
        ),
        promptAction: () => this.handleOnboard(false),
      });
      return;
    }

    this.setState({ isLoading: true });

    try {
      const param = {
        yieldSetId: selectedYieldSet.yieldSetId,
        extUnitIds: selectedItems.map((item) => item.extGamUnitId),
      };
      const result = await OnboardingAPI.onboardGamAdUnits(param);
      if (result) {
        this.setState({
          msg: ["Onboard Ad Units successfully", MESSAGE.SUCCESS],
        });
      }
    } catch (error) {
      this.setState({
        msg: [error, MESSAGE.ERROR],
      });
    }

    this.setState({ isLoading: false });
  }

  // parent and leaf unit both applicable
  // select or unselect
  onSelectUnit(targetUnit) {
    let newSelectedItems = [];
    // unselect if unit is selected
    if (targetUnit.checkboxStatus === "SELECTED") {
      targetUnit.checkboxStatus = "NOT_SELECTED";

      _recursivelySubtractParentSelectedDirectCount(targetUnit);

      newSelectedItems = _.without(this.state.selectedItems, targetUnit);
    } else {
      // select if unit is not selected
      targetUnit.checkboxStatus = "SELECTED";

      _recursivelyAddParentSelectedDirectCount(targetUnit);

      newSelectedItems = [...this.state.selectedItems, targetUnit];
    }

    this.setState({
      selectedItems: newSelectedItems,
    });
  }

  // for layer header
  onAddUnits(targetUnits) {
    for (let targetUnit of targetUnits) {
      targetUnit.checkboxStatus = "SELECTED";
      _recursivelyAddParentSelectedDirectCount(targetUnit);
    }

    let newSelectedItems = _.union(this.state.selectedItems, targetUnits);
    this.setState({
      selectedItems: newSelectedItems,
    });
  }

  // for layer header
  onRemoveUnits(targetUnits) {
    for (let targetUnit of targetUnits) {
      targetUnit.checkboxStatus = "NOT_SELECTED";
      _recursivelySubtractParentSelectedDirectCount(targetUnit);
    }

    let newSelectedItems = _.difference(this.state.selectedItems, targetUnits);
    this.setState({
      selectedItems: newSelectedItems,
    });
  }

  // Select all child units or Unselect all child units
  // isSelectAll: true -> Select all child units
  // isSelectAll: false -> Unselect all child units
  onSelectAllChildUnits(targetUnit, { isSelectAll }) {
    if (targetUnit.numOnboardableChildren === 0) return;
    let goSelected = isSelectAll;

    // 1. find child units of targetUnit
    // 2. set all child units' childrenSelectionStatus to ALL_SELECTED
    // 3. filter compatible units inside child units to add to selectedItems

    // 1. find root unit
    // if targetUnit is in root layer: use name
    // if not in root layer: get root parent name from parentPath
    const rootUnit = _findRootUnit(targetUnit);
    const fullPath = _getUnitFullPath(targetUnit);

    const pendingOp = (queryError) => {
      if (queryError) {
        if (rootUnit.queryError) return;
        rootUnit.queryError = queryError;
        notify.show(queryError.message, "error");

        setTimeout(() => {
          this.setState({
            activeQuery: null,
          });
        });
        return;
      }

      let allChildUnits = _.filter(rootUnit.childUnits, (unit) => {
        return _.startsWith(unit.parentPath, fullPath);
      });

      let allCompatibleUnits = _.filter(allChildUnits, (unit) => {
        return unit.isCompatible && !unit.isOnboarded;
      });

      let newSelectedItems;
      // unselect all child units
      if (goSelected === false) {
        for (let u of allCompatibleUnits) {
          // To avoid over updating, we should only update the lowest selectable nodes
          if (
            u.checkboxStatus !== "NOT_SELECTED" &&
            u.selectableDirectChildUnitCount === 0
          ) {
            _recursivelySubtractParentSelectedDirectCount(u);
          }
        }
        for (let i = 0; i < allCompatibleUnits.length; ++i) {
          allCompatibleUnits[i].checkboxStatus = "NOT_SELECTED";
        }

        newSelectedItems = _.difference(
          this.state.selectedItems,
          allCompatibleUnits
        );
      } else {
        // selected or partially selected
        for (let u of allCompatibleUnits) {
          // To avoid over updating, we should only update the lowest selectable nodes
          if (
            u.checkboxStatus !== "SELECTED" &&
            u.selectableDirectChildUnitCount === 0
          ) {
            _recursivelyAddParentSelectedDirectCount(u);
          }
        }
        for (let i = 0; i < allCompatibleUnits.length; ++i) {
          allCompatibleUnits[i].checkboxStatus = "SELECTED";
        }

        newSelectedItems = _.union(
          this.state.selectedItems,
          allCompatibleUnits
        );
      }

      setTimeout(() => {
        this.setState({
          activeQuery: null,
          selectedItems: newSelectedItems,
        });
      });
    };

    // 2. check if rootUnit.childUnits have been cached
    // if has childUnits -> step3
    // if not -> query
    if (rootUnit.queryError) {
      notify.show(rootUnit.queryError.message, "error");
    } else if (rootUnit.childUnits) {
      pendingOp();
    } else {
      // if child units have not been queried yet
      this.setState({
        activeQuery: {
          targetUnit,
          pendingOp,
        },
      });
    }
  }

  // layerNum: start from 1 (root layer = 1)
  onDrillDown(targetUnit, layerNum) {
    // 1. find root unit
    // 2. check if rootUnit.childUnits have been cached
    // 3. find next layer units

    // 1. find root unit
    // if targetUnit is in root layer: use name
    // if not in root layer: get root parent name from parentPath

    const rootUnit = _findRootUnit(targetUnit);

    const currentLayers = this.state.layers;
    const pendingOp = (queryError) => {
      if (queryError) {
        if (rootUnit.queryError) return;
        rootUnit.queryError = queryError;
        notify.show(queryError.message, "error");

        setTimeout(() => {
          this.setState({
            activeQuery: null,
          });
        });
        return;
      }

      const nextLayerUnits = targetUnit.directChildUnits;

      // update layers
      let layerCount = 0;
      let newLayers = [];
      while (layerCount < layerNum) {
        newLayers.push(currentLayers[layerCount]);
        ++layerCount;
      }
      newLayers.push(nextLayerUnits);

      // update drilledDownUnits
      for (let ddu of this.state.drilledDownUnits) {
        ddu.isDrilledDown = false;
      }
      layerCount = 0;
      let newDrilledDownUnits = [];
      while (layerCount < layerNum - 1) {
        const oldDrilledDownUnit = this.state.drilledDownUnits[layerCount];
        oldDrilledDownUnit.isDrilledDown = true;
        newDrilledDownUnits.push(oldDrilledDownUnit);
        ++layerCount;
      }
      newDrilledDownUnits.push(targetUnit);
      targetUnit.isDrilledDown = true;

      setTimeout(() => {
        this.setState({
          activeQuery: null,
          layers: newLayers,
          drilledDownUnits: newDrilledDownUnits,
        });
      });
    };

    // 2. check if rootUnit.childUnits have been cached
    // if has childUnits -> step3
    // if not -> query

    if (rootUnit.queryError) {
      notify.show(rootUnit.queryError.message, "error");
    } else if (rootUnit.childUnits) {
      pendingOp();
    } else {
      // if child units have not been queried yet
      this.setState({
        activeQuery: {
          targetUnit,
          pendingOp,
        },
      });
    }

    setTimeout(() => {
      if (layerNum < 2) return;
      const wrapper = document.getElementById("layers-wrapper");
      wrapper.scrollLeft += wrapper.scrollWidth;
    });
  }

  // bottom selected items table
  onRemoveSelectedUnit(targetUnit) {
    this.onSelectUnit(targetUnit);
  }

  // bottom selected items table
  onRemoveAllSelectedUnits() {
    for (let ru of this.state.rootLayer) {
      this.onSelectAllChildUnits(ru, { isSelectAll: false });
    }

    const list = _.filter(this.state.rootLayer, (item) => {
      return item.isCompatible && !item.isOnboarded;
    });
    this.onRemoveUnits(list);

    setTimeout(() => {
      this.setState({
        selectedItems: [],
      });
    });
  }

  render() {
    const {
      queryNetworkId,
      queryYieldSetId,
      selectedNetwork,
      selectedYieldSet,
      layers,
      selectedItems,
      isLoading,
      msg,
      isPromptModalOpen,
      promptAction,
      promptMessage,
    } = this.state;

    const isSubmitDisabled = selectedItems.length > ONBOARD_LIMIT_UNIT_COUNT;

    return (
      <div>
        {isLoading && (
          <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-gray-800 opacity-25">
            <LoadingUI iconOnly={true}></LoadingUI>
          </div>
        )}
        <div className="bg-white px-12">
          <h1 className="pb-4 pt-8 text-4xl font-extrabold text-gray-900">
            Onboard Ad Units Tree View
          </h1>
        </div>

        <div
          className="bg-gray-200 px-12 py-8"
          style={{ height: "calc(100vh - 8rem)" }}
        >
          <div className="mb-2 flex gap-8">
            <div className="w-7/12">
              <div className="mb-4 w-2/3">
                1. Select Network:
                <div>
                  <NetworksSelector
                    selectedId={queryNetworkId}
                    selectedNetwork={selectedNetwork}
                    handleOnChange={this.handleSelectNetwork}
                    handleNoData={() => this.setState({ isLoading: false })}
                  ></NetworksSelector>
                </div>
              </div>

              <div className="mb-4 w-2/3">
                2. Select Yield Set:
                <div>
                  {selectedNetwork ? (
                    <YieldSetsSelector
                      defaultValue={queryYieldSetId}
                      selectedNetwork={selectedNetwork}
                      selectedYieldSet={selectedYieldSet}
                      networkId={selectedNetwork.networkId}
                      handleOnChange={this.handleSelectYieldSet}
                      handleNoData={() => this.setState({ isLoading: false })}
                    ></YieldSetsSelector>
                  ) : (
                    <div className="text-sm text-gray-700">
                      Please select a network first
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-1 flex items-center gap-2">
                3. Select Ad Units:
                <button
                  onClick={() => this.handleRefresh(true)}
                  className={`rounded bg-gray-800 p-1 text-base text-white ${
                    selectedNetwork === null || selectedYieldSet === null
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                  data-tip
                  data-for="refresh-button"
                  disabled={
                    selectedNetwork === null || selectedYieldSet === null
                  }
                >
                  <FiRefreshCw></FiRefreshCw>
                </button>
                <ReactTooltip
                  id="refresh-button"
                  type="dark"
                  effect="solid"
                  place="top"
                >
                  Synchronize your inventory with Google Ad Manager
                </ReactTooltip>
              </div>
              <div className="w-full bg-gray-400">
                <TreeLayers
                  layers={layers}
                  onSelectUnit={this.onSelectUnit}
                  onSelectAllChildUnits={this.onSelectAllChildUnits}
                  onDrillDown={this.onDrillDown}
                  onAddUnits={this.onAddUnits}
                  onRemoveUnits={this.onRemoveUnits}
                ></TreeLayers>
              </div>
            </div>
            <div className="w-1/3">
              <SelectedItemsTable
                selectedItems={selectedItems}
                isSubmitDisabled={isSubmitDisabled}
                onRemoveSelectedUnit={this.onRemoveSelectedUnit}
                onRemoveAllSelectedUnits={this.onRemoveAllSelectedUnits}
              ></SelectedItemsTable>

              {selectedItems.length > 0 && (
                <div className="flex w-full justify-center">
                  <button
                    className={buttonConfirmClass}
                    onClick={this.handleOnboard}
                  >
                    Onboard Ad Units
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {isPromptModalOpen && (
          <PromptModal
            isOpenModal={isPromptModalOpen}
            handleConfirm={promptAction}
            msg={msg}
            handleCancel={() =>
              this.setState({ isPromptModalOpen: false, msg: null })
            }
            header="Onboard Ad Units"
          >
            {promptMessage}
          </PromptModal>
        )}
      </div>
    );
  }
}

export default OnboardAdUnitsTreeView;

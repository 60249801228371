export const unitPilotMode = {
  AUTO: "AUTO",
  MANUAL: "MANUAL",
  SYSTEM_DEFAULT: "SYSTEM_DEFAULT",
};

export const NET_REV = {
  GOOGLE: "NET_GOOGLE_INCR_REV",
  HOLISTIC: "NET_HOLISTIC_INCR_REV",
  BILLABLE: "NET_BILLABLE_REV",
};

export const netRevTitle = new Map([
  [NET_REV.GOOGLE, "Net Google Inc Rev Lift"],
  [NET_REV.HOLISTIC, "Net Holistic Inc Rev Lift"],
  [NET_REV.BILLABLE, "Net Billable Rev Lift"],
]);

import React from "react";
import _ from "lodash";
import queryString from "query-string";
import { NetworkAPI } from "apis";
import LoadingUI from "../../../common/LoadingUI";
import NetworkInfoHeader from "../../common/NetworkInfoHeader";
import UprTargetingPopover from "./UprTargetingPopover";
import DateTimeFormatter from "../../../common/DateTimeFormatter";
import { notify } from "react-notify-toast";
import UprPriceSectionPopover from "./UprPriceSectionPopover";
import ReactTooltip from "react-tooltip";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import CustomUPRSettingModal from "./CustomUPRSettingModal";
import BatchUpdateRulesModal from "./BatchUpdateRulesModal";
import BatchUpdateLabelModal from "./BatchUpdateLabelModal";
import CustomUPRDiffView from "./CustomUPRDiffView";
import SelectedStickyFooter from "components/common/SelectedStickyFooter";
import PromptModal from "components/common/PromptModal";
import SimpleDataTable from "components/common/SimpleDataTable";
import ItemsFilter from "components/ops-mgmt/common/ItemsFilter";
import NetworkEnsureResourcesButton from "../NetworkEnsureResources";
import Checkbox from "components/common/Checkbox";
import HighLightRowWrapper from "components/common/HighLightRowWrapper";
import PriceSectionView from "components/common/PriceSectionView";

const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 whitespace-pre shadow hover:bg-gray-300 text-gray-900 font-semibold";

const buttonDisabledClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-200 whitespace-pre text-gray-400 font-semibold cursor-not-allowed";

class NetworkUPRSettingViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      networkId: null,
      networkInfo: null,
      errMsg: "",

      units: [],
      virtualPlacements: [],
      currentRule: null,
      originalRules: null, // save original for diff
      customRules: null,
      isDisabledHidden: true,

      labels: [],
      configsByLabel: [],
      searchByLabel: [],
      configsBySearch: [],
      isSearch: false,

      selectedIds: [],
      isUpdateLabelModalOpen: false,

      modalType: null, // "ADD", "EDIT"
      isModalOpen: false,
      formErrMsg: null,

      isEditing: false,
      saveErrMsg: null,

      isBatchUpdateModalOpen: false,
      isPromptModalOpen: false,
      promptHeader: null,
      promptMessage: null,
      promptAction: null,

      etag: null,
    };

    this.searchRef = React.createRef();

    this.handleCurrentRuleChanged = this.handleCurrentRuleChanged.bind(this);
    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleOpenAdd = this.handleOpenAdd.bind(this);
    this.handleOpenDuplicate = this.handleOpenDuplicate.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleEditRule = this.handleEditRule.bind(this);
    this.handleAddRule = this.handleAddRule.bind(this);
    this.handleRemoveRule = this.handleRemoveRule.bind(this);
    this.handleToggleEnabled = this.handleToggleEnabled.bind(this);
    this.handleToggleEnabledByLabel =
      this.handleToggleEnabledByLabel.bind(this);
    this.handleRemoveAllByLabel = this.handleRemoveAllByLabel.bind(this);

    this.handleSaveCustomUprs = this.handleSaveCustomUprs.bind(this);

    this.handleIsEditing = this.handleIsEditing.bind(this);
    this.handleOpenBatchUpdateModal =
      this.handleOpenBatchUpdateModal.bind(this);
    this.handleBatchUpdateRules = this.handleBatchUpdateRules.bind(this);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleOpenUpdateLabelModal =
      this.handleOpenUpdateLabelModal.bind(this);
    this.handleSelectedRemove = this.handleSelectedRemove.bind(this);
    this.handleSelectedStatusChanged =
      this.handleSelectedStatusChanged.bind(this);
    this.handleBatchUpdateLabel = this.handleBatchUpdateLabel.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);

    this.scrollToLabel = this.scrollToLabel.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.toggleHideDisabled = this.toggleHideDisabled.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId } = this.props.match.params;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }
      const networkInfo = await NetworkAPI.getNetworkInfo({ networkId });
      if (!networkInfo) {
        throw new Error("Invalid Network");
      }

      const units = await NetworkAPI.getUnitsByNetworkId({ networkId });
      const virtualPlacements = await NetworkAPI.getNetworkVirtualPlacements({
        networkId,
      });
      const { proposedAt, deployedAt, customSettings, etag } =
        await NetworkAPI.getNetworkCustomUpr({ networkId });
      // New schema for v2: customs is an array of array instead of an array of objects
      // convert customs to v2
      _.forEach(customSettings, (c) => {
        if (c.targetingSection && c.targetingSection.customs) {
          if (!_.isArray(c.targetingSection.customs[0])) {
            c.targetingSection.customs = [c.targetingSection.customs];
          }
        }
      });

      // Has import querystring
      // const value = queryString.parse(this.props.location.search);
      const { importId } = queryString.parse(this.props.location.search);
      if (importId) {
        this.handleAutoImport({ importId });
      }

      this.setState(
        {
          networkId,
          networkInfo,
          originalRules: _.cloneDeep(customSettings),
          customRules: _.cloneDeep(customSettings),
          proposedAt,
          deployedAt,
          units,
          virtualPlacements,
          etag,

          isLoading: false,
        },
        () => {
          this.handleFilter({
            isDisabledHidden: this.state.isDisabledHidden,
            searchText: this.state.searchText,
          });
        }
      );
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isDisabledHidden !== this.state.isDisabledHidden) {
      this.handleFilter({
        isDisabledHidden: this.state.isDisabledHidden,
        searchText: this.state.searchText,
      });
    }
  }

  _groupLabels(customSettings, searchSettings, isSearch = false) {
    const searchByLabel = _.groupBy(searchSettings, "label");
    const configsByLabel = _.groupBy(customSettings, "label");
    let labels = _.keys(configsByLabel)
      .sort()
      .map((label, i) => {
        // label could be undefined
        return {
          name: `${label === "undefined" ? "No label" : label} (${
            isSearch ? `${(searchByLabel[label] || []).length}/` : ""
          }${configsByLabel[label].length})`,
          value: label,
          numOfItems: configsByLabel[label].length,
          numOfItemsSearch: (searchByLabel[label] || []).length,
          index: label === "undefined" ? 99999 : i,
        };
      });
    labels = _.sortBy(labels, "index");

    this.setState({ labels, configsByLabel, searchByLabel });
  }

  _searchConfig(inputValue, customSettings) {
    if (inputValue === "") return _.cloneDeep(customSettings);
    const filteredConfigs = _.filter(customSettings, (r) => {
      return r.name.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredConfigs;
  }

  async handleSaveCustomUprs() {
    const { networkId, customRules, etag } = this.state;
    this.setState({ isSaving: true });
    try {
      console.log("update upr", networkId, customRules);
      const { result } = await NetworkAPI.updateNetworkCustomUpr({
        networkId,
        customSettings: customRules,
        etag,
      });
      console.log(result);
      if (result.op === "ok" || result.op === "OK") {
        this.setState({ saveErrMsg: null });
        notify.show("Rule updated! Reloading page...", "success");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        throw `Failed response: ${JSON.stringify(result)}`;
      }
    } catch (err) {
      this.setState({ saveErrMsg: err.toString() });
    }
    this.setState({ isSaving: false });
  }

  handleCurrentRuleChanged(newRule) {
    this.setState({
      currentRule: newRule,
    });
  }

  handleAutoImport({ importId }) {
    this.handleOpenAdd({ importId });
  }

  handleOpenAdd({ importId }) {
    const defaultRule = {
      enabled: true,
      targetingSection: {},
      priceSection: {
        priceType: 0,
      },
    };
    this.setState({
      isModalOpen: true,
      modalType: "ADD",
      currentRule: defaultRule,
      importId,
    });
  }

  handleOpenEdit(rule) {
    console.log("edit", rule);
    this.setState({ currentRule: rule, isModalOpen: true, modalType: "EDIT" });
  }

  handleOpenDuplicate(rule) {
    let duplicateRule = _.cloneDeep(rule);
    duplicateRule.id = "";
    duplicateRule.name = `${rule.name} copied`;

    this.setState({
      currentRule: duplicateRule,
      isModalOpen: true,
      modalType: "ADD",
    });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false,
      currentRule: null,
      modalType: null,
      formErrMsg: null,
      isEditing: false,
      isBatchUpdateModalOpen: false,
      isUpdateLabelModalOpen: false,
    });
  }

  validate() {
    let hasError = false;
    let newRule = this.state.currentRule;

    if (!newRule.id) {
      this.setState({
        formErrMsg: "ID is required",
      });
      hasError = true;
      return hasError;
    }
    if (!newRule.name) {
      this.setState({
        formErrMsg: "Name is required",
      });
      hasError = true;
      return hasError;
    }
    if (!newRule.priceSection) {
      this.setState({
        formErrMsg: "Pricing is required",
      });
      hasError = true;
      return hasError;
    } else {
      // LGO (2) and Use Brand Setting (-1) has no need for price
      if (
        newRule.priceSection.priceType !== 2 &&
        newRule.priceSection.priceType !== -1 &&
        (newRule.priceSection.price === null ||
          newRule.priceSection.price === undefined ||
          newRule.priceSection.price === "")
      ) {
        this.setState({
          formErrMsg: "Price is required",
        });
        hasError = true;
        return hasError;
      }
    }

    if (!newRule.targetingSection || _.isEmpty(newRule.targetingSection)) {
      this.setState({
        formErrMsg: "Targeting is required",
      });
      hasError = true;
      return hasError;
    }

    const unitIds = _.get(newRule, [
      "targetingSection",
      "inventory",
      "unitIds",
    ]);
    // const placementIds = _.get(newRule, [
    //   "targetingSection",
    //   "inventory",
    //   "placementIds",
    // ]);
    // const virtualPlacementIds = _.get(newRule, [
    //   "targetingSection",
    //   "inventory",
    //   "virtualPlacementIds",
    // ]);
    const excludedUnitIds = _.get(newRule, [
      "targetingSection",
      "inventory",
      "excludedUnitIds",
    ]);
    const hasUnitIds = unitIds && unitIds.length > 0;
    // const hasPlacementIds = placementIds && placementIds.length > 0;
    // const hasVirtualPlacementIds =
    // virtualPlacementIds && virtualPlacementIds.length > 0;
    const hasExcludeUnitIds = excludedUnitIds && excludedUnitIds.length > 0;

    // Inventory Targeting is not required 10/13/2023
    // if (
    //   !hasUnitIds &&
    //   !hasPlacementIds &&
    //   !hasVirtualPlacementIds &&
    //   !hasExcludeUnitIds
    // ) {
    //   this.setState({
    //     formErrMsg: "Targeting inventory is required",
    //   });

    //   hasError = true;
    //   return hasError;
    // }

    // unitIds and excludeUnitIds cannot include the same unit ids
    if (hasUnitIds && hasExcludeUnitIds) {
      const duplicateIds = _.intersection(unitIds, excludedUnitIds);
      if (duplicateIds.length > 0) {
        this.setState({
          formErrMsg: `Targeting units cannot be targeted in both Unit IDs and Exclude Unit IDs`,
        });

        hasError = true;
        return hasError;
      }
    }
  }

  handleEditRule() {
    if (this.state.isEditing) {
      return this.setState({
        formErrMsg: "Customs or Brand Price Settings is still editing",
      });
    }

    const hasError = this.validate();
    if (hasError) return;

    let { customRules } = this.state;
    let editedRule = this.state.currentRule;

    const unitIds = _.get(editedRule, [
      "targetingSection",
      "inventory",
      "unitIds",
    ]);
    const excludedUnitIds = _.get(editedRule, [
      "targetingSection",
      "inventory",
      "excludedUnitIds",
    ]);
    const placementIds = _.get(editedRule, [
      "targetingSection",
      "inventory",
      "placementIds",
    ]);
    const virtualPlacementIds = _.get(editedRule, [
      "targetingSection",
      "inventory",
      "virtualPlacementIds",
    ]);

    // remove empty inventory array...
    // ex. placementIds: [] // is not allowed
    if (unitIds && unitIds.length === 0) {
      editedRule = _.omit(editedRule, ["targetingSection.inventory.unitIds"]);
    }
    if (excludedUnitIds && excludedUnitIds.length === 0) {
      editedRule = _.omit(editedRule, [
        "targetingSection.inventory.excludedUnitIds",
      ]);
    }
    if (placementIds && placementIds.length === 0) {
      editedRule = _.omit(editedRule, [
        "targetingSection.inventory.placementIds",
      ]);
    }
    if (virtualPlacementIds && virtualPlacementIds.length === 0) {
      editedRule = _.omit(editedRule, [
        "targetingSection.inventory.virtualPlacementIds",
      ]);
    }

    const inv = _.get(editedRule, ["targetingSection", "inventory"], null);
    if (!inv || _.isEmpty(inv)) {
      editedRule = _.omit(editedRule, ["targetingSection.inventory"]);
    }
    if (editedRule.label === "") {
      editedRule.label = undefined;
    }

    const newRules = _.map(customRules, (r) => {
      // find the edited rule
      if (r.id === editedRule.id) {
        r = editedRule;
      }

      return r;
    });

    this.setState({ customRules: newRules }, this._refreshFiltersCb);
    this.handleCloseModal();
  }

  handleAddRule() {
    if (this.state.isEditing) {
      return this.setState({
        formErrMsg: "Customs or Brand Price Settings is still editing",
      });
    }

    const hasError = this.validate();
    if (hasError) return;

    let { customRules } = this.state;
    let newRule = this.state.currentRule;

    const unitIds = _.get(newRule, [
      "targetingSection",
      "inventory",
      "unitIds",
    ]);
    const excludedUnitIds = _.get(newRule, [
      "targetingSection",
      "inventory",
      "excludedUnitIds",
    ]);
    const placementIds = _.get(newRule, [
      "targetingSection",
      "inventory",
      "placementIds",
    ]);
    const virtualPlacementIds = _.get(newRule, [
      "targetingSection",
      "inventory",
      "virtualPlacementIds",
    ]);
    // remove empty inventory array...
    // ex. placementIds: [] // is not allowed
    if (unitIds && unitIds.length === 0) {
      newRule = _.omit(newRule, ["targetingSection.inventory.unitIds"]);
    }
    if (excludedUnitIds && excludedUnitIds.length === 0) {
      newRule = _.omit(newRule, ["targetingSection.inventory.excludedUnitIds"]);
    }
    if (placementIds && placementIds.length === 0) {
      newRule = _.omit(newRule, ["targetingSection.inventory.placementIds"]);
    }
    if (virtualPlacementIds && virtualPlacementIds.length === 0) {
      newRule = _.omit(newRule, [
        "targetingSection.inventory.virtualPlacementIds",
      ]);
    }

    const inv = _.get(newRule, ["targetingSection", "inventory"], null);
    if (!inv || _.isEmpty(inv)) {
      newRule = _.omit(newRule, ["targetingSection.inventory"]);
    }
    if (newRule.label === "") {
      newRule.label = undefined;
    }

    newRule.id = _.parseInt(newRule.id);
    // console.log(customRules, newRule);

    // ID should be unique
    const isIdUniq =
      _.find(customRules, (r) => {
        return r.id === newRule.id;
      }) === undefined;
    if (!isIdUniq) {
      this.setState({
        formErrMsg: "This ID already exists, please change the ID number.",
      });
    } else {
      let newRules = [...customRules, newRule];
      newRules = _.sortBy(newRules, ["id"]);

      this.setState(
        {
          formErrMsg: null,
          customRules: newRules,
        },
        this._refreshFiltersCb
      );
      this.handleCloseModal();
    }
  }

  handleRemoveRule(rule, isPrompt = true) {
    if (isPrompt) {
      this.setState({
        isPromptModalOpen: true,
        promptHeader: "Remove Rule",
        promptMessage: (
          <>
            Are you sure you want to remove rule: {rule.id} {rule.name}?
          </>
        ),
        promptAction: () => {
          this.handleRemoveRule(rule, false);
          this.setState({ isPromptModalOpen: false });
        },
      });
      return;
    }

    const { customRules, selectedIds } = this.state;
    const newRules = _.filter(customRules, (r) => {
      return r.id !== rule.id;
    });
    const newSelectedIds = _.filter(selectedIds, (id) => {
      return id !== rule.id;
    });

    this.setState(
      { customRules: newRules, selectedIds: newSelectedIds },
      this._refreshFiltersCb
    );
  }

  handleToggleEnabled(rule) {
    let { customRules } = this.state;
    const newRules = _.map(customRules, (r) => {
      if (r.id === rule.id) {
        return { ...r, enabled: !rule.enabled };
      }
      return r;
    });

    this.setState({ customRules: newRules }, this._refreshFiltersCb);
  }

  _refreshFiltersCb() {
    const { isDisabledHidden, searchText } = this.state;
    this.handleFilter({ isDisabledHidden, searchText });
  }

  handleToggleEnabledByLabel({ label, isEnable }, isPrompt = true) {
    const isNoLabel = label === "undefined";
    const { isSearch, searchByLabel, configsByLabel } = this.state;
    const searchList = _.map(searchByLabel[label], "id");
    const configList = _.map(configsByLabel[label], "id");

    if (isPrompt) {
      this.setState({
        isPromptModalOpen: true,
        promptHeader: `${isEnable ? "Enable" : "Disable"} Rules`,
        promptMessage: (
          <>
            {isEnable ? "Enable" : "Disable"}{" "}
            {isSearch ? `${searchList.length}/${configList.length}` : "all"}{" "}
            rules in {isNoLabel ? "No label" : label}?
          </>
        ),
        promptAction: () => {
          this.handleToggleEnabledByLabel({ label, isEnable }, false);
          this.setState({ isPromptModalOpen: false });
        },
      });
      return;
    }

    const { customRules } = this.state;
    const newRules = _.map(customRules, (r) => {
      if (searchList.indexOf(r.id) !== -1) {
        return { ...r, enabled: isEnable ? true : false };
      }

      return r;
    });

    this.setState({ customRules: newRules }, this._refreshFiltersCb);
  }

  handleRemoveAllByLabel({ label }, isPrompt = true) {
    const isNoLabel = label === "undefined";

    const {
      customRules,
      selectedIds,
      searchByLabel,
      configsByLabel,
      isSearch,
    } = this.state;
    const searchList = _.map(searchByLabel[label], "id");
    const configList = _.map(configsByLabel[label], "id");

    if (isPrompt) {
      this.setState({
        isPromptModalOpen: true,
        promptHeader: `Remove ${isSearch ? "Searched" : "All"} Rules`,
        promptMessage: (
          <>
            Remove{" "}
            {isSearch
              ? `${searchList.length}/${configList.length}`
              : `all ${searchList.length}`}{" "}
            rules in {isNoLabel ? "No label" : label}?
          </>
        ),
        promptAction: () => {
          this.handleRemoveAllByLabel({ label }, false);
          this.setState({ isPromptModalOpen: false });
        },
      });
      return;
    }

    const newRules = _.filter(customRules, (r) => {
      return searchList.indexOf(r.id) === -1;
    });

    const newSelectedIds = _.filter(selectedIds, (id) => {
      const item = _.find(newRules, (r) => {
        return r.id === id;
      });
      return item ? true : false;
    });

    this.setState(
      { customRules: newRules, selectedIds: newSelectedIds },
      this._refreshFiltersCb
    );
  }

  handleIsEditing(isEditing) {
    this.setState({ isEditing });
  }

  handleOpenBatchUpdateModal() {
    this.setState({ isBatchUpdateModalOpen: true });
  }

  handleBatchUpdateRules(ruleIds, newTargetingSection, targetingType) {
    let { customRules } = this.state;

    const newRules = _.map(customRules, (r) => {
      if (_.indexOf(ruleIds, r.id) !== -1) {
        r.targetingSection = {
          ...r.targetingSection,
          [targetingType]: newTargetingSection[targetingType],
        };
        console.log(r);
      }
      return r;
    });

    console.log(newRules);

    this.setState(
      {
        customRules: newRules,
        isBatchUpdateModalOpen: false,
      },
      this._refreshFiltersCb
    );
  }

  handleSelect(selected) {
    this.setState({ selectedIds: selected });
  }

  handleOpenUpdateLabelModal() {
    this.setState({ isUpdateLabelModalOpen: true });
  }

  handleSelectedRemove(isPrompt = true) {
    const { selectedIds, customRules } = this.state;

    if (isPrompt) {
      const selectedList = _.map(selectedIds, (id) => {
        return (
          _.find(customRules, (r) => {
            return r.id === id;
          }) || {}
        );
      });
      this.setState({
        isPromptModalOpen: true,
        promptHeader: "Remove Selected Rules",
        promptMessage: (
          <>
            Are you sure you want to remove the {selectedList.length} selected
            rules? <br />
            <div
              className="my-2 overflow-y-auto"
              style={{ maxHeight: "20rem" }}
            >
              <SimpleDataTable
                items={selectedList}
                columns={[
                  { key: "id", label: "ID", className: "text-right" },
                  {
                    key: "name",
                    label: "Name",
                    className: "text-right",
                    style: { width: "70%" },
                  },
                ]}
              ></SimpleDataTable>
            </div>
          </>
        ),
        promptAction: () => {
          this.handleSelectedRemove(false);
          this.setState({ isPromptModalOpen: false });
        },
      });
      return;
    }

    const newRules = _.filter(customRules, (r) => {
      return selectedIds.indexOf(r.id) === -1;
    });

    this.setState(
      { customRules: newRules, selectedIds: [] },
      this._refreshFiltersCb
    );
  }

  handleSelectedStatusChanged(status, isPrompt = true) {
    const { selectedIds, customRules } = this.state;

    if (isPrompt) {
      const selectedList = _.map(selectedIds, (id) => {
        return (
          _.find(customRules, (r) => {
            return r.id === id;
          }) || {}
        );
      });
      this.setState({
        isPromptModalOpen: true,
        promptHeader: `${status ? "Enable" : "Disable"} Selected Rules`,
        promptMessage: (
          <>
            Are you sure you want to {status ? "enable" : "disable"} the
            {` ${selectedList.length}`} selected rules? <br />
            <div
              className="my-2 overflow-y-auto"
              style={{ maxHeight: "20rem" }}
            >
              <SimpleDataTable
                items={selectedList}
                columns={[
                  { key: "id", label: "ID", className: "text-right" },
                  {
                    key: "name",
                    label: "Name",
                    className: "text-right",
                    style: { width: "70%" },
                  },
                ]}
              ></SimpleDataTable>
            </div>
          </>
        ),
        promptAction: () => {
          this.handleSelectedStatusChanged(status, false);
          this.setState({ isPromptModalOpen: false });
        },
      });
      return;
    }

    const newRules = _.map(customRules, (r) => {
      if (selectedIds.indexOf(r.id) >= 0) {
        return { ...r, enabled: status };
      }

      return r;
    });

    this.setState({ customRules: newRules }, this._refreshFiltersCb);
  }

  handleBatchUpdateLabel(label) {
    const { customRules, selectedIds } = this.state;

    const newRules = _.map(customRules, (r) => {
      // find the edited rule
      if (selectedIds.indexOf(r.id) !== -1) {
        return { ...r, label };
      }
      return r;
    });

    this.setState({ customRules: newRules }, this._refreshFiltersCb);
    this.handleCloseModal();
  }

  handleSearch({ value }) {
    const { customRules, isDisabledHidden } = this.state;
    let status = isDisabledHidden ? "ENABLED" : "ALL";
    let filteredRules = this._filterByStatus(status, customRules);
    this._handleSearch({
      value,
      settings: filteredRules,
    });
  }

  _handleSearch({ value, settings }) {
    let inputValue = value;
    if (inputValue === undefined && this.searchRef.current) {
      inputValue = this.searchRef.current.state.inputValue;
    }

    let ruleSettings = settings || this.state.customRules;
    const result = this._searchConfig(inputValue, ruleSettings);
    this.setState({ configsBySearch: result, isSearch: inputValue !== "" });
    this._groupLabels(ruleSettings, result, inputValue !== "");
  }

  handleClearSearch() {
    if (this.searchRef.current) {
      this.searchRef.current.handleClearInput();
      this.handleSearch({ value: "" });
    }
  }

  _filterByStatus(status, customSettings) {
    if (status === "ALL") return _.cloneDeep(customSettings);
    return _.filter(customSettings, (r) => {
      return r.enabled === (status === "ENABLED" ? true : false);
    });
  }

  handleFilter({ isDisabledHidden, searchText }) {
    const { customRules } = this.state;
    let status = isDisabledHidden ? "ENABLED" : "ALL";
    let filteredRules = this._filterByStatus(status, customRules);
    this._handleSearch({
      value: searchText,
      settings: filteredRules,
    });
  }

  toggleHideDisabled() {
    this.setState({ isDisabledHidden: !this.state.isDisabledHidden });
  }

  scrollToLabel(label) {
    const element = document.getElementById(label);

    if (element) {
      window.scrollTo({
        behavior: "auto",
        top: element.offsetTop - 56,
      });
    }
  }

  render() {
    const {
      networkId,
      networkInfo,
      originalRules,
      customRules,
      proposedAt,
      deployedAt,

      currentRule,
      units,
      virtualPlacements,

      labels,
      configsByLabel,
      searchByLabel,
      configsBySearch,

      isDisabledHidden,

      isLoading,
      errMsg,

      isModalOpen,
      modalType,
      formErrMsg,
      isEditing,

      isSaving,
      saveErrMsg,

      isBatchUpdateModalOpen,
      isUpdateLabelModalOpen,
      isPromptModalOpen,

      promptHeader,
      promptMessage,
      promptAction,

      importId,
      selectedIds,

      isSearch,
    } = this.state;

    const selectedRules = _.filter(customRules, (r) => {
      return selectedIds.indexOf(r.id) !== -1;
    });

    const allEnabledNum = _.filter(customRules, (r) => r.enabled).length;
    const allDisabledNum = _.filter(customRules, (r) => !r.enabled).length;

    const filteredEnabledNum = _.filter(
      configsBySearch,
      (r) => r.enabled
    ).length;
    const filteredDisabledNum = _.filter(
      configsBySearch,
      (r) => !r.enabled
    ).length;

    return (
      <div>
        {isLoading && <LoadingUI></LoadingUI>}
        {errMsg && <div className="text-red-800">{errMsg}</div>}

        {networkInfo && (
          <div>
            <NetworkInfoHeader networkInfo={networkInfo}></NetworkInfoHeader>

            <div className="min-h-screen bg-gray-200 px-12 py-8">
              <div className="flex items-center justify-between">
                <PageTitle title="Network Custom UPRs"></PageTitle>
              </div>

              <div className="flex w-full">
                <div className="my-2 w-3/4 pr-2">
                  <div className="rounded flex items-center justify-between bg-white p-4">
                    <div className="flex gap-2">
                      <button
                        type="button"
                        className={buttonActionClass}
                        onClick={this.handleOpenBatchUpdateModal}
                      >
                        Batch update rules
                      </button>
                    </div>
                    <button
                      type="button"
                      className="shadow rounded bg-blue-200 px-4 py-2 font-medium text-blue-800 hover:bg-blue-300"
                      onClick={this.handleOpenAdd}
                    >
                      Add custom rule
                    </button>
                  </div>

                  <div className="mt-2 flex items-center gap-4 text-gray-800">
                    <div>
                      <span className="font-semibold">
                        Total: {customRules.length} rules
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <span className="flex items-center gap-1 text-green-600">
                        <FiCheckCircle></FiCheckCircle> Enabled
                      </span>
                      : {allEnabledNum}
                    </div>
                    <div className="flex items-center gap-1">
                      <span className="flex items-center gap-1 text-red-600">
                        <FiXCircle></FiXCircle> Disabled
                      </span>
                      : {allDisabledNum}
                    </div>
                    <label className="rounded ml-4 flex cursor-pointer items-center py-2">
                      <Checkbox
                        isChecked={isDisabledHidden}
                        onChange={this.toggleHideDisabled}
                      ></Checkbox>
                      <span className="ml-1 align-middle text-sm font-semibold text-gray-600 hover:text-gray-700">
                        Hide Disabled
                      </span>
                    </label>
                  </div>

                  <div style={{ marginBottom: "600px" }}>
                    <div className="mb-2 flex w-2/3 gap-2">
                      <div className="w-3/4">
                        <ItemsFilter
                          ref={this.searchRef}
                          handleSearch={(value) => this.handleSearch({ value })}
                          placeholder="Search by UPR Name"
                        ></ItemsFilter>
                      </div>
                      <div className="p-1">
                        <button
                          type="button"
                          className={buttonActionClass}
                          onClick={this.handleClearSearch}
                        >
                          Clear Search
                        </button>
                      </div>
                    </div>

                    <div className="mb-2 font-medium text-gray-900">
                      Showing {configsBySearch.length} out of{" "}
                      {customRules.length} rules ({filteredEnabledNum} enabled,{" "}
                      {filteredDisabledNum} disabled)
                    </div>

                    <div className="my-2 flex flex-wrap items-center gap-1 text-sm">
                      <div className="font-semibold text-gray-700">
                        Jump to:{" "}
                      </div>
                      {labels.map((label) => {
                        return (
                          <button
                            key={label.value}
                            type="button"
                            className="border rounded border-gray-200 bg-white px-2 py-1 text-gray-800 hover:border-blue-400"
                            onClick={() => {
                              this.scrollToLabel(label.value);
                            }}
                          >
                            {label.name}
                          </button>
                        );
                      })}
                    </div>

                    {labels.map((label) => {
                      return (
                        <div
                          id={label.value}
                          key={label.value}
                          className="border mb-8 border-gray-400"
                        >
                          <div className="flex items-center justify-between  bg-white p-4 text-xl font-semibold text-gray-800">
                            <div>
                              {label.value === "undefined"
                                ? "No label"
                                : label.value}
                            </div>

                            <div className="flex items-center gap-2">
                              <>
                                <div
                                  className="text-right"
                                  style={{ width: "6.5rem" }}
                                >
                                  {isSearch && (
                                    <span>
                                      <span className="text-gray-700 underline">
                                        {label.numOfItemsSearch}
                                      </span>{" "}
                                      /{" "}
                                    </span>
                                  )}
                                  {label.numOfItems}
                                </div>
                              </>
                            </div>
                          </div>

                          <UPRSettingsTable
                            networkId={networkId}
                            allItems={configsByLabel[label.value]}
                            isSearch={isSearch}
                            items={searchByLabel[label.value]}
                            networkInfo={networkInfo}
                            units={units}
                            virtualPlacements={virtualPlacements}
                            selectedItems={this.state.selectedIds}
                            handleOpenEdit={this.handleOpenEdit}
                            handleOpenDuplicate={this.handleOpenDuplicate}
                            handleRemoveRule={this.handleRemoveRule}
                            handleToggleEnabled={this.handleToggleEnabled}
                            handleSelect={this.handleSelect}
                          ></UPRSettingsTable>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="w-1/4 pl-2">
                  <div className="mb-4 text-sm font-semibold text-gray-700">
                    <div className="text-gray-600">Proposed at:</div>
                    <DateTimeFormatter
                      datetime={proposedAt}
                    ></DateTimeFormatter>
                  </div>

                  <div className="text-sm font-semibold text-gray-700">
                    <div className="text-gray-600">Deployed at:</div>
                    <DateTimeFormatter
                      datetime={deployedAt}
                    ></DateTimeFormatter>
                  </div>

                  <div className="mt-4 flex justify-center">
                    <button
                      type="button"
                      className="shadow rounded bg-blue-400 px-8 py-3 font-semibold text-white hover:bg-blue-600"
                      onClick={this.handleSaveCustomUprs}
                    >
                      Save Custom UPRs
                    </button>
                  </div>
                  <div className="font-semibold text-red-600">{saveErrMsg}</div>

                  <div
                    className="mt-4 overflow-y-auto overflow-x-hidden py-4"
                    style={{ maxHeight: "440px" }}
                  >
                    <CustomUPRDiffView
                      originalRules={originalRules}
                      newRules={customRules}
                    ></CustomUPRDiffView>
                  </div>

                  <div className="mt-4 flex items-center justify-center bg-gray-100 p-4">
                    <NetworkEnsureResourcesButton
                      networkId={networkId}
                    ></NetworkEnsureResourcesButton>
                  </div>
                </div>
                <SelectedStickyFooter
                  idList={["id"]}
                  selectedItems={selectedIds}
                  handleSelectedByKey={() => {}}
                  handleClear={() => this.handleSelect([])}
                  actionButtons={
                    <div className="flex gap-2">
                      <button
                        type="button"
                        disabled={selectedIds.length === 0}
                        className={
                          selectedIds.length > 0
                            ? buttonActionClass
                            : buttonDisabledClass
                        }
                        onClick={this.handleOpenUpdateLabelModal}
                      >
                        Batch update label
                      </button>
                      <button
                        type="button"
                        disabled={selectedIds.length === 0}
                        className={
                          selectedIds.length > 0
                            ? buttonActionClass
                            : buttonDisabledClass
                        }
                        onClick={() => this.handleSelectedStatusChanged(true)}
                      >
                        Enable selected
                      </button>
                      <button
                        type="button"
                        disabled={selectedIds.length === 0}
                        className={
                          selectedIds.length > 0
                            ? buttonActionClass
                            : buttonDisabledClass
                        }
                        onClick={() => this.handleSelectedStatusChanged(false)}
                      >
                        Disable selected
                      </button>
                      <button
                        type="button"
                        disabled={selectedIds.length === 0}
                        className={
                          selectedIds.length > 0
                            ? buttonActionClass
                            : buttonDisabledClass
                        }
                        onClick={this.handleSelectedRemove}
                      >
                        Remove selected
                      </button>
                    </div>
                  }
                ></SelectedStickyFooter>
              </div>

              {isModalOpen && (
                <CustomUPRSettingModal
                  networkId={networkId}
                  networkInfo={networkInfo}
                  currentRule={currentRule}
                  isModalOpen={isModalOpen}
                  modalType={modalType}
                  importId={importId}
                  formErrMsg={formErrMsg}
                  isEditing={isEditing}
                  isSaving={isSaving}
                  handleCloseModal={this.handleCloseModal}
                  handleIsEditing={this.handleIsEditing}
                  handleCurrentRuleChanged={this.handleCurrentRuleChanged}
                  handleAddRule={this.handleAddRule}
                  handleEditRule={this.handleEditRule}
                ></CustomUPRSettingModal>
              )}

              {isBatchUpdateModalOpen && (
                <BatchUpdateRulesModal
                  networkId={networkId}
                  networkInfo={networkInfo}
                  isModalOpen={isBatchUpdateModalOpen}
                  customRules={customRules}
                  selectedRules={selectedRules}
                  //
                  formErrMsg={formErrMsg}
                  isEditing={isEditing}
                  isSaving={isSaving}
                  handleCloseModal={this.handleCloseModal}
                  handleBatchUpdateRules={this.handleBatchUpdateRules}
                ></BatchUpdateRulesModal>
              )}

              {isUpdateLabelModalOpen && (
                <BatchUpdateLabelModal
                  rules={selectedRules}
                  networkInfo={networkInfo}
                  isModalOpen={isUpdateLabelModalOpen}
                  handleCloseModal={this.handleCloseModal}
                  handleConfirm={this.handleBatchUpdateLabel}
                ></BatchUpdateLabelModal>
              )}

              {isPromptModalOpen && (
                <PromptModal
                  isOpenModal={isPromptModalOpen}
                  handleConfirm={promptAction}
                  handleCancel={() =>
                    this.setState({ isPromptModalOpen: false })
                  }
                  header={promptHeader}
                >
                  {promptMessage}
                </PromptModal>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

class UPRSettingsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      popupId: "",
    };
  }

  render() {
    const {
      items,
      units,
      virtualPlacements,
      networkInfo,
      handleOpenEdit,
      handleRemoveRule,
      handleOpenDuplicate,
      handleToggleEnabled,
      networkId,
      selectedItems,
      handleSelect,
      allItems,
      isSearch,
    } = this.props;

    const { popupId } = this.state;

    // if (!items) return "";

    // if (items.length === 0) {
    //   return <div>No Custom UPRs</div>;
    // }

    const allItemsByEnabled = _.groupBy(allItems, "enabled");
    const allEnabledNum = _.get(allItemsByEnabled, ["true"], []).length;
    const allDisabledNum = _.get(allItemsByEnabled, ["false"], []).length;

    const itemsByEnabled = _.groupBy(items, "enabled");
    const enabledNum = _.get(itemsByEnabled, ["true"], []).length;
    const disabledNum = _.get(itemsByEnabled, ["false"], []).length;

    function handleSelectItem(itemId) {
      const tempList = [...selectedItems];
      if (itemId === "ALL") {
        const itemsList = _.map(items, (item) => item.id);
        const allItemsList = _.map(allItems, (item) => item.id);
        const newList = isAll()
          ? _.filter(tempList, (id) => !allItemsList.includes(id))
          : _.union(selectedItems, itemsList);
        handleSelect(newList);
        return;
      }

      const newList = tempList.includes(itemId)
        ? _.without(tempList, itemId)
        : [...tempList, itemId];
      handleSelect(newList);
    }

    function isAll() {
      if (!items || items.length === 0) return false;
      return (
        _.filter(items, (item) => {
          return selectedItems.indexOf(item.id) !== -1;
        }).length === items.length
      );
    }

    return (
      <div>
        <div className="-mt-6 flex justify-end gap-2 bg-white px-4 py-2 text-sm text-gray-700">
          <>
            <div className="flex items-center">
              <span className="text-green-600">
                <FiCheckCircle></FiCheckCircle>
              </span>
              : {isSearch && <span> {enabledNum} / </span>} {allEnabledNum}
            </div>
            <div className="flex items-center">
              <span className="text-red-600">
                <FiXCircle></FiXCircle>
              </span>
              : {isSearch && <span> {disabledNum} / </span>} {allDisabledNum}
            </div>
          </>
        </div>

        <table className="border shadow table w-full text-sm">
          <thead className="border-b bg-gray-200 text-xs text-blue-800">
            <tr>
              <th className="px-2 text-center" style={{ width: "35px" }}>
                <input
                  style={{
                    width: "18px",
                    height: "18px",
                    marginTop: "4px",
                  }}
                  type="checkbox"
                  checked={isAll()}
                  onChange={() => handleSelectItem("ALL")}
                ></input>
              </th>
              <th
                className="border px-2 py-1 text-center"
                style={{ width: "55px" }}
              >
                Enabled
              </th>
              <th className="border px-2 py-1 text-right">ID</th>
              <th
                className="border px-2 py-1 text-right"
                style={{ width: "70%" }}
              >
                Name
              </th>
              <th className="border px-2 py-1 text-center">Targeting</th>
              <th className="border px-2 py-1 text-right">Prices</th>
              <th
                className="border px-2 py-1 text-right"
                style={{ width: "252px" }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white text-gray-900">
            {(items || []).map((item) => {
              return (
                <HighLightRowWrapper
                  key={item.id}
                  selected={popupId === item.id}
                >
                  <td className="px-2 text-center">
                    <input
                      style={{
                        width: "18px",
                        height: "18px",
                        marginTop: "4px",
                      }}
                      type="checkbox"
                      checked={selectedItems.indexOf(item.id) !== -1}
                      onChange={() => handleSelectItem(item.id)}
                    ></input>
                  </td>
                  <td className="border px-2 py-1 text-center">
                    <div className="flex items-center justify-center text-center text-lg">
                      {item.enabled ? (
                        <span className="text-green-600">
                          <FiCheckCircle></FiCheckCircle>
                        </span>
                      ) : (
                        <span className="text-red-600">
                          <FiXCircle></FiXCircle>
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="border px-2 py-1 text-right">
                    <span
                      className={`font-mono ${
                        popupId === item.id ? "font-semibold" : ""
                      }`}
                    >
                      {item.id}
                    </span>
                  </td>

                  <td
                    className={`border px-2 py-1 text-right font-medium ${
                      popupId === item.id ? "font-semibold" : ""
                    }`}
                  >
                    {item.name}
                    {/* <div
                      className="break-words"
                      style={{ maxWidth: "200px", overflow: "scroll" }}
                    >
                      {item.name}
                    </div> */}
                  </td>

                  <td className="border px-2 py-1 text-center">
                    <UprTargetingPopover
                      id={item.id}
                      name={item.name}
                      networkId={networkId}
                      targetingSection={item.targetingSection}
                      units={units}
                      virtualPlacements={virtualPlacements}
                      triggerElement={
                        // <div className="cursor-pointer hover:underline">
                        //   {JSON.stringify(Object.keys(item.targetingSection))}
                        // </div>
                        <div>
                          <button
                            data-tip
                            data-for={item.id + item.name}
                            type="button"
                            className="rounded bg-blue-100 px-4 py-2 font-semibold text-blue-600 hover:bg-blue-200"
                          >
                            {Object.keys(item.targetingSection).length}
                          </button>
                          <ReactTooltip
                            id={item.id + item.name}
                            type="dark"
                            effect="solid"
                          >
                            {Object.keys(item.targetingSection).join(", ")}
                          </ReactTooltip>
                        </div>
                      }
                      onOpen={() => this.setState({ popupId: item.id })}
                      onClose={() => this.setState({ popupId: "" })}
                    ></UprTargetingPopover>
                  </td>

                  <td className="whitespace-no-wrap border px-2 py-1 text-right">
                    <div className="-mx-2 px-2">
                      <PriceSectionView
                        priceType={item.priceSection.priceType}
                        item={{
                          priceType: item.priceSection.priceType,
                          price: item.priceSection.price,
                        }}
                        currency={networkInfo.currency}
                      ></PriceSectionView>
                    </div>
                    {item.priceSection.brandedPriceSettings && (
                      <div className="border-t">
                        <UprPriceSectionPopover
                          networkId={networkInfo.networkId}
                          currency={networkInfo.currency}
                          targetingSection={item.priceSection}
                          triggerElement={
                            <div className="cursor-pointer hover:underline">
                              <BrandedPriceView
                                item={item.priceSection}
                                currency={networkInfo.currency}
                              ></BrandedPriceView>
                            </div>
                          }
                          onOpen={() => this.setState({ popupId: item.id })}
                          onClose={() => this.setState({ popupId: "" })}
                        ></UprPriceSectionPopover>
                      </div>
                    )}
                  </td>

                  <td className="whitespace-no-wrap border px-2 py-1 text-right">
                    <div className="flex items-center justify-end gap-4">
                      <button
                        type="button"
                        className={`rounded text-blue-600 hover:underline ${
                          item.enabled
                            ? "hover:text-red-600"
                            : "hover:text-green-600"
                        }`}
                        onClick={() => handleToggleEnabled(item)}
                      >
                        {item.enabled ? "Disable" : "Enable"}
                      </button>
                      <button
                        type="button"
                        className="text-blue-600 hover:underline"
                        onClick={() => handleOpenEdit(item)}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="text-blue-600 hover:underline"
                        onClick={() => handleOpenDuplicate(item)}
                      >
                        Duplicate
                      </button>
                      <button
                        type="button"
                        className="text-blue-600 hover:text-red-600 hover:underline"
                        onClick={() => handleRemoveRule(item)}
                      >
                        Remove
                      </button>
                    </div>
                  </td>
                </HighLightRowWrapper>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

function BrandedPriceView({ item, currency }) {
  const { brandedPriceSettings } = item;

  return (
    <div>
      <span className="rounded bg-green-200 px-2 text-xs text-green-900">
        Branded ({brandedPriceSettings.length})
      </span>
    </div>
  );
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

export default NetworkUPRSettingViewer;

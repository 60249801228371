import _ from "lodash";

import {
  api,
  fetchRespJson,
  fetchRespJsonWithoutCredentials,
  postData,
} from "apis/base";

export default {
  getLatestObserverData,
  getAnatomyReport,
  getCstAnatomyReport,
  getCstAnatomyReportLink,
  getUnitRevLiftTrend,

  // anatomy exp debug
  getExpDebugLog,
};

async function getCstAnatomyReportLink({
  networkId,
  extGamUnitId,
  startDate,
  endDate,
}) {
  const apiUrl = `${api}/cst/anatomy-report-link`;
  let postParams = {
    networkId,
    extGamUnitId,
    startDate,
    endDate,
  };
  const { url } = await postData(apiUrl, postParams);
  const data = await fetchRespJsonWithoutCredentials(url);
  return data;
}

async function getCstAnatomyReport({
  networkId,
  extGamUnitId,
  startDate,
  endDate,
}) {
  const apiUrl = `${api}/cst/anatomy-report`;
  let postParams = {
    networkId,
    extGamUnitId,
    startDate,
    endDate,
  };
  return await postData(apiUrl, postParams);
}

async function getExpDebugLog({ expId }) {
  // /anatomy/debug/:expId
  const apiUrl = `${api}/anatomy/debug/${expId}`;
  return await fetchRespJson(apiUrl);
}

async function getLatestObserverData(
  unitId,
  dateRange = 7,
  isForceRefresh = false,
  useNewRequest = false
) {
  const latestFile = await _getLatestFileLink(unitId, {
    dateRange,
    isForceRefresh,
    useNewRequest,
  });
  const resultUrl = latestFile.link;

  // const resultUrl = `https://s3-ap-northeast-1.amazonaws.com/yb-observer-results-dev/observer-sample.json`;
  const resultData = await fetchRespJsonWithoutCredentials(resultUrl);

  return resultData;
}

async function getAnatomyReport(queryParams) {
  // const queryParams = {
  //   startDate,
  //   endDate,
  //   dateRange,
  //   isForceRefresh,
  //   useNewRequest,
  // };
  let queryString = "";
  console.log(queryParams);
  const qs = _.map(queryParams, (value, key) => {
    return `${key}=${value}`;
  });
  queryString = `?${qs.join("&")}`;
  const resultUrl = `${api}/anatomy/unit/${queryParams.unitId}${queryString}`;
  const { link } = await fetchRespJson(resultUrl);
  console.log(link);
  return await fetchRespJsonWithoutCredentials(link);
}

async function getUnitRevLiftTrend({
  extGamUnitId,
  startDate,
  endDate,
  reportType,
}) {
  const apiUrl = `${api}/unit/rev-lift-trend`;
  const postParams = {
    extGamUnitId,
    startDate,
    endDate,
    reportType,
  };
  return await postData(apiUrl, postParams);
}

// local function
async function _getLatestFileLink(unitId, queryParams) {
  let queryString = "";
  if (queryParams) {
    const qs = _.map(queryParams, (value, key) => {
      return `${key}=${value}`;
    });
    queryString = `?${qs.join("&")}`;
  }
  const resultUrl = `${api}/get-latest-result-url/${unitId}${queryString}`;
  return await fetchRespJson(resultUrl);
}

import React from "react";
import _ from "lodash";
import ReactModal from "react-modal";
import ModalWrapper from "../common/ModalWrapper";
import NetworkStatusIndicator from "../common/NetworkStatusIndicator";
import NetworksSelector from "../ops-mgmt/account/NetworksSelector";
import PromptModal from "components/common/PromptModal";

ReactModal.setAppElement("body");

const buttonActionRedClass =
  "px-2 py-2 mr-1 my-1 bg-red-400 text-xs rounded shadow hover:bg-red-600 text-white font-semibold";

const thClass =
  "px-4 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
const tdClass = "px-4 py-2 border";
const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";

const TAB = {
  NETWORK: "NETWORK",
  CODE: "CODE",
};

const PURPOSE = {
  CONSOLE: "CONSOLE",
  API: "API",
  NONE: "NONE",
};

const PURPOSE_OPTIONS = {
  [PURPOSE.CONSOLE]: "For Console Proxy",
  [PURPOSE.API]: "For GAM API",
  [PURPOSE.NONE]: "None",
};

class VaultAccountBindNetworkModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNetwork: null,
      selectedTab: TAB.NETWORK,

      // can also choose to use network code
      networkCode: "",
      purpose: PURPOSE.API,

      // prompt related
      isPromptOpen: false,
      isPromptLoading: false,
      promptMsg: [],
      promptHeader: "",
      promptMessage: "",
      promptAction: null,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleBind = this.handleBind.bind(this);
    this.handleBindWithCode = this.handleBindWithCode.bind(this);
    this.onSelectNetwork = this.onSelectNetwork.bind(this);

    this.handleCodeChanged = this.handleCodeChanged.bind(this);
  }

  handleCancel() {
    this.setState({
      selectedNetwork: null,
    });
    this.props.handleClose();
  }

  handleRemove(network, isPrompt = true) {
    const { account } = this.props;
    if (isPrompt) {
      this.setState({
        isPromptOpen: true,
        promptMsg: [],
        promptHeader: "Remove Network Binding",
        promptMessage: (
          <div>
            Remove this network binding?
            <div className="font-semibold text-red-600">
              {network.name} ({network.code})
            </div>
          </div>
        ),
        promptAction: () => this.handleRemove(network, false),
      });
      return;
    }

    this.setState({ isPromptLoading: true });

    this.props.handleRemoveBindNetwork({
      networkCode: network.code,
      accountId: account.id,
    });
  }

  handleBind() {
    const { selectedNetwork, purpose } = this.state;
    if (!selectedNetwork) return;

    const { account } = this.props;
    this.props.handleBindNetwork({
      networkCode: selectedNetwork.code,
      accountId: account.id,
      purpose: this._parsePurposeParam(purpose),
    });
  }

  handleBindWithCode() {
    const { networkCode, purpose } = this.state;
    if (!networkCode) return;

    const { account } = this.props;
    this.props.handleBindNetwork({
      networkCode,
      accountId: account.id,
      purpose: this._parsePurposeParam(purpose),
    });
  }

  onSelectNetwork(network) {
    const { selectedNetwork } = this.state;
    if (selectedNetwork && network.networkId !== selectedNetwork.networkId) {
      this.props.handleClearSaveErrorMsg();
    }
    this.setState({ selectedNetwork: network });
  }

  handleCodeChanged(e) {
    this.setState({ networkCode: e.target.value });
  }

  _parsePurposeParam(purpose) {
    if (purpose === PURPOSE.CONSOLE)
      return {
        forConsoleProxy: 1,
        forGamApi: 0,
      };

    if (purpose === PURPOSE.API)
      return {
        forConsoleProxy: 0,
        forGamApi: 1,
      };

    if (purpose === PURPOSE.NONE)
      return {
        forConsoleProxy: 0,
        forGamApi: 0,
      };

    return {};
  }

  _parsePurpose({ for_gam_api, for_console_proxy }) {
    if (for_gam_api && for_gam_api === 1) {
      return <div className="font-semibold text-green-600">For GAM API</div>;
    }

    if (for_console_proxy && for_console_proxy === 1) {
      return (
        <div className="font-semibold text-gray-600">For Console Proxy</div>
      );
    }

    return <div></div>;
  }

  render() {
    const {
      isOpenModal,
      handleClose,
      isSaving,
      saveErrorMsg,
      isRemoving,
      account,
      networks,
    } = this.props;

    const {
      selectedNetwork,
      selectedTab,
      networkCode,
      purpose,
      isPromptOpen,
      isPromptLoading,
      promptAction,
      promptHeader,
      promptMessage,
      promptMsg,
    } = this.state;

    const hasNetworkBinding = account.networks.length > 0;

    let disableConsole = false;

    if (selectedTab === TAB.NETWORK && selectedNetwork) {
      const network = _.find(networks, { code: selectedNetwork.code });
      if (network) {
        disableConsole = !_.every(
          network.accounts,
          (acc) => acc.for_console_proxy === 0
        );
      }
    }

    if (selectedTab === TAB.CODE && networkCode !== "") {
      const network = _.find(networks, { code: networkCode });
      if (network) {
        disableConsole = !_.every(
          network.accounts,
          (acc) => acc.for_console_proxy === 0
        );
      }
    }

    let buttonText = "";
    let buttonAction = null;
    const buttonDisabled =
      selectedTab === TAB.NETWORK ? !selectedNetwork : networkCode === "";
    if (selectedTab === TAB.NETWORK) {
      buttonText = "Bind network";
      buttonAction = this.handleBind;
    } else if (selectedTab === TAB.CODE) {
      buttonText = "Bind with network code";
      buttonAction = this.handleBindWithCode;
    }

    return (
      <>
        <ModalWrapper isOpen={isOpenModal} showCloseFooter={false} width="60%">
          <div>
            <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
              Bind networks to {account.email}
            </div>

            {hasNetworkBinding && (
              <div className="border-b -mx-8 mb-6 px-8 pb-6">
                <div className="font-semibold">
                  Networks Binding{" "}
                  {account.networks.length > 1 && (
                    <span className="text-blue-600">
                      ({account.networks.length})
                    </span>
                  )}
                </div>
                <div className="overflow-y-auto" style={{ maxHeight: "12rem" }}>
                  <table className="shadow divide-y min-w-full">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className={thClass}>id</th>
                        <th className={thClass}>name</th>
                        <th className={thClass}>code</th>
                        <th className={thClass}>purpose</th>
                        <th className={thClass + " text-right"}>Action</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y bg-white text-sm">
                      {account.networks.map((network) => {
                        return (
                          <tr
                            key={network.networkId}
                            className="hover:bg-gray-100"
                          >
                            <td className={tdClass}>{network.networkId}</td>
                            <td className={tdClass}>
                              <div className="flex items-end">
                                <div className="mr-1 flex items-center">
                                  <NetworkStatusIndicator
                                    status={network.status}
                                  ></NetworkStatusIndicator>
                                  <span className="ml-1">
                                    {network.networkId}
                                  </span>
                                </div>

                                <div className="font-medium">
                                  {network.name}
                                </div>
                              </div>
                            </td>
                            <td className={tdClass}>{network.code}</td>
                            <th className={tdClass}>
                              {this._parsePurpose(network)}
                            </th>

                            <td className={tdClass + " text-right"}>
                              <button
                                type="button"
                                className={buttonActionRedClass}
                                onClick={() => this.handleRemove(network)}
                                disabled={isRemoving}
                              >
                                {isRemoving ? "Removing..." : "Remove bind"}
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            <div className="mb-4 flex gap-2">
              {_.values(TAB).map((key) => (
                <button
                  key={key}
                  type="button"
                  className={`rounded px-2 py-1 text-sm font-semibold text-gray-700 ${
                    selectedTab === key
                      ? "bg-blue-200"
                      : "bg-gray-100 hover:bg-gray-200 hover:text-gray-800"
                  }`}
                  onClick={() =>
                    this.setState({
                      selectedTab: key,
                      networkCode: "",
                      saveErrorMsg: null,
                    })
                  }
                >
                  {_.startCase(_.lowerCase(key))}
                </button>
              ))}
            </div>

            <div className="font-semibold">
              {selectedTab === TAB.NETWORK
                ? "Add Network Binding:"
                : "Add Binding with Network Code:"}
            </div>
            <div>
              <div className="mb-2">
                {selectedTab === TAB.NETWORK ? (
                  <NetworksSelector
                    valueKey="code"
                    isMulti={false}
                    handleOnChange={this.onSelectNetwork}
                  ></NetworksSelector>
                ) : (
                  <input
                    className={inputClass}
                    id="network-code"
                    type="text"
                    value={networkCode}
                    onChange={this.handleCodeChanged}
                  ></input>
                )}
              </div>

              <div className="font-semibold">Set Purpose:</div>
              <div className="mb-2 flex gap-4">
                {_.values(PURPOSE).map((key) => (
                  <label
                    htmlFor={`radio_type_${key}`}
                    className="flex cursor-pointer items-center gap-1"
                    key={key}
                  >
                    <input
                      id={`radio_type_${key}`}
                      type="radio"
                      disabled={
                        key === PURPOSE.CONSOLE ? disableConsole : false
                      }
                      checked={purpose === key}
                      onChange={() => this.setState({ purpose: key })}
                    />{" "}
                    {PURPOSE_OPTIONS[key]}
                  </label>
                ))}
              </div>

              <div className="text-red-700">{saveErrorMsg}</div>

              <div className="mt-8 flex items-center justify-end">
                <button
                  className="rounded flex-shrink-0 border-4 border-transparent px-2 py-1 text-sm text-blue-500 hover:text-blue-800"
                  type="button"
                  onClick={handleClose}
                >
                  Cancel
                </button>

                <button
                  className={`rounded flex-shrink-0 border-4 px-2 py-1 text-sm font-semibold text-white ${
                    isSaving || buttonDisabled
                      ? "cursor-not-allowed border-blue-300 bg-blue-300"
                      : "border-blue-500 bg-blue-500 hover:border-blue-700 hover:bg-blue-800"
                  }`}
                  type="button"
                  disabled={isSaving || buttonDisabled}
                  onClick={buttonAction}
                >
                  {isSaving ? "Binding..." : buttonText}
                </button>
              </div>
            </div>
          </div>
        </ModalWrapper>

        {isPromptOpen && (
          <PromptModal
            msg={promptMsg}
            isOpenModal={isPromptOpen}
            isLoading={isPromptLoading}
            handleConfirm={promptAction}
            handleCancel={() => this.setState({ isPromptOpen: false })}
            header={promptHeader}
          >
            {promptMessage}
          </PromptModal>
        )}
      </>
    );
  }
}

export default VaultAccountBindNetworkModal;

import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import NumberFormat from "../common/NumberFormat";
import ReactTooltip from "react-tooltip";
import { FiExternalLink } from "react-icons/fi";
import UnitStatus from "../common/UnitStatus";
import UnitMode from "../common/UnitMode";
import AutoPilotConfigEditButton from "./AutoPilotConfigEditButton";
import DateTimeFormatter from "../common/DateTimeFormatter";
import ClickToCopyWrapper from "../common/ClickToCopyWrapper";
import UnitCardMiniSparkline from "./UnitCardMiniSparkline";
import UnitRevenueLiftTrendModal from "./UnitRevenueLiftTrendModal";

import { NET_REV, netRevTitle } from "constants/Unit";
import { FaSearch } from "react-icons/fa";

// show original if no abbreviation is provided
const demandChannelsMap = {
  "Ad Exchange": "Adx",
  AdSense: "Ads",
  "Exchange Bidding": "EB",
  "Price Priority": "PP",
  "Header Bidding": "HB",
  // SMART: "SMART",
  Sponsorship: "Sponsor",
  "Billable House": "BH",
  "Preferred Deal": "PD",
  Mediation: "Mediation",
  "SDK Bidding": "SDK Bidding",
};

const demandTypePriorityMap = {
  Sponsor: 1,
  Standard: 2,
  PD: 3,
  Adx: 4,
  Ads: 5,
  OB: 6,
  EB: 7,
  "Billable House": 8,
  PP: 9,
  Bulk: 10,
  House: 12,
  Network: 11,
  Mediation: 13,
  "SDK Bidding": 14,
};

class AdUnitBasicCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleGoToCstAnatomy = this.handleGoToCstAnatomy.bind(this);
    this.handleGoToObserver = this.handleGoToObserver.bind(this);
    this.handleGoToUnitInsight = this.handleGoToUnitInsight.bind(this);
    this.handleGoToUnitAPConfigPage =
      this.handleGoToUnitAPConfigPage.bind(this);
  }

  handleGoToCstAnatomy(unitId) {
    window.open(`${window.location.origin}/#/anatomy/cst/${unitId}`);
  }

  handleGoToObserver(unitId) {
    window.open(`${window.location.origin}/#/anatomy/${unitId}`);
  }

  handleGoToUnitInsight(unitId) {
    window.open(`${window.location.origin}/#/unit-insight/${unitId}`);
  }

  handleGoToUnitAPConfigPage(unitId) {
    window.open(
      `${window.location.origin}/#/control-center/unit/${unitId}/auto-pilot/config`
    );
  }

  render() {
    const {
      unit,
      networkName,
      networkId,
      type,
      showFullWidthCard,
      isCompactMode,
    } = this.props;

    const demandTypes = _(unit.demand_types.split(","))
      .map((t) => {
        return demandChannelsMap[t] || t;
      })
      .uniq()
      .compact()
      .orderBy((t) => {
        return demandTypePriorityMap[t];
      })
      .value();

    if (type === "NOT_ONBOARDED_UNITS") {
      return (
        <UnitCardNotOnboarded
          unit={unit}
          demandTypes={demandTypes}
          showFullWidthCard={showFullWidthCard}
        ></UnitCardNotOnboarded>
      );
    } else {
      return (
        <UnitCardRevenueDriver
          unit={unit}
          type={type}
          demandTypes={demandTypes}
          networkName={networkName}
          networkId={networkId}
          handleGoToCstAnatomy={this.handleGoToCstAnatomy}
          handleGoToObserver={this.handleGoToObserver}
          handleGoToUnitInsight={this.handleGoToUnitInsight}
          handleGoToUnitAPConfigPage={this.handleGoToUnitAPConfigPage}
          showFullWidthCard={showFullWidthCard}
          isCompactMode={isCompactMode}
        ></UnitCardRevenueDriver>
      );
    }
  }
}

class UnitMetricPerDay extends React.Component {
  render() {
    let { unit, day, flag = "" } = this.props;
    const weekday = moment(unit.date).format("ddd");

    return (
      <div>
        <div
          className={`mb-1 mt-2 text-center text-xs font-semibold ${
            day === 1
              ? "bg-gray-400 text-gray-800"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          <span
            data-tip
            data-for={`date-${unit.gam_unit_id}-${day}`}
            className={`${
              weekday !== "Sun" && weekday !== "Sat" ? "" : "underline"
            }`}
          >
            {moment(unit.date).format("YYYY-MM-DD ddd")}
          </span>
        </div>
        <div
          className={`flex justify-between text-right ${
            day === 1 ? "font-semibold" : ""
          }`}
        >
          <div className="flex flex-col justify-between text-right text-xs">
            <div className="leading-snug">Rev Ratio</div>
            <div>
              <small>Raw: </small>
              <NumberFormat
                value={unit.raw_eligible_rev_ratio * 100}
                postfix="%"
              ></NumberFormat>
            </div>

            <div className="flex items-center gap-1 font-light leading-none">
              <small>
                G:{" "}
                <NumberFormat
                  value={unit.google_rev_ratio * 100}
                  postfix="%"
                ></NumberFormat>
              </small>
              <span className="text-gray-500">·</span>
              <small>
                H:{" "}
                <NumberFormat
                  value={unit.holistic_rev_ratio * 100}
                  postfix="%"
                ></NumberFormat>
              </small>
            </div>
          </div>

          <div className={`flex flex-col justify-between text-right text-xs `}>
            <div className="leading-snug">Net Google</div>
            <div
              className={`${flag === NET_REV.GOOGLE && "bg-blue-200"}
                ${unit.net_google_inc_rev_ratio < 0 ? "text-red-600" : ""}
                `}
            >
              <NumberFormat
                value={unit.net_google_inc_rev_ratio * 100}
                postfix="%"
              ></NumberFormat>
            </div>
            <div
              className={`font-light leading-none ${
                unit.net_google_inc_rev_trans < 0 ? "text-red-600" : ""
              }`}
            >
              {/* <small className="text-gray-800">
                Cost:{" "}
                <NumberFormat
                  value={unit.extra_ad_serving_cost_trans}
                  format="$0,0"
                ></NumberFormat>
              </small>
              <span className="text-gray-500">·</span> */}
              <small
                data-tip
                data-for={`${unit.gam_unit_id}_${unit.date}_cost`}
              >
                <NumberFormat
                  value={unit.net_google_inc_rev_trans}
                  prefix="$"
                ></NumberFormat>
              </small>
              <ReactTooltip
                type="dark"
                effect="solid"
                place="top"
                id={`${unit.gam_unit_id}_${unit.date}_cost`}
              >
                Cost:{" "}
                <NumberFormat
                  value={unit.extra_ad_serving_cost_trans}
                  format="$0,0.00"
                ></NumberFormat>
              </ReactTooltip>
            </div>
          </div>

          <div className={`flex flex-col justify-between text-right text-xs`}>
            <div className="leading-snug">Net Holistic</div>
            <div
              className={`${flag === NET_REV.HOLISTIC && "bg-blue-200"} ${
                unit.net_holistic_inc_rev_ratio < 0 ? "text-red-600" : ""
              }`}
            >
              <NumberFormat
                value={unit.net_holistic_inc_rev_ratio * 100}
                postfix="%"
              ></NumberFormat>
            </div>
            <div
              className={`font-light leading-none ${
                unit.net_holistic_inc_rev_trans < 0 ? "text-red-600" : ""
              }`}
            >
              <small>
                <NumberFormat
                  value={unit.net_holistic_inc_rev_trans}
                  prefix="$"
                ></NumberFormat>
              </small>
            </div>
          </div>

          <div className="flex flex-col justify-between text-right text-xs">
            <div className="leading-snug">Google Rev</div>
            <NumberFormat
              value={unit.google_rev_trans}
              format="$0,0.00a"
            ></NumberFormat>

            <div className="text-xs font-light leading-none">
              <small>
                RPM:{" "}
                <NumberFormat
                  value={unit.google_rev_rpm}
                  format="$0,0.00"
                ></NumberFormat>
              </small>
            </div>
          </div>

          <div className="flex flex-col justify-between text-right text-xs">
            <div className="leading-snug">Holistic Rev</div>
            <div>
              <NumberFormat
                value={unit.holistic_rev_trans}
                format="$0,0.00a"
              ></NumberFormat>
            </div>

            <div className="flex items-center gap-1 font-light leading-none">
              <small>
                Req:{" "}
                <NumberFormat value={unit.req} format="0,0.00a"></NumberFormat>
              </small>
              <span className="text-gray-500">·</span>
              <small>
                RPM:{" "}
                <NumberFormat
                  value={unit.holistic_rev_rpm}
                  format="$0,0.00"
                ></NumberFormat>
              </small>
            </div>
          </div>

          <div className={`flex flex-col justify-between text-right text-xs`}>
            <div className="leading-snug">Net Billable</div>
            <div
              className={`${flag === NET_REV.BILLABLE && "bg-blue-200"} ${
                unit.net_billable_lift < 0 ? "text-red-600" : ""
              }`}
            >
              <NumberFormat
                value={unit.net_billable_lift * 100}
                postfix="%"
                format="0,0.00"
              ></NumberFormat>
            </div>
            <div className="font-light leading-none">
              <small>
                <NumberFormat
                  value={unit.net_billable_rev_trans}
                  format="$0,0.00"
                ></NumberFormat>
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Avg7DaySplotline extends React.PureComponent {
  constructor(props) {
    super(props);

    const { data, dates, roundDecimal } = this.props;
    let sparklineData = _.map(dates, (d, i) => {
      return {
        date: d,
        value: _.round(data[i] * 100, roundDecimal || 0) + "%",
      };
    });
    sparklineData = _.orderBy(sparklineData, "date", "desc");
    const avg = _.mean(data);

    this.state = {
      sparklineData,
      avg,
      hoveringMetric: null,
    };

    this.handleMouseEnterToPoint = this.handleMouseEnterToPoint.bind(this);
    this.handleMouseLeaveGraph = this.handleMouseLeaveGraph.bind(this);
  }

  handleMouseEnterToPoint(point, pointIndex) {
    // console.log(point, pointIndex);
    this.setState({
      hoveringMetric: this.state.sparklineData[pointIndex].value,
    });
  }

  handleMouseLeaveGraph() {
    this.setState({ hoveringMetric: null });
  }

  render() {
    const {
      title,
      subtitle,
      data,
      unitId,
      description,
      expandable = false,
    } = this.props;
    const { sparklineData, avg, hoveringMetric } = this.state;

    const tooltipId = `${unitId}_${title}_detail_tooltip`;

    let dataTable = `<table>${sparklineData
      .map((d) => {
        return `<tr>
          <td>${moment(d.date).format("YYYY-MM-DD ddd")}</td>
          <td style='text-align: right; padding-left: 4px;'>${d.value}</td>
        </tr>`;
      })
      .join("")}</table>`;

    return (
      <div className="mr-1 inline-block">
        <div
          className="rounded bg-indigo-100 px-2 py-1 text-xs"
          data-tip
          data-for={tooltipId}
          onMouseEnter={() =>
            this.props.handleMouseEnter && this.props.handleMouseEnter()
          }
          onMouseLeave={() =>
            this.props.handleMouseLeave && this.props.handleMouseLeave()
          }
        >
          <div className="flex gap-2">
            <small>
              {subtitle} <span className="font-semibold">{title}</span>
            </small>
          </div>
          <div className="flex items-center gap-2">
            <div className="font-semibold">
              {hoveringMetric ? (
                <NumberFormat
                  value={hoveringMetric}
                  format="0,0%"
                ></NumberFormat>
              ) : (
                <NumberFormat value={avg} format="0,0%"></NumberFormat>
              )}
            </div>
            <UnitCardMiniSparkline
              data={data}
              width={title.includes("Net") ? 100 : undefined}
            ></UnitCardMiniSparkline>
            {expandable && (
              <div
                className="ml-auto cursor-pointer text-gray-600 hover:text-blue-600"
                onClick={() =>
                  this.props.handleExpand && this.props.handleExpand(unitId)
                }
              >
                <FaSearch></FaSearch>
              </div>
            )}
          </div>
        </div>
        <ReactTooltip
          html={true}
          id={tooltipId}
          type="dark"
          effect="solid"
          place="top"
          getContent={() => {
            return `
                <div>
                  ${subtitle || ""} ${title} (Avg: ${_.round(avg * 100, 0)}%)
                </div>
                ${
                  description
                    ? `<div className="text-xs">(${description})</div>`
                    : ""
                }
                ${dataTable}
              `;
          }}
        ></ReactTooltip>
      </div>
    );
  }
}

class RequestTypesSection extends React.PureComponent {
  render() {
    const { requestTypes } = this.props;
    return (
      <div className="my-2 flex">
        {requestTypes.split(",").map((d) => {
          if (d === "") return "";
          return (
            <div
              key={d}
              className="rounded mr-2 bg-indigo-100 px-2 text-xs text-gray-600"
              title={d}
            >
              {d === "Google Publisher Tag" ? "GPT" : d}
            </div>
          );
        })}
      </div>
    );
  }
}

class InspectionSection extends React.PureComponent {
  render() {
    const { forceInspection } = this.props;
    if (forceInspection) {
      return (
        <div className="border-l px-1 text-xs" title="Force Inspection">
          {forceInspection && (
            <span className="rounded bg-orange-400 px-2 font-bold text-orange-100">
              INSP
            </span>
          )}
        </div>
      );
    } else {
      return "";
    }
  }
}

class BmSection extends React.PureComponent {
  render() {
    const { unitId, bpm, enableAbuse, bmAbuseSizes } = this.props;
    /*
    bmAbuseSizes=[
      [
        "fluid",
        [
          1,
          1
        ]
      ]
    ]
    */
    let abuseSizesString = "None";
    if (bmAbuseSizes) {
      let sizes = bmAbuseSizes[0];
      abuseSizesString = sizes
        .map((s) => {
          if (typeof s === "string") {
            return s;
          } else {
            return s.join("x");
          }
        })
        .join(", ");
    }

    return (
      <div
        className="border-l px-1 text-xs"
        title="Benchmark Performance Multiplier"
      >
        BPM:{" "}
        {bpm < 1 ? (
          <>
            <span
              className="font-bold text-orange-600"
              data-tip
              data-for={`bpm-label-${unitId}`}
            >
              <NumberFormat value={bpm} format="0,0%"></NumberFormat>
            </span>
            <ReactTooltip
              id={`bpm-label-${unitId}`}
              type="dark"
              effect="solid"
              place="top"
            >
              Enable benchmark tfcd abuse:{" "}
              <b>{enableAbuse ? "true" : "false"}</b>
              <br></br>
              Benchmark Abuse Sizes: <b>{abuseSizesString}</b>
            </ReactTooltip>
          </>
        ) : (
          <span className="text-gray-600">
            <NumberFormat value={bpm} format="0,0%"></NumberFormat>
          </span>
        )}
      </div>
    );
  }
}

class SegmentResearchSection extends React.PureComponent {
  render() {
    const { hasSegmentConfig } = this.props;
    return hasSegmentConfig ? (
      <div className="px-1 text-xs" title="Segment Research">
        <span className="rounded bg-purple-500 px-2 font-bold text-pink-100">
          SR
        </span>
      </div>
    ) : (
      ""
    );
  }
}

class CustomSegSection extends React.PureComponent {
  render() {
    const { enabled } = this.props;
    return enabled ? (
      <div className="px-1 text-xs">
        <span className="rounded bg-yellow-600 px-2 font-bold text-yellow-100">
          CUSTOM SEG
        </span>
      </div>
    ) : (
      ""
    );
  }
}

class DebugModeSection extends React.PureComponent {
  render() {
    const { enableDispatcherDebug } = this.props;
    return enableDispatcherDebug ? (
      <div className="px-1 text-xs">
        <span className="rounded bg-pink-500 px-2 font-bold text-pink-100">
          DEBUG
        </span>
      </div>
    ) : (
      ""
    );
  }
}

class MaxTrafficRatioSection extends React.PureComponent {
  render() {
    const { maxTrafficRatio, minTrafficRatio } = this.props;
    return (
      <div className="border-l px-1 text-xs">
        Traffic:{" "}
        <span className="text-gray-600">
          <NumberFormat value={minTrafficRatio} format="0,0%"></NumberFormat>
        </span>
        <span className="px-1">-</span>
        <span className="text-gray-600">
          <NumberFormat value={maxTrafficRatio} format="0,0%"></NumberFormat>
        </span>
      </div>
    );
  }
}

class AdPodsSection extends React.PureComponent {
  render() {
    const { enableAdPods } = this.props;

    return (
      <>
        {enableAdPods ? (
          <div className="border-l px-1 text-xs">
            <span className="font-bold text-purple-600">ADPODS</span>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

class UnitChangeTimeSection extends React.PureComponent {
  render() {
    const { unit, type } = this.props;

    return (
      <>
        <div
          data-tip
          data-for={`unit-changed-at-${unit.gam_unit_id}`}
          className="whitespace-no-wrap border-l px-1 text-xs text-gray-700"
        >
          {type === "NEWLY_ONBOARDED_UNITS" ? (
            <>
              Onboarded{" "}
              <span className="text-gray-900">
                <DateTimeFormatter
                  fromNowOnly={true}
                  datetime={unit.created_at}
                ></DateTimeFormatter>
              </span>
            </>
          ) : (
            <>
              Changes made{" "}
              <span className="text-gray-900">
                <DateTimeFormatter
                  fromNowOnly={true}
                  datetime={unit.last_changed_at}
                ></DateTimeFormatter>
              </span>
            </>
          )}
        </div>

        <ReactTooltip
          id={`unit-changed-at-${unit.gam_unit_id}`}
          type="dark"
          effect="solid"
          place="top"
        >
          <table>
            <tbody>
              <tr>
                <td className="pr-2 text-right">Created At</td>
                <td>
                  <b>
                    <DateTimeFormatter
                      format="YYYY-MM-DD HH:mm"
                      datetime={unit.created_at}
                    ></DateTimeFormatter>
                  </b>
                </td>
              </tr>

              <tr>
                <td className="pr-2 text-right">Updated At</td>
                <td>
                  <b>
                    <DateTimeFormatter
                      format="YYYY-MM-DD HH:mm"
                      datetime={unit.updated_at}
                    ></DateTimeFormatter>
                  </b>
                </td>
              </tr>

              <tr>
                <td className="pr-2 text-right">
                  Auto Pilot Config Updated At
                </td>
                <td>
                  <b>
                    <DateTimeFormatter
                      format="YYYY-MM-DD HH:mm"
                      datetime={unit.config_updated_at}
                    ></DateTimeFormatter>
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </ReactTooltip>
      </>
    );
  }
}

class UnitTagsSection extends React.PureComponent {
  render() {
    const { tags } = this.props;
    const inventoryTags = _.remove(_.cloneDeep(tags), (t) => {
      return _.startsWith(t, "IVF:");
    });

    return (
      <div className="border-t border-b">
        {inventoryTags && !_.isEmpty(inventoryTags) ? (
          <div className="whitespace-no-wrap my-1 flex w-full overflow-x-auto">
            {inventoryTags.map((t) => {
              return (
                <div
                  key={t}
                  className="rounded mr-2 bg-orange-200 px-2 text-gray-600"
                  style={{ fontSize: "12px" }}
                >
                  {t}
                </div>
              );
            })}
          </div>
        ) : (
          <div style={{ height: "24px" }}> </div>
        )}
        <div className="border-t">
          <div className="whitespace-no-wrap my-1 flex w-full overflow-x-auto">
            {tags &&
              tags.map((t) => {
                return (
                  <div
                    key={t}
                    className="rounded mr-2 bg-orange-200 px-2 text-gray-600"
                    style={{ fontSize: "12px" }}
                  >
                    {t}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

class DemandTypesSection extends React.PureComponent {
  render() {
    const { demandTypes } = this.props;

    return (
      <>
        {demandTypes.map((d) => {
          return (
            <div
              key={d}
              className="rounded mr-2 bg-gray-200 px-2 text-xs text-gray-600"
            >
              {d}
            </div>
          );
        })}
      </>
    );
  }
}

class UnitCardRevenueDriver extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hoverFlag: "",
      isOpenMonthDataModal: false,
      dataType: "",
      data: null,
    };
  }

  render() {
    const {
      unit,
      type,
      demandTypes,
      networkName,
      networkId,
      handleGoToObserver,
      handleGoToCstAnatomy,
      handleGoToUnitInsight,
      handleGoToUnitAPConfigPage,
      showFullWidthCard,
      isCompactMode,
    } = this.props;

    const { hoverFlag, isOpenMonthDataModal } = this.state;

    const unitD1 = unit.last_day_metrics;
    const unitD2 = unit.last_2_day_metrics;
    const unitD3 = unit.last_3_day_metrics;

    const unitMetricDaysData = isCompactMode
      ? [unit, unitD1]
      : [unit, unitD1, unitD2, unitD3];

    return (
      <div>
        <div
          key={unit.id}
          className="border border-gray-300 bg-white p-2 text-sm text-gray-800"
          style={{ width: showFullWidthCard ? "100%" : "36rem" }}
          // style={{ width: "22rem" }}
        >
          <div className="-ml-2 -mr-2 -mt-2 flex items-baseline justify-between bg-gray-100 px-2 py-1 text-gray-700">
            <div className="flex w-3/5 items-center overflow-x-auto">
              <div className="mr-1 font-semibold text-gray-800">
                {unit.gam_unit_id}
              </div>
              <div className="truncate mr-1 text-gray-800" title={unit.name}>
                {unit.name}
              </div>
              {unit.deleted_at && (
                <>
                  <span
                    className="rounded mx-1 bg-red-300 px-1 text-xs font-bold uppercase text-red-800"
                    data-tip
                    data-for={`${unit.gam_unit_id}-deleted-at`}
                  >
                    Deleted
                  </span>
                  <ReactTooltip
                    type="dark"
                    effect="solid"
                    place="top"
                    id={`${unit.gam_unit_id}-deleted-at`}
                  >
                    Deleted at:{" "}
                    <DateTimeFormatter
                      datetime={unit.deleted_at}
                    ></DateTimeFormatter>
                  </ReactTooltip>
                </>
              )}
            </div>

            <div className="flex w-2/5 items-center justify-end text-sm leading-none">
              {/* Dummy span to make sure parent flexbox is aligned at baseline */}
              <span className="text-gray-100">.</span>

              <div className="pl-1">
                <SegmentResearchSection
                  hasSegmentConfig={unit.has_segment_config}
                ></SegmentResearchSection>
              </div>
              <div>
                <CustomSegSection
                  enabled={unit.autopilot_brief.custom_segmentation}
                ></CustomSegSection>
              </div>
              <div>
                <DebugModeSection
                  enableDispatcherDebug={
                    unit.autopilot_brief.enable_dispatcher_debugging
                  }
                ></DebugModeSection>
              </div>
              <div className="px-1">
                <UnitStatus unit={unit}></UnitStatus>
              </div>
              <div className="px-1">
                <UnitMode unit={unit}></UnitMode>
              </div>
              {/* <div className="pl-1">
                <UnitUpdateMode unit={unit}></UnitUpdateMode>
              </div> */}
            </div>
          </div>

          <div className="-ml-2 -mr-2 -mt-2 bg-gray-100 px-2 py-2 text-gray-700">
            <UnitMetadata unit={unit}></UnitMetadata>
          </div>

          <div className="border-b whitespace-no-wrap flex items-center justify-between">
            <div
              className="flex w-full overflow-x-auto"
              style={{ minWidth: "48px" }}
            >
              <RequestTypesSection
                requestTypes={unit.request_types}
              ></RequestTypesSection>
            </div>

            <div className="flex">
              <div>
                <div className="border-l px-1 text-xs text-gray-600">
                  <span className="text-gray-800">YS: </span>
                  <span title="YieldSet ID">{unit.ys_id} </span>
                  <span title="YieldSet Name">{unit.ys_name}</span>
                </div>
              </div>
              <div>
                <AdPodsSection
                  enableAdPods={unit.autopilot_brief.enable_ad_pods}
                ></AdPodsSection>
              </div>
              <div>
                <InspectionSection
                  unitId={unit.gam_unit_id}
                  forceInspection={unit.autopilot_brief.force_inspection}
                ></InspectionSection>
              </div>
              <div>
                <BmSection
                  unitId={unit.gam_unit_id}
                  bpm={unit.autopilot_brief.benchmark_performance_multiplier}
                  enableAbuse={unit.autopilot_brief.enable_benchmark_tfcd_abuse}
                  bmAbuseSizes={unit.autopilot_brief.benchmark_abuse_sizes}
                ></BmSection>
              </div>
              <div>
                <MaxTrafficRatioSection
                  minTrafficRatio={unit.autopilot_brief.minimum_traffic_ratio}
                  maxTrafficRatio={unit.autopilot_brief.maximum_traffic_ratio}
                ></MaxTrafficRatioSection>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="whitespace-no-wrap mb-1 mt-2 flex w-full overflow-x-auto">
              <DemandTypesSection
                demandTypes={demandTypes}
              ></DemandTypesSection>
            </div>
            <div className="mb-1 mt-2 flex">
              <UnitChangeTimeSection
                unit={unit}
                type={type}
              ></UnitChangeTimeSection>
            </div>
          </div>

          <UnitTagsSection tags={unit.tags}></UnitTagsSection>

          <div className="whitespace-no-wrap border-b overflow-x-auto py-1">
            <Avg7DaySplotline
              unitId={unit.gam_unit_id}
              subtitle="Req"
              title="PScore"
              description="Intowow optimized (true & false) / Total compatible"
              data={unit.pscore_trend}
              dates={unit.trend_dates}
            ></Avg7DaySplotline>
            <Avg7DaySplotline
              unitId={unit.gam_unit_id}
              subtitle="Req"
              title="DScore"
              description="Intowow optimized (true) / Intowow optimized (true & false)"
              data={unit.dscore_trend}
              dates={unit.trend_dates}
            ></Avg7DaySplotline>
            <Avg7DaySplotline
              unitId={unit.gam_unit_id}
              subtitle="Req"
              title="UPR Key"
              description="Intowow upr key20 / Intowow optimized (true & false)"
              data={unit.uprkey_trend}
              dates={unit.trend_dates}
            ></Avg7DaySplotline>
            <Avg7DaySplotline
              unitId={unit.gam_unit_id}
              subtitle="H Rev"
              title="MScore Algo"
              description="Intowow algo managed / Intowow upr key20 (optimized)"
              data={unit.algomscore_trend}
              dates={unit.trend_dates}
            ></Avg7DaySplotline>
            <Avg7DaySplotline
              unitId={unit.gam_unit_id}
              subtitle=""
              title="IVT Ratio"
              data={unit.ivt_trend}
              dates={unit.trend_dates}
            ></Avg7DaySplotline>
            <Avg7DaySplotline
              unitId={unit.gam_unit_id}
              subtitle=""
              title="Cost Ratio"
              data={unit.cost_trend}
              dates={unit.trend_dates}
            ></Avg7DaySplotline>
          </div>

          <div className="whitespace-no-wrap overflow-x-auto pt-1">
            <Avg7DaySplotline
              unitId={unit.gam_unit_id}
              // subtitle="Net"
              title={netRevTitle.get(NET_REV.GOOGLE)}
              // description="Intowow optimized (true & false) / Total compatible"
              data={unit.g_inc_rev_ratio_trend}
              dates={unit.trend_dates}
              handleMouseEnter={() =>
                this.setState({ hoverFlag: NET_REV.GOOGLE })
              }
              handleMouseLeave={() => this.setState({ hoverFlag: "" })}
              roundDecimal={2}
              expandable={true}
              handleExpand={() => {
                this.setState({
                  isOpenMonthDataModal: true,
                  dataType: NET_REV.GOOGLE,
                });
              }}
            ></Avg7DaySplotline>
            <Avg7DaySplotline
              unitId={unit.gam_unit_id}
              title={netRevTitle.get(NET_REV.HOLISTIC)}
              data={unit.h_inc_rev_ratio_trend}
              dates={unit.trend_dates}
              handleMouseEnter={() =>
                this.setState({ hoverFlag: NET_REV.HOLISTIC })
              }
              handleMouseLeave={() => this.setState({ hoverFlag: "" })}
              roundDecimal={2}
              expandable={true}
              handleExpand={() => {
                this.setState({
                  isOpenMonthDataModal: true,
                  dataType: NET_REV.HOLISTIC,
                });
              }}
            ></Avg7DaySplotline>
            <Avg7DaySplotline
              unitId={unit.gam_unit_id}
              title={netRevTitle.get(NET_REV.BILLABLE)}
              data={unit.b_rev_lift_trend}
              dates={unit.trend_dates}
              handleMouseEnter={() =>
                this.setState({ hoverFlag: NET_REV.BILLABLE })
              }
              handleMouseLeave={() => this.setState({ hoverFlag: "" })}
              roundDecimal={2}
              expandable={true}
              handleExpand={() => {
                this.setState({
                  isOpenMonthDataModal: true,
                  dataType: NET_REV.BILLABLE,
                });
              }}
            ></Avg7DaySplotline>
          </div>

          {unitMetricDaysData.map((u, i) => {
            let isMissingCurrentDay = !_.last(unitMetricDaysData);

            return (
              <React.Fragment key={i}>
                {u && (
                  <UnitMetricPerDay
                    flag={hoverFlag}
                    unit={u}
                    day={isMissingCurrentDay ? i + 1 : i}
                  ></UnitMetricPerDay>
                )}
              </React.Fragment>
            );
          })}

          <div className="border-t mt-4 flex justify-between pt-2">
            <div className="flex gap-8">
              <button
                type="button"
                className="border rounded hover:shadow mr-2 flex items-center border-blue-300 px-1 text-xs text-blue-500"
                onClick={() => handleGoToObserver(unit.gam_unit_id)}
              >
                Anatomy
                <span className="ml-1">
                  <FiExternalLink></FiExternalLink>
                </span>
              </button>

              <button
                type="button"
                className="border rounded hover:shadow mr-2 flex items-center border-blue-300 px-1 text-xs text-blue-500"
                onClick={() => handleGoToUnitInsight(unit.gam_unit_id)}
              >
                Insight
                <span className="ml-1">
                  <FiExternalLink></FiExternalLink>
                </span>
              </button>
            </div>

            <button
              type="button"
              className="border rounded hover:shadow mr-2 flex items-center border-blue-300 px-1 text-xs text-blue-500"
              onClick={() => handleGoToCstAnatomy(unit.gam_unit_id)}
            >
              CST Anatomy
              <span className="ml-1">
                <FiExternalLink></FiExternalLink>
              </span>
            </button>

            <AutoPilotConfigEditButton
              buttonText={"Edit Auto Pilot Config"}
              goToConfigControlPage={() =>
                handleGoToUnitAPConfigPage(unit.gam_unit_id)
              }
            ></AutoPilotConfigEditButton>
          </div>
        </div>

        {isOpenMonthDataModal && (
          <UnitRevenueLiftTrendModal
            networkName={networkName}
            networkId={networkId}
            extUnitId={unit.ext_gam_unit_id}
            unitName={unit.name}
            unitId={unit.gam_unit_id}
            dataType={this.state.dataType}
            handleClose={() => this.setState({ isOpenMonthDataModal: false })}
          ></UnitRevenueLiftTrendModal>
        )}
      </div>
    );
  }
}

class UnitCardNotOnboarded extends React.PureComponent {
  render() {
    const { unit, demandTypes, showFullWidthCard } = this.props;

    return (
      <>
        <div
          key={unit.ext_gam_unit_id}
          className="border border-gray-300 bg-white p-2 text-sm text-gray-800"
          style={{ width: showFullWidthCard ? "100%" : "20rem" }}
        >
          <div className="-ml-2 -mr-2 -mt-2 bg-gray-100 px-2 py-1 text-gray-700">
            <div className="mr-1 text-gray-600">
              Ext Gam Unit ID: {unit.ext_gam_unit_id}
            </div>
          </div>

          <div className="border-b whitespace-no-wrap flex items-center justify-between">
            <div className="flex w-full overflow-x-auto">
              <RequestTypesSection
                requestTypes={unit.request_types}
              ></RequestTypesSection>
            </div>
          </div>

          <div className="border-b flex w-full justify-between overflow-x-auto">
            {demandTypes && demandTypes.length > 0 ? (
              <div className="whitespace-no-wrap my-2 flex">
                <DemandTypesSection
                  demandTypes={demandTypes}
                ></DemandTypesSection>
              </div>
            ) : (
              <div className="mb-1 h-8"></div>
            )}
          </div>

          <div className="mt-2 flex justify-between text-right">
            <div>
              <div className="text-xs leading-snug">Raw Rev Ratio</div>
              <div className="font-bold">
                <NumberFormat
                  value={unit.raw_eligible_rev_ratio * 100}
                  postfix="%"
                ></NumberFormat>
              </div>
            </div>

            <div>
              <div className="text-xs leading-snug">Client Req</div>
              <div>
                <NumberFormat
                  value={unit.client_request}
                  format="0,0.00a"
                ></NumberFormat>
              </div>
            </div>

            <div>
              <div className="flex flex-col justify-between text-right text-sm">
                <div className="text-xs leading-snug">Raw Eligible Rev</div>
                <div
                  className={`font-semibold ${
                    unit.raw_eligible_rev_trans < 0 ? "text-red-600" : ""
                  }`}
                >
                  <NumberFormat
                    value={unit.raw_eligible_rev_trans}
                    prefix="$"
                  ></NumberFormat>
                </div>
              </div>

              <div className="mt-2 flex flex-col justify-between text-right text-sm">
                <div className="text-xs leading-snug">Raw Eligible Imp</div>

                <div
                  className={unit.raw_eligible_imp < 0 ? "text-red-600" : ""}
                >
                  <NumberFormat
                    value={unit.raw_eligible_imp}
                    format="0,0"
                  ></NumberFormat>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class UnitMetadata extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { unit } = this.props;

    return (
      <>
        <div className="mt-1 flex justify-between text-xs text-gray-600">
          <div className="flex font-mono" title="Ext Unit ID">
            {unit.ext_gam_unit_id}
            <ClickToCopyWrapper
              copyText={unit.ext_gam_unit_id}
            ></ClickToCopyWrapper>
          </div>

          <div
            className="whitespace-no-wrap w-full overflow-x-auto text-right"
            title="Ext Unit Path"
          >
            {unit.ext_unit_path}
          </div>
        </div>
      </>
    );
  }
}

export default AdUnitBasicCard;

import React from "react";
import Select from "react-select";
import ModalFooter from "components/common/ModalFooter";

import { OnboardingAPI } from "apis";
import { MESSAGE } from "constants/Message";
import { inputClass } from "helpers/StyleClass.js";
import _ from "lodash";

class AdxAccounContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: null,
      name: "",
      webPropertyCode: null,
      webPropertyCodeList: [],
      result: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    const { networkId } = this.props;
    try {
      const result = await OnboardingAPI.getWebPropertyCodeOptions({
        networkId,
      });

      if (result) {
        this.setState({ webPropertyCodeList: result });
      }
    } catch (error) {
      this.setState({
        msg: ["Failed to fetch web property code options", MESSAGE.ERROR],
      });
    }
  }

  handleInputChange(e) {
    this.setState({ name: e.target.value });
  }

  handleSelectChange(select) {
    this.setState({ webPropertyCode: select.value });
  }

  async handleConfirm() {
    const { networkId } = this.props;
    const { webPropertyCode, name } = this.state;

    this.props.onLoading(true);

    const param = {
      networkId,
      name,
      code: webPropertyCode,
    };

    try {
      const result = await OnboardingAPI.createAdxAccount(param);
      if (result) {
        this.setState({ msg: ["Create Success", MESSAGE.SUCCESS], result });
      }
    } catch (error) {
      this.setState({ msg: [error, MESSAGE.ERROR] });
    }

    this.props.onLoading(false);
  }

  handleClose() {
    const { result } = this.state;
    if (result) {
      this.props.onClose(result);
    }
  }

  render() {
    const { msg, webPropertyCodeList, name, webPropertyCode } = this.state;

    const options = webPropertyCodeList.map((code) => ({
      value: code,
      label: code,
    }));

    return (
      <>
        <div>
          <label>Name</label>
          <input
            type="text"
            placeholder="Adx Account Name"
            className={inputClass}
            onChange={this.handleInputChange}
          ></input>
        </div>

        <div className="">
          <label>Web Property Code</label>
          <Select
            options={options}
            isSearchable={true}
            onChange={this.handleSelectChange}
          />
        </div>

        <div className="mt-auto">
          <ModalFooter
            msg={msg}
            confirmText="Create Adx Account"
            onClose={this.handleClose}
            onCancel={this.props.onCancel}
            onConfirm={this.handleConfirm}
            disableConfirm={name === "" || !webPropertyCode}
          ></ModalFooter>
        </div>
      </>
    );
  }
}

export default AdxAccounContent;

import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { NetworkAPI } from "apis";
import LoadingUI from "components/common/LoadingUI";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import FirstLookRulesTable from "./FirstLookRulesTable";
import { PRICE_TYPE_BY_NAME } from "constants/PriceType";

const buttonClass =
  "px-2 py-1 bg-gray-100 text-xs rounded border border-gray-400 hover:bg-gray-200 text-gray-900 font-semibold";

function _isSectionEmpty(section) {
  if (!section || _.isEmpty(section)) {
    return true;
  } else if (_.has(section, "includes") && _.has(section, "excludes")) {
    return _.isEmpty(section.includes) && _.isEmpty(section.excludes);
  } else if (_.has(section, "includes") && _.isEmpty(section.includes)) {
    return true;
  } else if (_.has(section, "excludes") && _.isEmpty(section.excludes)) {
    return true;
  } else {
    return false;
  }
}

const TYPE_FILTER = {
  ALL: "ALL",
  DISPLAY: "DISPLAY",
  MOBILE: "MOBILE",
};

class FirstLookRulesViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: "",

      rules: null, // show in json
      transformedRules: null, // show in table
      filteredRules: null,
      updatedAt: null,

      isJsonView: false,
      typeCounts: null,
      typeFilter: TYPE_FILTER.ALL,
    };

    this.toggleJsonView = this.toggleJsonView.bind(this);
    this.handleForceRefresh = this.handleForceRefresh.bind(this);
    this.handleTypeFilterChanged = this.handleTypeFilterChanged.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { networkId } = this.props;
    if (!networkId) {
      this.setState({ errMsg: "Missing Network ID" });
      return;
    } else {
      document.title = `${networkId} First Look Rules | YB Observer`;
      this.setState({ networkId });
    }

    try {
      let { rules, transformedRules, updatedAt } = await this._getRules({
        isForceRefresh: false,
      });

      let typeCounts = _.countBy(transformedRules, "type");
      typeCounts.ALL = transformedRules.length;

      this.setState({
        rules,
        transformedRules,
        updatedAt,
        typeCounts,

        isLoading: false,
      });

      setTimeout(() => {
        this.handleTypeFilterChanged(this.state.typeFilter);
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  async _getRules({ isForceRefresh = false }) {
    const { networkId } = this.props;
    const { rules, updatedAt } = await NetworkAPI.getFirstLookRules({
      networkId,
      isForceRefresh,
    });

    return {
      rules,
      transformedRules: this._transformRules(rules),
      updatedAt,
    };
  }

  _transformRules(rules) {
    const transformedRules = _.map(rules, (rule) => {
      let priceSection = {};
      const setForEverythingSetting = _.find(rule.priceSection.priceSettings, {
        isSetForEverything: true,
      });
      if (setForEverythingSetting) {
        priceSection.priceType = setForEverythingSetting.priceType;
        priceSection.price = setForEverythingSetting.price;
        priceSection.currency = setForEverythingSetting.currency;
        priceSection.branded = setForEverythingSetting.branded;
      } else {
        priceSection.priceType = PRICE_TYPE_BY_NAME.BRAND_SETTING;
        priceSection.currency = _.get(rule.priceSection.priceSettings, [
          0,
          "currency",
        ]);
        priceSection.brandedPriceSettings = rule.priceSection.priceSettings;
        priceSection.branded = rule.priceSection.branded;
      }

      let targetingSection = {};
      if (rule.targetingSection.inventory) {
        if (!_isSectionEmpty(rule.targetingSection.inventory.unitIds)) {
          _.set(
            targetingSection,
            ["inventory", "units"], // units not unitIds!
            rule.targetingSection.inventory.unitIds
          );
        }
        if (!_isSectionEmpty(rule.targetingSection.inventory.placementIds)) {
          _.set(
            targetingSection,
            ["inventory", "placementIds"],
            rule.targetingSection.inventory.placementIds
          );
        }
        if (!_isSectionEmpty(rule.targetingSection.inventory.mcm)) {
          _.set(
            targetingSection,
            ["inventory", "mcm"],
            rule.targetingSection.inventory.mcm
          );
        }
      }

      const targetingKeys = [
        "customs",
        "deviceCategory",
        "os",
        "country",
        "browser",
        "inventoryTypes",
        "videoPositions",
        "inventoryFormat",
        "inventoryUrl",
        "inventorySize",
        "mobileApplication",
      ];
      _.forEach(targetingKeys, (tk) => {
        if (!_isSectionEmpty(rule.targetingSection[tk])) {
          targetingSection[tk] = rule.targetingSection[tk];
        }
      });

      return {
        ...rule,
        priceSection,
        targetingSection,
        // props: _.get(resourceById, [rule.externalId, "props"], null),
      };
    });

    return transformedRules;
  }

  async handleForceRefresh() {
    this.setState({
      isLoading: true,
      updatedAt: null,
      rules: null,
      transformedRules: null,
    });
    try {
      const { rules, transformedRules, updatedAt } = await this._getRules({
        isForceRefresh: true,
      });

      this.setState({
        rules,
        transformedRules,
        updatedAt,

        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  toggleJsonView() {
    this.setState({ isJsonView: !this.state.isJsonView });
  }

  handleTypeFilterChanged(newFilter) {
    const { transformedRules } = this.state;
    let filteredRules = [];
    if (newFilter === TYPE_FILTER.ALL) {
      filteredRules = transformedRules;
    } else {
      filteredRules = _.filter(transformedRules, { type: newFilter });
    }

    this.setState({
      filteredRules,
      typeFilter: newFilter,
    });
  }

  render() {
    const { networkId, networkInfo, units, virtualPlacements } = this.props;
    const {
      isLoading,
      errMsg,

      updatedAt,
      rules,
      transformedRules,
      filteredRules,

      isJsonView,
      typeFilter,
      typeCounts,
    } = this.state;

    const end = moment();
    const start = moment(updatedAt);
    const duration = moment.duration(end.diff(start));
    const updatedAgo = duration.asHours();

    return (
      <div>
        <div>
          <PageTitle title="First Look Rules"></PageTitle>

          {isLoading && <LoadingUI></LoadingUI>}
          {errMsg && <div className="text-red-800">{errMsg}</div>}

          {rules && (
            <div>
              <div className="mb-2 flex items-center justify-end gap-2">
                <div className="text-sm">
                  Updated at:{" "}
                  <DateTimeFormatter datetime={updatedAt}></DateTimeFormatter>
                  {updatedAgo > 1 && (
                    <span className="pl-2 font-semibold text-red-600">
                      (Rules could be outdated)
                    </span>
                  )}
                </div>

                <button className={buttonClass} onClick={this.toggleJsonView}>
                  {!isJsonView ? "View original JSON" : "View in table"}
                </button>

                <button
                  className={buttonClass}
                  onClick={this.handleForceRefresh}
                >
                  Force Refresh
                </button>
              </div>

              <div className="mt-2 flex flex-wrap items-center text-sm">
                <div className="mr-1 font-semibold text-gray-700">
                  Type Filter:{" "}
                </div>
                {_.values(TYPE_FILTER).map((type) => {
                  const isSelected = typeFilter === type;
                  return (
                    <button
                      key={type}
                      type="button"
                      className={`border rounded mb-1 mr-1 border-gray-200 ${
                        isSelected ? "bg-blue-200" : "bg-white"
                      }  px-2 py-1 text-gray-800 hover:border-blue-400`}
                      onClick={() => {
                        this.handleTypeFilterChanged(type);
                      }}
                    >
                      {type} ({typeCounts[type]})
                    </button>
                  );
                })}
              </div>

              <div className="flex w-full">
                <div className="w-full">
                  <div className="mb-1 mt-2 flex items-center justify-between">
                    <div className="text-lg font-semibold">
                      First Look Rules (
                      {filteredRules ? filteredRules.length : 0}):{" "}
                    </div>
                  </div>

                  {updatedAt && (
                    <>
                      {isJsonView ? (
                        <textarea
                          style={{ width: "100%", height: "600px" }}
                          defaultValue={JSON.stringify(rules, null, 4)}
                          readOnly
                        ></textarea>
                      ) : (
                        <div style={{ marginBottom: "600px" }}>
                          {networkInfo && filteredRules && (
                            <FirstLookRulesTable
                              items={filteredRules}
                              networkInfo={networkInfo}
                              networkId={networkId}
                              units={units}
                              virtualPlacements={virtualPlacements}
                            ></FirstLookRulesTable>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

export default FirstLookRulesViewer;

import React from "react";
import _ from "lodash";
import GoToPageSelector from "components/common/GoToPageSelector";

const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900 font-semibold";

class PublishersTable extends React.PureComponent {
  getActionPaths(publisher) {
    const { publisherId } = publisher;
    const isBillingDisabled =
      publisher.type === "RESELLER_PUBLISHER" ||
      (publisher.accountType !== "ACTIVE" &&
        publisher.accountType !== "RESELLER");

    return [
      {
        name: "Publisher Features",
        path: `/ops-mgmt/account/publisher/${publisherId}/features`,
      },
      {
        name: "Billing Setting",
        path: `/ops-mgmt/account/publisher/${publisherId}/billing-setting`,
        isDisabled: isBillingDisabled,
      },
      {
        name: "Pricing Config",
        path: `/ops-mgmt/account/publisher/${publisherId}/pricing-config`,
        isDisabled: isBillingDisabled,
      },
      {
        name: "App Configuration",
        path: `/ops-mgmt/account/publisher/${publisherId}/ac-raw-config`,
      },
      {
        name: "Onboard GAM account",
        path: `/ops-mgmt/account/publisher/${publisherId}/gam-accounts`,
      },
    ];
  }

  render() {
    const { items, hideAction, handleTrialModal, handleCurrencyModal } =
      this.props;
    const thClass =
      "px-4 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
    const tdClass = "px-4 py-2";

    return (
      <>
        <table className="shadow divide-y min-w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className={thClass} style={{ width: "30%" }}>
                Publisher
              </th>
              <th className={thClass}>Reporting Currency</th>
              <th className={thClass}>Account Type</th>
              <th className={thClass}>Trial Status</th>

              {!hideAction && <th className={thClass}>Action</th>}
            </tr>
          </thead>
          <tbody className="divide-y bg-white text-sm">
            {items.map((item) => {
              return (
                <tr key={item.publisherId} className="hover:bg-gray-100">
                  <td className={tdClass}>
                    <span className="text-lg font-semibold text-gray-800">
                      {item.publisherId} - {item.name}
                    </span>
                    <div className="text-xs text-gray-600">{item.type}</div>
                  </td>

                  <td className={tdClass}>
                    <div className="font-semibold text-gray-700">
                      {item.reportingCurrency}
                    </div>
                  </td>

                  <td className={tdClass}>
                    <div className="font-semibold text-gray-700">
                      {item.accountType === "DUMMY" ? "-" : item.accountType}
                    </div>
                  </td>

                  <td className={tdClass}>
                    {item.accountStatus && item.accountStatus.trialStatus ? (
                      <div>
                        <div className="text-left">
                          <span className="text-gray-700">
                            Trial Expire Date:{" "}
                          </span>
                          <span>
                            {item.accountStatus.trialStatus.expDate
                              ? item.accountStatus.trialStatus.expDate.substring(
                                  0,
                                  10
                                )
                              : "-"}
                          </span>
                        </div>
                        <div className="text-left">
                          <span className="text-gray-700">
                            Billable Start Date:{" "}
                          </span>
                          <span>
                            {item.accountStatus.trialStatus.billableStartDate
                              ? item.accountStatus.trialStatus.billableStartDate.substring(
                                  0,
                                  10
                                )
                              : "-"}
                          </span>
                        </div>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>

                  {!hideAction && (
                    <td className={tdClass + " text-right"}>
                      <div className="flex justify-end gap-2">
                        {item.accountType === "TRIAL" ||
                        item.accountType === "TRIAL_EXPIRED" ||
                        item.accountType === "ACTIVE" ||
                        item.accountType === "INACTIVE" ||
                        item.accountType === "CHARGE_FAILED" ? (
                          <div>
                            <button
                              type="button"
                              className={buttonActionClass}
                              onClick={() => handleTrialModal(item)}
                            >
                              Update Trial Status
                            </button>
                          </div>
                        ) : (
                          "-"
                        )}
                        <div>
                          <button
                            type="button"
                            className={buttonActionClass}
                            onClick={() => handleCurrencyModal(item)}
                          >
                            Update Currency
                          </button>
                        </div>
                        <GoToPageSelector
                          goToPaths={this.getActionPaths(item)}
                        ></GoToPageSelector>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default PublishersTable;

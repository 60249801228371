import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { NetworkAPI } from "apis";
import LoadingUI from "components/common/LoadingUI";
import GamUprTable from "./GamUprTable";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import SelectedStickyFooter from "components/common/SelectedStickyFooter";
import ItemsFilter from "components/ops-mgmt/common/ItemsFilter";
import { searchIncludes } from "helpers/Utils";
import { PRICE_TYPE, PRICE_TYPE_BY_NAME } from "constants/PriceType";

const buttonClass =
  "px-2 py-1 bg-gray-100 text-xs rounded border border-gray-400 hover:bg-gray-200 text-gray-900 font-semibold";

const RULEFILTER = {
  ALL: "ALL",
  INTOWOW_ONLY: "INTOWOW_ONLY",
  PUBLISHER_ONLY: "PUBLISHER_ONLY",
};

const PPFILTER = {
  ALL: "ALL",
  PROTECTED: "PROTECTED",
  PRESERVED: "PRESERVED",
  NOT_PROTECTED_PRESERVED: "NOT_PROTECTED_PRESERVED",
};

function _isSectionEmpty(section) {
  if (!section || _.isEmpty(section)) {
    return true;
  } else if (_.has(section, "includes") && _.has(section, "excludes")) {
    return _.isEmpty(section.includes) && _.isEmpty(section.excludes);
  } else if (_.has(section, "includes") && _.isEmpty(section.includes)) {
    return true;
  } else if (_.has(section, "excludes") && _.isEmpty(section.excludes)) {
    return true;
  } else {
    return false;
  }
}

class NetworkGamUprViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      networkId: null,
      networkInfo: null,
      errMsg: "",

      updatedAt: null,
      originalUprs: null,
      newUprs: null,
      ruleFilters: null,
      priceTypeFilters: null,
      resourceFilters: null,
      ppFilters: null,
      currentFilter: {
        ruleType: RULEFILTER.ALL,
        priceType: "ALL",
        resourceType: "ALL", // when Intowow Rules
        ppType: PPFILTER.ALL, // when Publisher Rules
      },

      isJsonView: false,
      inputValue: "",

      selectedIds: [],
      selectedKey: "",
    };

    this.searchRef = React.createRef();

    this.toggleJsonView = this.toggleJsonView.bind(this);
    this.handleRuleTypeFilter = this.handleRuleTypeFilter.bind(this);
    this.handlePPTypeFilter = this.handlePPTypeFilter.bind(this);
    this.handlePriceTypeFilter = this.handlePriceTypeFilter.bind(this);
    this.handleForceRefresh = this.handleForceRefresh.bind(this);
    this.handleResourceTypeFilter = this.handleResourceTypeFilter.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectedByKey = this.handleSelectedByKey.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { networkId } = this.props;
    if (!networkId) {
      this.setState({ errMsg: "Missing Network ID" });
      return;
    } else {
      document.title = `${networkId} GAM UPRs | YB Observer`;
      this.setState({ networkId });
    }

    try {
      let { originalUprs, allUprs, ruleFilters, ppFilters, updatedAt } =
        await this._getGamUprsAndFilters({
          isForceRefresh: false,
        });

      const priceTypeFilters = this._createPriceTypeFilters(ruleFilters[2]);
      const resourceFilters = this._getI2wResourceMappingFilters(
        ruleFilters[1]
      );

      this.setState({
        originalUprs,
        newUprs: allUprs,
        ruleFilters,
        priceTypeFilters,
        resourceFilters,
        ppFilters,
        updatedAt,

        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  async _getGamUprsAndFilters({ isForceRefresh = false }) {
    const { networkId } = this.props;
    const { uprs, updatedAt } = await NetworkAPI.getGamUprs({
      networkId,
      isForceRefresh,
    });

    const {
      // placement,
      upr = {},
    } = await NetworkAPI.getNetworkResourceMapping({ networkId });

    const { config } = await NetworkAPI.getRawNetworkFeatures({ networkId });
    let protectedUprIds = _.get(config, "publisherUPRProtectionList", []);
    let preservedUprIds = _.get(config, "preservedUPRIds", []);

    let resourceById = _.keyBy(upr.mapping, (item) => {
      return item.id;
    });

    let allUprs = this._transformRules({
      uprs: _.cloneDeep(uprs),
      protectedUprIds,
      preservedUprIds,
      resourceById,
    });

    const intowowRules = _.filter(allUprs, { __isI2wRule: true });
    const publisherRules = _.filter(allUprs, { __isPubRule: true });
    let ruleFilters = [
      {
        key: RULEFILTER.ALL,
        name: "ALL",
        uprs: allUprs,
      },
      {
        key: RULEFILTER.INTOWOW_ONLY,
        name: "Intowow Rules",
        uprs: intowowRules,
      },
      {
        key: RULEFILTER.PUBLISHER_ONLY,
        name: "Publisher Rules",
        uprs: publisherRules,
      },
    ];

    const tempPubRules = _.cloneDeep(publisherRules);
    const protectedRules = _.remove(tempPubRules, { __isProtected: true });
    const preservedRules = _.remove(tempPubRules, { __isPreserved: true });
    let ppFilters = [
      {
        key: PPFILTER.ALL,
        name: "ALL",
        uprs: publisherRules,
      },
      {
        key: PPFILTER.PROTECTED,
        name: "Protected UPRs",
        uprs: protectedRules,
      },
      {
        key: PPFILTER.PRESERVED,
        name: "Preserved UPRs",
        uprs: preservedRules,
      },
      {
        key: PPFILTER.NOT_PROTECTED_PRESERVED,
        name: "Not Protected or Preserved UPRs",
        uprs: tempPubRules,
      },
    ];

    return {
      originalUprs: uprs,
      allUprs,
      ruleFilters,
      ppFilters,
      updatedAt,
    };
  }

  _transformRules({ uprs, protectedUprIds, preservedUprIds, resourceById }) {
    let allUprs = _.map(uprs, (upr) => {
      let priceSection = {};
      const setForEverythingSetting = _.find(upr.priceSection.priceSettings, {
        isSetForEverything: true,
      });
      if (setForEverythingSetting) {
        priceSection.priceType = setForEverythingSetting.priceType;
        priceSection.price = setForEverythingSetting.price;
        priceSection.currency = setForEverythingSetting.currency;
      } else {
        priceSection.priceType = PRICE_TYPE_BY_NAME.BRAND_SETTING;
        priceSection.currency = _.get(upr.priceSection.priceSettings, [
          0,
          "currency",
        ]);
        priceSection.brandedPriceSettings = upr.priceSection.priceSettings;
      }

      let targetingSection = {};
      if (!_isSectionEmpty(upr.targetingSection.inventoryTypes)) {
        targetingSection.inventoryTypes = upr.targetingSection.inventoryTypes;
      }
      if (upr.targetingSection.inventory) {
        if (!_isSectionEmpty(upr.targetingSection.inventory.unitIds)) {
          _.set(
            targetingSection,
            ["inventory", "units"], // units not unitIds!
            upr.targetingSection.inventory.unitIds
          );
        }
        if (!_isSectionEmpty(upr.targetingSection.inventory.placementIds)) {
          _.set(
            targetingSection,
            ["inventory", "placementIds"],
            upr.targetingSection.inventory.placementIds
          );
        }
        if (!_isSectionEmpty(upr.targetingSection.inventory.mcm)) {
          _.set(
            targetingSection,
            ["inventory", "mcm"],
            upr.targetingSection.inventory.mcm
          );
        }
      }
      if (!_isSectionEmpty(upr.targetingSection.browser)) {
        targetingSection.browser = upr.targetingSection.browser;
      }
      if (!_isSectionEmpty(upr.targetingSection.country)) {
        targetingSection.country = upr.targetingSection.country;
      }
      if (!_isSectionEmpty(upr.targetingSection.customs)) {
        targetingSection.customs = upr.targetingSection.customs;
      }
      if (!_isSectionEmpty(upr.targetingSection.deviceCategory)) {
        targetingSection.deviceCategory = upr.targetingSection.deviceCategory;
      }
      if (!_isSectionEmpty(upr.targetingSection.mobileApplication)) {
        targetingSection.mobileApplication =
          upr.targetingSection.mobileApplication;
      }
      if (!_isSectionEmpty(upr.targetingSection.os)) {
        targetingSection.os = upr.targetingSection.os;
      }

      upr.__isI2wRule = _.startsWith(upr.uprName, "Intowow_");
      upr.__isPubRule = !_.startsWith(upr.uprName, "Intowow_");
      upr.__isProtected =
        _.indexOf(protectedUprIds, _.parseInt(upr.externalId)) !== -1;
      upr.__isPreserved =
        _.indexOf(preservedUprIds, _.parseInt(upr.externalId)) !== -1;

      return {
        ...upr,
        priceSection,
        targetingSection,
        props: _.get(resourceById, [upr.externalId, "props"], null),
      };
    });
    allUprs = _.sortBy(allUprs, "uprName");
    return allUprs;
  }

  async handleForceRefresh() {
    const {
      currentFilter: { ppType, priceType, resourceType, ruleType },
    } = this.state;
    this.setState({
      isLoading: true,
      updatedAt: null,
      originalUprs: null,
      newUprs: null,
      ruleFilters: null,
      priceTypeFilters: null,
      resourceFilters: null,
      ppFilters: null,
      selectedIds: [],
      selectedKey: "",
    });

    try {
      const { originalUprs, allUprs, ruleFilters, ppFilters, updatedAt } =
        await this._getGamUprsAndFilters({
          isForceRefresh: true,
        });
      const ppSelected = _.find(ppFilters, { key: ppType });
      const priceTypeFilters = this._createPriceTypeFilters(ppSelected);
      const resourceFilters = this._getI2wResourceMappingFilters(
        ruleFilters[1]
      );

      let newUprs = allUprs;
      let tempResourceType = resourceType;
      if (ruleType === RULEFILTER.INTOWOW_ONLY) {
        const tempResource = _.find(resourceFilters, { key: resourceType });
        if (!tempResource) tempResourceType = "ALL";
        newUprs = _.find(resourceFilters, { key: tempResourceType }).uprs;
      } else if (ruleType === RULEFILTER.PUBLISHER_ONLY) {
        newUprs = _.find(priceTypeFilters, { key: priceType }).uprs;
      }

      this.setState({
        originalUprs,
        newUprs,
        ruleFilters,
        priceTypeFilters,
        resourceFilters,
        ppFilters,
        updatedAt,
        currentFilter: {
          ...this.state.currentFilter,
          resourceType: tempResourceType,
        },
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  _createPriceTypeFilters(selectedFilter) {
    const uprsGrouped = _.groupBy(selectedFilter.uprs, (upr) => {
      return _.get(upr, ["priceSection", "priceType"]);
    });
    let priceTypeFilters = _.map(_.keys(PRICE_TYPE), (pt) => {
      return {
        key: PRICE_TYPE[pt],
        name: PRICE_TYPE[pt],
        uprs: uprsGrouped[pt] || [],
      };
    });
    priceTypeFilters.unshift({
      key: "ALL",
      name: "ALL",
      uprs: selectedFilter.uprs,
    });

    return priceTypeFilters;
  }

  _getI2wResourceMappingFilters(selectedFilter) {
    const uprsGrouped = _.groupBy(selectedFilter.uprs, (upr) => {
      return _.get(upr, ["props", "type"]);
    });

    let resourceFilters = _.map(_.keys(uprsGrouped), (rt) => {
      return {
        key: rt,
        name: rt,
        uprs: uprsGrouped[rt] || [],
      };
    });
    resourceFilters.unshift({
      key: "ALL",
      name: "ALL",
      uprs: selectedFilter.uprs,
    });

    return resourceFilters;
  }

  toggleJsonView() {
    this.setState({ isJsonView: !this.state.isJsonView });
  }

  handleRuleTypeFilter({ selectedFilter }) {
    // currentFilter: {
    //   ruleType: "PUBLISHER_ONLY",
    //   priceType: "ALL",
    // },

    // selectedFilter: {key, name, uprs}

    const newUprs = selectedFilter.uprs;
    const currentFilter = {
      ruleType: selectedFilter.key,
      resourceType: "ALL",
      ppType: "ALL",
      priceType: "ALL",
    };
    const priceTypeFilters = this._createPriceTypeFilters(selectedFilter);
    this.setState({ newUprs, currentFilter, priceTypeFilters });
  }

  handlePPTypeFilter({ selectedFilter }) {
    // currentFilter: {
    //   ruleType: "PUBLISHER_ONLY",
    //   priceType: "ALL",
    // },

    // selectedFilter: {key, name, uprs}

    const newUprs = selectedFilter.uprs;
    let currentFilter = {
      ...this.state.currentFilter,
      ppType: selectedFilter.key,
      priceType: "ALL",
    };
    const priceTypeFilters = this._createPriceTypeFilters(selectedFilter);
    this.setState({
      newUprs,
      currentFilter,
      priceTypeFilters,
    });
  }

  handlePriceTypeFilter(selectedFilter) {
    const newUprs = selectedFilter.uprs;
    let currentFilter = {
      ...this.state.currentFilter,
      priceType: selectedFilter.key,
    };
    this.setState({ newUprs, currentFilter });
  }

  handleResourceTypeFilter(selectedFilter) {
    const newUprs = selectedFilter.uprs;
    let currentFilter = {
      ...this.state.currentFilter,
      resourceType: selectedFilter.key,
    };
    this.setState({ newUprs, currentFilter });
  }

  handleSearch(inputValue) {
    this.setState({ inputValue });
  }

  handleSelect(selected) {
    this.setState({ selectedIds: selected });
  }

  handleSelectedByKey(key) {
    this.setState({ selectedKey: key });
  }

  render() {
    const { networkId, networkInfo, units, virtualPlacements } = this.props;
    const {
      isLoading,
      errMsg,

      updatedAt,
      originalUprs,
      newUprs,

      ruleFilters,
      priceTypeFilters,
      currentFilter,
      resourceFilters,
      ppFilters,

      isJsonView,
      selectedIds,
      selectedKey,
    } = this.state;

    const end = moment();
    const start = moment(updatedAt);
    const duration = moment.duration(end.diff(start));
    const updatedAgo = duration.asHours();

    const filteredUprs = newUprs
      ? newUprs.filter((upr) => {
          return searchIncludes(upr.uprName, this.state.inputValue);
        })
      : [];

    const selectedByKey =
      selectedKey === ""
        ? []
        : selectedIds.map((extId) => {
            return _.get(
              _.find(originalUprs, { externalId: extId }),
              selectedKey
            );
          });

    return (
      <div>
        <div>
          <PageTitle title="GAM UPRs"></PageTitle>

          {isLoading && <LoadingUI></LoadingUI>}
          {errMsg && <div className="text-red-800">{errMsg}</div>}

          {newUprs && (
            <div>
              <div className="mb-2 flex items-center justify-end gap-2">
                <div className="text-sm">
                  Updated at:{" "}
                  <DateTimeFormatter datetime={updatedAt}></DateTimeFormatter>
                  {updatedAgo > 1 && (
                    <span className="pl-2 font-semibold text-red-600">
                      (Rules could be outdated)
                    </span>
                  )}
                </div>

                <button className={buttonClass} onClick={this.toggleJsonView}>
                  {!isJsonView ? "View original JSON" : "View in table"}
                </button>

                <button
                  className={buttonClass}
                  onClick={this.handleForceRefresh}
                >
                  Force Refresh
                </button>
              </div>

              {!isJsonView && updatedAt && (
                <div>
                  <div className="flex flex-wrap items-center text-sm">
                    <div className="mr-1 font-semibold text-gray-700">
                      Rule Filter:{" "}
                    </div>
                    {ruleFilters.map((item) => {
                      const isSelected = item.key === currentFilter.ruleType;
                      return (
                        <button
                          key={item.name}
                          type="button"
                          className={`border rounded mb-1 mr-1 border-gray-200 ${
                            isSelected ? "bg-blue-200" : "bg-white"
                          }  px-2 py-1 text-gray-800 hover:border-blue-400`}
                          onClick={() => {
                            this.handleRuleTypeFilter({
                              filterFn: item.filterFn,
                              currentFilter,
                              selectedFilter: item,
                            });
                          }}
                        >
                          {item.name} ({item.uprs.length})
                        </button>
                      );
                    })}
                  </div>

                  {currentFilter.ruleType === "INTOWOW_ONLY" && (
                    <div className="mt-2 flex flex-wrap items-center text-sm">
                      <div className="mr-1 font-semibold text-gray-700">
                        UPR Resource Filter:{" "}
                      </div>
                      {resourceFilters.map((item) => {
                        const isSelected =
                          currentFilter.resourceType === item.key;
                        return (
                          <button
                            key={item.name}
                            type="button"
                            className={`border rounded mb-1 mr-1 border-gray-200 ${
                              isSelected ? "bg-blue-200" : "bg-white"
                            }  px-2 py-1 text-gray-800 hover:border-blue-400`}
                            onClick={() => {
                              this.handleResourceTypeFilter(item);
                            }}
                          >
                            {item.name} ({item.uprs.length})
                          </button>
                        );
                      })}
                    </div>
                  )}

                  {currentFilter.ruleType === "PUBLISHER_ONLY" && (
                    <div className="mt-2 flex flex-wrap items-center text-sm">
                      <div className="mr-1 font-semibold text-gray-700">
                        Protected or Preserved Filter:{" "}
                      </div>
                      {ppFilters.map((item) => {
                        const isSelected = currentFilter.ppType === item.key;
                        return (
                          <button
                            key={item.name}
                            type="button"
                            className={`border rounded mb-1 mr-1 border-gray-200 ${
                              isSelected ? "bg-blue-200" : "bg-white"
                            }  px-2 py-1 text-gray-800 hover:border-blue-400`}
                            onClick={() => {
                              this.handlePPTypeFilter({
                                filterFn: item.filterFn,
                                currentFilter,
                                selectedFilter: item,
                              });
                            }}
                          >
                            {item.name} ({item.uprs.length})
                          </button>
                        );
                      })}
                    </div>
                  )}

                  {currentFilter.ruleType === "PUBLISHER_ONLY" && (
                    <div className="mt-2 flex flex-wrap items-center text-sm">
                      <div className="mr-1 font-semibold text-gray-700">
                        Price Type Filter:{" "}
                      </div>
                      {priceTypeFilters.map((item) => {
                        const isSelected = currentFilter.priceType === item.key;
                        return (
                          <button
                            key={item.name}
                            type="button"
                            className={`border rounded mb-1 mr-1 border-gray-200 ${
                              isSelected ? "bg-blue-200" : "bg-white"
                            }  px-2 py-1 text-gray-800 hover:border-blue-400`}
                            onClick={() => {
                              this.handlePriceTypeFilter(item);
                            }}
                          >
                            {item.name} ({item.uprs.length})
                          </button>
                        );
                      })}
                    </div>
                  )}
                  <div className="mt-2">
                    <ItemsFilter
                      ref={this.searchRef}
                      inputValue={this.state.inputValue}
                      handleSearch={this.handleSearch}
                      placeholder={"Search by UPR name"}
                    ></ItemsFilter>
                  </div>
                </div>
              )}

              <div className="flex w-full">
                <div className="w-full">
                  <div className="mb-1 mt-2 flex items-center justify-between">
                    <div className="text-lg font-semibold">
                      GAM UPRs ({filteredUprs ? filteredUprs.length : 0}):{" "}
                    </div>
                  </div>

                  {updatedAt && (
                    <>
                      {isJsonView ? (
                        <textarea
                          style={{ width: "100%", height: "600px" }}
                          defaultValue={JSON.stringify(originalUprs, null, 4)}
                          readOnly
                        ></textarea>
                      ) : (
                        <div style={{ marginBottom: "600px" }}>
                          <GamUprTable
                            items={filteredUprs}
                            networkInfo={networkInfo}
                            networkId={networkId}
                            isI2wRuleSelected={
                              currentFilter.ruleType === "INTOWOW_ONLY"
                            }
                            units={units}
                            virtualPlacements={virtualPlacements}
                            handleSelect={this.handleSelect}
                            selectedItems={selectedIds}
                          ></GamUprTable>
                          <SelectedStickyFooter
                            idList={["externalId"]}
                            selectedItems={selectedByKey}
                            handleSelectedByKey={this.handleSelectedByKey}
                            handleClear={() => this.handleSelect([])}
                          ></SelectedStickyFooter>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

export default NetworkGamUprViewer;

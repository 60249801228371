import React from "react";
import _ from "lodash";
import { PlatformAPI } from "apis";
import ViewerWrapper from "components/common/ViewerWrapper";
import NetworkStatusIndicator from "components/common/NetworkStatusIndicator";
import ClickToCopyButton from "components/common/ClickToCopyButton";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import HeaderTabs from "components/common/HeaderTabs";
import ItemsFilter from "components/ops-mgmt/common/ItemsFilter";

import { tdClass, thClass } from "helpers/StyleClass";

const TABS = {
  ALL: "all",
  CRITICAL: "critical",
  NORMAL: "normal",
};

class CompetitorsSummaryViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errMsg: null,
      selectedTab: TABS.ALL,
      searchText: "",

      competitors: [],
      tabs: [],
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const result = await PlatformAPI.getCompetitorSummary();

      const competitors = _.map(result, (competitor) => {
        const isCritical = _.some(_.keys(competitor.summary), (key) => {
          return _.some(competitor.summary[key], (item) => item.isCritical);
        });

        return {
          ...competitor,
          list: competitor.summary ? _.keys(competitor.summary) : [],
          isCritical,
        };
      });

      const tabs = _.values(TABS).map((tab) => {
        const count =
          tab === TABS.ALL
            ? competitors.length
            : _.filter(competitors, (c) => {
                return tab === TABS.CRITICAL ? c.isCritical : !c.isCritical;
              }).length;
        return {
          key: tab,
          title: `${_.startCase(tab)} (${count})`,
        };
      });

      this.setState({ competitors, tabs, isLoading: false });
    } catch (error) {
      this.setState({ errMsg: error.message });
    }
  }

  render() {
    const { isLoading, errMsg, competitors, tabs, selectedTab, searchText } =
      this.state;

    const filteredCompetitors = _(competitors)
      .filter(({ networkId, name, list }) => {
        if (searchText === "") return true;
        if (_.includes(networkId.toString(), searchText)) return true;
        if (_.includes(name, searchText)) return true;
        return _.some(list, (key) => _.includes(key, searchText));
      })
      .map((competitor) => {
        if (searchText === "") return competitor;

        if (_.some(competitor.list, (key) => _.includes(key, searchText))) {
          const result = {};
          competitor.list.forEach((key) => {
            if (_.includes(key, searchText)) {
              result[key] = competitor.summary[key];
            }
          });
          return { ...competitor, summary: result };
        } else {
          return competitor;
        }
      })
      .filter((competitor) => {
        if (selectedTab === TABS.ALL) {
          return true;
        }

        return selectedTab === TABS.CRITICAL
          ? competitor.isCritical
          : !competitor.isCritical;
      })
      .orderBy(["networkId"], ["desc"])
      .value();

    return (
      <>
        <ViewerWrapper
          title="Competitor Summary"
          isLoading={isLoading}
          hasHeader={false}
        >
          {errMsg && <div className="text-red-500">{errMsg}</div>}
          <HeaderTabs
            tabs={tabs}
            selectedTabKey={selectedTab}
            handleTabChange={(tab) => this.setState({ selectedTab: tab })}
          ></HeaderTabs>
          <div className="mt-4">
            <ItemsFilter
              handleSearch={(value) => this.setState({ searchText: value })}
              placeholder={"Search by network or competitor"}
            ></ItemsFilter>
          </div>
          <div
            className="mt-4 w-full overflow-y-auto"
            style={{ height: "calc(100% - 6rem)" }}
          >
            <table className="shadow divide-y w-full table-fixed">
              <thead className="bg-blue-100">
                <tr>
                  <th className={thClass + " w-64"}>Network</th>
                  {/* <th className={thClass + " w-32"}>Code</th> */}
                  <th className={thClass + " w-40"}>Competitor Name</th>
                  <th className={thClass + " w-24"}>Critical</th>
                  <th className={thClass} style={{ width: "11rem" }}>
                    User ID
                  </th>
                  <th className={thClass} style={{ width: "13rem" }}>
                    User
                  </th>
                  <th className={thClass}>Email</th>
                  <th className={thClass + " w-24"}>Account</th>
                  {/* <th className={thClass + " w-24"}>Macthed Rule Id</th> */}
                  <th className={thClass + " w-48"}>First Detect</th>
                </tr>
              </thead>
              <tbody className="bg-gray-100">
                {filteredCompetitors.map((competitor) => {
                  const list = competitor.list.filter(
                    (key) => competitor.summary[key]
                  );

                  const count = list.reduce((acc, key) => {
                    return acc + competitor.summary[key].length;
                  }, 0);

                  return list.map((key, sIdx) => {
                    return competitor.summary[key].map((item, idx) => {
                      return (
                        <tr key={item.user.id}>
                          {sIdx === 0 && idx === 0 && (
                            <>
                              <td className={tdClass} rowSpan={count}>
                                <div className="flex items-center gap-1">
                                  <NetworkStatusIndicator
                                    status={competitor.status}
                                  ></NetworkStatusIndicator>
                                  <span className="pl-2">
                                    {competitor.networkId} {competitor.name}
                                  </span>
                                </div>
                                <div className="text-xs text-gray-700">
                                  Code: {competitor.code}
                                </div>
                              </td>
                              {/* <td className={tdClass} rowSpan={count}>
                                {competitor.code}
                              </td> */}
                            </>
                          )}
                          {idx === 0 && (
                            <>
                              <td
                                className={tdClass}
                                rowSpan={competitor.summary[key].length}
                              >
                                {key}
                              </td>
                              <td
                                className={`text-center font-semibold ${tdClass} ${
                                  competitor.isCritical
                                    ? "text-red-600"
                                    : "text-green-600"
                                }`}
                                rowSpan={competitor.summary[key].length}
                              >
                                {competitor.isCritical ? "Critical" : "Normal"}
                              </td>
                            </>
                          )}

                          <td className={tdClass}>
                            <div className="flex items-center justify-between gap-4">
                              <div>{item.user.id}</div>
                              <ClickToCopyButton
                                copyText={JSON.stringify(item.user.id)}
                                tooltipMessage="Copy User Id"
                              ></ClickToCopyButton>
                            </div>
                          </td>
                          <td className={tdClass}>
                            <div
                              className={`break-all font-semibold ${
                                item.user.isActive ? "text-green-600" : ""
                              }`}
                            >
                              {item.user.name}
                            </div>
                            <div className="text-gray-600">
                              {item.user.roleName}
                            </div>
                          </td>
                          <td className={tdClass}>
                            <div className="flex w-full items-center justify-between gap-4">
                              <div className="break-all">{item.user.email}</div>
                              <ClickToCopyButton
                                copyText={item.user.email}
                                tooltipMessage="Copy User Email"
                              ></ClickToCopyButton>
                            </div>
                          </td>
                          <td className={tdClass + " text-center"}>
                            {item.user.isServiceAccount ? "Service" : "Human"}
                          </td>
                          {/* <td className={tdClass + " text-center"}>
                            {item.macthedRuleId}
                          </td> */}
                          <td className={tdClass}>
                            <DateTimeFormatter
                              datetime={item.firstDetectedAt}
                            ></DateTimeFormatter>
                          </td>
                        </tr>
                      );
                    });
                  });
                })}
              </tbody>
            </table>
          </div>
        </ViewerWrapper>
      </>
    );
  }
}

export default CompetitorsSummaryViewer;

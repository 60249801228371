import React from "react";
import _ from "lodash";
import PopupWrapper from "../../../common/PopupWrapper";
import { formatMoneyWithCurrency } from "components/playground/home-dashboard-v1/ReportsHelper";
import AdvertiserAndBrandsSelector from "./AdvertiserAndBrandsSelector";
import { GAM_CREATIVE_TYPES_NAME } from "./Constants";

const PRICE_TYPE_NAME = {
  0: "Floor",
  1: "Target CPM",
  // LGO: 2, // not an option for brand price settings
};

class UprPriceSectionPopover extends React.PureComponent {
  render() {
    const {
      targetingSection = {},
      triggerElement,
      currency,
      networkId,
      onOpen,
      onClose,
    } = this.props;
    // inventory, device category, os, country...
    const { brandedPriceSettings } = targetingSection;

    return (
      <PopupWrapper
        place="bottom"
        triggerType="click"
        hideArrow={false}
        triggerElement={triggerElement}
        onOpen={onOpen}
        onClose={onClose}
        popupElement={
          <div className="shadow border p-4" style={{ width: "600px" }}>
            <div>
              <div className="mt-2 font-semibold text-gray-900">
                Branded Price Settings (
                {brandedPriceSettings ? brandedPriceSettings.length : 0})
              </div>
              <div>
                {brandedPriceSettings ? (
                  <div>
                    {brandedPriceSettings.map((item, index) => {
                      let numOfAdvertisersAndBrands = 0;
                      if (item.targeting.advertisersAndBrands) {
                        numOfAdvertisersAndBrands = _.filter(
                          item.targeting.advertisersAndBrands,
                          (a) => {
                            return a.advertiserId !== "0";
                          }
                        ).length;
                      }
                      return (
                        <div key={index} className="border mb-2 p-2">
                          <div className="border-b mb-2">
                            <div className="text-sm font-semibold leading-tight text-gray-800">
                              Price
                            </div>
                            <div>
                              {PRICE_TYPE_NAME[item.priceType]}{" "}
                              {formatMoneyWithCurrency({
                                currency: item.currency || currency,
                                value: item.price,
                              })}
                            </div>
                          </div>

                          {item.branded && (
                            <div className="border-b mb-2">
                              <div className="text-sm font-semibold leading-tight text-gray-800">
                                Branded
                              </div>
                              <div
                                className={
                                  item.branded === "BLOCKED" && "text-red-600"
                                }
                              >
                                {item.branded}
                              </div>
                            </div>
                          )}

                          <div className="border-b mb-2">
                            <div className="text-sm font-semibold leading-tight text-gray-800">
                              Creative Format
                            </div>
                            <div className="text-sm">
                              {item.targeting.creativeTypes ? (
                                <span>
                                  {
                                    GAM_CREATIVE_TYPES_NAME[
                                      item.targeting.creativeTypes
                                    ]
                                  }
                                </span>
                              ) : (
                                "Any Creative Type"
                              )}
                            </div>
                          </div>

                          <div className="border-b mb-2">
                            <div className="mt-2 text-sm font-semibold leading-tight text-gray-800">
                              Sizes
                            </div>
                            <div>
                              {item.targeting.sizes ? (
                                <SegmentTarget
                                  targetType="SIZES"
                                  targetData={item.targeting.sizes}
                                ></SegmentTarget>
                              ) : (
                                "-"
                              )}
                            </div>
                          </div>

                          <div className="">
                            <div className="mt-2 text-sm font-semibold leading-tight text-gray-800">
                              Advertisers and Brands{" "}
                              <span>({numOfAdvertisersAndBrands})</span>
                            </div>
                            <div className="leading-tight">
                              {item.targeting.advertisersAndBrands ? (
                                <div>
                                  <AdvertiserAndBrandsSelector
                                    networkId={networkId}
                                    // targetingType="advertisersAndBrands"
                                    targeting={
                                      item.targeting["advertisersAndBrands"]
                                    }
                                    isDisabled={true}
                                    // handleTargetingChanged={this.handleAdvertiserAndBrandsChanged}
                                  ></AdvertiserAndBrandsSelector>
                                </div>
                              ) : (
                                "-"
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
        }
      ></PopupWrapper>
    );
  }
}

class SegmentTarget extends React.PureComponent {
  render() {
    const { targetData, targetType } = this.props;

    const isInclude = targetData.includes;
    let op = isInclude ? "Includes" : "Excludes";
    let targets = isInclude ? targetData.includes : targetData.excludes;

    targets = _.map(targets, (t) => {
      return t;
    });

    return (
      <div className="text-sm">
        {targetData && (
          <div>
            <div
              className={`${
                isInclude ? "text-green-500" : "text-red-500"
              } font-medium`}
            >
              {op}
            </div>
            <div>
              {targets.map((value, i) => {
                return (
                  <span key={i} className="mr-1 bg-gray-200 px-1">
                    {value}
                  </span>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default UprPriceSectionPopover;

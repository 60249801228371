import React from "react";
import _ from "lodash";

import { ParentUnitLabel } from "./ParentUnitLabel";
import { MCMLabel } from "./MCMLabel";
import YbReqStatDiagram from "./ReqStatDiagram";
import YbReqStatTooltip from "./ReqStatTooltip";

import { ONBOARD_LIMIT_EXCEED_ERROR_MESSAGE } from "./constants";
import { FaTimes } from "react-icons/fa";

import CommonStyle from "./css/common.module.css";
import FormStyle from "./css/form.module.css";
import Style from "./css/ad-units.module.css";
import ItemStyle from "./css/inventory-item.module.css";

export function SelectedItemsTable(props) {
  let {
    selectedItems,
    isSubmitDisabled,

    // actions
    onRemoveSelectedUnit,
    onRemoveAllSelectedUnits,
  } = props;

  if (!selectedItems.length) {
    return <span />;
  }

  // Sort candidates!
  selectedItems = _.orderBy(selectedItems, ["dailyCompatibleAvgReq"], ["desc"]);

  const hasSelectedParentUnit = _.some(selectedItems, { hasChildren: true });

  return (
    <div className="mt-1">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className={FormStyle.label}>
          Selected ad units ({selectedItems.length})
          <span style={{ marginLeft: "12px" }}>
            <button
              className={CommonStyle.buttonLink}
              onClick={onRemoveAllSelectedUnits}
            >
              <div className="flex items-center">
                <FaTimes></FaTimes> Remove all
              </div>
            </button>
          </span>
        </div>

        <div style={{ display: "flex", gap: "24px" }}>
          {hasSelectedParentUnit && (
            <div style={{ fontSize: "12px", color: "#595959" }}>
              <div style={{ fontWeight: "600" }}>Parent Unit</div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ParentUnitLabel></ParentUnitLabel>
              </div>
            </div>
          )}
        </div>
      </div>

      {isSubmitDisabled && (
        <div className="alert alert-danger">
          {ONBOARD_LIMIT_EXCEED_ERROR_MESSAGE}
        </div>
      )}

      <div className={Style.selectedAdUnits}>
        {selectedItems.map((item) => {
          return (
            <div className={ItemStyle.selectedRow} key={item.extGamUnitId}>
              <div className={ItemStyle.selectedRowStart}>
                <div style={{ marginRight: "18px" }}>
                  <button
                    className={CommonStyle.buttonLink}
                    onClick={() => onRemoveSelectedUnit(item)}
                  >
                    <FaTimes></FaTimes>
                  </button>
                </div>

                <div>
                  <div>
                    {/* <div className={ItemStyle.unitExtId}>{item.extGamUnitId}</div> */}
                    <div className={ItemStyle.unitName}>{item.fullPath}</div>

                    {item.hasChildren && <ParentUnitLabel></ParentUnitLabel>}
                  </div>

                  {item.siteNetworkCodes && (
                    <MCMLabel
                      id={item.extGamUnitId}
                      siteNetworkCodes={item.siteNetworkCodes}
                    ></MCMLabel>
                  )}
                </div>
              </div>
              <YbReqStatTooltip
                item={item}
                tooltipKey={item.extGamUnitId + "-table-req-tooltip"}
              ></YbReqStatTooltip>
              <div
                className={ItemStyle.itemStat}
                data-tip
                data-for={item.extGamUnitId + "-table-req-tooltip"}
              >
                <YbReqStatDiagram item={item}></YbReqStatDiagram>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

import _ from "lodash";
import { api, fetchRespJson, postData } from "./base";

export default {
  // vault
  getVaultSetting,
  getVaultGamAccounts,
  getVaultGamAccountById,
  createVaultAccount,
  updateVaultAccount,
  updateVaultAccountStatus,
  deleteVaultAccount,
  bindNetworkToVaultAccount,
  getAccountByNetworkCode,
  getAccountByNetworkId,
  removeNetworkAccountBinding,
  // users
  getVaultUsers,
  getCurrentUser,
  createVaultUser,
  updateVaultUser,
  deleteVaultUser,
};

async function deleteVaultUser(params) {
  const apiUrl = `${api}/vault/delete-user`;
  return await postData(apiUrl, params);
}

async function updateVaultUser(params) {
  const apiUrl = `${api}/vault/update-user`;
  return await postData(apiUrl, params);
}

async function createVaultUser(params) {
  const apiUrl = `${api}/vault/create-user`;
  return await postData(apiUrl, params);
}

async function getVaultUsers(params) {
  const apiUrl = `${api}/vault/users`;
  return await postData(apiUrl, params);
}

async function getCurrentUser() {
  const apiUrl = `${api}/vault/user`;
  return await fetchRespJson(apiUrl);
}

async function removeNetworkAccountBinding(params) {
  const apiUrl = `${api}/v2/vault/remove-bind-network-account`;
  return await postData(apiUrl, params);
}

async function bindNetworkToVaultAccount(params) {
  const apiUrl = `${api}/v2/vault/bind-network-account`;
  return await postData(apiUrl, params);
}

async function createVaultAccount(params) {
  const apiUrl = `${api}/v2/vault/create-account`;
  return await postData(apiUrl, params);
}

async function updateVaultAccount(params) {
  const apiUrl = `${api}/v2/vault/update-account`;
  return await postData(apiUrl, params);
}

async function updateVaultAccountStatus(params) {
  const apiUrl = `${api}/vault/update-account-status`;
  return await postData(apiUrl, params);
}

async function deleteVaultAccount(params) {
  const apiUrl = `${api}/v2/vault/delete-account`;
  return await postData(apiUrl, params);
}

async function getAccountByNetworkId({ networkId }) {
  let apiUrl = `${api}/v2/network/${networkId}/vault-accounts`;
  return await fetchRespJson(apiUrl);
}

async function getAccountByNetworkCode({ networkCode }) {
  let apiUrl = `${api}/v2/vault/${networkCode}/accounts`;
  return await fetchRespJson(apiUrl);
}

async function getVaultGamAccountById({ accountId }) {
  let apiUrl = `${api}/v2/vault/accounts/${accountId}`;
  return await fetchRespJson(apiUrl);
}

async function getVaultGamAccounts() {
  let apiUrl = `${api}/v2/vault/accounts`;
  return await fetchRespJson(apiUrl);
}

async function getVaultSetting() {
  let apiUrl = `${api}/vault-setting`;
  return await fetchRespJson(apiUrl);
}

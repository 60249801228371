import React from "react";
import _ from "lodash";
import Swal from "sweetalert2";

import { OnboardingAPI } from "apis";
import NetworksSelector from "./NetworksSelector";
import YieldSetsSelector from "./YieldSetsSelector";
import Checkbox from "components/common/Checkbox";
import ClickToCopyButton from "components/common/ClickToCopyButton";
import PromptModal from "components/common/PromptModal";
import LoadingUI from "components/common/LoadingUI";

import { getQueryStringToObject } from "helpers/QueryString";
import { MESSAGE } from "constants/Message";

const RESULT = {
  VALID: "valid",
  INVALID: "invalid",
};

const STATUS = {
  SUCCESS: "Success",
};

class OnboardAdUnits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isPromptModalOpen: false,
      promptHeader: "",
      promptMessage: "",
      promptAction: null,

      queryNetworkId: null,
      queryYieldSetId: null,

      selectedNetwork: null,
      selectedYieldSet: null,

      onboardAdUnitsCsvString: "",
      extUnitIds: [],

      skipParentChildrenOnboardConflictionCheck: false,
      skipEligibleRequestVolumeCheck: false,
      skipEligibleInventoryFormatCheck: false,

      msg: [],
      errMsg: "",
      isSaving: false,

      selectedUnits: [],
      validateUnits: [],
      validateResult: null,
      flag: RESULT.VALID,
      onboardedUnits: [],
    };

    this.onSelectNetwork = this.onSelectNetwork.bind(this);
    this.onSelectYieldSet = this.onSelectYieldSet.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSkipChanged = this.handleSkipChanged.bind(this);
    this.handleSkipReqVolCheckChanged =
      this.handleSkipReqVolCheckChanged.bind(this);
    this.handleSkipInvFormatCheckChanged =
      this.handleSkipInvFormatCheckChanged.bind(this);
    this.handleSkipAll = this.handleSkipAll.bind(this);
    this.handleSelectUnitChanged = this.handleSelectUnitChanged.bind(this);

    this.handleValidateClicked = this.handleValidateClicked.bind(this);
    this.handleOnboard = this.handleOnboard.bind(this);
  }

  componentDidMount() {
    const { networkId, yieldSetId } = getQueryStringToObject(
      this.props.location.search
    );

    if (networkId) {
      this.setState({
        queryNetworkId: _.toInteger(networkId),
        isLoading: true,
      });
    }

    if (yieldSetId && networkId) {
      this.setState({
        queryYieldSetId: _.toInteger(yieldSetId),
      });
    }
  }

  onSelectNetwork(network) {
    if (!this.state.queryYieldSetId) {
      this.setState({ isLoading: false });
    }

    if (network) {
      this.setState({
        selectedNetwork: network,
        selectedYieldSet: null,
      });
    }
  }

  onSelectYieldSet(ys) {
    this.setState({ isLoading: false });

    if (ys) {
      this.setState({
        selectedYieldSet: ys,
      });
    }
  }

  onChange(e) {
    this.setState({
      onboardAdUnitsCsvString: e.target.value,
      extUnitIds: this.transformCsvToArray(e.target.value),
    });
  }

  handleSkipChanged() {
    this.setState({
      skipParentChildrenOnboardConflictionCheck:
        !this.state.skipParentChildrenOnboardConflictionCheck,
    });
  }

  handleSkipReqVolCheckChanged() {
    this.setState({
      skipEligibleRequestVolumeCheck:
        !this.state.skipEligibleRequestVolumeCheck,
    });
  }

  handleSkipInvFormatCheckChanged() {
    this.setState({
      skipEligibleInventoryFormatCheck:
        !this.state.skipEligibleInventoryFormatCheck,
    });
  }

  handleSkipAll() {
    this.setState({
      skipParentChildrenOnboardConflictionCheck: true,
      skipEligibleRequestVolumeCheck: true,
      skipEligibleInventoryFormatCheck: true,
    });
  }

  handleSelectUnitChanged(id) {
    const { selectedUnits } = this.state;

    if (_.indexOf(selectedUnits, id) === -1) {
      this.setState({
        selectedUnits: [...selectedUnits, id],
      });
    } else {
      this.setState({
        selectedUnits: _.filter(selectedUnits, (unit) => unit !== id),
      });
    }
  }

  async handleValidateClicked() {
    let saveSwal = Swal;

    try {
      const {
        selectedNetwork,
        selectedYieldSet,
        onboardAdUnitsCsvString,
        extUnitIds,
        skipParentChildrenOnboardConflictionCheck,
        skipEligibleInventoryFormatCheck,
        skipEligibleRequestVolumeCheck,
      } = this.state;

      if (!selectedNetwork) {
        throw "Please select a network";
      }

      if (!selectedYieldSet) {
        throw "Please select a Yield Set";
      }

      if (onboardAdUnitsCsvString === "") {
        throw "Please input ext unit ids that you would like to onboard";
      }

      const params = {
        networkId: selectedNetwork.networkId,
        networkCode: selectedNetwork.code,
        extUnitIds,
        skipParentChildrenOnboardConflictionCheck,
        skipEligibleInventoryFormatCheck,
        skipEligibleRequestVolumeCheck,
      };

      this.setState({
        onboardedUnits: [],
        selectedUnits: [],
        validateUnits: [],
        validateResult: null,
      });

      this._showSavingPopup(saveSwal, "Validating...");

      const result = await OnboardingAPI.validateOnboardGamAdUnits(params);

      const response = await fetch(result.detailUrl);
      if (!response.ok) {
        throw new Error(
          `Download Fail: ${response.status} ${response.statusText}`
        );
      }

      const text = await response.text();
      const validateUnits = this._parseCsvData(text);

      const unitsList = await OnboardingAPI.getOnboardAdUnitCandidates({
        networkId: selectedNetwork.networkId,
        adUnitIds: validateUnits.map((unit) => unit.id),
        reload: false,
      });

      validateUnits.forEach((item, index) => {
        const unit = _.find(unitsList, { extGamUnitId: item.id });
        if (unit) {
          validateUnits[index].name = unit.name;
          validateUnits[index].inventoryFormats = unit.inventoryFormats;
        }
      });

      this.setState({
        selectedUnits: validateUnits
          .filter((unit) => unit.status === STATUS.SUCCESS)
          .map((unit) => unit.id),
        validateUnits,
        validateResult: result,
        errMsg: "",
      });
    } catch (err) {
      this.setState({
        errMsg: err,
      });
    }

    setTimeout(() => {
      // window.location.reload(false);
      // this.props.history.goBack();
      this._hideSavingPopup(saveSwal);
    }, 500);
  }

  async handleOnboard(isPrompt = true) {
    const { selectedUnits, validateUnits } = this.state;

    if (isPrompt) {
      this.setState({
        msg: [],
        isPromptModalOpen: true,
        promptHeader: "Onboard the following ad units?",
        promptMessage: (
          <>
            {selectedUnits.map((id) => {
              const unit = _.find(validateUnits, { id });
              return (
                <div key={id} className="text-blue-600">
                  {unit.id} - {unit.name}
                </div>
              );
            })}
          </>
        ),
        promptAction: () => {
          this.handleOnboard(false);
        },
      });
      return;
    }

    try {
      const {
        selectedNetwork,
        selectedYieldSet,
        onboardAdUnitsCsvString,
        skipParentChildrenOnboardConflictionCheck,
        skipEligibleInventoryFormatCheck,
        skipEligibleRequestVolumeCheck,
      } = this.state;
      if (!selectedNetwork) {
        throw "Please select a network";
      }

      if (!selectedYieldSet) {
        throw "Please select a Yield Set";
      }

      if (onboardAdUnitsCsvString === "") {
        throw "Please input ext unit ids that you would like to onboard";
      }

      const params = {
        yieldSetId: selectedYieldSet.yieldSetId,
        extUnitIds: selectedUnits,
        skipParentChildrenOnboardConflictionCheck,
        skipEligibleInventoryFormatCheck,
        skipEligibleRequestVolumeCheck,
      };

      this.setState({
        isSaving: true,
      });

      const result = await OnboardingAPI.onboardGamAdUnits(params);

      this.setState({
        isSaving: false,
        errMsg: "",
        msg: [
          <>
            <div>Success Onboard: (Ad Unit Id - Name)</div>
            {result.gamAdUnits.map((unit) => {
              return (
                <div key={unit.adUnitId} className="text-blue-600">
                  {unit.adUnitId} - {unit.name}
                </div>
              );
            })}
          </>,
          MESSAGE.SUCCESS,
        ],
        onboardedUnits: result.gamAdUnits,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
        isSaving: false,
        onboardedUnits: null,
        msg: [err, MESSAGE.ERROR],
      });
    }
  }

  transformCsvToArray(csv) {
    let string = this._transformFn(csv);
    let items = _.split(string, ",");
    items = _.uniq(_.compact(items));
    return items;
  }

  _transformFn(content) {
    const charArr = content.split("");
    let validContent = [];
    for (let c of charArr) {
      const lastC = _.last(validContent);
      switch (c) {
        case "\r": {
          continue;
        }

        case "\n":
        case " ": {
          // Ignore if we see continuous seperator
          if (lastC === undefined || lastC === ",") {
            continue;
          }

          c = ",";
          validContent.push(c);
          break;
        }

        default: {
          if (c >= "0" && c <= "9") {
            validContent.push(c);
          } else if (lastC && lastC != ",") {
            validContent.push(",");
          }
          break;
        }
      }
    }

    return validContent.join("");
  }

  _parseCsvData(data) {
    const rows = data.split("\n");
    const headers = rows[0].split(",").map((t) => _.trim(t));
    const result = []; // { id, status }[]

    rows.slice(1).forEach((row) => {
      const values = row.split(",");

      values.forEach((value, index) => {
        if (value !== "") {
          result.push({
            id: value,
            status: headers[index],
            name: "",
            inventoryFormats: [],
          });
        }
      });
    });

    return result;
  }

  _showSavingPopup(saveSwal, title) {
    saveSwal.fire({
      title: title || "Saving...",
      timerProgressBar: true,
      showConfirmButton: true,
      didOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
    });
  }

  _hideSavingPopup(saveSwal) {
    saveSwal.close();
  }

  render() {
    const {
      isLoading,
      isSaving,
      isPromptModalOpen,
      promptAction,
      promptHeader,
      promptMessage,
      queryNetworkId,
      queryYieldSetId,
      selectedNetwork,
      selectedYieldSet,
      onboardAdUnitsCsvString,
      extUnitIds,
      msg,
      errMsg,
      validateResult,
      onboardedUnits,
      selectedUnits,
      skipParentChildrenOnboardConflictionCheck,
      skipEligibleInventoryFormatCheck,
      skipEligibleRequestVolumeCheck,
      flag,
      validateUnits,
    } = this.state;

    const validUnits = validateUnits.filter(
      (unit) => unit.status === STATUS.SUCCESS
    );

    const invalidUnits = validateUnits.filter(
      (unit) => unit.status !== STATUS.SUCCESS
    );

    const showUnits = flag === RESULT.VALID ? validUnits : invalidUnits;

    return (
      <div>
        {isLoading && (
          <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-gray-800 opacity-25">
            <LoadingUI iconOnly={true}></LoadingUI>
          </div>
        )}

        <div className="bg-white px-12">
          <h1 className="pb-4 pt-8 text-4xl font-extrabold text-gray-900">
            Onboard Ad Units
          </h1>
        </div>

        <div className="min-h-screen bg-gray-200 px-12 py-8">
          <div className="flex gap-6">
            <div className="w-1/2">
              <div className="mb-4">
                1. Select Network:
                <div>
                  <NetworksSelector
                    selectedId={queryNetworkId}
                    selectedNetwork={selectedNetwork}
                    handleOnChange={this.onSelectNetwork}
                    handleNoData={() => this.setState({ isLoading: false })}
                  ></NetworksSelector>
                </div>
              </div>

              <div className="mb-4">
                2. Select Yield Set:
                <div>
                  {selectedNetwork ? (
                    <YieldSetsSelector
                      defaultValue={queryYieldSetId}
                      selectedNetwork={selectedNetwork}
                      selectedYieldSet={selectedYieldSet}
                      networkId={selectedNetwork.networkId}
                      handleOnChange={this.onSelectYieldSet}
                      handleNoData={() => this.setState({ isLoading: false })}
                    ></YieldSetsSelector>
                  ) : (
                    <div className="text-sm text-gray-700">
                      Please select a network first
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-4">
                3. Ext Unit Ids (Use <b>comma</b>, <b>whitespace</b> or{" "}
                <b>line break</b> to separate ids){" "}
                <div className="text-sm text-gray-600">ex. 423,235,246</div>
                <div>
                  <textarea
                    className="border rounded w-full border-gray-400 p-2"
                    value={onboardAdUnitsCsvString}
                    onChange={this.onChange}
                    rows={5}
                  ></textarea>
                  <div className="text-sm leading-none text-gray-700">
                    {extUnitIds.length} units
                  </div>
                </div>
              </div>

              <div className="rounded mb-4 w-full bg-blue-200 p-4">
                <div className="mb-1 flex flex-col gap-1">
                  <label className="flex cursor-pointer items-center">
                    <Checkbox
                      isChecked={skipParentChildrenOnboardConflictionCheck}
                      onChange={this.handleSkipChanged}
                    ></Checkbox>
                    <span className="ml-1 align-middle text-sm font-semibold text-gray-700 hover:text-gray-700">
                      Skip parent children onboard confliction check
                    </span>
                  </label>
                  <label className="flex cursor-pointer items-center">
                    <Checkbox
                      isChecked={skipEligibleRequestVolumeCheck}
                      onChange={this.handleSkipReqVolCheckChanged}
                    ></Checkbox>
                    <span className="ml-1 align-middle text-sm font-semibold text-gray-700 hover:text-gray-700">
                      Skip eligible request volume check
                    </span>
                  </label>
                  <label className="flex cursor-pointer items-center">
                    <Checkbox
                      isChecked={skipEligibleInventoryFormatCheck}
                      onChange={this.handleSkipInvFormatCheckChanged}
                    ></Checkbox>
                    <span className="ml-1 align-middle text-sm font-semibold text-gray-700 hover:text-gray-700">
                      Skip eligible inventory format check
                    </span>
                  </label>
                </div>

                <button
                  type="button"
                  className="rounded bg-gray-500 px-4 py-1 text-sm font-semibold text-white hover:bg-gray-600"
                  onClick={this.handleSkipAll}
                >
                  Skip all checks
                </button>
              </div>

              <div className="flex w-full justify-end">
                <button
                  type="button"
                  className="rounded bg-teal-500 px-6 py-2 font-semibold text-white hover:bg-teal-600"
                  onClick={this.handleValidateClicked}
                >
                  Validate and review
                </button>
              </div>
            </div>

            <div className="w-1/3">
              {onboardedUnits.length === 0 ? (
                <>
                  <div
                    className={`border ${
                      validateResult && "shadow border-blue-400"
                    } rounded mb-4 p-4`}
                    style={{ height: "24.25rem" }}
                  >
                    <div className="mb-2 font-semibold text-gray-700">
                      Validate Results:
                    </div>
                    {validateResult ? (
                      <>
                        <div className="relative flex gap-2">
                          <div
                            className={`border-l border-r border-t rounded cursor-pointer rounded-b-none px-2 py-1 text-sm ${
                              flag === RESULT.VALID
                                ? "border-gray-400 font-semibold text-blue-600"
                                : "border-gray-300"
                            }`}
                            onClick={() =>
                              this.setState({
                                flag: RESULT.VALID,
                              })
                            }
                          >
                            Valid Ad Units (<span>{validUnits.length}</span>)
                          </div>
                          <div
                            className={`border-l border-r border-t rounded cursor-pointer rounded-b-none px-2 py-1 text-sm ${
                              flag === RESULT.INVALID
                                ? "border-gray-400 font-semibold text-blue-600"
                                : "border-gray-300"
                            }`}
                            onClick={() =>
                              this.setState({
                                flag: RESULT.INVALID,
                              })
                            }
                          >
                            Invalid Ad Units (<span>{invalidUnits.length}</span>
                            )
                          </div>
                          <div
                            className="absolute right-0 text-sm"
                            style={{ top: "-1rem" }}
                          >
                            <ClickToCopyButton
                              copyText={showUnits
                                .map((unit) => unit.id)
                                .join(",")}
                              buttonText="Copy Ext Unit Ids"
                            ></ClickToCopyButton>
                          </div>
                        </div>
                        <div
                          className="border divide-y flex w-full flex-col overflow-y-auto border-gray-400"
                          style={{ height: "18rem" }}
                        >
                          {showUnits.map((unit) => (
                            <div
                              className="flex items-center justify-between bg-white p-2"
                              key={unit.id}
                            >
                              <label className="flex cursor-pointer items-center gap-4">
                                <Checkbox
                                  isChecked={
                                    _.indexOf(selectedUnits, unit.id) !== -1
                                  }
                                  onChange={() =>
                                    this.handleSelectUnitChanged(unit.id)
                                  }
                                  isDisabled={unit.status !== STATUS.SUCCESS}
                                ></Checkbox>
                                <div className="flex flex-col">
                                  <div className="text-sm text-gray-600">
                                    {unit.id}
                                  </div>
                                  <div className="mb-1">
                                    {unit.name || "--"}
                                  </div>
                                  {unit.status !== STATUS.SUCCESS ? (
                                    <div className="w-48 bg-orange-200 px-2 py-1 text-center text-xs font-semibold text-orange-600">
                                      {_.startCase(unit.status)}
                                    </div>
                                  ) : (
                                    <div className="flex flex-wrap gap-2">
                                      {unit.inventoryFormats.map((format) => (
                                        <div
                                          className="bg-blue-200 px-2 py-1 text-center text-xs font-semibold text-blue-600"
                                          key={format}
                                        >
                                          {_.startCase(format)}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </label>
                              <div className="opacity-50 hover:opacity-100">
                                <ClickToCopyButton
                                  copyText={unit.id}
                                  tooltipMessage="Copy Ext Unit Id"
                                ></ClickToCopyButton>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <div className="text-sm text-gray-600">
                        Click the validate button to see validate results
                      </div>
                    )}
                  </div>

                  <div className="mb-4 flex justify-end">
                    <button
                      type="button"
                      className={`rounded px-6 py-2 font-semibold text-white ${
                        selectedUnits.length === 0
                          ? "cursor-not-allowed bg-blue-300"
                          : "cursor-pointer bg-blue-500 hover:bg-blue-600"
                      }`}
                      disabled={
                        selectedUnits.length === 0 ||
                        onboardedUnits.length !== 0
                      }
                      onClick={this.handleOnboard}
                    >
                      Onboard selected ad units (
                      <span>{selectedUnits.length}</span>)
                    </button>
                  </div>
                </>
              ) : (
                <div
                  className={`border ${
                    onboardedUnits && "shadow border-blue-400"
                  } rounded mb-4 p-4`}
                >
                  <div className="mb-2 font-semibold text-gray-700">
                    Onboarded Ad Units List: ({onboardedUnits.length} units)
                  </div>
                  {onboardedUnits ? (
                    <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                      <ul>
                        {onboardedUnits.map((unit) => {
                          return (
                            <li key={unit.adUnitId} className="text-gray-900">
                              {unit.adUnitId} - {unit.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    <div className="text-sm text-gray-600">
                      Shows the list of units that were onboarded
                    </div>
                  )}
                </div>
              )}

              <div>
                {errMsg !== "" && (
                  <div className="rounded mb-4 bg-red-100 p-4 font-semibold text-red-700">
                    {errMsg}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {isPromptModalOpen && (
          <PromptModal
            msg={msg}
            isLoading={isSaving}
            isOpenModal={isPromptModalOpen}
            handleConfirm={promptAction}
            handleCancel={() => this.setState({ isPromptModalOpen: false })}
            header={promptHeader}
          >
            {promptMessage}
          </PromptModal>
        )}
      </div>
    );
  }
}

export default OnboardAdUnits;

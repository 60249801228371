import _ from "lodash";
import { api, fetchRespJson, postData } from "./base";

export default {
  getGamAccounts,
  createGamAccount,
  authorizeGamAccount,
  syncGamAccount,
};

async function getGamAccounts({ pubId }) {
  let queryString = `?pubId=${pubId}`;
  const apiUrl = `${api}/gam-account/list${queryString}`;
  return await fetchRespJson(apiUrl);
}

// params: { pubId, name, authorizationCode }
async function createGamAccount(params) {
  const apiUrl = `${api}/gam-account/create`;
  return await postData(apiUrl, params);
}

// params: { accountId, authorizationCode }
async function authorizeGamAccount(params) {
  const apiUrl = `${api}/gam-account/${params.accountId}/authorize`;
  return await postData(apiUrl, params);
}

// params: { accountId }
async function syncGamAccount(params) {
  const apiUrl = `${api}/gam-account/${params.accountId}/sync`;
  return await postData(apiUrl, params);
}

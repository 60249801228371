import React from "react";

import { MESSAGE } from "constants/Message";

import {
  buttonModalConfirmDisable,
  buttonModalConfirmClass,
  buttonModalCancelDisable,
  buttonModalCancelClass,
} from "helpers/StyleClass.js";

class ModalFooter extends React.Component {
  render() {
    const {
      msg,
      disableCancel = false,
      disableConfirm = false,
      cancelText = "Cancel",
      confirmText = "Proceed",
      closeText = "Close",
    } = this.props;
    return (
      <>
        {msg && (
          <div
            className={`mt-2 ${
              msg[1] === "error" ? "text-red-500" : "text-green-500"
            }`}
          >
            {msg[0]}
          </div>
        )}
        <div className="mt-2 flex items-center justify-end gap-2">
          {msg && msg[1] === MESSAGE.SUCCESS ? (
            <>
              <button
                type="button"
                className={buttonModalConfirmClass}
                onClick={this.props.onClose}
              >
                {closeText}
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className={
                  disableCancel
                    ? buttonModalCancelDisable
                    : buttonModalCancelClass
                }
                disabled={disableCancel}
                onClick={this.props.onCancel}
              >
                {cancelText}
              </button>

              <button
                type="button"
                className={
                  disableConfirm
                    ? buttonModalConfirmDisable
                    : buttonModalConfirmClass
                }
                disabled={disableConfirm}
                onClick={this.props.onConfirm}
              >
                {confirmText}
              </button>
            </>
          )}
        </div>
      </>
    );
  }
}

export default ModalFooter;

import React, { useState } from "react";

import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";
import OpenLinkInNewWindowWrapper from "components/common/OpenLinkInNewWindowWrapper";
import UprTargetingPopover from "components/ops-mgmt/account/custom-upr/UprTargetingPopover";
import HighLightRowWrapper from "components/common/HighLightRowWrapper";

import UprPriceSection from "../gam-upr/UprPriceSection";

function FirstLookRulesTable({
  items,
  units,
  virtualPlacements,
  networkId,
  networkInfo,
  currency,
}) {
  const [popupId, setPopupId] = useState("");
  const networkCode = networkInfo.code;

  return (
    <table className="table w-full text-sm">
      <thead className="border-b bg-gray-200 text-xs text-blue-800">
        <tr>
          <th className="border px-2 py-1 text-center ">External ID</th>
          <th className="border px-2 py-1 text-left ">Name</th>
          <th className="border px-2 py-1 text-center ">Priority</th>
          <th className="border px-2 py-1 text-center ">Status</th>
          <th className="border px-2 py-1 text-center ">Type</th>
          <th className="border px-2 py-1 text-right ">Price Section</th>
          <th
            className="border px-2 py-1 text-left"
            style={{ paddingRight: "124px" }}
          >
            Targeting Section
          </th>
        </tr>
      </thead>
      <tbody className="bg-white  text-gray-900">
        {items.map((item, i) => {
          const gamLink = `https://admanager.google.com/${networkCode}#inventory/first_look/detail/first_look_id=${item.externalId}`;

          return (
            <HighLightRowWrapper selected={popupId === item.externalId} key={i}>
              <td className="border px-2 py-1 text-right">
                <div className="flex items-center justify-between">
                  <span
                    className={`font-mono ${
                      popupId === item.externalId ? "font-semibold" : ""
                    }`}
                  >
                    {item.externalId}
                  </span>

                  <div className="flex items-center text-blue-500">
                    <OpenLinkInNewWindowWrapper
                      href={gamLink}
                      tooltipText="Open in GAM"
                    ></OpenLinkInNewWindowWrapper>
                    <ClickToCopyWrapper
                      copyText={gamLink}
                      tooltipMessage="Click to copy GAM link"
                    ></ClickToCopyWrapper>
                  </div>
                </div>
              </td>
              <td
                className={`border px-2 py-1 text-left ${
                  popupId === item.externalId ? "font-semibold" : ""
                }`}
              >
                {item.name}
              </td>
              <td className="border px-2 py-1 text-center">{item.priority}</td>
              <td className="border px-2 py-1 text-center">{item.status}</td>
              <td className="border px-2 py-1 text-center">{item.type}</td>
              <td className="border px-2 py-1 text-right">
                <UprPriceSection
                  item={item.priceSection}
                  currency={item.priceSection.currency}
                  networkId={networkId}
                ></UprPriceSection>
              </td>
              <td className="border px-2 py-1 text-left font-mono">
                <div>
                  {item.targetingSection && (
                    <UprTargetingPopover
                      id={item.externalId}
                      name={item.name}
                      networkId={networkId}
                      units={units}
                      virtualPlacements={virtualPlacements}
                      targetingSection={item.targetingSection}
                      triggerElement={
                        <div className="inline-block cursor-pointer  text-blue-600 hover:text-blue-700 hover:underline">
                          {Object.keys(item.targetingSection).join(", ")}
                        </div>
                      }
                      onOpen={() => setPopupId(item.externalId)}
                      onClose={() => setPopupId("")}
                    ></UprTargetingPopover>
                  )}
                </div>
              </td>
            </HighLightRowWrapper>
          );
        })}
      </tbody>
    </table>
  );
}

export default FirstLookRulesTable;

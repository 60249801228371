import _ from "lodash";

const GROUP_CATEGORY = {
  GENERAL: "General",
  UPR: "UPR",
  CODE_SNIPPET: "Code Snippet",
  PREBID: "Prebid General",
  PREBID_WEB: "Prebid Web",
  PREBID_MOBILE: "Prebid Mobile",
  DISPATCHER_TAGGING: "Dispatcher Tagging",
  REPORTING: "Reporting",
  REQUEST_SIGNAL_AUGMENTATION: "Request Signal Augmentation",
  // requestSignalAugmentation
};

const baseGroupings = [
  {
    groupName: GROUP_CATEGORY.GENERAL,
    keys: [
      "enableCSTPreview",
      "enableOptABTesting",
      "defaultUnitStatus",
      "ignoredWebPropertyCodes",
      "preservedCreativeIds",
      "preservedLineItemIds",
      "preservedOrderIds",
      "scriptImplementationVersion",
      "skipBMOrderValidation",
      "usePlacementForShareResources",
    ],
  },
  {
    groupName: GROUP_CATEGORY.UPR,
    keys: [
      "enableBackgroundUPRSync",
      "enableBackgroundUPRInverseTargetingSync",
      "cstUPRInverseTargetingScope",
      "publisherUPRProtectionList",
      "preservedUPRIds",
    ],
  },
  {
    groupName: GROUP_CATEGORY.CODE_SNIPPET,
    keys: ["uiEnhancement", "virtualEventRemoval"],
  },
  {
    groupName: GROUP_CATEGORY.PREBID,
    keys: [
      "enableCSTDynamicPrebidABTest",
      "enableCSTDynamicPrebid",
      "enableCSTPrebidProtection",
      "disableCSTDynamicPrebidForCatchall",
      "pbjsNamespaces",

      "integrationType",
      "priceFormatStyle",
      "sendAllBidsEnabled",
    ],
  },
  {
    groupName: GROUP_CATEGORY.PREBID_WEB,
    keys: ["enableUPA", "UPACurrency", "integrationTypes"],
  },
  {
    groupName: GROUP_CATEGORY.PREBID_MOBILE,
    keys: ["enableUPAMobile", "UPACurrencyMobile", "integrationTypesMobile"],
  },
  {
    groupName: GROUP_CATEGORY.DISPATCHER_TAGGING,
    keys: ["customDimensionEnabled", "benchmarkValueEnabled"],
  },
  {
    groupName: GROUP_CATEGORY.REPORTING,
    keys: [
      "cstCountryWhitelist",
      "enableCSTNopbReport",
      "cstNonbillableSetting",
    ],
  },
  {
    groupName: GROUP_CATEGORY.REQUEST_SIGNAL_AUGMENTATION,
    keys: ["position", "userBehavior", "unitPath", "requestPattern"],
  },

  // position: enabled, reportableType
  // userBehavior: enabled, reportableType
  // unitPath: enabled, reportableType
  // requestPattern: enabled, reportableType
];

export function getFeaturesSchema(defaultConfig) {
  // console.log(defaultConfig);
  // add default values
  const schemas = _getSchemas();
  _.forEach(schemas, (s) => {
    if (s.prefix === "") {
      s.defaultValue = _.get(defaultConfig, [s.key], "-");
    } else {
      s.defaultValue = _.get(defaultConfig, [s.prefix, s.key], "-");
    }
  });
  // group to sections
  const sections = _getSections();
  const groupedSchemas = _.groupBy(schemas, "section");
  const configSchema = _.mapValues(sections, (s) => {
    let gs = groupedSchemas[s.sectionKey];
    if (s.sectionKey === "base") {
      s.configSchemas = _.reduce(
        baseGroupings,
        (result, group) => {
          let { groupName, keys } = group;
          _.forEach(gs, (keyConfig) => {
            if (_.indexOf(keys, keyConfig.key) !== -1) {
              keyConfig.groupCategory = groupName;
              result.push(keyConfig);
            }
          });
          return result;
        },
        []
      );
    } else {
      s.configSchemas = gs;
    }

    return s;
  });

  return configSchema;
}

export function getSchemaByKey(configKey) {
  const schemaMap = _schemaMap();
  return _.get(schemaMap, configKey);
}

function _getSections() {
  return {
    base: {
      sectionKey: "base",
      sectionTitle: "Base",
      sectionConfigKey: "",
      indexOrder: 1,
    },
  };
}

function _schemaMap() {
  const schemas = _getSchemas();
  return _.mapKeys(schemas, "key");
}

export function getAllConfigKeys() {
  const schemas = _getSchemas();
  return _.map(schemas, (s) => {
    return `${s.section}.${s.key}`;
  });
}

function _getSchemas() {
  return [
    {
      key: "enableCSTPreview",
      description: "Whether or not to enable CST preview.",
      valueType: "boolean", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "enableOptABTesting",
      description:
        "Create key-values for optimization 'X' group to enable real-time A/B testing capability for autopilot core internally",
      valueType: "boolean", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "defaultUnitStatus",
      title: "Default Unit Status",
      unit: "unit status",
      description: `To control the default status for newly onboarded units.
        - RUNNING: status = 0, mode = 0
        - PAUSE: status = 1, mode = 0`,
      format: "enum", // how the ui should present this value
      section: "base",
      valueType: "string_enum",
      enum: {
        RUNNING: "status = 0, mode = 0",
        PAUSE: "status = 1, mode = 0",
      },
      prefix: "",
    },
    {
      key: "skipBMOrderValidation",
      description:
        "Puppeteer will skip all operations related to benchmark orders. Therefore, we should setup inverse targeting ourself. ",
      valueType: "boolean", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "enableCSTDynamicPrebidABTest",
      description: `We want to evaluate the effectiveness of prebid dynamic uprs.
      So, we set the same floor price for d9 and d10 for all upr segments and
      we only apply this feature to d10. Then, we can compare rpm between d9 and
      d10 to know how good current prebid bucket setting is!`,
      valueType: "boolean", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "enableCSTDynamicPrebid",
      description: `Turn on/off dynamic prebid floor price optimization feature.`,
      valueType: "boolean", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "enableCSTPrebidProtection",
      description: `Turn on/off prebid protection feature.`,
      valueType: "boolean", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "enableUPA",
      description: `Turn on/off unified prebid analytic feature.`,
      valueType: "boolean", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "UPACurrency",
      description: `Currency used in UPA.`,
      valueType: "currency", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "integrationTypes",
      description: "",
      valueType: "integration_type",
      section: "base",
      prefix: "prebid",
    },
    {
      key: "enableUPAMobile",
      description: `Turn on/off unified prebid analytic feature for mobile.`,
      valueType: "boolean", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "UPACurrencyMobile",
      description: `Currency used in UPA for mobile.`,
      valueType: "currency", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "integrationTypesMobile",
      description: "",
      valueType: "integration_type",
      section: "base",
      prefix: "prebid",
    },
    {
      key: "disableCSTDynamicPrebidForCatchall",
      description: `Turn on/off dynamic prebid floor price optimization for catchall layer. Make CST dynamic prebid feature only effective for the client-side request only.`,
      valueType: "boolean", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "enablePBHook",
      description: "Set to true to apply the prebid hook",
      section: "base",
      valueType: "boolean", // json schema type
      prefix: "",
    },
    {
      key: "pbPriceGroups",
      unit: "price groups",
      description: `Config to control the price cluster when detecting price collision.`,
      format: "array", // how the ui should present this value
      section: "base",
      valueType: "price_groups",

      sample: `[{ "inclusiveMin": 0, "exclusiveMax": 8, "delta": 0.01 }, { "inclusiveMin": 8, "exclusiveMax": 13, "delta": 0.5 }, { "inclusiveMin": 13, "exclusiveMax": 20, "delta": 1 }]`,
      prefix: "",
    },
    {
      key: "pbMaxPriceCollisionCount",
      unit: "",
      description: `The maximum number of slots with the same price that can be allowed in a refresh chunk. If this count is exceeded, a new chunk must be applied.`,
      format: "number", // how the ui should present this value
      section: "base",
      valueType: "integer",
      minimum: 0, // inclusive,
      prefix: "",
    },
    {
      key: "usePlacementForShareResources",
      description: `Puppeteer will default use ad unit level targeting for shared resources (smart / ads / ads). When we turn on the flag, it will generate intowow placements dynamically that include all onboarded units then use placement level targeting for shared resources.
      This mechanism is used to bypass maximum 800 targeting criteria for a GAM line item`,
      valueType: "boolean", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "ignoredWebPropertyCodes",
      unit: "web property codes",
      description: `When puppeteer is computing whether it should patch benchmark lineitems to meet our inverse targeting requirements, it will skip this line item if 
          1. It is NOT an AdExchange lineitem 
          2. Its web property is in the ignore list 
        (If a line item is ignored, we will not add inverse targeting!)`,
      section: "base",
      format: "array", // how the ui should present this value
      valueType: "array_string",
      prefix: "",
    },
    {
      key: "pbjsNamespaces",
      unit: "namespaces",
      description: `Implement prebid js library hook for given namespaces. (Default = ["pbjs"])
        It means we will prepare pbjs hook => window[pbNamespace] in our 
        dispatcher/smart iframe if necessary.`,
      section: "base",
      format: "array", // how the ui should present this value
      valueType: "array_string",
      prefix: "",
    },
    {
      key: "preservedCreativeIds",
      unit: "creative IDs",
      description: `Preserved creative IDs.`,
      format: "array", // how the ui should present this value
      section: "base",
      valueType: "array_integer",
      prefix: "",
    },
    {
      key: "preservedLineItemIds",
      unit: "line item IDs",
      description: `Preserved line item IDs.`,
      format: "array", // how the ui should present this value
      section: "base",
      valueType: "array_integer",
      prefix: "",
    },
    {
      key: "preservedOrderIds",
      unit: "Order IDs",
      description: `Preserved order IDs.`,
      format: "array", // how the ui should present this value
      section: "base",
      valueType: "array_integer",
      prefix: "",
    },
    {
      key: "scriptImplementationVersion",
      title: "Script implementation version",
      unit: "version",
      description: `Script Implementation Version
        - v1: Sync script loading
        - v2: Async script loading`,
      format: "enum", // how the ui should present this value
      section: "base",
      valueType: "string_enum",
      enum: {
        v1: "v1: Sync script loading",
        v2: "v2: Async script loading",
      },
      prefix: "",
    },

    // UPR

    {
      key: "enableBackgroundUPRSync",
      description: "Whether or not to enable background UPR sync.",
      valueType: "boolean", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "enableBackgroundUPRInverseTargetingSync",
      description: `When doing GAM UPR syncing, should we apply sync inverse targetings for Publisher’s UPR.`,
      valueType: "boolean", // json schema type
      section: "base",
      prefix: "",
    },
    {
      key: "cstUPRInverseTargetingScope",
      title: "CST UPR Inverse Targeting Scope",
      unit: "scope",
      description: `
        - PROTECT_CATCHALL : Make sure our uprs will not be affected by publisher’s uprs in catchall traffic.
        - PROTECT_MANAGED: Make sure our uprs will not be affected by publisher’s uprs if billingKey = True.
        - PROTECT_OPTIMIZED: Make sure our uprs will not be affected by publisher’s uprs for “active optimized” inventories.
        - PROTECT_DETECTOR:  Make sure our detector uprs will not be affected by publisher’s uprs for “active optimized” inventories.
        - PROTECT_CST_SPECIFIED: It means we will compute inverse targeting value of in2w_key16 based on upr_protection_mode of each enabled registry.`,
      format: "enum", // how the ui should present this value
      section: "base",
      valueType: "string_enum",
      enum: {
        PROTECT_CATCHALL: "PROTECT_CATCHALL",
        PROTECT_MANAGED: "PROTECT_MANAGED",
        PROTECT_OPTIMIZED: "PROTECT_OPTIMIZED",
        PROTECT_DETECTOR: "PROTECT_DETECTOR",
        PROTECT_CST_SPECIFIED: "PROTECT_CST_SPECIFIED",
      },
      prefix: "",
    },
    {
      key: "publisherUPRProtectionList",
      title: "Publisher UPR Protection List",
      unit: "UPR IDs",
      description: `When doing GAM UPR syncing, publisher’s uprs in this protection list will apply our inverse targetings. (Caution: Use this feature carefully)`,
      format: "array", // how the ui should present this value
      section: "base",
      valueType: "array_integer",
      prefix: "",
    },
    {
      key: "preservedUPRIds",
      title: "Preserved UPR IDs",
      unit: "UPR IDs",
      description: `Preserved UPR IDs.`,
      format: "array", // how the ui should present this value
      section: "base",
      valueType: "array_integer",
      prefix: "",
    },

    // codesnippet
    {
      key: "uiEnhancement",
      description: "Whether or not to enable UI enhancement.",
      section: "base",
      valueType: "boolean", // json schema type
      prefix: "codesnippet",
    },
    {
      key: "virtualEventRemoval",
      description: "Whether or not to enable virtual event removal.",
      section: "base",
      valueType: "boolean", // json schema type
      prefix: "codesnippet",
    },
    // prebid
    {
      key: "integrationType",
      description:
        "To indicate how publishers integrate Prebid on the client side",
      section: "base",
      prefix: "prebid",
      unit: "integration type",
      format: "enum", // how the ui should present this value
      valueType: "string_enum",
      enum: {
        hbpb: "prebid.js integration",
        openwrap: "PubMatic’s OpenWrap solution for header bidding",
        yieldlove: "Yieldlove solution",
        chegg: "chegg solution. Use custom key: bd",
      },
    },
    {
      key: "priceFormatStyle",
      description: "To indicate that the price format used by prebid",
      section: "base",
      prefix: "prebid",
      unit: "price format",
      format: "enum", // how the ui should present this value
      valueType: "string_enum",
      enum: {
        hbpb: "common prebid.js style. E.g. 0.01 for $0.01",
        kijiji: "Kijiji’s style. E.g. 100001 for $0.01, 101001 for $10.05",
        chegg: "chegg’s style. Pattern: (base64decode(value) - 95) / 100",
      },
    },
    {
      key: "sendAllBidsEnabled",
      description: "SendAllBids behavior enabled or not",
      section: "base",
      valueType: "boolean", // json schema type
      prefix: "prebid",
    },

    // dispatcherTagging
    {
      key: "customDimensionEnabled",
      description: "Whether or not to enable custom dimension.",
      section: "base",
      valueType: "boolean", // json schema type
      prefix: "dispatcherTagging",
    },
    {
      key: "benchmarkValueEnabled",
      description:
        "Whether benchmark value is required for in2w_key5. Whether benchmark_request value is required for in2w_key5.",
      section: "base",
      valueType: "boolean", // json schema type
      prefix: "dispatcherTagging",
    },
    // reporting
    {
      key: "cstCountryWhitelist",
      unit: "countries",
      description: `List of gam country criteria codes. Used for CST reports with country dimension as the country filter.`,
      format: "array", // how the ui should present this value
      section: "base",
      // valueType: "array_string",
      valueType: "countries",
      prefix: "reporting",
    },
    {
      key: "enableCSTNopbReport",
      description: `Setup GAM inventory channels and start preparing cst_nopb reports.`,
      section: "base",
      valueType: "boolean", // json schema type
      prefix: "reporting",
    },
    {
      key: "cstNonbillableSetting",
      description: `To exclude specific demand for billing`,
      format: "cstNonbillableObject", // how the ui should present this value
      section: "base",
      valueType: "cst_nonbillable_object",
      prefix: "reporting",
    },
    // requestSignalAugmentation
    {
      key: "position",
      description: `Turn on to create GAM custom key and values for position signal (in2w_key27)`,
      format: "rsaObject", // how the ui should present this value
      section: "base",
      valueType: "rsa_object",
      prefix: "requestSignalAugmentation",
    },
    {
      key: "userBehavior",
      description: `Turn on to create GAM custom key and values for userBehavior signal (in2w_key33)`,
      format: "rsaObject", // how the ui should present this value
      section: "base",
      valueType: "rsa_object",
      prefix: "requestSignalAugmentation",
    },
    {
      key: "unitPath",
      description: `Turn on to create GAM custom key and values for unitPath signal (in2w_key23)`,
      format: "rsaObject", // how the ui should present this value
      section: "base",
      valueType: "rsa_object",
      prefix: "requestSignalAugmentation",
    },
    {
      key: "requestPattern",
      description: `Turn on to create GAM custom key and values for requestPattern signal (in2w_key21)`,
      format: "rsaObject", // how the ui should present this value
      section: "base",
      valueType: "rsa_object",
      prefix: "requestSignalAugmentation",
    },
    // position: enabled, reportableType
    // userBehavior: enabled, reportableType
    // unitPath: enabled, reportableType
    // requestPattern: enabled, reportableType
  ];
}

import { LeafUnitItemComponent } from "./LeafUnitItemComponent";
import { ParentUnitItemComponent } from "./ParentUnitItemComponent";

/**
 * Leaf Unit:
 * 1. Selectable
 * 2. Not Selectable
 *    - Not compatible
 *    - Onboarded
 *    - Onboarded and not compatible
 *
 * Parent Unit:
 * 1. Selectable
 * 2. Not Selectable
 *    - Not compatible
 *    - Onboarded
 *    - Onboarded and not compatible
 */
function OnboardItemComponent(props) {
  const { item } = props;
  // console.log(props.layerNum, props.item);

  if (item.hasChildren) {
    return ParentUnitItemComponent(props);
  } else {
    return LeafUnitItemComponent(props);
  }
}

export default OnboardItemComponent;

import React from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { NetworkAPI, UnitAPI } from "apis";
import { Parser } from "expr-eval";
import NetworkPageWrapper from "components/diagnoser/NetworkPageWrapper";
import YieldSetsSelector from "components/ops-mgmt/account/YieldSetsSelector";
import UnitStatus from "components/common/UnitStatus";
import LoadingUI from "components/common/LoadingUI";
import UnitMode from "components/common/UnitMode";
import ItemsFilter from "components/ops-mgmt/common/ItemsFilter";
import SelectedStickyFooter from "components/common/SelectedStickyFooter";
import ModalWrapper from "components/common/ModalWrapper";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";

import { fixExpression, parseTagsToObject } from "./DataHelper";

import { FiInfo } from "react-icons/fi";

const YIELD_SET = {
  ALL: "ALL",
};

const UNIT_STATUS = {
  ALL: "ALL",
  RUNNING: "RUNNING",
  PAUSED: "PAUSED",
};

const UNIT_MODE = {
  ALL: "ALL",
  INITIAL: "INITIAL",
  NORMAL: "NORMAL",
  WATCH_LIST: "WATCH_LIST",
  SUSPENDED: "SUSPENDED",
};

const SEARCH_KEYS = {
  ALL: "ALL",
  UNIT_ID: "adUnitId",
  EXT_UNIT_ID: "extGamAdUnitId",
  NAME: "name",
  PARENT_PATH: "parentPath",
  SITE_NETWORK_CODE: "siteNetworkCode",
  TAGS: "tags",
};

const codeStyle =
  "font-mono text-sm font-semibold text-gray-600 rounded p-1 bg-gray-200";

class UnitsSearchViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,
      expErrMsg: null,

      networkId: null,
      networkInfo: null,
      selectedYieldSet: null,

      filter: {
        ys: YIELD_SET.ALL,
        mode: UNIT_MODE.ALL,
        status: UNIT_STATUS.ALL,
      },

      ysData: null,

      units: null,
    };

    this.unitsTableRef = React.createRef();

    this._getQueryParams = this._getQueryParams.bind(this);
    this.searchUnits = this.searchUnits.bind(this);
    this.handleYsChanged = this.handleYsChanged.bind(this);
    this.handleModeChanged = this.handleModeChanged.bind(this);
    this.handleStatusChanged = this.handleStatusChanged.bind(this);
    this.handleLoadYsData = this.handleLoadYsData.bind(this);
  }

  async componentDidMount() {
    const networkId = _.get(this.props, "match.params.networkId");
    this.setState({ networkId, isLoading: true });

    if (networkId) {
      try {
        const networkInfo = await NetworkAPI.getNetworkInfo({ networkId });
        this.setState({ networkInfo, errMsg: null });
      } catch (err) {
        this.setState({
          errMsg: err,
          networkInfo: null,
        });
      }
      this.setState({ isLoading: false });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Route changed!
      // console.log(
      //   "route changed",
      //   prevProps.location.pathname,
      //   this.props.location.pathname
      // );
    }
  }

  handleLoadYsData(items) {
    this.setState({ ysData: items });
  }

  handleModeChanged(newMode) {
    let filter = { ...this.state.filter };
    filter.mode = newMode;

    this.setState({ filter });
  }

  handleStatusChanged(newStatus) {
    let filter = { ...this.state.filter };
    filter.status = newStatus;

    this.setState({ filter });
  }

  handleYsChanged(newYs) {
    let filter = { ...this.state.filter };
    filter.ys = newYs;

    this.setState({
      filter,
      selectedYieldSet: newYs === YIELD_SET.ALL ? null : newYs,
    });
  }

  _getQueryParams() {
    const { filter } = this.state;

    let queryParams = {};
    if (filter.ys !== YIELD_SET.ALL) {
      queryParams.yieldSetId = filter.ys.yieldSetId;
    }

    if (filter.mode !== UNIT_MODE.ALL) {
      queryParams.mode = filter.mode;
    }

    if (filter.status !== UNIT_STATUS.ALL) {
      queryParams.status = filter.status;
    }

    return queryParams;
  }

  async searchUnits() {
    this.setState({ isLoading: true });
    try {
      const networkId = this.state.networkId;
      const queryParams = this._getQueryParams();
      // new api
      const r = await UnitAPI.searchUnits({ networkId, ...queryParams });
      this.setState({ units: r.units });

      this.unitsTableRef.current.handleClearAll();
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
      });
    }
    this.setState({ isLoading: false });
  }

  render() {
    const {
      isLoading,
      errMsg,
      networkId,
      networkInfo,

      filter,
      selectedYieldSet,
      isFilterDisabled = false,
      units,

      ysData,
    } = this.state;

    const unitModeOptions = _.transform(
      UNIT_MODE,
      (result, value) => {
        result[value] = { text: value };
      },
      {}
    );

    const unitStatusOptions = _.transform(
      UNIT_STATUS,
      (result, value) => {
        result[value] = { text: value };
      },
      {}
    );

    return (
      <>
        <BaseHeader title="Units Search"></BaseHeader>
        <NetworkPageWrapper
          // isLoading={isLoading}
          errMsg={errMsg}
          networkInfo={networkInfo}
        >
          <div className="border rounded mb-2 bg-white p-4">
            <div className="mb-2 flex items-center gap-2 text-sm font-semibold">
              <div className="w-20">YieldSet: </div>
              <AllSelectBtn
                value={filter.ys}
                handleClick={() => this.handleYsChanged(YIELD_SET.ALL)}
              ></AllSelectBtn>
              <div className="w-1/2">
                {networkId && (
                  <YieldSetsSelector
                    networkId={networkId}
                    handleOnChange={this.handleYsChanged}
                    handleOnLoadData={this.handleLoadYsData}
                    selectedYieldSet={selectedYieldSet}
                    defaultValue={filter.ys}
                  ></YieldSetsSelector>
                )}
              </div>
            </div>
            <div className="mb-2 flex items-center gap-2 text-sm font-semibold">
              <div className="w-20">Unit Mode: </div>
              <OptionSelector
                selected={filter.mode}
                options={unitModeOptions}
                allKey={UNIT_MODE.ALL}
                handleSelectChanged={this.handleModeChanged}
              ></OptionSelector>
            </div>

            <div className="mb-2 flex items-center gap-2 text-sm font-semibold">
              <div className="w-20">Unit Status: </div>
              <OptionSelector
                selected={filter.status}
                options={unitStatusOptions}
                allKey={UNIT_STATUS.ALL}
                handleSelectChanged={this.handleStatusChanged}
              ></OptionSelector>
            </div>

            <button
              type="button"
              onClick={this.searchUnits}
              className={`rounded px-6 py-2 font-semibold text-white ${
                isFilterDisabled
                  ? "bg-blue-200"
                  : "shadow bg-blue-400 hover:bg-blue-500"
              }`}
              disabled={isFilterDisabled}
            >
              Get units
            </button>
          </div>

          {errMsg && <div className="text-red-600">{errMsg}</div>}
          {isLoading && (
            <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
              <div>
                <LoadingUI></LoadingUI>
              </div>
            </div>
          )}

          {units && (
            <UnitsSelectionTable
              ref={this.unitsTableRef}
              ysData={ysData}
              units={units}
            ></UnitsSelectionTable>
          )}
        </NetworkPageWrapper>
      </>
    );
  }
}

class UnitsSelectionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      searchKey: SEARCH_KEYS.ALL,
      filterMode: UNIT_MODE.ALL,
      filterStatus: UNIT_STATUS.ALL,
      filterYeildSet: YIELD_SET.ALL,

      optionYieldSet: {},
      optionMode: {},
      optionStatus: {},
      optionSearchKey: {},

      selectedUnitIds: [],
      selectedKey: SEARCH_KEYS.UNIT_ID,

      filteredUnits: this.props.units,

      isOpenEquationHintModal: false,
    };

    this.searchRef = React.createRef();

    this.handleSelectUnit = this.handleSelectUnit.bind(this);
    this.handleClearSelected = this.handleClearSelected.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
    this.handleOptionChanged = this.handleOptionChanged.bind(this);
    this.handleSelectedKeyChanged = this.handleSelectedKeyChanged.bind(this);
  }

  componentDidMount() {
    const optionSearchKey = _.transform(
      SEARCH_KEYS,
      (result, value) => {
        result[value] = { text: value };
      },
      {}
    );
    this.setState({ optionSearchKey });
  }

  handleClearAll() {
    // for parent
    this.handleClearSelected();

    this.handleOptionChanged({
      filterMode: UNIT_MODE.ALL,
      filterStatus: UNIT_STATUS.ALL,
      filterYeildSet: YIELD_SET.ALL,
      searchKey: SEARCH_KEYS.ALL,
    });

    if (this.searchRef.current) {
      this.searchRef.current.handleClearInput();
    }
  }

  handleSelectUnit(unitId) {
    const { selectedUnitIds, filteredUnits } = this.state;
    if (unitId === "ALL") {
      if (
        selectedUnitIds.length > 0 &&
        filteredUnits.length === selectedUnitIds.length
      ) {
        // unselect all
        this.setState({ selectedUnitIds: [] });
      } else {
        const newUnits = _.map(filteredUnits, (u) => {
          return u.adUnitId;
        });
        this.setState({ selectedUnitIds: newUnits });
      }
    } else {
      let newUnits = [];
      if (_.includes(selectedUnitIds, unitId)) {
        newUnits = _.filter(selectedUnitIds, (uid) => {
          return uid !== unitId;
        });
      } else {
        newUnits = [...selectedUnitIds, unitId];
      }
      this.setState({ selectedUnitIds: newUnits });
    }
  }

  handleClearSelected() {
    this.setState({ selectedUnitIds: [] });
  }

  handleOptionChanged(param) {
    this.setState(param);

    setTimeout(() => {
      this.handleSearch();
    });
  }

  handleSelectedKeyChanged(key) {
    this.setState({ selectedKey: key });
  }

  handleSearch(value) {
    if (value === undefined) {
      if (this.searchRef.current) {
        const inputValue = this.searchRef.current.state.inputValue;
        this.execSearch(inputValue);
      }
    } else {
      this.execSearch(value);
    }
  }

  execSearch(inputValue) {
    const { units, ysData } = this.props;
    const { searchKey, filterMode, filterStatus, filterYeildSet } = this.state;

    let filterUnits = _.cloneDeep(units);

    // filter by yieldset
    const unitByYieldSet = _.countBy(units, "yieldSet.yieldSetId");
    const ysDataMap = _.keyBy(ysData, "yieldSetId");
    const optionYieldSet = {
      [YIELD_SET.ALL]: { count: units.length },
      ..._.mapValues(ysDataMap, ({ yieldSetId, name }) => ({
        count: unitByYieldSet[yieldSetId] || 0,
        text: name,
      })),
    };

    if (filterYeildSet !== YIELD_SET.ALL) {
      filterUnits = _.filter(filterUnits, (u) => {
        return u.yieldSet.yieldSetId === _.toInteger(filterYeildSet);
      });
    }

    // filter by mode
    const unitByMode = _.countBy(filterUnits, "mode");
    const optionMode = {
      ..._.mapValues(UNIT_MODE, (mode) => ({
        count: unitByMode[mode] || 0,
      })),
      [UNIT_MODE.ALL]: { count: filterUnits.length },
    };

    if (filterMode !== UNIT_MODE.ALL) {
      filterUnits = _.filter(filterUnits, (u) => {
        return u.mode === filterMode;
      });
    }

    // filter by status
    const unitByStatus = _.countBy(filterUnits, "status");
    const optionStatus = {
      ..._.mapValues(UNIT_STATUS, (status) => ({
        count: unitByStatus[status] || 0,
      })),
      [UNIT_STATUS.ALL]: { count: filterUnits.length, text: "ALL" },
    };

    if (filterStatus !== UNIT_STATUS.ALL) {
      filterUnits = _.filter(filterUnits, (u) => {
        return u.status === filterStatus;
      });
    }

    this.setState({
      optionYieldSet,
      optionMode,
      optionStatus,
    });

    let newUnits = [];

    this.setState({ expErrMsg: null });

    if (inputValue === "") {
      this.setState({ filteredUnits: filterUnits });
      return;
    }

    if (searchKey === SEARCH_KEYS.ALL) {
      const allKeys = _.values(SEARCH_KEYS);
      newUnits = _.filter(filterUnits, (unit) => {
        let isMatch = false;
        _.forEach(allKeys, (key) => {
          if (this._searchIncludes(unit[key], inputValue)) {
            isMatch = true;
            return;
          }
        });

        return isMatch;
      });
    } else if (searchKey === SEARCH_KEYS.TAGS) {
      const expression = fixExpression(inputValue);
      try {
        const parser = new Parser();
        const expr = parser.parse(expression);
        newUnits = _.filter(filterUnits, ({ tags }) => {
          const symbolObj = _.reduce(
            expr.symbols(),
            (acc, symbol) => {
              return {
                ...acc,
                [symbol]: false,
              };
            },
            {}
          );

          // Check if expression is true
          const tagsObj = {
            ...symbolObj,
            ...parseTagsToObject(tags),
          };
          return expr.evaluate(tagsObj);
        });
      } catch (err) {
        this.setState({ expErrMsg: err.message });
      }
    } else {
      newUnits = _.filter(filterUnits, (unit) => {
        let isMatch = false;
        const target = unit[searchKey];

        if (this._searchIncludes(target, inputValue)) {
          isMatch = true;
        }

        return isMatch;
      });
    }

    this.setState({
      filteredUnits: newUnits,
    });
  }

  _searchIncludes(target, inputValue) {
    return target && _.toLower(target).includes(_.toLower(inputValue));
  }

  getSelectedUnitsByKey(key) {
    // key: same as searchKey
    const { units } = this.props;
    const { selectedUnitIds } = this.state;

    let results = _.filter(units, (u) => {
      return _.indexOf(selectedUnitIds, u.adUnitId) !== -1;
    });

    results = _.map(results, key);
    return results;
  }

  render() {
    const { units } = this.props;
    const {
      isOpenEquationHintModal,
      selectedUnitIds,
      selectedKey,
      searchKey,
      filteredUnits,
      filterMode,
      filterStatus,
      filterYeildSet,
      optionMode,
      optionStatus,
      optionYieldSet,
      optionSearchKey,
      expErrMsg,
    } = this.state;

    if (units.length === 0) {
      return "No units found";
    }

    const selectedItems = this.getSelectedUnitsByKey(selectedKey);

    return (
      <>
        <div className="border rounded mb-1 p-4 text-xs font-semibold">
          <div className="mb-2 flex gap-2">
            <div className="w-20 pt-1">
              Yieldset ({_.keys(optionYieldSet).length - 1}):{" "}
            </div>
            <OptionSelector
              selected={filterYeildSet}
              options={optionYieldSet}
              allKey={YIELD_SET.ALL}
              // standaloneSelectAll={true}
              handleSelectChanged={(ys) => {
                this.handleOptionChanged({ filterYeildSet: ys });
              }}
            ></OptionSelector>
          </div>
          <div className="mb-2 flex gap-2">
            <div className="w-20 pt-1">Unit Mode: </div>
            <OptionSelector
              selected={filterMode}
              options={optionMode}
              allKey={UNIT_MODE.ALL}
              // standaloneSelectAll={true}
              handleSelectChanged={(mode) => {
                this.handleOptionChanged({ filterMode: mode });
              }}
            ></OptionSelector>
          </div>

          <div className="mb-2 flex gap-2">
            <div className="w-20 pt-1">Unit Status: </div>
            <OptionSelector
              selected={filterStatus}
              options={optionStatus}
              allKey={UNIT_STATUS.ALL}
              // standaloneSelectAll={true}
              handleSelectChanged={(status) => {
                this.handleOptionChanged({ filterStatus: status });
              }}
            ></OptionSelector>
          </div>
          <div className="border-t -mx-4 mb-1 mt-1 flex gap-2 px-4 pt-1">
            <div className="w-20 pt-1">Search key: </div>
            <OptionSelector
              selected={searchKey}
              options={optionSearchKey}
              allKey={SEARCH_KEYS.ALL}
              handleSelectChanged={(key) => {
                this.handleOptionChanged({ searchKey: key });
              }}
            ></OptionSelector>
            <div
              className="flex cursor-pointer items-center text-xs text-blue-600"
              onClick={() => {
                this.setState({
                  isOpenEquationHintModal: true,
                });
                this.handleOptionChanged({ searchKey: SEARCH_KEYS.TAGS });
              }}
            >
              <FiInfo data-tip data-for="equation-description"></FiInfo>
            </div>

            <ReactTooltip
              id="equation-description"
              type="dark"
              effect="solid"
              place="top"
            >
              Click to see how to search using tags and operators
            </ReactTooltip>
          </div>
          <ItemsFilter
            ref={this.searchRef}
            handleSearch={this.handleSearch}
          ></ItemsFilter>
          {expErrMsg && <div className="text-xs text-red-600">{expErrMsg}</div>}
        </div>

        <div className="italic text-gray-800">
          Showing <b>{filteredUnits.length}</b> out of {units.length} units
        </div>

        <table
          className="border shadow table w-full text-sm"
          style={{ marginBottom: "500px" }}
        >
          <thead className="border-b bg-gray-200 text-xs text-blue-800">
            <tr>
              <th className="px-2 text-center">
                <input
                  style={{
                    width: "18px",
                    height: "18px",
                    marginTop: "4px",
                  }}
                  type="checkbox"
                  checked={selectedUnitIds.length === filteredUnits.length}
                  onChange={() => this.handleSelectUnit("ALL")}
                ></input>
              </th>
              <th className="border px-2 py-1">Unit</th>
            </tr>
          </thead>
          <tbody className="bg-white font-mono font-normal text-gray-900">
            {filteredUnits &&
              filteredUnits.map((r, i) => {
                return (
                  <tr key={r.adUnitId} className="border-b hover:bg-gray-100">
                    <td className="px-2 text-center">
                      <input
                        style={{
                          width: "18px",
                          height: "18px",
                          marginTop: "4px",
                        }}
                        type="checkbox"
                        checked={selectedUnitIds.indexOf(r.adUnitId) !== -1}
                        onChange={() => this.handleSelectUnit(r.adUnitId)}
                      ></input>
                    </td>
                    <td className="whitespace-no-wrap border px-2 py-1 font-sans font-semibold">
                      <div className="flex items-center gap-2">
                        <div className="border-b border-gray-600">
                          {r.adUnitId}{" "}
                          <span className="mr-1 text-gray-800">{r.name}</span>
                          <UnitStatus unit={r}></UnitStatus>
                          <UnitMode unit={r} version="v2"></UnitMode>
                        </div>
                      </div>

                      <div className="flex items-center gap-2">
                        <div className="font-mono text-xs text-gray-700">
                          {r.extGamAdUnitId}
                        </div>
                        <div className="text-xs font-normal text-gray-700">
                          {`${r.parentPath !== "/" ? r.parentPath + "/" : "/"}${
                            r.code
                          }`}
                        </div>
                      </div>

                      <div className="text-xs font-normal text-gray-700">
                        YS: {r.yieldSet.yieldSetId} {r.yieldSet.name}
                      </div>

                      {r.siteNetworkCode && (
                        <div className="text-xs font-normal text-gray-600">
                          Site Network Code: {r.siteNetworkCode}
                        </div>
                      )}

                      <div
                        style={{ width: "100%" }}
                        className="w-full overflow-x-auto"
                      >
                        {r.tags && (
                          <div className="whitespace-no-wrap my-1 flex flex-wrap gap-2">
                            {r.tags.map((t) => {
                              return (
                                <div
                                  key={t}
                                  className="rounded bg-orange-200 px-2 text-gray-600"
                                  style={{ fontSize: "12px" }}
                                >
                                  {t}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <SelectedStickyFooter
          idList={[SEARCH_KEYS.UNIT_ID, SEARCH_KEYS.EXT_UNIT_ID]}
          selectedItems={selectedItems}
          handleSelectedByKey={this.handleSelectedKeyChanged}
          handleClear={this.handleClearSelected}
        ></SelectedStickyFooter>

        {isOpenEquationHintModal && (
          <ModalWrapper
            isOpen={true}
            showCloseFooter={true}
            customOverlayStyle={{ zIndex: 100 }}
            handleClose={() =>
              this.setState({ isOpenEquationHintModal: false })
            }
          >
            <div className="mb-2 text-lg font-semibold">
              Using Tags for Advanced Search
            </div>

            <div className="mt-2 font-semibold text-gray-800">
              Comparison Operators
            </div>
            <ul className="list-disc pl-8">
              <li>
                <span className={codeStyle}>{`>`}</span> (greater than),{" "}
                <span className={codeStyle}>{`>=`}</span> (greater than or equal
                to)
              </li>
              <li>
                <span className={codeStyle}>{`<`}</span> (less than),{" "}
                <span className={codeStyle}>{`<=`}</span> (less than or equal
                to)
              </li>
              <li>
                <span className={codeStyle}>{`==`}</span> (equals)
              </li>
              <li>
                Example:{" "}
                <div className="flex items-center">
                  <span
                    className={codeStyle}
                  >{`DROP_OFF_RATE_VIDEO >= 50`}</span>
                  <div className="text-blue-600">
                    <ClickToCopyWrapper
                      copyText={`DROP_OFF_RATE_VIDEO >= 50`}
                    ></ClickToCopyWrapper>
                  </div>
                </div>
              </li>
            </ul>

            <div className="mt-2 font-semibold text-gray-800">
              Logical Operators
            </div>
            <ul className="list-disc pl-8">
              <li>
                Use <span className={codeStyle}>&&</span> or{" "}
                <span className={codeStyle}>AND</span> for "and" conditions.
              </li>
              <li>
                Use <span className={codeStyle}>||</span> or{" "}
                <span className={codeStyle}>OR</span> for "or" conditions.
              </li>
              <li>
                Example:{" "}
                <div className="flex items-center">
                  <span
                    className={codeStyle}
                  >{`IMP_PCT:GOOGLE > 10 || IMP_PCT:GUARANTEED > 10`}</span>
                  <div className="text-blue-600">
                    <ClickToCopyWrapper
                      copyText={`IMP_PCT:GOOGLE > 10 || IMP_PCT:GUARANTEED > 10`}
                    ></ClickToCopyWrapper>
                  </div>
                </div>
                <div className="flex items-center">
                  <span
                    className={codeStyle}
                  >{`DROP_OFF_RATE_VIDEO >= 50 && VIDEO_CS_PCT > 10`}</span>
                  <div className="text-blue-600">
                    <ClickToCopyWrapper
                      copyText={`DROP_OFF_RATE_VIDEO >= 50 && VIDEO_CS_PCT > 10`}
                    ></ClickToCopyWrapper>
                  </div>
                </div>
              </li>
            </ul>

            <div className="mt-2 font-semibold text-gray-800">
              Checking Tag Existence
            </div>
            <ul className="list-disc pl-8">
              <li>
                Use <span className={codeStyle}>true</span> or{" "}
                <span className={codeStyle}>false</span> to check if a tag
                exists.
              </li>
              <li>
                Example:
                <div className="flex items-center">
                  <span className={codeStyle}>REFRESH == true</span>
                  <div className="text-blue-600">
                    <ClickToCopyWrapper
                      copyText={`REFRESH == true`}
                    ></ClickToCopyWrapper>
                  </div>
                  (Filter units with tag:REFRESH)
                </div>
                <div className="flex items-center">
                  <span className={codeStyle}>REQ_PCT:HBPB == false</span>
                  <div className="text-blue-600">
                    <ClickToCopyWrapper
                      copyText={`REQ_PCT:HBPB == false`}
                    ></ClickToCopyWrapper>
                  </div>
                  (Excludes units with tag:REQ_PCT:HBPB)
                </div>
              </li>
            </ul>

            <div className="mt-2 font-semibold text-gray-800">
              Grouping Conditions
            </div>
            <ul className="list-disc pl-8">
              <li>
                Use <span className={codeStyle}>()</span> to group conditions.
              </li>
              <li>
                Example:{" "}
                <div className="flex items-center">
                  <span
                    className={codeStyle}
                  >{`(DROP_OFF_RATE_VIDEO > 10 || DROP_OFF_RATE_DISPLAY < 5) && REQ_PCT:UPA == 20`}</span>
                  <div className="text-blue-600">
                    <ClickToCopyWrapper
                      copyText={`(DROP_OFF_RATE_VIDEO > 10 || DROP_OFF_RATE_DISPLAY < 5) && REQ_PCT:UPA == 20`}
                    ></ClickToCopyWrapper>
                  </div>
                </div>
              </li>
            </ul>

            <div className="mt-2 font-semibold text-gray-800">
              Advanced Examples
            </div>
            <ul className="mb-2 list-disc pl-8">
              <li>
                <div className="flex items-center">
                  <span
                    className={codeStyle}
                  >{`(REFRESH == true OR VIDEO_CREATIVE == true) && DROP_OFF_RATE_VIDEO > 5`}</span>
                  <div className="text-blue-600">
                    <ClickToCopyWrapper
                      copyText={`(REFRESH == true OR VIDEO_CREATIVE == true) && DROP_OFF_RATE_VIDEO > 5`}
                    ></ClickToCopyWrapper>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <span
                    className={codeStyle}
                  >{`IMP_PCT:GOOGLE > 50 && IMP_PCT:GOOGLE < 60 && VIDEO_CREATIVE == true`}</span>
                  <div className="text-blue-600">
                    <ClickToCopyWrapper
                      copyText={`IMP_PCT:GOOGLE > 50 && IMP_PCT:GOOGLE < 60 && VIDEO_CREATIVE == true`}
                    ></ClickToCopyWrapper>
                  </div>
                </div>
              </li>
            </ul>
          </ModalWrapper>
        )}
      </>
    );
  }
}

function AllSelectBtn({
  value,
  allValue = "ALL",
  handleClick,
  buttonText = "ALL",
}) {
  return (
    <button
      type="button"
      className={`rounded px-2 py-1 font-semibold ${
        value === allValue
          ? "bg-indigo-200 text-indigo-900"
          : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
      }`}
      onClick={handleClick}
    >
      {buttonText}
    </button>
  );
}

// options = > { key: {text, count} } object format asure key is unique
// allKey = > key for all button
function OptionSelector({
  options,
  selected,
  handleSelectChanged,
  allKey = "",
  standaloneSelectAll = false,
}) {
  const list = _.filter(_.keys(options), (key) => key !== allKey);
  return (
    <div className="flex flex-wrap gap-x-2 gap-y-1">
      {options[allKey] && (
        <div className={standaloneSelectAll ? "w-full" : ""}>
          <AllSelectBtn
            value={selected}
            buttonText={`${options[allKey].text || allKey} ${
              options[allKey].count !== undefined
                ? `(${options[allKey].count})`
                : ""
            }`}
            handleClick={() => handleSelectChanged(allKey)}
          ></AllSelectBtn>
        </div>
      )}
      {list.map((key) => {
        return (
          <button
            key={key}
            type="button"
            className={`rounded px-2 py-1 font-semibold ${
              selected === key
                ? "bg-indigo-200 text-indigo-900"
                : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
            }`}
            onClick={() => handleSelectChanged(key)}
          >
            {options[key].text || key}
            {options[key].count !== undefined ? ` (${options[key].count})` : ""}
          </button>
        );
      })}
    </div>
  );
}

const BaseHeader = ({ title }) => {
  return (
    <div className="border-b bg-gray-100 px-12 py-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="text-lg font-semibold text-gray-800">{title}</div>
        </div>
      </div>
    </div>
  );
};

export default UnitsSearchViewer;

import React from "react";
import _ from "lodash";
import Select from "react-select";
import CurrencySelector from "components/common/CurrencySelector";

import {
  buttonActionClass,
  buttonActionRedClass,
  buttonActionDisable,
} from "helpers/StyleClass";

import { FaEdit, FaTrash, FaTimes } from "react-icons/fa";

const TYPE = {
  hbpb: "prebid.js integration. Custom keys: ['hb_pb', 'hb_bidder', 'hb_size']",
  openwrap:
    "PubMatic’s OpenWrap solution for header bidding. Custom keys: ['pwtecp', 'pwtpid', 'pwtsz']",
  yieldlove:
    "yieldlove solution. Custom keys: ['yieldlove_hb_pb', 'yieldlove_hb_bidder', 'yieldlove_hb_size']",
  chegg: "chegg solution. Custom keys: ['bd', '', '']",
  nimbus: "Nimbus solution. Custom keys['na_bid', '', '']",
  criteo: "criteo solution. Custom keys: ['crt_cpm', '', 'crt_size']",
};

const PRICE_FORMAT = {
  hbpb: "common prebid.js style. E.g. 0.01 for $0.01",
  kijiji: "Kijiji’s style. E.g. 100001 for $0.01, 101001 for $10.05",
  chegg: "chegg’s style. Pattern: (base64decode(value) - 95) / 100",
  nimbus: "Pattern: value / 100 E.g. 1 -> $0.01, 2-> $0.02",
};

class IntegrationTypeControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIdx: -1,
      result: [...this.props.inputValue],
    };

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleUpdateResult = this.handleUpdateResult.bind(this);
  }

  handleAddItem() {
    this.setState({ selectedIdx: this.state.result.length });
    this.props.handleHasError(true);
  }

  handleEditItem(idx) {
    this.setState({
      selectedIdx: idx,
    });
    this.props.handleHasError(true);
  }

  handleDeleteItem(idx) {
    const result = [...this.state.result];
    result.splice(idx, 1);
    this.setState({ result }, () => {
      this.handleUpdateResult();
    });
  }

  handleCancel() {
    this.setState({
      selectedIdx: -1,
    });
    this.props.handleHasError(false);
  }

  handleConfirm(item) {
    const { selectedIdx, result } = this.state;
    const temp = _.cloneDeep(result);

    temp[selectedIdx] = item;

    this.setState({ result: temp }, () => {
      this.handleCancel();
      this.handleUpdateResult();
    });
  }

  handleUpdateResult() {
    this.props.handleNewValueChanged(this.state.result);
  }

  componentWillUnmount() {
    this.props.handleHasError(false);
  }

  render() {
    const { result, selectedIdx } = this.state;

    return (
      <div className="bg-white p-2">
        <div className="flex flex-col divide-y-2">
          {result.map((item, idx) => {
            return selectedIdx === idx ? (
              <EditIntegrationType
                typeValue={result[idx].type}
                priceFormatValue={result[idx].priceFormat}
                currencyValue={result[idx].currency}
                selectedType={_.map(result, "type").filter(
                  (item) => item !== result[idx].type
                )}
                handleCancel={this.handleCancel}
                handleConfirm={this.handleConfirm}
                key={`${item.type}-${idx}`}
              ></EditIntegrationType>
            ) : (
              <div className="flex" key={`${item.type}-${idx}`}>
                <div className="p-1 text-gray-800">
                  <div>
                    <span className="text-gray-600">Type:</span>{" "}
                    <span className="font-semibold">{item.type}</span>
                  </div>
                  <div>
                    <span className="text-gray-600">Price Format:</span>{" "}
                    <span className="font-semibold">{item.priceFormat}</span>
                  </div>
                  {item.currency && (
                    <div>
                      <span className="text-gray-600">Currency:</span>{" "}
                      <span className="font-semibold">{item.currency}</span>
                    </div>
                  )}
                </div>
                {selectedIdx < 0 && (
                  <div className="ml-auto flex items-center gap-1">
                    <button
                      type="button"
                      className={buttonActionClass}
                      onClick={() => this.handleEditItem(idx)}
                    >
                      <FaEdit></FaEdit>
                    </button>
                    <button
                      type="button"
                      className={buttonActionRedClass}
                      onClick={() => this.handleDeleteItem(idx)}
                    >
                      <FaTrash></FaTrash>
                    </button>
                  </div>
                )}
              </div>
            );
          })}
          {selectedIdx === result.length && (
            <div className="pt-2">
              <EditIntegrationType
                typeValue={""}
                priceFormatValue={""}
                handleCancel={this.handleCancel}
                handleConfirm={this.handleConfirm}
                selectedType={_.map(result, "type")}
              ></EditIntegrationType>
            </div>
          )}
        </div>
        {selectedIdx < 0 && (
          <div className="flex justify-center">
            <button
              type="button"
              className={`${buttonActionClass} w-1/3`}
              onClick={this.handleAddItem}
            >
              Add
            </button>
          </div>
        )}
      </div>
    );
  }
}

class EditIntegrationType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      typeValue: this.props.typeValue,
      priceFormatValue: this.props.priceFormatValue,
      currencyValue: this.props.currencyValue || "",

      typeOptions: _.map(TYPE, (value, key) => ({
        value: key,
        label: `[${key}] ${value}`,
      })),
      priceFormatOptions: _.map(PRICE_FORMAT, (value, key) => ({
        value: key,
        label: `[${key}] ${value}`,
      })),
    };

    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handlePriceFormatChange = this.handlePriceFormatChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleTypeChange(selected) {
    this.setState({ typeValue: selected.value });

    if (PRICE_FORMAT[selected.value]) {
      this.setState({ priceFormatValue: selected.value });
    }
  }

  handlePriceFormatChange(selected) {
    this.setState({ priceFormatValue: selected.value });
  }

  handleCurrencyChange(selected) {
    this.setState({ currencyValue: selected });
  }

  handleCancel() {
    this.props.handleCancel();
  }

  handleConfirm() {
    const item = {
      type: this.state.typeValue,
      priceFormat: this.state.priceFormatValue,
      currency: this.state.currencyValue,
    };

    if (item.currency === "") delete item.currency;

    this.props.handleConfirm(item);
  }

  render() {
    const { selectedType } = this.props;
    const {
      typeValue,
      priceFormatValue,
      currencyValue,
      typeOptions,
      priceFormatOptions,
    } = this.state;

    const typeSelected = _.find(typeOptions, { value: typeValue });
    const priceFormatSelected = _.find(priceFormatOptions, {
      value: priceFormatValue,
    });

    const filteredTypeOptions = _.filter(typeOptions, (item) => {
      return _.indexOf(selectedType, item.value) === -1;
    });

    return (
      <div className="flex flex-col gap-1 px-2">
        <div style={{ zIndex: 70 }}>
          <label>Type:</label>
          <Select
            value={typeSelected}
            options={filteredTypeOptions}
            onChange={this.handleTypeChange}
          ></Select>
        </div>

        <div style={{ zIndex: 60 }}>
          <label>Price Format:</label>
          <Select
            value={priceFormatSelected}
            options={priceFormatOptions}
            onChange={this.handlePriceFormatChange}
          ></Select>
        </div>

        <div>
          <label>
            Currency: <span className="text-gray-600">(optional)</span>
          </label>
          <div className="flex gap-1">
            <CurrencySelector
              selectedCurrency={currencyValue}
              handleCurrencyChanged={this.handleCurrencyChange}
            ></CurrencySelector>
            <button
              type="button"
              className={
                currencyValue !== "" ? buttonActionClass : buttonActionDisable
              }
              disabled={currencyValue === ""}
              onClick={() => this.setState({ currencyValue: "" })}
            >
              <FaTimes></FaTimes>
            </button>
          </div>
        </div>

        <div className="flex justify-end gap-1">
          <button className={buttonActionClass} onClick={this.handleCancel}>
            Cancel
          </button>
          <button
            className={
              typeValue === "" || priceFormatValue === ""
                ? buttonActionDisable
                : buttonActionClass
            }
            onClick={this.handleConfirm}
            disabled={typeValue === "" || priceFormatValue === ""}
          >
            Confirm
          </button>
        </div>
      </div>
    );
  }
}

export default IntegrationTypeControl;

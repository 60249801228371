import React from "react";
import NumberFormat from "./NumberFormat";
import ItemStyle from "./css/inventory-item.module.css";

// isGraphOnly: used inside YbReqStatTooltip
const YbReqStatDiagram = ({ item, isGraphOnly }) => {
  return (
    <div>
      {item.isCompatible && !isGraphOnly && (
        <YbReqStatTextAboveGraph item={item}></YbReqStatTextAboveGraph>
      )}

      <YbReqStatGraph item={item}></YbReqStatGraph>
    </div>
  );
};

function YbReqStatTextAboveGraph({ item }) {
  return (
    <div>
      <NumberFormat
        value={item.dailyCompatibleAvgReq}
        format="0,0a"
      ></NumberFormat>
    </div>
  );
}

function YbReqStatGraph({ item }) {
  const { gptReqPercentage, ampReqPercentage } = item;
  return (
    <div className={ItemStyle.statBarWrapper}>
      <div
        className={ItemStyle.statIndicator}
        style={{ left: `${gptReqPercentage + ampReqPercentage}%` }}
      ></div>

      <div
        className={ItemStyle.gptBar}
        style={{ width: `${gptReqPercentage}%` }}
      ></div>

      <div
        className={ItemStyle.ampBar}
        style={{
          left: `${gptReqPercentage}%`,
          width: `${ampReqPercentage}%`,
        }}
      ></div>
    </div>
  );
}

export default YbReqStatDiagram;

import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { BiCheckDouble, BiCheck, BiX } from "react-icons/bi";
import { FaCheck, FaExclamationTriangle, FaAngleRight } from "react-icons/fa";

import YbReqStatWrapper from "./ReqStatWrapper";
import { MCMLabel } from "./MCMLabel";

import {
  CHECKBOX_STATUS,
  CHILDREN_ONBOARD_STATUS,
  CHILDREN_SELECTION_STATUS,
} from "./constants";
import { onClickableAreaClicked } from "./utils";
import ItemStyle from "./css/inventory-item.module.css";

/**
 * Parent Unit:
 * 1. Selectable
 * 2. Not Selectable
 *    - All Onboarded
 *    - All Not Compatible
 *    - Special Case: Onboarded Parent Unit
 */
export function ParentUnitItemComponent(props) {
  const { item, layerNum, onSelectUnit, onSelectAllChildUnits, onDrillDown } =
    props;

  let unitItemClass =
    ItemStyle.unitItem +
    " " +
    (item.isDrilledDown && ItemStyle.unitItemIsDrilledDown);

  let checkboxClass = ItemStyle.checkbox;
  let checkboxElement = "";

  if (item.checkboxStatus === CHECKBOX_STATUS.NOT_COMPATIBLE) {
    // has no checkbox
  } else if (item.checkboxStatus === CHECKBOX_STATUS.ONBOARDED) {
    checkboxClass += " " + ItemStyle.isOnboarded;
    checkboxElement = (
      <>
        <button
          type="button"
          className={checkboxClass}
          data-tip
          data-for={`"checkbox-${item.extGamUnitId}"`}
        >
          <FaCheck></FaCheck>
        </button>
        <ReactTooltip
          id={`"checkbox-${item.extGamUnitId}"`}
          type="dark"
          effect="solid"
          place="top"
        >
          Already onboarded
        </ReactTooltip>
      </>
    );
  } else {
    if (item.checkboxStatus === CHECKBOX_STATUS.SELECTED) {
      checkboxClass += " " + ItemStyle.selected;
    }

    checkboxElement = (
      <button
        type="button"
        className={checkboxClass}
        onClick={(event) => {
          onSelectUnit(item);
          event.stopPropagation();
        }}
      >
        <FaCheck></FaCheck>
      </button>
    );
  }

  if (item.queryError) {
    unitItemClass = ItemStyle.unitItemHasError;
    checkboxElement = (
      <>
        <span
          style={{ color: "red" }}
          data-tip
          data-for={`"error-hint-${item.extGamUnitId}"`}
        >
          <FaExclamationTriangle></FaExclamationTriangle>
        </span>
        <ReactTooltip
          id={`"error-hint-${item.extGamUnitId}"`}
          type="dark"
          effect="solid"
          place="top"
        >
          {item.queryError.message}
        </ReactTooltip>
      </>
    );
  }

  const [isHovered, setIsHovered] = useState(false);

  const shouldShowActionArea =
    item.childrenOnboardStatus !== CHILDREN_ONBOARD_STATUS.ALL_NOT_COMPATIBLE &&
    item.childrenOnboardStatus !== CHILDREN_ONBOARD_STATUS.ALL_ONBOARDED;

  const shouldShowSelectAllChildBtn =
    item.childrenSelectionStatus !== CHILDREN_SELECTION_STATUS.ALL_SELECTED;
  const shouldShowUnselectAllChildBtn =
    item.childrenSelectionStatus !== CHILDREN_SELECTION_STATUS.NONE_SELECTED;

  return (
    <div
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={unitItemClass}
        onClick={() =>
          onClickableAreaClicked(item, layerNum, onDrillDown, onSelectUnit)
        }
      >
        <div className={ItemStyle.checkboxWrapper}>{checkboxElement}</div>

        <div className={ItemStyle.clickableArea}>
          <div className={ItemStyle.itemName}>
            <div>{item.name}</div>
            <div style={{ display: "flex", gap: "4px" }}>
              {item.siteNetworkCodes && (
                <MCMLabel
                  id={item.extGamUnitId}
                  siteNetworkCodes={item.siteNetworkCodes}
                ></MCMLabel>
              )}
            </div>
          </div>

          <div>
            {!item.hasChildren && !item.isCompatible && (
              <div className={ItemStyle.notCompatibleText}>Not compatible</div>
            )}
            <YbReqStatWrapper item={item}></YbReqStatWrapper>
          </div>

          <div className={ItemStyle.arrowWrapper}>
            <div
              className={`${ItemStyle.arrowIcon} flex items-center justify-center text-gray-600`}
            >
              <FaAngleRight></FaAngleRight>
            </div>
            <ChildrenOnboardStatus item={item}></ChildrenOnboardStatus>
          </div>
        </div>
      </div>
      {shouldShowActionArea && (
        <div className={ItemStyle.actionArea}>
          {isHovered && (
            <>
              {shouldShowSelectAllChildBtn && (
                <div
                  className={ItemStyle.selectAllChildren}
                  onClick={() =>
                    onSelectAllChildUnits(item, { isSelectAll: true })
                  }
                >
                  + All child units
                </div>
              )}

              {shouldShowUnselectAllChildBtn && (
                <div
                  className={ItemStyle.unselectAllChildren}
                  onClick={() =>
                    onSelectAllChildUnits(item, { isSelectAll: false })
                  }
                >
                  - All child units
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

function ChildrenOnboardStatus({ item }) {
  // selection status > onboard status
  let hasCircle = true;
  let circleColorClass = "";
  let iconColorClass = "";
  let iconElement = "";
  let tooltipMessage = null;
  switch (item.childrenOnboardStatus) {
    case CHILDREN_ONBOARD_STATUS.ALL_NOT_COMPATIBLE: {
      hasCircle = false;
      iconColorClass = ItemStyle.iconColorLightGray;
      iconElement = <BiX></BiX>;
      tooltipMessage = "All child units are not compatible";
      break;
    }
    case CHILDREN_ONBOARD_STATUS.ALL_ONBOARDED: {
      hasCircle = false;
      iconColorClass = ItemStyle.iconColorLightOrange;
      iconElement = <BiCheckDouble></BiCheckDouble>;
      tooltipMessage = "All compatible child units have been onboarded";
      break;
    }
    case CHILDREN_ONBOARD_STATUS.SOME_ONBOARDED: {
      circleColorClass = ItemStyle.circleColorLight;
      iconColorClass = ItemStyle.iconColorLightOrange;
      iconElement = <BiCheck></BiCheck>;
      tooltipMessage = "Some compatible child units have been onboarded";
      break;
    }
    default: {
      // nah
    }
  }

  switch (item.childrenSelectionStatus) {
    case CHILDREN_SELECTION_STATUS.ALL_SELECTED: {
      circleColorClass = ItemStyle.circleColorSolid;
      iconColorClass = ItemStyle.iconColorWhite;
      iconElement = <BiCheckDouble></BiCheckDouble>;
      tooltipMessage = "All compatible child units have been selected";
      break;
    }
    case CHILDREN_SELECTION_STATUS.SOME_SELECTED: {
      circleColorClass = ItemStyle.circleColorSolid;
      iconColorClass = ItemStyle.iconColorWhite;
      iconElement = <BiCheck></BiCheck>;
      tooltipMessage = "Some compatible child units have been selected";
      break;
    }
    default: {
      //nah
    }
  }

  if (item.isDrilledDown) {
    circleColorClass += " " + ItemStyle.isDrilledDown;
  }

  let circleClass = hasCircle ? ItemStyle.childStatusIconCircle : "";
  circleClass += " " + circleColorClass;

  return (
    <div className={ItemStyle.childStatusWrapper}>
      <div className={circleClass}>
        <div
          className={ItemStyle.childStatusIcon + " " + iconColorClass}
          data-tip
          data-for={`"icon-hint-${item.extGamUnitId}"`}
        >
          {iconElement}
        </div>
        <ReactTooltip
          id={`"icon-hint-${item.extGamUnitId}"`}
          type="dark"
          effect="solid"
          place="top"
        >
          {tooltipMessage}
        </ReactTooltip>
      </div>
    </div>
  );
}

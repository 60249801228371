export const ACTIONS = {
  ADVISE: "ADVISE",
  APPROVE: "APPROVE",
  AUDIT: "AUDIT",
  BAD_DEBT: "BAD_DEBT",
  RECEIVE_PAYMENT: "RECEIVE_PAYMENT",
  SEND_OVERDUE_EMAIL: "SEND_OVERDUE_EMAIL",
  ISSUE: "ISSUE",
  VOID: "VOID",
};

export const COLUMNS = {
  INVOICE_STATUS: "INVOICE_STATUS",
  APPROVAL_STATUS: "APPROVAL_STATUS",
  PAYMENT_STATUS: "PAYMENT_STATUS",
  AFTER_TAX_AMOUNT: "AFTER_TAX_AMOUNT",
  DUE_DATE: "DUE_DATE",
  RECEIVED_AMOUNT: "RECEIVED_AMOUNT",
  COLLECTION_DATE: "COLLECTION_DATE",
  BADDEBT_DATE: "BADDEBT_DATE",
};

// price type: 0:floor, 1:targetCPM, 2: LGO
export const PRICE_TYPE = {
  0: "Floor",
  1: "Target CPM",
  2: "LGO", // let google optimize
  "-1": "Use Brand Setting",
};

export const PRICE_TYPE_BY_NAME = {
  FLOOR: 0,
  CPM: 1,
  LGO: 2,
  BRAND_SETTING: -1,
};

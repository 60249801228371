import React from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

class Pagination extends React.Component {
  render() {
    const { pageNum, pageIdx, total, handlePageChanged } = this.props;
    const totalPages = Math.ceil(total / pageNum);

    const disabledPrev = pageIdx === 0;
    const disabledNext = pageIdx === totalPages - 1;

    return (
      <div className="flex items-center justify-center gap-4">
        <button
          type="button"
          onClick={() => handlePageChanged(pageIdx - 1)}
          className={`rounded bg-gray-100 px-2 py-1 ${
            disabledPrev
              ? "cursor-not-allowed text-gray-500"
              : "text-gray-700 hover:bg-gray-200"
          }`}
          disabled={disabledPrev}
        >
          <IoIosArrowBack></IoIosArrowBack>
        </button>
        <div>
          {pageIdx + 1} / {totalPages}
        </div>
        <button
          type="button"
          onClick={() => handlePageChanged(pageIdx + 1)}
          className={`rounded bg-gray-100 px-2 py-1 ${
            disabledNext
              ? "cursor-not-allowed text-gray-500"
              : "text-gray-700 hover:bg-gray-200"
          }`}
          disabled={disabledNext}
        >
          <IoIosArrowForward></IoIosArrowForward>
        </button>
      </div>
    );
  }
}

export default Pagination;

import React from "react";
import moment from "moment";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { CSVLink } from "react-csv";
import { AnalysisReportsAPI } from "apis";
import { IoIosArrowDown } from "react-icons/io";
import PopupWrapper from "components/common/PopupWrapper";
import DateRangePopover from "components/playground/home-dashboard-v2/DateRangePopover";
import NetworksSelector from "components/ops-mgmt/account/NetworksSelector";
import LoadingUI from "components/common/LoadingUI";
import SimpleDataTable from "components/common/SimpleDataTable";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import Checkbox from "components/common/Checkbox";
import Pagination from "components/common/Pagination";
import { getQueryStringToObject, setQueryString } from "helpers/QueryString";

const reportPaths = {
  abTesting: "/integration-verification/ab-testing",
  codeSnippet: "/integration-verification/code-snippet",
};

const fixedWidthStyle = { width: "1280px", margin: "auto" };

const baseNavButton =
  "rounded px-4 py-1 font-semibold text-gray-700 bg-gray-200 hover:bg-gray-200 hover:text-gray-800";

const baseButton =
  "rounded px-4 py-1 font-semibold text-gray-700 bg-gray-300 border border-gray-400 hover:bg-gray-200 hover:text-gray-800";

const selectedButton =
  "rounded px-4 py-1 font-semibold text-blue-800 bg-blue-200";

const REPORT = {
  AB_TESTING: "ab-testing",
  CODE_SNIPPET: "code-snippet",
};

const tableStyleMap = new Map()
  .set("date", { className: "w-32" })
  .set("ext_gam_unit_id", { className: "w-32" });

const DEFAULT_NUM_DAYS = 3;

class IntegrationVerificationViewer extends React.Component {
  constructor(props) {
    super(props);

    const end = moment().subtract(1, "day");
    const start = end.clone().subtract(DEFAULT_NUM_DAYS, "day");

    this.state = {
      isLoading: false,
      errMsg: null,
      startDate: start.format("YYYY-MM-DD"),
      endDate: end.format("YYYY-MM-DD"),
      selectedNetworkId: null,
      selectedNetwork: null,
      checkInputGamId: true,
      selectedGamId: [],
      isForceReload: false,

      data: null,
      selectedReport: null,
      updateTime: null,
      inputSearch: "",

      pageNum: 15,
      pageIdx: 0,

      tempParam: {
        report: null,
        networkId: null,
        startDate: null,
        endDate: null,
        gamId: null,
        reload: false,
      },
    };

    this.child = React.createRef();

    this.handleNetworksChanged = this.handleNetworksChanged.bind(this);
    this.handleClosePopover = this.handleClosePopover.bind(this);
    this.handleDateRangeFilterChanged =
      this.handleDateRangeFilterChanged.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.handleReportChanged = this.handleReportChanged.bind(this);
    this.handleGamIdChange = this.handleGamIdChange.bind(this);
    this.handleSearchChanged = this.handleSearchChanged.bind(this);

    this.validationGamId = this.validationGamId.bind(this);
    this.debounceValidation = _.debounce(this.validationGamId, 300);
  }

  async componentDidMount() {
    const { networkId, startDate, endDate } = getQueryStringToObject(
      this.props.location.search
    );

    startDate && this.setState({ startDate });
    endDate && this.setState({ endDate });
    networkId &&
      this.setState({ selectedNetworkId: _.toInteger(networkId) }, () => {
        this.handleGetData();
      });
  }

  componentWillUnmount() {
    this.debouncedValidation.cancel();
  }

  handleDateRangeFilterChanged({ startDate, endDate }) {
    setQueryString(this.props, {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    });

    this.setState({
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    });
  }

  handleClosePopover() {
    this.child.current.handleClosePopup();
  }

  handleNetworksChanged(network) {
    const { networkId } = network;

    setQueryString(this.props, { networkId });

    this.setState({
      selectedNetworkId: networkId,
      selectedNetwork: network,
    });
  }

  handleReportChanged(report) {
    this.setState({ selectedReport: report, pageIdx: 0 });
  }

  handleGamIdChange(e) {
    this.debounceValidation(e.target.value);
  }

  handleSearchChanged(e) {
    this.setState({ inputSearch: e.target.value, pageIdx: 0 });
  }

  async handleGetData() {
    const {
      selectedNetworkId,
      startDate,
      endDate,
      isForceReload,
      selectedGamId,
    } = this.state;
    const report = _.get(this.props, "match.params.report");
    const days = moment(endDate).diff(moment(startDate), "days");
    try {
      this.setState({
        isLoading: true,
        errMsg: null,
        data: null,
        updateTime: null,
        pageIdx: 0,
        inputSearch: "",
      });

      if (days > 7) {
        throw new Error("Invalid date range: limit 7 days");
      }

      const params = {
        networkId: selectedNetworkId,
        startDate,
        endDate,
        forceReload: isForceReload,
        extGamUnitIds: selectedGamId,
      };

      let result = null;

      if (report === REPORT.AB_TESTING) {
        // fetch ab testing data
        result = await AnalysisReportsAPI.getAbTestingVerificationReports(
          params
        );
      } else if (report === REPORT.CODE_SNIPPET) {
        // fetch code snippet data
        result =
          await AnalysisReportsAPI.getCodeSnippetIntegrationVerificationReports(
            params
          );
      }

      const { data_generated_time } = result;
      delete result.data_generated_time;

      this.setState({
        data: result,
        selectedReport: _.keys(result)[0],
        updateTime: data_generated_time,
        tempParam: {
          report,
          networkId: selectedNetworkId,
          startDate,
          endDate,
          gamId: selectedGamId,
          reload: isForceReload,
        },
      });
    } catch (err) {
      this.setState({ errMsg: err.message });
    }

    this.setState({ isLoading: false });
  }

  validationGamId(value) {
    const regex = /^\d+(\s*,\s*\d+)*(\s*,\s*)?\s*$/;
    const check = value === "" ? true : regex.test(value);

    if (check) {
      this.setState({
        checkInputGamId: check,
        selectedGamId:
          value === ""
            ? []
            : value
                .split(",")
                .map((d) => _.trim(d))
                .filter((d) => d !== ""),
      });
    } else {
      this.setState({ checkInputGamId: check });
    }
  }

  render() {
    const {
      selectedNetworkId,
      selectedReport,
      selectedGamId,
      isForceReload,
      startDate,
      endDate,
      errMsg,
      isLoading,
      data,
      updateTime,
      pageNum,
      pageIdx,
      tempParam,
      checkInputGamId,
      inputSearch,
    } = this.state;

    const report = _.get(this.props, "match.params.report");

    const isChanged = !_.isEqual(tempParam, {
      report,
      networkId: selectedNetworkId,
      startDate,
      endDate,
      gamId: selectedGamId,
      reload: isForceReload,
    });

    const isGettingDataDisabled = !startDate || !endDate || !selectedNetworkId;

    const columns = (
      data && data[selectedReport] ? data[selectedReport].columns : []
    ).map((cKey) => ({
      key: cKey,
      label: _.startCase(cKey),
      ...(tableStyleMap.get(cKey) || {}),
    }));

    const records =
      data && data[selectedReport] ? data[selectedReport].records : [];

    const filterData = _.filter(records, (r) => {
      const dateIdx = _.findIndex(columns, { key: "date" });
      const extGamUnitIdIdx = _.findIndex(columns, { key: "ext_gam_unit_id" });
      return (
        (dateIdx >= 0 && r[dateIdx].includes(inputSearch)) ||
        (extGamUnitIdIdx >= 0 &&
          r[extGamUnitIdIdx].toString().includes(inputSearch))
      );
    });

    const pageRec = filterData.slice(
      pageIdx * pageNum,
      (pageIdx + 1) * pageNum
    );

    const fileName = `${selectedNetworkId}_${report}_${selectedReport}_${startDate}_${endDate}.csv`;
    const csvData = data ? [data[selectedReport].columns, ...filterData] : [];

    const { location } = this.props;
    const queryString = location.search;

    return (
      <>
        <div className="border-bottom border flex bg-white">
          <div
            style={fixedWidthStyle}
            className="flex items-center justify-start gap-2 py-2"
          >
            <div className="mr-2 text-base font-bold">
              Integration Verification
            </div>
            <NavLink
              to={`${reportPaths.abTesting}${queryString}`}
              className={baseNavButton}
              activeClassName={selectedButton}
            >
              A/B Testing
            </NavLink>
            <NavLink
              to={`${reportPaths.codeSnippet}${queryString}`}
              className={baseNavButton}
              activeClassName={selectedButton}
            >
              Code Snippet
            </NavLink>
          </div>
        </div>
        <div className="bg-white">
          <div className="sticky top-0 z-40 bg-gray-200 py-2">
            <div
              className="flex flex-col justify-between"
              style={fixedWidthStyle}
            >
              <div className="flex w-full items-center gap-2">
                <div className="w-1/3">
                  <NetworksSelector
                    selectedId={selectedNetworkId}
                    handleOnChange={this.handleNetworksChanged}
                  ></NetworksSelector>
                </div>
                <PopupWrapper
                  ref={this.child}
                  place="bottom left"
                  triggerType="click"
                  hideArrow={false}
                  triggerElement={
                    <div className="border rounded hover:shadow focus:outline-none inline-flex cursor-pointer items-center border-gray-400 bg-white px-4 py-2 text-sm text-gray-700">
                      <span className="mr-4">
                        <span className="text-sm text-gray-600">
                          From <b className="text-gray-700">{startDate}</b> to{" "}
                          <b className="text-gray-700">{endDate}</b>
                        </span>
                      </span>

                      <IoIosArrowDown></IoIosArrowDown>
                    </div>
                  }
                  popupElement={
                    <DateRangePopover
                      startDate={startDate}
                      endDate={endDate}
                      dateRanges={[]}
                      months={1}
                      handleClosePopover={this.handleClosePopover}
                      handleDateRangeFilterChanged={
                        this.handleDateRangeFilterChanged
                      }
                    ></DateRangePopover>
                  }
                ></PopupWrapper>
                <label className="rounded mr-2 flex cursor-pointer items-center py-2">
                  <Checkbox
                    isChecked={isForceReload}
                    onChange={(val) => this.setState({ isForceReload: val })}
                  ></Checkbox>
                  <span className="ml-1 align-middle text-sm font-semibold text-gray-600 hover:text-gray-700">
                    Force Reload
                  </span>
                </label>
                <button
                  type="button"
                  onClick={this.handleGetData}
                  className={`rounded px-6 py-2 font-semibold text-white ${
                    isGettingDataDisabled || !isChanged || !checkInputGamId
                      ? "cursor-not-allowed bg-blue-200"
                      : "shadow bg-blue-400 hover:bg-blue-500"
                  }`}
                  disabled={
                    isGettingDataDisabled || !isChanged || !checkInputGamId
                  }
                >
                  Get data
                </button>
              </div>
              <input
                type="text"
                className="rounded focus:outline-none hover:shadow mt-2 flex w-full appearance-none border-2 border-gray-300 bg-white px-2 py-1 leading-normal hover:border-blue-300 focus:border-blue-400 focus:shadow-inner"
                placeholder="Ext GAM Unit ID (use , to separate)"
                onChange={this.handleGamIdChange}
              ></input>
            </div>
          </div>
          <div className="min-h-screen bg-white py-4" style={fixedWidthStyle}>
            {errMsg && <div className="text-center text-red-600">{errMsg}</div>}
            {isLoading && (
              <div
                className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <LoadingUI iconOnly={true}></LoadingUI>
              </div>
            )}
            {data && (
              <>
                <div className="rounded border mb-4 bg-gray-100 px-4 py-2">
                  <div className="flex">
                    <div className="text-base text-gray-700">
                      <span className="font-semibold">
                        {_.startCase(tempParam.report)}
                      </span>{" "}
                      - Report (
                      <span className="font-semibold">
                        {_.keys(data).length}
                      </span>
                      )
                    </div>
                    <div className="mb-2 ml-auto text-right text-sm font-semibold text-gray-700">
                      Data last updated:{" "}
                      <DateTimeFormatter
                        datetime={updateTime}
                      ></DateTimeFormatter>
                    </div>
                  </div>
                  <div className="mb-2 flex flex-wrap gap-2">
                    {_.map(_.keys(data), (key, i) => (
                      <button
                        className={`${
                          selectedReport === key ? selectedButton : baseButton
                        } text-sm`}
                        style={{ width: "calc(16.66% - (0.5rem * 5) / 6)" }}
                        key={i}
                        onClick={() => this.handleReportChanged(key)}
                      >
                        {_.startCase(key)}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="mb-2 flex justify-between">
                  <input
                    type="text"
                    className="rounded focus:outline-none hover:shadow flex w-1/3 appearance-none border-2 border-gray-300 bg-white px-2 py-1 leading-normal hover:border-blue-300 focus:border-blue-400 focus:shadow-inner"
                    placeholder="Search Date or Ext Gam Unit Id"
                    onChange={this.handleSearchChanged}
                    value={inputSearch}
                  ></input>
                  <CSVLink
                    data={csvData}
                    separator=","
                    filename={fileName}
                    className="shadow rounded bg-blue-200 px-4 py-2 font-medium text-blue-800 hover:bg-blue-300"
                  >
                    Download CSV
                  </CSVLink>
                </div>
                <div className="mb-2">
                  <SimpleDataTable
                    items={pageRec}
                    columns={columns}
                  ></SimpleDataTable>
                </div>
                {filterData.length > 0 && (
                  <Pagination
                    pageNum={pageNum}
                    pageIdx={pageIdx}
                    total={filterData.length}
                    handlePageChanged={(pIdx) =>
                      this.setState({ pageIdx: pIdx })
                    }
                  ></Pagination>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default IntegrationVerificationViewer;

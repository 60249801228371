import React from "react";

import TreeLayer from "./TreeLayer";

import TreeStyle from "./css/tree-selector.module.css";

export function TreeLayers(props) {
  const {
    layers,

    // actions
    onSelectUnit,
    onSelectAllChildUnits,
    onDrillDown,

    onAddUnits,
    onRemoveUnits,
  } = props;

  function _isAllCompatibleSelected(candidates) {
    let selectedUnitsCount = 0;
    let selectableUnitsCount = 0;

    for (let i = 0; i < candidates.length; ++i) {
      const item = candidates[i];
      if (item.isCompatible && !item.isOnboarded) {
        ++selectableUnitsCount;

        if (item.checkboxStatus === "SELECTED") {
          ++selectedUnitsCount;
        }
      }
    }

    return selectedUnitsCount === selectableUnitsCount;
  }

  const randomId = Math.random().toString(36).substring(7);

  return (
    <div
      id="layers-wrapper"
      style={{
        width: "100%",
        overflowX: "scroll",
        overscrollBehaviorX: "contain",
        scrollBehavior: "smooth",
        whiteSpace: "nowrap",
        borderTop: "1px solid #9a9a9a",
        backgroundColor: "#f9f9f9",
      }}
      className={TreeStyle.treeSelector}
    >
      {layers.map((layerItems, layerIndex) => {
        const isAllCompatibleSelected = _isAllCompatibleSelected(layerItems);

        return (
          <div
            key={`${randomId}-${layerIndex}`}
            className="border inline-block border-gray-400 bg-white"
            style={{
              width: "500px",
              verticalAlign: "top",
              whiteSpace: "normal",
            }}
          >
            <TreeLayer
              candidates={layerItems}
              layerNum={layerIndex + 1}
              isAllCompatibleSelected={isAllCompatibleSelected}
              onSelectUnit={onSelectUnit}
              onSelectAllChildUnits={onSelectAllChildUnits}
              onDrillDown={onDrillDown}
              onAddUnits={onAddUnits}
              onRemoveUnits={onRemoveUnits}
            ></TreeLayer>
          </div>
        );
      })}
    </div>
  );
}

import React from "react";
import _ from "lodash";
import Select from "react-select";
import { GAM_COUNTRY } from "../../constants/GAMCountry";

// For segmentation country selection
// can be multiselect
class CountrySelector extends React.PureComponent {
  constructor(props) {
    super(props);
    // props:
    // selectedItems
    // handleChanged

    const { selectedItems, showCountryId = false, isMulti = true } = props;
    const keys = _.sortBy(_.keys(GAM_COUNTRY), (id) => {
      return GAM_COUNTRY[id];
    });
    const allCategories = _.map(keys, (c) => {
      const name = `${showCountryId ? c + ": " : ""}${GAM_COUNTRY[c]}`;
      return {
        id: c,
        name,
      };
    });
    const options = _.map(allCategories, (c) => {
      return { value: c.id, label: c.name };
    });

    const selectedValue = isMulti
      ? _.filter(options, (option) => {
          return _.indexOf(selectedItems, option.value) !== -1;
        })
      : _.find(options, (option) => {
          return option.value === selectedItems;
        });

    this.state = {
      options,
      selectedValue,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleChange(options) {
    const { isMulti = true } = this.props;
    this.setState({
      selectedValue: options,
    });

    this.props.handleChanged(isMulti ? _.map(options, "value") : options.value);
  }

  handleInputChange(input) {
    if (_.isEmpty(input)) return;
    // Check if there is seperator
    // user can paste a list of values
    const { isMulti = true } = this.props;
    if (input.includes(",") && isMulti) {
      let parts = input.split(",");
      parts = _.map(_.compact(parts), _.trim);
      if (parts.length > 0) {
        // check if this is a list of values
        const selectedValue = _.filter(this.state.options, (option) => {
          const isNamePasted = _.some(parts, (p) => {
            return p.toLowerCase() === option.label.toLowerCase();
          });

          return _.indexOf(parts, option.value) !== -1 || isNamePasted;
        });

        if (selectedValue.length > 0) {
          const newValues = _.uniqBy(
            [...(this.state.selectedValue || []), ...selectedValue],
            "value"
          );
          this.handleChange(newValues);
          return "";
        }
      }
    }

    return input;
  }

  render() {
    const { isReadonly, isMulti = true } = this.props;
    const { options, selectedValue } = this.state;

    return (
      <>
        <Select
          // defaultValue={selectedValue}
          // value={selectedValue}
          // onChange={this.handleChange}
          // options={options}
          // isSearchable={true}
          // isMulti={true}

          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          options={options}
          isSearchable={true}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          isDisabled={isReadonly}
        />
      </>
    );
  }
}

export default CountrySelector;

import React from "react";
import _ from "lodash";
import Select from "react-select";

import { NetworkAPI } from "apis";

class NetworksSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      options: [],
      selectedValue: null,
      isLoading: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const items = await NetworkAPI.getNetworks();

    const valueKey = this.props.valueKey || "networkId";

    let options = _.map(items, (r) => {
      return {
        value: r[valueKey],
        label: `${r.networkId} - ${r.name}`,
      };
    });

    options = _.orderBy(options, ["value"], ["desc"]);

    const selectedValue = _.find(options, { value: this.props.selectedId });

    this.setState(
      {
        items,
        options,
        isLoading: false,
      },
      () => {
        if (selectedValue) {
          this.handleChange(selectedValue);
        } else {
          if (this.props.handleNoData) this.props.handleNoData();
        }
      }
    );
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    if (this.props.isMulti) {
      const ids = _.map(option, (o) => {
        return o.value;
      });
      this.props.handleOnChange(ids);
    } else {
      const valueKey = this.props.valueKey || "networkId";
      const item = _.find(this.state.items, { [valueKey]: option.value });
      this.props.handleOnChange(item);
    }
  }

  render() {
    const { options, selectedValue, isLoading } = this.state;

    return (
      <>
        <Select
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isMulti={this.props.isMulti}
          isLoading={isLoading}
        />
      </>
    );
  }
}

export default NetworksSelector;

import VaultAPI from "apis/vault.api";
import VseAPI from "apis/vse.api";
import SnapshotAPI from "apis/snapshot.api";
import AnatomyAPI from "apis/anatomy.api";
import UnitInsightAPI from "apis/unit-insight.api";
import AutoPilotAPI from "apis/auto-pilot.api";
import BillingAPI from "apis/billing.api";
import PublisherAPI from "apis/publisher.api";
import DashboardAPI from "apis/dashboard.api";
import OnboardingAPI from "apis/onboarding.api";
import NetworkAPI from "apis/network.api";
import CommonAPI from "apis/common.api";
import CstAPI from "apis/cst.api";
import UnitAPI from "apis/unit.api";
import AppConfiguratorAPI from "apis/app-configurator.api";
import AnalysisReportsAPI from "apis/analysis-reports.api";
import GamAccountAPI from "apis/gam-account.api";
import PlatformAPI from "apis/platform.api";

export {
  VaultAPI,
  VseAPI,
  SnapshotAPI,
  AnatomyAPI,
  UnitInsightAPI,
  AutoPilotAPI,
  BillingAPI,
  PublisherAPI,
  DashboardAPI,
  OnboardingAPI,
  NetworkAPI,
  CommonAPI,
  CstAPI,
  UnitAPI,
  AppConfiguratorAPI,
  AnalysisReportsAPI,
  GamAccountAPI,
  PlatformAPI,
};

import React from "react";
import ItemStyle from "./css/inventory-item.module.css";

export function ParentUnitLabel() {
  return (
    <div className={ItemStyle.parentLabel} title="Parent Unit">
      P
    </div>
  );
}

import React from "react";
import LoadingUI from "./LoadingUI";

class ViewerWrapper extends React.Component {
  render() {
    const { isLoading = false, hasHeader = true } = this.props;

    return (
      <div>
        {isLoading && (
          <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-gray-800 opacity-25">
            <LoadingUI iconOnly={true}></LoadingUI>
          </div>
        )}

        <div className="bg-white px-12">
          <h1 className="pb-4 pt-8 text-4xl font-extrabold text-gray-900">
            {this.props.title}
          </h1>
        </div>

        <div
          className="bg-gray-200 px-12 py-8"
          style={{
            height: hasHeader
              ? "calc(100vh - 7.6875rem)"
              : "calc(100vh - 6.375rem)",
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default ViewerWrapper;

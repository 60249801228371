import React from "react";
import _ from "lodash";
import { formatMoneyWithCurrency } from "components/playground/home-dashboard-v1/ReportsHelper";
import { GAM_CREATIVE_TYPES_NAME } from "./Constants";
import PriceSectionView from "components/common/PriceSectionView";

class BrandPriceSettingsTable extends React.PureComponent {
  render() {
    const { currency, items, handleEdit, handleRemove, handleOpenDuplicate } =
      this.props;

    if (items.length === 0) {
      return <div>No Settings</div>;
    }

    return (
      <div>
        <div className="text-sm text-gray-800">{items.length} settings</div>

        <table className="border shadow table w-full text-sm">
          <thead className="border-b bg-gray-200 text-xs text-blue-800">
            <tr>
              <th className="border py-1 px-2 text-left ">Targeting</th>
              <th className="border py-1 px-2 text-left ">Creative Format</th>
              <th className="border py-1 px-2 text-left ">Price</th>
              <th className="border py-1 px-2 text-right ">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white  text-gray-900">
            {items.map((item, index) => {
              return (
                <tr key={index} className="border-b hover:bg-gray-100">
                  <td className="whitespace-no-wrap border py-1 px-2 text-left">
                    {/* {JSON.stringify(item.targeting)} */}
                    {item.targeting.sizes && (
                      <div>
                        <span className="text-gray-700">Sizes: </span>
                        <div className="leading-tight">
                          {JSON.stringify(item.targeting.sizes)}
                        </div>
                      </div>
                    )}
                    {item.targeting.advertisersAndBrands && (
                      <div className="mt-1">
                        <span className="text-gray-700">
                          Advertisers and Brands: (
                          {item.targeting.advertisersAndBrands.length}){" "}
                        </span>

                        <div
                          className="leading-tight"
                          style={{ maxWidth: "420px", overflowX: "scroll" }}
                        >
                          {JSON.stringify(item.targeting.advertisersAndBrands)}
                        </div>
                      </div>
                    )}
                  </td>

                  <td className="border py-1 px-2">
                    <CreativeTypeSectionView
                      creativeType={_.get(item.targeting, ["creativeTypes", 0])}
                    ></CreativeTypeSectionView>
                  </td>

                  <td className="border py-1 px-2 text-right">
                    <PriceSectionView
                      item={item}
                      currency={currency}
                    ></PriceSectionView>
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-2 text-right">
                    <div className="flex items-center justify-end gap-4">
                      <button
                        type="button"
                        className="text-blue-600 hover:underline"
                        onClick={() => handleEdit({ setting: item }, index)}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="text-blue-600 hover:underline"
                        onClick={() => handleOpenDuplicate(item, index)}
                      >
                        Duplicate
                      </button>
                      <button
                        type="button"
                        className="text-blue-600 hover:underline"
                        onClick={() => handleRemove(item, index)}
                      >
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

function CreativeTypeSectionView({ creativeType }) {
  const ct = creativeType
    ? GAM_CREATIVE_TYPES_NAME[creativeType]
    : "Any Creative Type";
  return (
    <div>
      <div className="text-xs font-medium text-gray-600">{ct}</div>
    </div>
  );
}

export default BrandPriceSettingsTable;

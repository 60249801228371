import React from "react";

import { formatMoneyWithCurrency } from "components/playground/home-dashboard-v1/ReportsHelper";
import { PRICE_TYPE, PRICE_TYPE_BY_NAME } from "constants/PriceType";

function PriceSectionView({ item, currency = "USD" }) {
  const { priceType, price } = item;

  const showPrice =
    priceType !== PRICE_TYPE_BY_NAME.LGO &&
    priceType !== PRICE_TYPE_BY_NAME.BRAND_SETTING;

  return (
    <div className="flex items-center justify-end gap-1">
      <div className="text-xs font-medium leading-none text-gray-600">
        {PRICE_TYPE[priceType]}
      </div>
      {showPrice && (
        <div className="font-mono">
          {formatMoneyWithCurrency({
            currency,
            value: price,
          })}
        </div>
      )}
    </div>
  );
}

export default PriceSectionView;

import React from "react";
import _ from "lodash";
import { FaExternalLinkAlt } from "react-icons/fa";

class TextAreaWithCsv extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      items: [],
    };

    this.onChange = this.onChange.bind(this);
    this.transformCsvToArray = this.transformCsvToArray.bind(this);
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
      items: this.transformCsvToArray(e.target.value),
    });

    this.props.onChange(this.transformCsvToArray(e.target.value));
  }

  transformCsvToArray(csv) {
    let string = this._transformFn(csv);
    let items = _.split(string, ",");
    items = _.uniq(_.compact(items));
    return items;
  }

  _transformFn(content) {
    const charArr = content.split("");
    let validContent = [];
    for (let c of charArr) {
      const lastC = _.last(validContent);
      switch (c) {
        case "\r": {
          continue;
        }

        case "\n":
        case " ": {
          // Ignore if we see continuous seperator
          if (lastC === undefined || lastC === ",") {
            continue;
          }

          c = ",";
          validContent.push(c);
          break;
        }

        default: {
          if (c >= "0" && c <= "9") {
            validContent.push(c);
          } else if (lastC && lastC != ",") {
            validContent.push(",");
          }
          break;
        }
      }
    }

    return validContent.join("");
  }

  render() {
    const {
      readOnly = false,
      disabled = false,
      countUnit = "items",
    } = this.props;
    const { value, items } = this.state;

    return (
      <div>
        <textarea
          className="border rounded w-full border-gray-400 p-2"
          value={value}
          onChange={this.onChange}
          rows={5}
          readOnly={readOnly}
          disabled={disabled}
        ></textarea>
        <div className="text-sm leading-none text-gray-700">
          {items.length} {countUnit}
        </div>
      </div>
    );
  }
}

export default TextAreaWithCsv;

import React from "react";
import _ from "lodash";

import NetworkInfoHeader from "components/ops-mgmt/common/NetworkInfoHeader";
import LoadingUI from "components/common/LoadingUI";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import ModalWrapper from "components/common/ModalWrapper";
import YiedlSetModal from "./YieldSetModal";
import AdxAccountContent from "./AdxAccountContent";

import { OnboardingAPI } from "apis";
import { MESSAGE } from "constants/Message";
import {
  thClass,
  tdClass,
  buttonConfirmClass,
  buttonActionClass,
} from "helpers/StyleClass.js";

const EVENTTYPE = {
  YIELD_SET: "yield-set",
  ADX_ACCOUNT: "adx-account",
};

const STATUS = {
  RUNNING: "RUNNING",
  PAUSED: "PAUSED",
};

class YieldSetViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isYieldSetModalOpen: false,
      isAdxAccountModalOpen: false,
      isLoading: false,
      event: null,
      msg: null,

      networkId: null,
      data: [],
      adxAccounts: [],
      selectedYieldSet: null,
    };

    this.handleEventStart = this.handleEventStart.bind(this);
    this.handleEventEnd = this.handleEventEnd.bind(this);
  }

  async componentDidMount() {
    const { networkId } = this.props.match.params;

    if (!networkId) {
      this.setState({ msg: ["Missing Network ID", MESSAGE.ERROR] });
      return;
    }

    this.setState({ networkId, isLoading: true });

    try {
      const result = await OnboardingAPI.getYieldSetsWithUnitSummary({
        networkId,
      });

      if (result) {
        this.setState({ data: result });
      }

      const adxAccounts = await OnboardingAPI.getAdxAccounts({ networkId });

      if (adxAccounts) {
        this.setState({ adxAccounts });
      }
    } catch (error) {
      this.setState({ msg: [error, MESSAGE.ERROR] });
    }

    this.setState({ isLoading: false });
  }

  handleEventStart(event, data = null) {
    switch (event) {
      case EVENTTYPE.YIELD_SET:
        this.setState({ isYieldSetModalOpen: true, selectedYieldSet: data });
        break;
      case EVENTTYPE.ADX_ACCOUNT:
        this.setState({ isAdxAccountModalOpen: true });
        break;
      default:
        break;
    }
  }

  handleEventEnd(event, data = null) {
    switch (event) {
      case EVENTTYPE.YIELD_SET:
        this.setState({ isYieldSetModalOpen: false });
        if (data) {
          this.updateYieldSet(data);
        }
        break;
      case EVENTTYPE.ADX_ACCOUNT:
        this.setState({ isAdxAccountModalOpen: false });
        if (data) {
          this.updateAdxAccount(data);
        }
        break;
      default:
        break;
    }
  }

  updateYieldSet(data) {
    const idx = _.findIndex(this.state.data, { yieldSetId: data.yieldSetId });
    if (idx < 0) {
      this.setState({ data: [...this.state.data, data] });
    } else {
      const newData = [...this.state.data];
      newData[idx] = {
        ...newData[idx],
        ...data,
      };
      this.setState({ data: newData });
    }
  }

  updateAdxAccount(data) {
    this.setState({ adxAccounts: [...this.state.adxAccounts, data] });
  }

  render() {
    const {
      isYieldSetModalOpen,
      isAdxAccountModalOpen,
      networkId,
      isLoading,
      data,
      adxAccounts,
      selectedYieldSet,
    } = this.state;

    return (
      <>
        {isLoading && (
          <div
            className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              zIndex: 101,
            }}
          >
            <LoadingUI iconOnly={true}></LoadingUI>
          </div>
        )}
        {networkId && (
          <NetworkInfoHeader networkId={networkId}></NetworkInfoHeader>
        )}
        <div className="min-h-screen bg-white px-12 py-8">
          <div className="mb-2 text-3xl font-extrabold">Onboard Yield Sets</div>
          <div className="relative">
            <div
              className="absolute right-0 flex gap-2"
              style={{ top: "-3.5rem" }}
            >
              <button
                className={buttonConfirmClass}
                onClick={() => this.handleEventStart(EVENTTYPE.YIELD_SET)}
              >
                Add Yield Set
              </button>
              <button
                className={buttonConfirmClass}
                onClick={() => this.handleEventStart(EVENTTYPE.ADX_ACCOUNT)}
              >
                Add Adx Account
              </button>
            </div>
            <table className="shadow divide-y min-w-full">
              <thead className="bg-gray-100">
                <tr>
                  <th className={`${thClass} w-32`}>Yield Set ID</th>
                  <th className={`${thClass} w-56`}>Name</th>
                  <th className={`${thClass} w-40`}>Adx Account</th>
                  <th className={`${thClass} w-32`}>Status</th>
                  <th className={thClass}>GAM Ad Units</th>
                  <th className={`${thClass} w-56`}>Create At</th>
                  <th className={`${thClass} w-56`}>Update At</th>
                  <th className={`${thClass}`} style={{ width: "18rem" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y bg-white text-sm">
                {data.map((item, idx) => (
                  <tr key={`${item.yieldSetId}-${idx}`}>
                    <td className={tdClass}>{item.yieldSetId}</td>
                    <td className={tdClass}>{item.name}</td>
                    <td className={tdClass}>{item.adxAccount.name}</td>
                    <td
                      className={`${tdClass} ${
                        item.status === STATUS.RUNNING
                          ? "text-green-500"
                          : "text-gray-500"
                      } font-semibold`}
                    >
                      {item.status}
                    </td>
                    <td className={tdClass}>
                      {item.adUnitCountByStatus && (
                        <div className="flex gap-2">
                          {_.keys(STATUS).map((status) => (
                            <StatusLabel
                              key={status}
                              status={status}
                              count={item.adUnitCountByStatus[status]}
                            ></StatusLabel>
                          ))}
                        </div>
                      )}
                    </td>
                    <td className={tdClass}>
                      <DateTimeFormatter
                        datetime={item.createdAt}
                        isLineBreak={true}
                      ></DateTimeFormatter>
                    </td>
                    <td className={tdClass}>
                      <DateTimeFormatter
                        datetime={item.updatedAt}
                        isLineBreak={true}
                      ></DateTimeFormatter>
                    </td>
                    <td className={tdClass}>
                      <button
                        className={buttonActionClass}
                        onClick={() =>
                          this.handleEventStart(EVENTTYPE.YIELD_SET, item)
                        }
                      >
                        Edit
                      </button>
                      <button
                        className={buttonActionClass}
                        onClick={() =>
                          window.open(
                            `${window.location.origin}/#/ops-mgmt/account/onboard-ad-units-tree-view?yieldSetId=${item.yieldSetId}&networkId=${networkId}`
                          )
                        }
                      >
                        Tree View
                      </button>
                      <button
                        className={buttonActionClass}
                        onClick={() =>
                          window.open(
                            `${window.location.origin}/#/ops-mgmt/account/onboard-ad-units?yieldSetId=${item.yieldSetId}&networkId=${networkId}`
                          )
                        }
                      >
                        Batch Onboard
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {isYieldSetModalOpen && (
          <YiedlSetModal
            networkId={networkId}
            adxAccounts={adxAccounts}
            selectedYieldSet={selectedYieldSet}
            onLoading={(isLoading) => this.setState({ isLoading })}
            onCancel={() => this.handleEventEnd(EVENTTYPE.YIELD_SET)}
            onClose={(data) => this.handleEventEnd(EVENTTYPE.YIELD_SET, data)}
            onAddAdxAccount={(data) =>
              this.handleEventEnd(EVENTTYPE.ADX_ACCOUNT, data)
            }
          ></YiedlSetModal>
        )}

        {isAdxAccountModalOpen && (
          <ModalWrapper
            isOpen={true}
            showCloseFooter={false}
            customOverlayStyle={{
              zIndex: 100,
            }}
            width="30%"
            height="40%"
          >
            <div className="flex h-full flex-col gap-3">
              <div className="border-b -mx-5 border-gray-400 px-5 pb-4 text-lg font-semibold">
                New Adx Account
              </div>

              <AdxAccountContent
                networkId={networkId}
                onLoading={(isLoading) => this.setState({ isLoading })}
                onCancel={() => this.handleEventEnd(EVENTTYPE.ADX_ACCOUNT)}
                onClose={(data) =>
                  this.handleEventEnd(EVENTTYPE.ADX_ACCOUNT, data)
                }
              ></AdxAccountContent>
            </div>
          </ModalWrapper>
        )}
      </>
    );
  }
}

export default YieldSetViewer;

class StatusLabel extends React.Component {
  render() {
    const { status, count = 0 } = this.props;
    return count > 0 ? (
      <div
        className={`rounded-full px-2 py-1 text-xs ${
          status === STATUS.RUNNING
            ? "border border-green-400 bg-green-100 text-green-700"
            : "border border-gray-500 bg-gray-100 text-gray-700"
        }`}
      >
        {_.startCase(status)} (<span className="font-semibold">{count}</span>)
      </div>
    ) : (
      ""
    );
  }
}

import _ from "lodash";

import {
  CHECKBOX_STATUS,
  CHILDREN_ONBOARD_STATUS,
  CHILDREN_SELECTION_STATUS,
} from "./constants";

export function onClickableAreaClicked(
  item,
  layerNum,
  onDrillDown,
  onSelectUnit
) {
  if (item.queryError) return;
  if (!item.hasChildren && item.isOnboarded) return;
  if (!item.hasChildren && !item.isCompatible) return;

  if (item.hasChildren) {
    return onDrillDown(item, layerNum);
  }

  if (item.isCompatible && !item.isOnboarded) {
    return onSelectUnit(item);
  }
}

export function formatCandidates(candidates, rootUnit) {
  if (!candidates || candidates.length === 0) {
    return [];
  }

  const unitsMapByFullPath = _.keyBy(candidates, (unit) => {
    return `${unit.parentPath === "/" ? "" : unit.parentPath}/${unit.name}`;
  });

  if (rootUnit) {
    unitsMapByFullPath[`/${rootUnit.name}`] = rootUnit;
  }

  for (const c in candidates) {
    const item = candidates[c];

    item.fullPath = `${item.parentPath === "/" ? "" : item.parentPath}/${
      item.name
    }`;

    // to calculate child unit selection status
    item.parentUnit = unitsMapByFullPath[item.parentPath];
    item.directChildUnits = [];
    item.selectedDirectCount = 0;
    item.selectedPartialDirectCount = 0;
    item.selectableDirectChildUnitCount = 0;

    // default checkbox status
    if (item.isOnboarded) {
      item.checkboxStatus = CHECKBOX_STATUS.ONBOARDED;
    } else if (!item.isCompatible) {
      item.checkboxStatus = CHECKBOX_STATUS.NOT_COMPATIBLE;
    } else {
      item.checkboxStatus = CHECKBOX_STATUS.NOT_SELECTED;
    }

    // child unit onboarding status
    let childrenOnboardStatus = CHILDREN_ONBOARD_STATUS.NONE_ONBOARDED;
    if (item.numOnboardableChildren === 0 && item.numOnboardedChildren === 0) {
      childrenOnboardStatus = CHILDREN_ONBOARD_STATUS.ALL_NOT_COMPATIBLE;
    }

    if (item.numOnboardableChildren === 0 && item.numOnboardedChildren > 0) {
      childrenOnboardStatus = CHILDREN_ONBOARD_STATUS.ALL_ONBOARDED;
    }

    if (item.numOnboardableChildren > 0 && item.numOnboardedChildren > 0) {
      childrenOnboardStatus = CHILDREN_ONBOARD_STATUS.SOME_ONBOARDED;
    }
    item.childrenOnboardStatus = childrenOnboardStatus;

    // child unit selection status
    // will be changed when the unit's child units have been selected
    item.childrenSelectionStatus = CHILDREN_SELECTION_STATUS.NONE_SELECTED;

    // Portfolio
    const {
      gptReqPercentage,
      ampReqPercentage,
      dailyCompatibleAvgReq,
      othersReqPercentage,
      dailyTotalAvg,
      gptReq,
      ampReq,
      othersReq,
    } = _formatPortfolio(item);

    item.gptReqPercentage = gptReqPercentage;
    item.ampReqPercentage = ampReqPercentage;
    item.dailyCompatibleAvgReq = dailyCompatibleAvgReq;

    // used in tooltip
    item.othersReqPercentage = othersReqPercentage;
    item.dailyTotalAvg = dailyTotalAvg;
    item.gptReq = gptReq;
    item.ampReq = ampReq;
    item.othersReq = othersReq;
  }

  for (let c of candidates) {
    if (c.parentUnit) {
      c.parentUnit.directChildUnits.push(c);
    }
  }

  for (let c of candidates) {
    for (let dc of c.directChildUnits) {
      // is parent
      // or
      // if leaf
      // isCompatible
      // is not onboarded

      // no longer matters if its parent or leaf
      // isCompatible + !isOnboarded
      // let isSelectableParent = dc.hasChildren && dc.numOnboardableChildren > 0;
      // let isSelectableLeaf =
      //   !dc.hasChildren && dc.isCompatible && !dc.isOnboarded;
      let isSelectableUnit = dc.isCompatible && !dc.isOnboarded;

      // if (isSelectableParent || isSelectableLeaf) {
      if (dc.numOnboardableChildren > 0 || isSelectableUnit) {
        ++c.selectableDirectChildUnitCount;
      }
    }
  }

  if (rootUnit) {
    for (let dc of rootUnit.directChildUnits) {
      // let isSelectableParent = dc.hasChildren && dc.numOnboardableChildren > 0;
      // let isSelectableLeaf =
      //   !dc.hasChildren && dc.isCompatible && !dc.isOnboarded;
      let isSelectableUnit = dc.isCompatible && !dc.isOnboarded;

      // if (isSelectableParent || isSelectableLeaf) {
      if (dc.numOnboardableChildren > 0 || isSelectableUnit) {
        ++rootUnit.selectableDirectChildUnitCount;
      }
    }
  }

  // Sort candidates!
  candidates = _.orderBy(
    candidates,
    [
      "hasChildren",
      "isCompatible",
      "childrenOnboardStatus",
      "dailyCompatibleAvgReq",
      "isOnboarded",
    ],
    ["desc", "desc", "desc", "desc", "asc"]
  );

  return candidates;
}

// targetUnit can be leaf or parent
export function _recursivelyAddParentSelectedDirectCount(targetUnit) {
  let currentUnit = targetUnit;
  let fullDelta =
    currentUnit.selectedDirectCount ===
    currentUnit.selectableDirectChildUnitCount
      ? 1
      : 0;
  let partialDelta = 0;
  while (currentUnit.parentUnit && (fullDelta !== 0 || partialDelta !== 0)) {
    const pu = currentUnit.parentUnit;
    const oldSelectedDirectCount = pu.selectedDirectCount;
    const oldSelectedPartialCount = pu.selectedPartialDirectCount;

    pu.selectedDirectCount += fullDelta;
    pu.selectedPartialDirectCount += partialDelta;

    pu.childrenSelectionStatus = _getUnitChildSelectionStatus(pu);

    // <1, 0> ==> simply we have a full child
    // <0, 1> ==> we have a partial child
    // <1, -1> ==> we have a child changing from partial to full
    if (fullDelta === 1) {
      // full after add 1
      if (oldSelectedDirectCount === pu.selectableDirectChildUnitCount - 1) {
        fullDelta = 1;

        // Previous, we report partial so we should subtract it.
        if (pu.selectableDirectChildUnitCount > 1) {
          partialDelta = -1;
        }
        // No need to subtract back
        else {
          partialDelta = 0;
        }
      }
      // becoming partial full
      else if (oldSelectedDirectCount === 0 && oldSelectedPartialCount === 0) {
        fullDelta = 0;
        partialDelta = 1;
      } else {
        fullDelta = 0;
        partialDelta = 0;
      }
    } else if (partialDelta === 1) {
      if (oldSelectedPartialCount === 0 && oldSelectedDirectCount === 0) {
        fullDelta = 0;
        partialDelta = 1;
      } else {
        fullDelta = partialDelta = 0;
      }
    } else {
      fullDelta = partialDelta = 0;
    }

    currentUnit = pu;
  }
}

// targetUnit can be leaf or parent
export function _recursivelySubtractParentSelectedDirectCount(targetUnit) {
  let currentUnit = targetUnit;
  let fullDelta = -1;
  let partialDelta = 0;

  while (currentUnit.parentUnit && (fullDelta !== 0 || partialDelta !== 0)) {
    const pu = currentUnit.parentUnit;
    const oldSelectedDirectCount = pu.selectedDirectCount;
    const oldSelectedPartialCount = pu.selectedPartialDirectCount;

    pu.selectedDirectCount += fullDelta;
    pu.selectedPartialDirectCount += partialDelta;

    pu.childrenSelectionStatus = _getUnitChildSelectionStatus(pu);

    // <-1, 0>
    // <-1, 1>
    // <0, -1>
    if (fullDelta === -1) {
      if (oldSelectedDirectCount === pu.selectableDirectChildUnitCount) {
        // From full to partial <-1, 1>
        if (pu.selectableDirectChildUnitCount > 1) {
          fullDelta = -1;
          partialDelta = 1;
        }
        // From full to empty <-1, 0>
        else {
          fullDelta = -1;
          partialDelta = 0;
        }
      }
      // From partial to empty
      else if (
        oldSelectedDirectCount === 1 &&
        oldSelectedPartialCount === 0 &&
        partialDelta === 0
      ) {
        fullDelta = 0;
        partialDelta = -1;
      } else {
        fullDelta = partialDelta = 0;
      }
    } else if (partialDelta === -1) {
      // Partial to empty
      if (oldSelectedPartialCount === 1 && oldSelectedDirectCount === 0) {
        fullDelta = 0;
        partialDelta = -1;
      }
      // Partial to partial => No change!
      else {
        fullDelta = partialDelta = 0;
      }
    } else {
      fullDelta = partialDelta = 0;
    }

    currentUnit = pu;
  }
}

export function _findRootUnit(targetUnit) {
  let currentUnit = targetUnit;
  while (currentUnit.parentUnit) {
    currentUnit = currentUnit.parentUnit;
  }
  return currentUnit;
}

export function _getUnitFullPath(unit) {
  return `${unit.parentPath === "/" ? "" : unit.parentPath}/${unit.name}`;
}

function _getUnitChildSelectionStatus(pu) {
  // All selected:
  // 1. All direct child units are selected
  // => selectedDirectCount > 0 && selectableDirectChildUnitCount === selectedDirectCount
  // 2. All child units are selected
  // => selectedDirectCount > 0 && numOnboardableChildren === selectedDirectCount

  // Some selected:
  // 1. Some direct child units selected
  // => selectedDirectCount > 0
  // 2. Some child units selected
  // => selectedPartialDirectCount > 0

  // None selected:
  // => selectedDirectCount === 0 && selectedPartialDirectCount === 0

  if (pu.selectedDirectCount > 0) {
    if (pu.selectableDirectChildUnitCount === pu.selectedDirectCount) {
      return CHILDREN_SELECTION_STATUS.ALL_SELECTED;
    }

    if (pu.numOnboardableChildren === pu.selectedDirectCount) {
      return CHILDREN_SELECTION_STATUS.ALL_SELECTED;
    }

    return CHILDREN_SELECTION_STATUS.SOME_SELECTED;
  }

  if (pu.selectedPartialDirectCount > 0) {
    return CHILDREN_SELECTION_STATUS.SOME_SELECTED;
  }

  return CHILDREN_SELECTION_STATUS.NONE_SELECTED;
}

// const REQ_TYPE = {
//   0: "Unknown",
//   1: "GPT",
//   2: "AMP",
//   3: "GPT Lite",
//   4: "APP",
//   5: "Video",
// };
function _formatPortfolio(item) {
  // Important! portfolio schema is fixed!
  let reqStatPortfolio = item.portfolio[0];
  let gptReq = 0;
  let ampReq = 0;
  let othersReq = 0;
  let dailyCompatibleAvgReq = 0;
  let dailyTotalAvg = 0;

  for (let i = 0; i < reqStatPortfolio.length; ++i) {
    let reqPortfolio = reqStatPortfolio[i];
    let reqType = reqPortfolio[0];
    let isCompatible = reqPortfolio[1];
    let dailyReq = reqPortfolio[2];

    dailyTotalAvg += dailyReq;
    if (isCompatible) {
      dailyCompatibleAvgReq += dailyReq;
    }

    if (reqType === 1) {
      gptReq += dailyReq;
    } else if (reqType === 2) {
      ampReq += dailyReq;
    } else {
      othersReq += dailyReq;
    }
  }
  let gptReqPercentage = dailyTotalAvg > 0 ? (gptReq / dailyTotalAvg) * 100 : 0;
  let ampReqPercentage = dailyTotalAvg > 0 ? (ampReq / dailyTotalAvg) * 100 : 0;
  let othersReqPercentage =
    dailyTotalAvg > 0 ? (othersReq / dailyTotalAvg) * 100 : 0;

  // if (!item.isCompatible) {
  //   gptReqPercentage = 0;
  //   ampReqPercentage = 0;
  //   dailyCompatibleAvgReq = 0;
  // }

  return {
    gptReqPercentage,
    ampReqPercentage,
    dailyCompatibleAvgReq,
    othersReqPercentage,
    dailyTotalAvg,
    gptReq,
    ampReq,
    othersReq,
  };
}

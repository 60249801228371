import _ from "lodash";

import { api, fetchRespJson } from "apis/base";

export default {
  // platform wise apis
  getCompetitorSummary,
};

async function getCompetitorSummary() {
  try {
    let apiUrl = `${api}/competitor-summary`;
    return await fetchRespJson(apiUrl);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

import React from "react";
import _ from "lodash";
import PopupWrapper from "../../../common/PopupWrapper";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";
import UprTargetingsView from "../custom-upr/UprTargetingsView";

class CustomTargetingPopover extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      networkId,
      triggerElement,
      customTargeting,
      title,
      units,
      virtualPlacements,
    } = this.props;

    const extraTargetingsKeys = customTargeting.extraTargetings
      ? Object.keys(customTargeting.extraTargetings)
      : [];
    return (
      <PopupWrapper
        place="bottom"
        triggerType="click"
        hideArrow={false}
        triggerElement={triggerElement}
        onOpen={this.props.onOpen}
        onClose={this.props.onClose}
        popupElement={
          <div className="shadow border p-4 text-sm" style={{ width: "600px" }}>
            <div className="border-b mb-2 text-lg">{title}</div>

            <div style={{ maxHeight: "400px", overflow: "auto" }}>
              <div className="text-gray-800">
                Name:{" "}
                <span className="font-semibold">{customTargeting.name}</span>
              </div>
              <div className="mt-1 flex items-center gap-1 text-gray-800">
                ID: <div className="font-semibold">{customTargeting.id}</div>
                <div className="text-gray-500">
                  <ClickToCopyWrapper
                    copyText={customTargeting.id}
                  ></ClickToCopyWrapper>
                </div>
              </div>
              <div className="mt-1 text-gray-800">
                Values({customTargeting.values.length}):
              </div>
              <ValuesTable items={customTargeting.values}></ValuesTable>

              {customTargeting.extraTargetings &&
                extraTargetingsKeys.length > 0 && (
                  <div>
                    <div className="mt-1 text-gray-800">
                      Extra Targetings(
                      {extraTargetingsKeys.length}
                      ):
                    </div>
                    <UprTargetingsView
                      networkId={networkId}
                      targetingSection={customTargeting.extraTargetings}
                      units={units}
                      virtualPlacements={virtualPlacements}
                    ></UprTargetingsView>
                  </div>
                )}
            </div>
          </div>
        }
      ></PopupWrapper>
    );
  }
}

function ValuesTable({ items }) {
  return (
    <table className="border table w-full text-sm">
      <thead className="border-b bg-gray-200 text-xs text-blue-800">
        <tr>
          <th className="border px-2 py-1 text-right">ID</th>
          <th className="border px-2 py-1 text-right ">Name</th>
          <th className="border px-2 py-1 text-right ">Price</th>
        </tr>
      </thead>
      <tbody className="bg-white  text-gray-900">
        {items.map((item) => {
          return (
            <tr key={item.id} className="border-b">
              <td className="border px-2 py-1 text-right">
                <div className="flex items-center justify-end">
                  {item.id}
                  <div className="text-gray-500">
                    <ClickToCopyWrapper copyText={item.id}></ClickToCopyWrapper>
                  </div>
                </div>
              </td>
              <td className="border px-2 py-1 text-right">
                <div className="flex items-center justify-end">
                  {item.name}
                  <div className="text-gray-500">
                    <ClickToCopyWrapper
                      copyText={item.name}
                    ></ClickToCopyWrapper>
                  </div>
                </div>
              </td>
              <td className="border px-2 py-1 text-right font-mono">
                {item.price}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default CustomTargetingPopover;

import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { DateRange } from "react-date-range";
import { DATE_RANGES, MIN_DATE, getDateRangeByType } from "./DateHelper";

class DateRangePopover extends React.PureComponent {
  constructor(props) {
    super(props);

    const { startDate, endDate } = this.props;

    this.state = {
      startDate,
      endDate,
    };

    this.onDateRangeChange = this.onDateRangeChange.bind(this);
    this.handleSelectDateRange = this.handleSelectDateRange.bind(this);
    this.handleApplyDateRangeFilter =
      this.handleApplyDateRangeFilter.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  onDateRangeChange(ranges) {
    const { startDate, endDate } = ranges.dateRange;

    this.setState({
      startDate,
      endDate,
    });
  }

  handleSelectDateRange(dateRangeType) {
    const { startDate, endDate } = getDateRangeByType(dateRangeType);

    this.setState({
      startDate,
      endDate,
    });
  }

  handleApplyDateRangeFilter() {
    const { startDate, endDate } = this.state;
    this.props.handleDateRangeFilterChanged({ startDate, endDate });
    this.props.handleClosePopover();
  }

  handleCancel() {
    // close and nothing happened
    if (this.props.handleCancel) {
      this.props.handleCancel();
    } else {
      this.props.handleClosePopover();
    }
  }

  render() {
    const { startDate, endDate } = this.state;
    const { hidePresets } = this.props;

    return (
      <>
        <div className="p-4 shadow-2xl">
          <div className="flex">
            {!hidePresets && (
              <div>
                {(this.props.dateRanges || DATE_RANGES).map((dateRangeType) => {
                  const dateRangeTitle = _.capitalize(
                    _.lowerCase(dateRangeType)
                  );
                  return (
                    <div key={dateRangeType}>
                      <button
                        type="button"
                        onClick={() =>
                          this.handleSelectDateRange(dateRangeType)
                        }
                        className="border rounded mb-2 mr-3 inline-flex cursor-pointer bg-white px-3 py-1 text-sm text-gray-700 hover:border-blue-400"
                      >
                        {dateRangeTitle}
                      </button>
                    </div>
                  );
                })}
              </div>
            )}

            <div>
              <DateRange
                editableDateInputs={true}
                onChange={this.onDateRangeChange}
                moveRangeOnFirstSelection={false}
                months={this.props.months || 2}
                dateDisplayFormat={"yyyy/MM/dd"}
                direction="horizontal"
                scroll={{ enabled: true }}
                maxDate={moment().toDate()}
                minDate={this.props.minDate || MIN_DATE}
                ranges={[
                  {
                    startDate: moment(startDate).toDate(),
                    endDate: moment(endDate).toDate(),
                    key: "dateRange",
                  },
                ]}
              />
            </div>
          </div>

          <div className="border-t flex justify-end pt-4">
            <button
              type="button"
              className="px-6 text-blue-500 hover:text-blue-600"
              onClick={this.handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              className="rounded inline-flex justify-center bg-blue-500 px-10 py-2 text-sm text-white shadow-sm hover:bg-blue-600"
              onClick={this.handleApplyDateRangeFilter}
            >
              Apply
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default DateRangePopover;

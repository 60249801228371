import React from "react";
import _ from "lodash";
import AdSizesFormatter from "./AdSizesFormatter";
import SetOfAdSizesFormatter from "./SetOfAdSizesFormatter";
import BatchOfSegmentsFormatter from "./BatchOfSegmentsFormatter";
import CstPredictScheduleFormatter from "./CstPredictScheduleFormatter";
import CountrySelector from "components/common/CountrySelector";
import { CHANGETYPE, showEmpty } from "helpers/Compare";

class ConfigObjectView extends React.PureComponent {
  render() {
    function showValue(value) {
      if (_.isArray(value)) {
        return <ConfigArrayView value={value}></ConfigArrayView>;
      }
      if (_.isBoolean(value)) {
        return value ? "true" : "false";
      }
      if (_.isPlainObject(value)) {
        return <ConfigObjectView value={value}></ConfigObjectView>;
      }
      return value;
    }

    const { value } = this.props;
    const labelClass = "leading-tight";
    const valueClass = "leading-tight text-gray-700";
    return (
      <div className="border rounded flex flex-col gap-0 border-gray-400 p-2">
        {value &&
          Object.keys(value).map((key) => {
            return (
              <div key={key}>
                <div className={labelClass}>{_.startCase(key)}:</div>
                <div className={valueClass}>{showValue(value[key])}</div>
              </div>
            );
          })}
        {this.props.children}
      </div>
    );
  }
}

class ConfigArrayView extends React.PureComponent {
  render() {
    function showValue(value) {
      if (value.length !== 0 && value.every((item) => _.isArray(item))) {
        return value.map((item, i) => (
          <ConfigArrayView value={item} key={i}></ConfigArrayView>
        ));
      } else if (
        value.length !== 0 &&
        value.every((item) => _.isPlainObject(item))
      ) {
        return value.map((item, idx) => (
          <ConfigObjectView value={item} key={idx}></ConfigObjectView>
        ));
      }
      return <div className="font-mono font-normal">[{value.join(", ")}]</div>;
    }

    const { value } = this.props;

    return (
      <div className="flex flex-col gap-1">
        {showValue(value)}
        {value.length > 1 && (
          <div className="text-sm">({value.length} items)</div>
        )}
      </div>
    );
  }
}

class ConfigValueReadonly extends React.PureComponent {
  render() {
    const {
      valueType,
      configValue,
      //valueSchema
    } = this.props;

    if (valueType === "boolean") {
      return configValue === true ? "true" : "false";
    }

    // Publisher Features
    if (valueType === "countries") {
      if (
        configValue === "-" ||
        configValue === "None" ||
        !configValue ||
        _.isEmpty(configValue)
      ) {
        return showEmpty(configValue);
      } else {
        return (
          <CountrySelector
            selectedItems={configValue}
            isReadonly={true}
            showCountryId={true}
          ></CountrySelector>
        );
      }
    }

    // Auto Pilot Config
    if (valueType === "ad_sizes") {
      return (
        <AdSizesFormatter
          adSizes={configValue}
          showRemoveButton={false}
        ></AdSizesFormatter>
      );
    }

    // Auto Pilot Config
    if (valueType === "ad_sizes_layers") {
      return (
        <AdSizesFormatter
          adSizes={configValue}
          showRemoveButton={false}
        ></AdSizesFormatter>
      );
    }

    // Auto Pilot Config
    if (valueType === "sets_of_ad_sizes") {
      return (
        <SetOfAdSizesFormatter
          setsOfAdSizes={configValue}
          showActionButtons={false}
        ></SetOfAdSizesFormatter>
      );
    }

    if (valueType === "batch_of_segments") {
      return (
        <BatchOfSegmentsFormatter
          includeBatchKey={true}
          batchSegments={configValue}
          showActionButtons={false}
        ></BatchOfSegmentsFormatter>
      );
    }

    // Auto Pilot Config
    if (valueType === "upr_prices") {
      if (!configValue || _.isEmpty(configValue)) return showEmpty(configValue);

      if (_.isArray(configValue)) {
        return (
          <div className="text-sm">
            {configValue &&
              configValue.map((p, i) => {
                return (
                  <div key={i}>
                    Price: {p.price}, Weight: {p.weight}
                  </div>
                );
              })}
          </div>
        );
      }
    }

    if (valueType === "price_groups") {
      if (!configValue || _.isEmpty(configValue)) return showEmpty(configValue);

      if (_.isArray(configValue)) {
        return (
          <div className="text-sm">
            {configValue &&
              configValue.map((p, i) => {
                return (
                  <div key={i}>
                    inclusiveMin: {p.inclusiveMin}, exclusiveMax:{" "}
                    {p.exclusiveMax}, delta: {p.delta}
                  </div>
                );
              })}
          </div>
        );
      }
    }

    // Auto Pilot Config
    if (valueType === "cst_algorithms") {
      if (!configValue || _.isEmpty(configValue)) return showEmpty(configValue);

      if (_.isArray(configValue)) {
        const labelClass = "leading-tight";
        const valueClass = "leading-tight text-gray-700";
        return (
          <div className="mb-1 text-sm">
            {configValue &&
              configValue.map((item, i) => {
                return (
                  <div key={i} className="border rounded border-gray-400 p-2">
                    <div className="mb-2">
                      <div className={labelClass}>Name:</div>
                      <div className={valueClass}>{item.name}</div>
                    </div>

                    <div className="mb-2">
                      <div className={labelClass}>Algorithm:</div>
                      <div className={valueClass}>{item.algorithm}</div>
                    </div>

                    <div>
                      <div className={labelClass}>Params:</div>
                      <div className={valueClass}>
                        <div>{JSON.stringify(item.params, null, 4)}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        );
      }
    }

    // Auto Pilot Config
    if (valueType === "cst_predict_schedule") {
      // return JSON.stringify(configValue);
      return (
        <CstPredictScheduleFormatter
          configValue={configValue}
        ></CstPredictScheduleFormatter>
      );
    }

    // Network Features
    if (valueType === "rsa_object") {
      if (!configValue || _.isEmpty(configValue)) {
        return showEmpty(configValue);
      }

      return (
        <div>
          <div>Enabled: {configValue.enabled ? "true" : "false"}</div>
          <div>Reportable Type: {configValue.reportableType}</div>
        </div>
      );
    }

    // Network Features
    if (valueType === "cst_nonbillable_object") {
      if (!configValue || _.isEmpty(configValue)) {
        return showEmpty(configValue);
      }

      return (
        <div>
          <div>Type: {configValue.type}</div>
          <div>IDs: {configValue.ids.join(",")}</div>
        </div>
      );
    }

    // Auto Pilot Config
    if (
      valueType === "client_refresh_labels" ||
      valueType === "device_categories"
    ) {
      if (_.isArray(configValue)) {
        return _.isEmpty(configValue)
          ? showEmpty(configValue)
          : configValue.join(", ");
      }
    }

    // Auto Pilot Config
    if (valueType === "demand_types") {
      if (typeof configValue === "string") {
        return configValue;
      }

      if (!configValue) return showEmpty(configValue);

      if (_.isArray(configValue)) {
        return _.isEmpty(configValue)
          ? showEmpty(configValue)
          : configValue.join(", ");
      }
    }

    // CST config
    if (valueType === "domain_specific_managed_ratios") {
      if (!configValue || _.isEmpty(configValue)) return showEmpty(configValue);

      if (_.isArray(configValue)) {
        return (
          <div className="mb-1 text-sm">
            {configValue &&
              configValue.map((item, i) => {
                return (
                  <ConfigObjectView value={item} key={i}></ConfigObjectView>
                );
              })}
          </div>
        );
      }
    }

    // CST config
    if (valueType === "benchmarkValues" || valueType === "managedValues") {
      if (!configValue) return showEmpty(configValue);

      if (_.isArray(configValue) && configValue.length > 0) {
        return (
          <div className="mb-1 text-sm">
            {configValue &&
              configValue.map((item, i) => {
                return (
                  <ConfigObjectView value={item} key={i}></ConfigObjectView>
                );
              })}
          </div>
        );
      }

      return showEmpty(configValue);
    }

    // CST config
    if (valueType === "ab_test_traffic_allocation") {
      if (!configValue || !_.isObject(configValue))
        return showEmpty(configValue);

      if (_.isObject(configValue)) {
        return (
          <div className="mb-1 text-sm">
            <ConfigObjectView value={configValue}></ConfigObjectView>
          </div>
        );
      }
    }

    // CST config
    if (valueType === "inventory_object") {
      if (!configValue || !_.isObject(configValue))
        return showEmpty(configValue);

      if (_.isObject(configValue)) {
        const filterValue = _.omit(configValue, "overrides");

        return (
          <div className="mb-1 text-sm">
            {configValue && (
              <ConfigObjectView value={filterValue}>
                {configValue["overrides"] && (
                  <div>
                    <div>{_.startCase("overrides")}:</div>
                    {_.isArray(configValue["overrides"])
                      ? configValue["overrides"].map((item, i) => {
                          return (
                            <ConfigObjectView
                              value={item}
                              key={i}
                            ></ConfigObjectView>
                          );
                        })
                      : configValue["overrides"]}
                  </div>
                )}
              </ConfigObjectView>
            )}
          </div>
        );
      }
    }

    // CST config
    if (valueType === "array_array_integer") {
      if (!_.isArray(configValue)) return showEmpty(configValue);

      if (_.isArray(configValue) && _.isEmpty(configValue))
        return showEmpty(configValue);

      return configValue.map((list, i) => {
        return (
          <div key={i}>
            <div className="font-mono">[{list.join(", ")}]</div>
          </div>
        );
      });
    }

    if (
      valueType === "integer" ||
      valueType === "float" ||
      valueType === "integer_enum"
    ) {
      // careful of 0
      return _.isUndefined(configValue) ? showEmpty(configValue) : configValue;
    }

    if (
      valueType === "array_string" ||
      valueType === "array_integer" ||
      valueType === "array_float"
    ) {
      if (_.isArray(configValue) && _.isEmpty(configValue))
        return showEmpty(configValue);

      return _.isArray(configValue) ? (
        <div>
          <div className="font-mono font-normal">{configValue.join(", ")}</div>
          {configValue.length > 1 && (
            <div className="text-sm">({configValue.length} items)</div>
          )}
        </div>
      ) : (
        configValue || showEmpty(configValue)
      );
    }

    if (valueType === "integration_type") {
      return (
        <>
          <div className="divide-y flex flex-col gap-1 divide-gray-400">
            {configValue.map((item, idx) => (
              <div key={`${item.type}-${idx}`} className="pr-6 pt-1">
                <div>
                  <span className="text-gray-500">Type:</span>{" "}
                  <span className="font-semibold">{item.type}</span>
                </div>
                <div>
                  <span className="text-gray-500">Price Format:</span>{" "}
                  <span className="font-semibold">{item.priceFormat}</span>
                </div>
                {item.currency && (
                  <div>
                    <span className="text-gray-500">Currency:</span>{" "}
                    <span className="font-semibold">{item.currency}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
          {configValue.length > 1 && <div>({configValue.length} items)</div>}
        </>
      );
    }

    if (
      valueType === CHANGETYPE.ARRAY_CHANGED ||
      valueType === CHANGETYPE.ARRAY_ADDED ||
      valueType === CHANGETYPE.ARRAY_REMOVED
    ) {
      if (!configValue) return showEmpty(configValue);
      return _.isArray(configValue) && _.isEmpty(configValue) ? (
        "[]"
      ) : (
        <ConfigArrayView value={configValue}></ConfigArrayView>
      );
    }

    if (
      valueType === CHANGETYPE.VALUE_CHANGED ||
      valueType === CHANGETYPE.VALUE_ADDED ||
      valueType === CHANGETYPE.VALUE_REMOVED ||
      valueType === CHANGETYPE.TYPE_DIFFERENT
    ) {
      if (!configValue) return showEmpty(configValue);
      if (_.isPlainObject(configValue))
        return <ConfigObjectView value={configValue}></ConfigObjectView>;
      return JSON.stringify(configValue);
    }
    // "string_enum"
    return configValue || showEmpty(configValue);
  }
}

export default ConfigValueReadonly;

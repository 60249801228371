import _ from "lodash";

export function searchIncludes(target, inputValue) {
  return target && _.toLower(target).includes(_.toLower(inputValue));
}

export function addChartBgSection(chart) {
  const xAxis = chart.xAxis[0];

  const startDate = new Date(xAxis.min);
  const endDate = new Date(xAxis.max);

  // Clear previous bg
  if (chart.customBg) {
    chart.customBg.forEach((bg) => bg.destroy());
  }
  chart.customBg = [];

  // Setup current to startDate of the week
  let current = new Date(startDate);
  const currentToSaturday = (current.getUTCDay() + 1) % 7;
  current.setUTCDate(current.getUTCDate() - currentToSaturday);
  let isGray = false;

  while (current < endDate) {
    if (isGray) {
      // From Sat. noon to next Sat. noon
      const startOfWeek = new Date(current);
      const daysToSaturday = (startOfWeek.getUTCDay() + 1) % 7;
      startOfWeek.setUTCDate(startOfWeek.getUTCDate() - daysToSaturday);
      startOfWeek.setUTCHours(12, 0, 0, 0);

      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setUTCDate(endOfWeek.getUTCDate() + 7);

      let xMin = xAxis.toPixels(startOfWeek.getTime());
      let xMax = xAxis.toPixels(endOfWeek.getTime());

      // Avoid drawing outside plot
      xMin = Math.max(xMin, chart.plotLeft);
      xMax = Math.min(xMax, chart.plotLeft + chart.plotWidth);

      const rect = chart.renderer
        .rect(xMin, chart.plotTop, xMax - xMin, chart.plotHeight)
        .attr({
          fill: "#f2f2f2",
          zIndex: -1,
        })
        .add();
      chart.customBg.push(rect);
    }

    isGray = !isGray;
    current.setUTCDate(current.getUTCDate() + 7);
  }
}

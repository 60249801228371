export const ONBOARD_LIMIT_UNIT_COUNT = 1000;
export const ONBOARD_LIMIT_EXCEED_ERROR_MESSAGE = `Sorry, we are unable to onboard more than ${ONBOARD_LIMIT_UNIT_COUNT} ad units at a time.`;

export const CHILDREN_ONBOARD_STATUS = {
  ALL_NOT_COMPATIBLE: "ALL_NOT_COMPATIBLE",
  ALL_ONBOARDED: "ALL_ONBOARDED",
  SOME_ONBOARDED: "SOME_ONBOARDED",
};

export const CHILDREN_SELECTION_STATUS = {
  SOME_SELECTED: "SOME_SELECTED",
  ALL_SELECTED: "ALL_SELECTED",
  NONE_SELECTED: "NONE_SELECTED",
};

export const CHECKBOX_STATUS = {
  ONBOARDED: "ONBOARDED",
  NOT_COMPATIBLE: "NOT_COMPATIBLE",
  SELECTED: "SELECTED",
  NOT_SELECTED: "NOT_SELECTED",
};
